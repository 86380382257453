/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: RedeemModel
 * Description: This component represents a modal for redeeming gift cards. Users can view information about the gift card, such as name, value, expiry date, and remaining value after redemption. The modal provides options to use the gift card, updating the remaining value accordingly.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the redeem gift card model is closed.
 * - onSave: (data: any) => void - Callback function triggered when redeem gift card data is saved.
 * - data?: any - Optional data for the redeem gift card.
 * - appointmentData?: any - Appointment data.
 *
 */

import { LoaderOverlay, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { useState } from 'react';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { palette } from 'styled/common';

const RedeemModel = ({
    onClose,
    onSave,
    data,
    appointmentData,
}: {
    onClose(): void;
    onSave(data: any): void;
    data?: any;
    appointmentData?: any;
}) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState<any>(false);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    //  redeem coupon
    const useGiftCard = () => {
        setLoading(true);
        api.post(`/gift-card/use-coupen`, { redeem: data?.redeem })
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t(`Redeem successfully`));
                onSave(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    openSnackbar(response?.message);
                }
            });
    };
    let totalValueDrawer: any =
        appointmentData?.total?.length > 1
            ? appointmentData?.total?.substring(1, appointmentData?.total?.length)
            : 0;
    const calculateInPercentage = (totalValueDrawer / 100) * parseInt(data?.percentage);
    const afterPercentageValue = totalValueDrawer - calculateInPercentage;

    return (
        <ModelBody>
            {loading && <LoaderOverlay />}
            <ModelHeading>
                <Heading>{t('Gift Card Info')}</Heading>
                <Cover onClick={() => !loading && onClose()}>
                    <i className="fal fa-times"></i>
                </Cover>
            </ModelHeading>
            <ModelHead></ModelHead>
            {data === '' ? (
                ''
            ) : (
                <>
                    <div>
                        <CustomersBody>
                            <SearchItemWrapper>
                                <Title>{t('Name')}</Title>
                                <Title fw="light">{data.name}</Title>
                            </SearchItemWrapper>
                            {data?.in_percentage === true ? (
                                <SearchItemWrapper>
                                    <Title>{t('Percentage Value')}</Title>
                                    <Title fw="light">{parseInt(data?.percentage)}%</Title>
                                </SearchItemWrapper>
                            ) : (
                                <SearchItemWrapper>
                                    <Title>{t('Price')}</Title>
                                    <Title fw="light">{_userData?.currency + data?.price}</Title>
                                </SearchItemWrapper>
                            )}
                            <SearchItemWrapper>
                                <Title>{t('Expiry Date')}</Title>
                                <Title fw="light">
                                    {moment(data?.expiry_date).format('YYYY-MM-DD')}
                                </Title>
                            </SearchItemWrapper>
                            <HeaderContainer>
                                <Header>{t('After Use Gift Card')}</Header>
                            </HeaderContainer>
                            <SearchItemWrapper>
                                <Title>{t('Total Price')}</Title>
                                <Title fw="light">{appointmentData?.total}</Title>
                            </SearchItemWrapper>
                            {data?.in_percentage === true ? (
                                <SearchItemWrapper>
                                    <Title>{t('Remaining Price')}</Title>
                                    <Title fw="light">
                                        {_userData?.currency + afterPercentageValue?.toFixed(2)}
                                    </Title>
                                </SearchItemWrapper>
                            ) : (
                                <SearchItemWrapper>
                                    <Title>{t('Remaining Price')}</Title>
                                    <Title fw="light">
                                        {_userData?.currency +
                                            (totalValueDrawer - data?.price)?.toFixed(2)}
                                    </Title>
                                </SearchItemWrapper>
                            )}
                        </CustomersBody>
                    </div>
                    <Footer>
                        <div></div>
                        <Button
                            bgtype={'secondary'}
                            ifClicked={useGiftCard}
                            label={t('Use Gift Card')}></Button>
                    </Footer>
                </>
            )}
        </ModelBody>
    );
};
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

const CustomersBody = styled.div<any>`
    flex: 0 0 25rem;
    background-color: ${palette.white};
    margin-left: 0rem;
    overflow-y: auto;
    min-height: ${({ height }) => (height ? `58vh` : '71vh')};
`;

const SearchItemWrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    padding: 12px 0;
    justify-content: space-between;
`;
const Title = styled.div<any>`
    display: ${({ flexDirection }) => (flexDirection ? flexDirection : 'flex')};
    justify-content: start;
    font-weight: ${({ fw }) => (fw ? fw : '400')};
`;
export default RedeemModel;
