/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ContentList Component
 * Description: This component displays a list of user with details such as name, icon, link, phone, email, and availability status for service providers.
 *
 * Props:
 * - queryId: string - ID for identifying the list of user.
 * - name: string - Name of the user.
 * - icon: any - Icon/image for the user.
 * - link: any - Link to navigate when the list of user is clicked.
 * - phone: any - Phone number of the user.
 * - email: any - Email of the user.
 * - serviceProvider: any - Boolean indicating whether the user is a service provider.
 * - id: any - ID of the user.
 */

import { api } from 'helpers/auth-axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';

export interface DataProps {
    queryId?: string;
    fieldName?: string;
    name?: string;
    icon?: any;
    link?: any;
    phone?: any;
    email?: any;
    serviceProvider?: any;
    id?: any;
}

const ContentList = ({
    queryId,
    name,
    icon,
    link,
    phone,
    email,
    serviceProvider,
    id,
}: DataProps) => {
    const navigate = useNavigate();
    const [availabilityStatus, setAvailabilityStatus] = useState<any[]>([]);
    const [is_available, setIs_available] = useState(false);
    const todayTime = moment().format('HH:mm');

    useEffect(() => {
        if (serviceProvider === true && id) {
            getAvailabilityOfServiceProvider(id);
        }
    }, [id, serviceProvider]);

    useEffect(() => {
        if (availabilityStatus?.length) {
            let format = 'hh:mm';
            for (let i = 0; i <= availabilityStatus?.length; i++) {
                let time = moment(todayTime, format),
                    beforeTime = moment(availabilityStatus[i], format),
                    afterTime = moment(availabilityStatus[i + 1], format);

                if (time.isBetween(beforeTime, afterTime)) {
                    setIs_available(true);
                } else {
                }
            }
        }
    }, [availabilityStatus]);

    const getAvailabilityOfServiceProvider = (id: any) => {
        setIs_available(false);
        if (id !== undefined) {
            let date: any = moment().format();
            let duration = '15';
            api.post(`appointments/availability_status/${id}`, {
                date: date,
                duration: duration,
            }).then((res: any) => {
                setAvailabilityStatus(res.data.slots);
            });
        }
    };

    return (
        <Container>
            <Row>
                <Col style={{ padding: '0rem' }} lg={12} onClick={() => navigate(`${link}`)}>
                    <div className={`${queryId}`}>
                        <List>
                            <div>
                                {is_available === true && <OnlineBadge></OnlineBadge>}
                                <Icon src={`${process.env.REACT_APP_PROFILE_URL}${icon}`}></Icon>
                            </div>
                            <Profile>
                                <Name>{name}</Name>
                                <Phone>{email}</Phone>
                                <Phone>{phone}</Phone>
                            </Profile>
                        </List>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const List = styled.div`
    padding: 0.85rem 1.71rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    border-bottom: 0.1rem solid ${palette.grey.lightest};
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest};
    }
`;

const Icon = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    overflow: auto;
    background: ${palette.primary};
    border-radius: 2rem;
    background: linear-gradient(-135deg, ${palette.grey.lightest}, ${palette.grey.lightest});
    color: ${palette.silver};
`;

const Name = styled.div`
    padding: 0 0.85rem;
    font-size: 1rem;
`;

const Phone = styled.div`
    padding: 0 0.85rem;
    font-size: 0.8rem;
`;

const Profile = styled.div`
    display: block;
`;

const OnlineBadge = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: ${palette.simpleGreen};
    border-radius: 50%;
    position: absolute;
    left: 3.8rem;
    bottom: 1.4rem;
`;

export default ContentList;
