/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Dropdown Component
 * Description: This component provides a dropdown container that wraps around its children. It manages the dropdown's open/closed state and exposes a context to its children through the 'DropdownContext'. The 'toggle' function allows toggling the dropdown's visibility.
 *
 * Props:
 * - children: React.ReactNode - The content to be rendered inside the dropdown.
 *
 * Purpose:
 * - Provides a reusable dropdown wrapper that manages its open/closed state
 * - Uses React state to control the visibility of the dropdown
 * - Exposes a context to share the dropdown state with its children
 * - Allows toggling the dropdown state with the 'toggle' function
 * - Wraps its children inside a DropdownContext for state propagation
 *
 */

import { useState } from 'react';
import DropdownContext from './DropdownContext';

const Dropdown = ({ children }: { children?: React.ReactNode }) => {
    const [isOpen, setOpen] = useState(true);

    const getContextValue = () => {
        return {
            isOpen: isOpen,
            toggleMenu: toggle,
        };
    };

    const toggle = () => {
        setOpen(prevValue => !prevValue);
    };

    return (
        <DropdownContext value={getContextValue()}>
            <div className="dropdown">{children}</div>
        </DropdownContext>
    );
};

export default Dropdown;
