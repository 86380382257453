/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: NewCreditCard
 * Description: This component serves as a wrapper for the CardForm component and is responsible for managing the state
 * of credit card details. It includes functionalities for updating and handling changes in credit card information.
 * The component integrates with the CardForm to facilitate user input of card details.
 *
 * Props:
 * - initialState: any - Initial state for the credit card form.
 * - onChange: (keyName: string, value: any) => void - Callback function triggered when a value in the form changes.
 * - loading: any - Indicates whether the component is in a loading state.
 * - cardDisable?: any - Indicates whether the credit card form is disabled.
 *
 * Usage Example:
 *
 */

import { useCallback, useRef, useState } from 'react';
import CardForm from './CardForm';

const NewCreditCard = ({
    initialState,
    onChange,
    loading,
    cardDisable,
}: {
    initialState: any;
    onChange(keyName: string, value: any): void;
    loading: any;
    cardDisable?: any;
}) => {
    const [state, setState] = useState(initialState);

    const updateStateValues = useCallback(
        (keyName: string, value: any) => {
            onChange(keyName, value);
            setState({
                ...state,
                [keyName]: value || initialState[keyName],
            });
        },
        [state]
    );

    const cardElementsRef: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
    };

    return (
        <div>
            <div className="">
                <div className="appointmentcard">
                    <div className="appointmentcard-body">
                        <div className="card-title">Card Details</div>

                        <CardForm
                            loading={loading}
                            cardMonth={state.cardMonth}
                            cardYear={state.cardYear}
                            onUpdateState={updateStateValues}
                            cardNumberRef={cardElementsRef.cardNumber}
                            cardHolderRef={cardElementsRef.cardHolder}
                            cardDateRef={cardElementsRef.cardDate}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCreditCard;
