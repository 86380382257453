/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Settings Component
 * Description: This component serves as the main entry point for the settings section. It includes the SettingsOptions component for managing various tabs to navigate on setting.
 *
 */

import { Layout } from '@components/common';
import { SettingsOptions } from '@components/settings';

const Settings = () => {
    return (
        <>
            <SettingsOptions />
        </>
    );
};

Settings.Layout = Layout;
export default Settings;
