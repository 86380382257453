/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Snackbar
 * Description: Snackbar component designed for displaying notifications, such as error or success messages, triggered by API interactions within the application.
 * Purpose:
 * - Provides a context for triggering and managing snackbars.
 * - Uses the CSSTransition component for smooth enter and exit animations.
 * - Default position: bottom-center
 * - Default duration: 5000ms
 * - Default interval: 250ms
 *
 * Props:
 * - children: React.ReactNode - The child components.
 *
 * Context:
 * - openSnackbar: (text: string, duration: number) => void - Function to trigger opening a snackbar.
 * - closeSnackbar: () => void - Function to close the currently open snackbar.
 */

import React, { FC, createContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { palette } from '../../../styled/common';

export const defaultPosition = 'bottom-center';
export const defaultDuration = 5000;
export const defaultInterval = 250;

export const SnackbarContext = createContext<any | null>({});

const Snackbar: FC<any> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    const [duration, setDuration] = useState(defaultDuration);
    const [timeoutId, setTimeoutId] = useState(0);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    const triggerSnackbar = (text: string, duration: number) => {
        setText(text);
        setDuration(duration);
        setOpen(true);
    };

    const openSnackbar = (text: string, duration: number) => {
        if (open === true) {
            setOpen(false);
            setTimeout(() => {
                triggerSnackbar(text, duration);
            }, defaultInterval);
        } else {
            triggerSnackbar(text, duration);
        }
    };

    const closeSnackbar = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
            {children}

            <CSSTransition
                in={open}
                mountOnEnter
                unmountOnExit
                onEnter={() => {
                    clearTimeout(timeoutId);
                    setTimeoutId(window.setTimeout(() => setOpen(false), duration));
                }}
                className={`snackbar--wrapper snackbar--wrapper-bottom-center ${
                    _userData?.leftMenu === false && 'snackbar--wrapper-updated'
                }`}
                classNames={{
                    enter: `snackbar-enter snackbar-enter-bottom-center`,
                    enterActive: `snackbar-enter-active snackbar-enter-active-bottom-center`,
                    exitActive: `snackbar-exit-active snackbar-exit-active-bottom-center`,
                }}
                timeout={150}>
                <div>
                    <div
                        className="snackbar"
                        style={{ background: palette.toast_notification_background }}>
                        <div className="snackbar--text">{text}</div>
                        <button className="snackbar--close" onClick={closeSnackbar}>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            </CSSTransition>
        </SnackbarContext.Provider>
    );
};

const CloseIcon = () => (
    <svg width="1em" height="1em" viewBox="0 0 12 12">
        <path
            fill="currentColor"
            d="M11.73 1.58L7.31 6l4.42 4.42-1.06 1.06-4.42-4.42-4.42 4.42-1.06-1.06L5.19 6 .77 1.58 1.83.52l4.42 4.42L10.67.52z"
            fillRule="evenodd"
        />
    </svg>
);

export default Snackbar;
