/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Layout Component
 * Description: React functional component representing the layout structure for sale.
 *
 * Purpose: This component defines the overall structure for sale, including the aside, header, and main content.
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Aside, Header } from '@components/common';
import { routes } from 'constants/routes';
import useRouter from 'hooks/router';

const Layout: FC<any> = ({ children, ...props }) => {
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    const activeRoute = () => {
        for (let i = 0; i < routes?.length; i++) {
            if (routes[i].patterns) {
                for (let j = 0; j < routes[i].patterns!.length; j++) {
                    if (router.pathname === routes[i].patterns![j].path) {
                        return routes[i].patterns![j];
                    }
                }
            }
            if (router.pathname === routes[i].path) {
                return routes[i];
            }
        }
    };

    return (
        <Wrapper flexDorection={_userData?.leftMenu === true && true}>
            <Aside />
            <PageContent margin={_userData?.leftMenu === true && true}>
                <Header activeRoute={activeRoute()!} />
                <Content>{children}</Content>
            </PageContent>
        </Wrapper>
    );
};

const Wrapper = styled.div<any>`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: ${({ flexDirection }) => (flexDirection ? 'row' : 'column')} !important;
    display: flex !important;
`;

const PageContent = styled.div<any>`
    margin-left: ${({ margin }) => (margin ? '18.6rem' : '')};
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    ${media.xs`
    margin-left: 0rem;
  `}
    ${media.sm`
    margin-left: 0rem;
  `}
    ${media.md`
	margin-left: 0rem;
  `}
    ${media.lg`
    margin-left: ${({ margin }: any) => (margin ? '18.6rem' : '')};
  `}
`;

const Content = styled.div`
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export default Layout;
