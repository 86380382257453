/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Create New Business Admin Component
 *
 * Description:
 * - Renders a modal for creating or editing a business admin.
 * - Provides a form for entering admin details like name, email, and password.
 * - Utilizes styled-components for styling and theming.
 * - Implements form validation using Yup.
 * - Communicates with the server using the api helper.
 * - Displays loading overlay during API requests.
 * - Handles phone number formatting.
 *
 * Props:
 * - onClose: () => void - A function to be called when the modal is closed.
 * - onSave: (data: any) => void - A function to be called when the form is submitted.
 * - title?: any - Title for the modal.
 * - onEdit?: any - Data for pre-filling the form in edit mode.
 */
import { Input, LoaderOverlay, Select, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const CreateBusinessAdmin = ({
    onClose,
    onSave,
    title,
    onEdit,
    editModel,
}: {
    onClose(): void;
    onSave(data: any): void;
    title?: any;
    onEdit?: any;
    editModel?: any;
}) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [phone, setPhone] = useState();
    const [loading, setLoading] = useState<any>(false);
    const [userBusiness, setUserBusiness] = useState([]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        email: Yup.string().email().required(t('This field is required')),
        password: Yup.string()
            .required(t('This field is required'))
            .min(8, t('Password must be at least 8 characters')),
        business_id: Yup.string().required(t('This field is required')),
    });
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        getUserBusiness();
    }, []);
    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            phoneNumber(name, value);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const onSubmit = async (data: any) => {
        setLoading(true);
        try {
            await api.post('/users/register', data).then(res => {
                setLoading(false);
                openSnackbar('Admin assigned successfull');
                onClose();
            });
        } catch (e: any) {
            setLoading(false);
            if (e?.response) {
                openSnackbar(e?.response?.data?.message);
            }
        }
    };

    useEffect(() => {
        if (onEdit && editModel === true) {
            setValue('firstname', onEdit?.firstname);
            setValue('lastname', onEdit?.lastname);
            setValue('email', onEdit?.email);
            setValue('phone', onEdit?.phone);
            setPhone(onEdit?.phone);
        }
    }, [setValue, getValues]);

    const phoneNumber = (name: any, value: any) => {
        let phone = value;
        if (phone?.length <= 3) {
            phone = phone.replace(/[- .]/g, '');
        } else if (phone?.length <= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
        } else if (phone?.length >= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
        }
        setPhone(phone);
        setValue(name as never, phone as never);
    };

    const getUserBusiness = async () => {
        await api.post('/businesses/all').then((res: any) => {
            if (res?.data?.length) {
                let business = res?.data?.map((e: any) => ({
                    label: e?.name,
                    value: e?._id,
                }));
                setUserBusiness(business);
            }
        });
    };

    return (
        <Model tabIndex={-1} onClick={e => !loading && closeModal(e)}>
            {loading && <LoaderOverlay />}
            <ModelBody style={{ width: '680px' }}>
                <ModelHeading>
                    <Heading>{title}</Heading>
                    <Cover onClick={() => !loading && onClose()}>
                        <i className="fal fa-times"></i>
                    </Cover>
                </ModelHeading>
                <ModelHead></ModelHead>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                        <Input
                            label={t('Name')}
                            name="name"
                            onChange={onChange}
                            error={errors.name as any}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            label={t('Email')}
                            name="email"
                            type="email"
                            onChange={onChange}
                            error={errors.email as any}
                            autoComplete="on"
                        />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            type={t('Password')}
                            name="password"
                            label="Password"
                            onChange={onChange}
                            error={errors.password as any}
                        />
                    </InputGroup>

                    <InputGroup>
                        <Select
                            disabled={true}
                            value={''}
                            name="business_id"
                            label={t('Select Business')}
                            onChange={(val: string) =>
                                onChange({ name: 'business_id', value: val })
                            }
                            options={userBusiness}
                            error={errors.business_id as any}
                        />
                    </InputGroup>

                    <Button
                        type={'submit'}
                        label={t('Create Account')}
                        width={'100% !important'}
                        bgtype={'secondary'}
                        disabled={loading}
                    />
                </form>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
    width: 100%;
`;

export default CreateBusinessAdmin;
