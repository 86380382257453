/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CardForm
 * Description: This component represents the form for entering credit card details, including card number,
 * cardholder name, expiration month and year, and CVV. It includes functionalities for formatting and validating
 * card number input. The component integrates with various UI elements, such as input fields and selects,
 * and supports features like card number formatting and CVV input. Developed with React and styled-components for styling.
 *
 * Props:
 * - cardMonth: string - The selected card expiry month.
 * - cardYear: string - The selected card expiry year.
 * - onUpdateState: (state: string, value: any) => void - Callback function triggered when the component state is updated.
 * - cardNumberRef: any - Reference to the card number input element.
 * - cardHolderRef: any - Reference to the card holder input element.
 * - cardDateRef: any - Reference to the card expiry date input element.
 * - loading?: any - Indicates whether the component is in a loading state.
 *
 */

import { Input, LoaderOverlay, Select } from '@components/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface CardFormProps {
    cardMonth: string;
    cardYear: string;
    onUpdateState(state: string, value: any): void;
    cardNumberRef: any;
    cardHolderRef: any;
    cardDateRef: any;
    loading?: any;
}

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
});
const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);

const CardForm = ({
    cardMonth,
    cardYear,
    onUpdateState,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    loading,
}: CardFormProps) => {
    const { t }: any = useTranslation();
    const [cardNumber, setCardNumber] = useState('');
    const handleFormChange = ({ name, value }: { name: string; value: any }) => {
        onUpdateState(name, value);
    };

    const onCardNumberChange = (name: string, value: string) => {
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        setCardNumber(cardNumber.trimRight());
        onUpdateState(name, cardNumber);
    };

    return (
        <Container>
            {loading && <LoaderOverlay />}
            <InputGroup>
                <Input
                    label={t('Card Number')}
                    value={cardNumber}
                    maxLength={19}
                    readOnly={loading}
                    name="cardNumber"
                    allowNumber={true}
                    onChange={({ name, value }: { name: string; value: string }) => {
                        onCardNumberChange(name, value);
                    }}
                />
            </InputGroup>
            <InputGroup>
                <Input
                    label={t('Name')}
                    name="cardHolder"
                    readOnly={loading}
                    onChange={handleFormChange}
                />
            </InputGroup>

            <InputGroup>
                <div
                    className="userDetails-group card-form_grid--exp"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                        gap: '10px',
                    }}>
                    <Select
                        label="MM"
                        maxLength={2}
                        disabled={true}
                        onChange={(value: string) => {
                            onUpdateState('cardMonth', value);
                        }}
                        readOnly={loading}
                        allowNumber={true}
                        options={monthsArr.map(val => {
                            return {
                                label: val.toString(),
                            };
                        })}
                    />

                    <Select
                        label="YYYY"
                        maxLength={4}
                        disabled={true}
                        readOnly={loading}
                        allowNumber={true}
                        onChange={(value: string) => {
                            onUpdateState('cardYear', value);
                        }}
                        options={yearsArr.map(val => {
                            return {
                                label: val.toString(),
                            };
                        })}
                    />
                </div>

                <InputGroup>
                    <Input
                        label="CVV"
                        name="cardCVV"
                        maxLength={4}
                        readOnly={loading}
                        allowNumber={true}
                        onFocus={() => onUpdateState('isCardFlipped', true)}
                        onBlur={() => onUpdateState('isCardFlipped', false)}
                        onChange={handleFormChange}
                    />
                </InputGroup>
            </InputGroup>
        </Container>
    );
};
const Container = styled.div`
    margin-top: 1rem;
`;

const InputGroup = styled.div`
    margin-top: 0.7rem;
`;
export default CardForm;
