/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateOther Component
 * Description: This component allows the user to create a new "Other" member, providing details such as name, email, phone, password, and short description. It also allows uploading a profile image.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the component is closed.
 * - onSave: (id: string) => void - Callback function triggered when the "Other" member is successfully saved, providing the ID of the saved member.
 *
 */

import React, { useEffect, useState } from 'react';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import { IOther } from 'interfaces/others.interface';
import { Col, Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import ModelHeader from '@components/common/modelHeader/ModelHeader';
import { CustomRow, FormContent } from '../createTeamMember/CreateTeamMember';
import { CameraIcon, FooterButton } from '../createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import { palette } from 'styled/common';

const CreateOther = ({ onClose, onSave }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        email: Yup.string().email().required(t('This field is required')),
        phone: Yup.string()
            .min(10, t('Phone must be a valid phone'))
            .required(t('This field is required')),
        password: Yup.string()
            .min(8, t('Password must be at least 8 characters'))
            .required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<IOther>({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        setTimeout(() => {
            setValue('name', '');
            setValue('email', '');
            setValue('password', '');
        });
    }, [setValue]);

    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone?.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: IOther) => {
        setLoading(true);
        api.post('/other', { ...data, email: data.email.toLowerCase(), photo: profileImage })
            .then((res: any) => {
                setLoading(false);
                onSave(res.data._id);
                if (res.error) {
                    openSnackbar(t('Failed to add Other'));
                } else {
                    openSnackbar(t('Others member saved successfully!'));
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <CustomRow>
                    <Col lg={6}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <ModelHeader
                                title={t('Add New Other')}
                                path={'/team/other-list'}
                                onClose={() => !loading && onClose()}
                            />
                            <Card>
                                <FormContent>
                                    <Circle>
                                        <CircleLabel
                                            htmlFor="faceImage"
                                            aria-label="upload picture">
                                            {profileImage ? (
                                                <Data src={profileImage}></Data>
                                            ) : (
                                                <CameraIcon className="fa fa-camera" />
                                            )}
                                        </CircleLabel>
                                        <FileInput
                                            accept="image/jpeg/png"
                                            id="faceImage"
                                            type="file"
                                            onChange={handleCapture}
                                        />
                                    </Circle>
                                    <InputField>
                                        <Input
                                            label={t('Name')}
                                            name="name"
                                            value={getValues('name')}
                                            onChange={onChange}
                                            error={errors.name as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            label={t('Email')}
                                            name="email"
                                            value={getValues('email')?.toLowerCase()}
                                            onChange={onChange}
                                            error={errors.email as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            label={t('Phone')}
                                            name="phone"
                                            value={getValues('phone')}
                                            onChange={onChange}
                                            allowPhoneNumberOnly={true}
                                            maxLength={12}
                                            error={errors.phone as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            label={t('Password')}
                                            type={t('Password')}
                                            name="password"
                                            value={getValues('password')}
                                            onChange={onChange}
                                            error={errors.password as any}
                                        />
                                    </InputField>

                                    <InputField>
                                        <Input
                                            label={t('Short Description')}
                                            name="description"
                                            value={getValues('description')}
                                            onChange={onChange}
                                        />
                                    </InputField>
                                    <FooterButton>
                                        <Button
                                            bgtype={'secondary'}
                                            type={'submit'}
                                            label={t('Add Other')}></Button>
                                    </FooterButton>
                                </FormContent>
                            </Card>
                        </Form>
                    </Col>
                </CustomRow>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;

const Form = styled.form``;

const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    background-clip: border-box;
    border: 0;
`;

const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;

const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const Data = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const FileInput = styled.input`
    display: none;
`;

export default CreateOther;
