/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomisedBookingsCalendar Component
 * Description: This component provides a calendar view for managing appointments, with features such as creating, modifying, and deleting appointments.
 * It includes functionalities such as navigating between days, weeks, and months, displaying daily, weekly, and monthly appointments, handling Pusher events for real-time updates, and fetching appointments based on the selected date range. Appointments are created based on working hours availability and also modified in available working hours.
 *
 * Props:
 * - initialView: string - The initial view for the calendar (e.g., 'teamGridWeek', 'timeGridDay').
 * - setView: (view: string) => void - Callback function triggered when the calendar view is changed.
 * - dateRange: any - The date range for the calendar.
 * - setDateRange: (range: any) => void - Callback function triggered when the date range is changed.
 * - serviceProviderId: any - The ID of the service provider for the calendar.
 * - setTeamCalendarDate: (date: any) => void - Callback function triggered when the team calendar date is changed.
 * - teamCalendarDate: any - The date for the team calendar.
 * - pickDate: any - Callback function triggered when a date is picked on the calendar.
 * - calendar_size: any - The size of the calendar (e.g., '15', '30').
 * - customBusinessHours: any - Information about custom business hours.
 * - vipAppointment: any - Information about VIP appointments.
 * - waitingList: any - Information about the waiting list appointments.
 */

import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { NewAppointmentDrawer, EditAppointmentDrawer } from '@components/appointments';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { v4 as uuid } from 'uuid';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import useTimeIndicator from './useTimeIndicator';
import moment from 'moment';
import './BookingCalendar.scss';
import { IAppointment } from 'interfaces/appointment.interface';
import { LoaderOverlay, useSnackbar } from '@components/common';
import useRouter from 'hooks/router';
import ModifyAppointmentModal from '../../appointments/modal/ModifyAppointmentModal';
import { api } from 'helpers/auth-axios';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import BlockModel from '../../appointments/modal/BlockModel';
import ConflictModal from '@components/appointments/modal/ConflictModal';
import jquery from 'jquery';
import Pusher from 'pusher-js';
import { useTranslation } from 'react-i18next';
import { media } from 'styled-bootstrap-grid';
import CustomiseBusinessHoursDrawer from '@components/appointments/modal/CustomiseBusinessHoursDrawer';
import { Tooltip } from 'bootstrap';
import ReminderModel from '@components/appointments/modal/ReminderModel';
import { palette } from 'styled/common';
let tooltipInstance: any = null;

export interface IEvent {
    id: string;
    start: string;
    end: string;
    service: IService;
    customer: ICustomer;
    calendarRef: any;
}

interface IProps {
    initialView: string;
    setView: any;
    dateRange: any;
    setDateRange: any;
    serviceProviderId: any;
    setTeamCalendarDate: any;
    teamCalendarDate: any;
    pickDate: any;
    calendar_size: any;
    customBusinessHours: any;
    vipAppointment: any;
    waitingList: any;
}
const CustomisedBookingsCalendar = forwardRef(
    (
        {
            initialView,
            setView,
            dateRange,
            setDateRange,
            serviceProviderId,
            setTeamCalendarDate,
            teamCalendarDate,
            pickDate,
            calendar_size,
            customBusinessHours,
            vipAppointment,
            waitingList,
        }: IProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            next() {
                next();
            },
            prev() {
                prev();
            },
            today() {
                today();
            },
            changeView(view?: string) {
                changeView(view);
            },
            updateCalendarDate() {
                updateCalendarDate();
            },
        }));
        if (initialView == 'teamGridWeek') {
            initialView = 'timeGridDay';
        }
        const $: JQueryStatic = jquery;
        const router = useRouter();
        const { t } = useTranslation();
        const [loading, setLoading] = useState(false);
        const [checkView, setCheckView] = useState<any>();
        const [confirmation, setConfirmation] = useState<any>({
            show: false,
            id: '',
            start: moment().format(),
            end: moment().format(),
            is_blocked: false,
            callback: () => {},
            appointment: {},
        });
        const calendarRef = useRef<FullCalendar>(null);
        const [newAppointment, setNewAppointment] = useState<any>({
            show: false,
            serviceProviderId: serviceProviderId,
            id: '',
            start: moment().format(),
            end: moment().format(),
        });
        const [workingHours, setWorkingHours] = useState<any>();
        const [blockDeleteModel, setBlockDeleteModel] = useState<any>(false);
        const [appointmentId, setAppointmentId] = useState<any>();
        const [blockModel, setBlockModel] = useState<any>(false);
        const [blockDate, setBlockDate] = useState<any>();
        const [indicate] = useTimeIndicator();
        const [openSnackbar] = useSnackbar();
        const [conflictDates, setConflictDates] = useState<string[]>([]);
        const [showConflictModal, setShowConflictModal] = useState(false);
        const [appointmentData, setAppointmentData] = useState<any>();
        const [reminderModel, setReminderModel] = useState(false);
        const [customSlots, setCustomSlots] = useState<any>({
            show: false,
            serviceProviderId: serviceProviderId,
            id: '',
            start: moment().format(),
            end: moment().format(),
        });
        let _userData = JSON.parse(localStorage.getItem('user') || '{}');
        const windowContainer: any = useRef(null);

        useEffect(() => {
            indicate();
            if (initialView == 'dayGridMonth') {
                monthlyCalenderHeaderDate();
            }
        });

        useEffect(() => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef?.current?.getApi();
                calendarApi.gotoDate(moment(teamCalendarDate).format());
            }
        }, []);

        useEffect(() => {
            updateCalendarDate();
        }, [teamCalendarDate]);

        useEffect(() => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                if (calendarApi.view.type === 'timeGridDay') {
                    setDateRange(calendarApi.view.title);
                    getDailyAppointments();
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    setDateRange(calendarApi.view.title);
                    getWeeklyAppointments();
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    setDateRange(calendarApi.view.title);
                    getMonthlyAppointments();
                }
            }
            if (newAppointment.show) {
                setNewAppointment({
                    show: false,
                });
                setTimeout(() => {
                    setNewAppointment({
                        show: true,
                    });
                }, 150);
            }
        }, [serviceProviderId]);

        useEffect(() => {
            let appointments =
                checkView === 'timeGridDay'
                    ? appointmentData?.dailyAppointments
                    : checkView === 'timeGridWeek'
                    ? appointmentData?.weeklyAppointments
                    : checkView === 'dayGridMonth' && appointmentData?.monthlyAppointments;
            if (vipAppointment) {
                removeAllEvent();
                appointments?.length &&
                    appointments?.map((appointment: any) => {
                        addEvent({
                            id: appointment._id,
                            start: moment(appointment?.booked_from_iso).format(),
                            end: moment(appointment?.booked_till_iso).format(),
                            service: appointment?.service,
                            customer: appointment?.customer,
                            is_blocked: appointment?.is_blocked,
                            appointmentId: appointment?._id,
                            is_vip: appointment?.is_vip,
                            status: appointment?.status,
                            is_reported: appointment?.reportId ? true : false,
                            is_video: appointment?.is_video_appointment,
                            appointment: appointment,
                            is_waiting: appointment?.is_waiting,
                        });
                    });
            }
            if (customBusinessHours) {
                removeAllEvent();
                appointmentData?.customizeWorkingHours?.map((workingHours: any) => {
                    if (workingHours?.timeOff === true) {
                    } else {
                        addEvent({
                            id: workingHours.id,
                            start: moment(workingHours?.start).format(),
                            end: moment(workingHours?.end).format(),
                            repeat: workingHours?.repeat,
                            bgcolor: workingHours?.color ? workingHours?.color : '30',
                            workingHours: true,
                            break: workingHours?.cutomBreak ? true : false,
                            data: workingHours,
                        });
                    }
                });
            } else if (appointmentData && vipAppointment == false) {
                removeAllEvent();
                appointments?.length &&
                    appointments?.map((appointment: any) => {
                        addEvent({
                            id: appointment._id,
                            start: moment(appointment?.booked_from_iso).format(),
                            end: moment(appointment?.booked_till_iso).format(),
                            service: appointment?.service,
                            customer: appointment?.customer,
                            is_blocked: appointment?.is_blocked,
                            appointmentId: appointment?._id,
                            is_vip: appointment?.is_vip,
                            status: appointment?.status,
                            is_reported: appointment?.reportId ? true : false,
                            is_video: appointment?.is_video_appointment,
                            appointment: appointment,
                            is_waiting: appointment?.is_waiting,
                        });
                    });
                appointmentData?.customizeWorkingHours?.map((workingHours: any) => {
                    if (workingHours?.timeOff === true) {
                    } else {
                        addEvent({
                            id: workingHours?.id,
                            start: moment(workingHours?.start).format(),
                            end: moment(workingHours?.end).format(),
                            repeat: workingHours?.repeat,
                            bgcolor: workingHours?.color,
                            display: workingHours?.cutomBreak ? undefined : 'background',
                            workingHours: true,
                            break: workingHours?.cutomBreak ? true : false,
                            data: workingHours,
                        });
                    }
                });
            }
        }, [appointmentData, customBusinessHours, vipAppointment]);

        // Pusher
        useEffect(() => {
            let PusherId = '';
            if (process.env.REACT_APP_PUSHER !== undefined) {
                PusherId = process.env.REACT_APP_PUSHER;
            }
            var pusher = new Pusher(PusherId, {
                cluster: 'ap2',
            });
            let businessId = _userData?.user?.business_id?._id;
            var channel = pusher.subscribe(businessId);
            channel.bind('eBookingSystem', function (data: any) {
                if (calendarRef.current !== null) {
                    const calendarApi = calendarRef.current.getApi().getEvents();
                    const eventsData: any = calendarApi.map((data: any) => data._def.publicId);
                    if (data?.rescheduleAppointment) {
                        editEvent({
                            id: data.rescheduleAppointment._id,
                            start: moment(data?.rescheduleAppointment?.booked_from_iso).format(),
                            end: moment(data?.rescheduleAppointment?.booked_till_iso).format(),
                            service: data?.rescheduleAppointment?.service,
                            customer: data?.rescheduleAppointment?.customer,
                            is_blocked: data?.rescheduleAppointment?.is_blocked,
                            is_vip: data?.rescheduleAppointment?.is_vip,
                            status: data?.rescheduleAppointment?.status,
                        });
                    }
                    if (data?.cancelledAppointment) {
                        editEvent({
                            id: data.cancelledAppointment._id,
                            start: moment(data?.cancelledAppointment?.booked_from_iso).format(),
                            end: moment(data?.cancelledAppointment?.booked_till_iso).format(),
                            service: data?.cancelledAppointment?.service,
                            customer: data?.cancelledAppointment?.customer,
                            is_blocked: data?.cancelledAppointment?.is_blocked,
                            is_vip: data?.cancelledAppointment?.is_vip,
                            status: data?.cancelledAppointment?.status,
                        });
                    }
                    if (data?.deleteAppointment) {
                        data?.deleteAppointment?.map((data: any) => {
                            removeEventFromPusher(data);
                        });
                    }

                    if (data?.newAppointment?.length) {
                        let obj = eventsData.find((x: any) => x === data?.newAppointment[0]?._id);
                        let checkServiceProvider =
                            serviceProviderId !== undefined &&
                            serviceProviderId === data?.newAppointment[0].serviceProvider._id;
                        if (checkServiceProvider === true && obj === undefined) {
                            data?.newAppointment?.map((event: any) => {
                                addEvent({
                                    id: event?._id,
                                    start: moment(event?.booked_from_iso).format(),
                                    end: moment(event?.booked_till_iso).format(),
                                    service: event?.service,
                                    customer: event?.customer,
                                    is_blocked: event?.is_blocked,
                                    is_vip: event?.is_vip,
                                    status: event?.status,
                                });
                            });
                        } else if (
                            data?.newAppointment[0].is_blocked === true &&
                            obj === undefined
                        ) {
                            data?.newAppointment?.map((event: any) => {
                                addEvent({
                                    id: event?._id,
                                    start: moment(event?.booked_from_iso).format(),
                                    end: moment(event?.booked_till_iso).format(),
                                    service: event?.service,
                                    customer: event?.customer,
                                    is_blocked: event?.is_blocked,
                                    is_vip: event?.is_vip,
                                    status: event?.status,
                                });
                            });
                        }
                    }
                }
            });
        }, [serviceProviderId]);

        const updateCalendarDate = () => {
            let dateFTC = moment(teamCalendarDate).format();
            const calendarApi = calendarRef?.current?.getApi();
            if (
                (calendarApi?.view.type === 'timeGridDay' ||
                    calendarApi?.view.type === 'dayGridMonth') &&
                dateFTC != ''
            ) {
                calendarApi.gotoDate(moment(dateFTC).format('YYYY-MM-DD'));
            } else if (calendarApi?.view.type === 'timeGridWeek' && dateFTC != '') {
                var format = /[–]+/;
                var doFormat: any = '';
                if (format.test(dateFTC)) {
                    doFormat = dateFTC.split(' ');
                    if (doFormat?.length !== 0 && doFormat?.length === 7) {
                        dateFTC = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[6];
                    } else if (doFormat?.length !== 0 && doFormat?.length === 6) {
                        dateFTC = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[5];
                    } else if (doFormat?.length !== 0 && doFormat?.length === 5) {
                        dateFTC = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[4];
                    }
                }
                calendarApi.gotoDate(moment(dateFTC).format('YYYY-MM-DD'));
            }
            changeView(initialView);
        };

        //get workingHopurs
        const getWorkingHours = () => {
            return workingHours;
        };

        const next = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.next();
                setDateRange(calendarApi.view.title);
                if (calendarApi.view.type === 'timeGridDay') {
                    const start_date: any = moment(calendarApi.view.activeStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getDailyAppointments(start_date);
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getWeeklyAppointments(start_date);
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getMonthlyAppointments(start_date);
                }
            }
        };

        const prev = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.prev();
                setDateRange(calendarApi.view.title);

                if (calendarApi.view.type === 'timeGridDay') {
                    const start_date: any = moment(calendarApi.view.activeStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getDailyAppointments(start_date);
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getWeeklyAppointments(start_date);
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getMonthlyAppointments(start_date);
                }
            }
        };

        const today = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.today();
                setDateRange(calendarApi.view.title);
                if (calendarApi.view.type === 'timeGridDay') {
                    const start_date: any = moment(calendarApi.view.activeStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getDailyAppointments(start_date);
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getWeeklyAppointments(start_date);
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getMonthlyAppointments(start_date);
                }
            }
        };

        const changeView = (view?: string) => {
            setCheckView(view);
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                if (view) calendarApi.changeView(view);
                setView(calendarApi.view.type);
                setDateRange(calendarApi.view.title);
            }
            if (view === 'timeGridDay') {
                return getDailyAppointments();
            } else if (view === 'timeGridWeek') {
                return getWeeklyAppointments();
            } else if (view === 'dayGridMonth') {
                return getMonthlyAppointments();
            } else {
                return null;
            }
        };

        const monthlyCalenderHeaderDate = () => {
            const weekClasses = [
                '.fc-day-sun',
                '.fc-day-mon',
                '.fc-day-tue',
                '.fc-day-wed',
                '.fc-day-thu',
                '.fc-day-fri',
                '.fc-day-sat',
            ];
            let week = 0;
            for (let i = 0; i < weekClasses.length; i++) {
                let headerDate: any = $(`th${weekClasses[week]} div a`).text();
                let dateList = headerDate.split(',');
                $(`th${weekClasses[week]} div a`).text('');
                $(`th${weekClasses[week]} div a`).text(dateList[0]);
                week = week + 1;
            }
        };

        const addEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.addEvent(event);
            }
        };

        const removeAllEvent = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.removeAllEvents();
            }
        };

        const editEvent = (event: any) => {
            removeEvent(event);
            addEvent(event);
        };

        const removeEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event.id)?.remove();
            }
        };

        const getDailyAppointments = (start_date = '') => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();
                if (serviceProviderId !== undefined) {
                    setLoading(true);
                    let date: any = moment(start_date ? start_date : teamCalendarDate).format();
                    api.post(`/appointments/daily/${serviceProviderId}`, {
                        date: date,
                        calendar: 'customise',
                    })
                        .then((res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            if (res.data !== undefined) {
                                setAppointmentData(res.data);
                                setWorkingHours(res.data.customizeWorkingHours);
                            }
                            setLoading(false);
                        })
                        .catch(e => {
                            setLoading(false);
                        });
                }
            }
        };

        const getWeeklyAppointments = (start_date = '') => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();
                if (serviceProviderId !== undefined) {
                    setLoading(true);
                    let date: any = moment(start_date ? start_date : teamCalendarDate).format();
                    api.post(`/appointments/weekly/${serviceProviderId}`, {
                        date: date,
                        calendar: 'customise',
                    })
                        .then((res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            setAppointmentData(res.data);
                            setWorkingHours(res.data.customizeWorkingHours);
                            setLoading(false);
                        })
                        .catch(e => {
                            if (e?.message) {
                                openSnackbar(e?.message);
                            }

                            setLoading(false);
                        });
                }
            }
        };

        const getMonthlyAppointments = (start_date = '') => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();
                if (serviceProviderId !== undefined) {
                    setLoading(true);
                    let date: any = moment(start_date ? start_date : teamCalendarDate).format();
                    api.post(`/appointments/monthly/${serviceProviderId}`, { date: date })
                        .then((res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            setAppointmentData(res.data);
                            setWorkingHours(res.data.customizeWorkingHours);
                            setLoading(false);
                        })
                        .catch(e => {
                            if (e?.message) {
                                openSnackbar(e?.message);
                            }
                            setLoading(false);
                        });
                }
            }
        };

        const renderEventContent = (arg: any) => {
            const props = arg.event.extendedProps;
            let currentDate: any = moment();
            let appointmentDate: any = moment(props?.appointment?.booked_from_iso);
            let appointmentDuration = moment.duration(appointmentDate?.diff(currentDate));
            let hours = appointmentDuration.asMinutes();
            const duration: any = moment
                .duration((arg.event.end as any) - (arg.event.start as any))
                .asMinutes();
            return props?.workingHours === true ? (
                <div
                    className={`slot_booking_event  ${
                        props?.break === true
                            ? 'breakTime'
                            : customBusinessHours && 'slots_event eventBg'
                    } service-color-${props?.break ? '' : props?.bgcolor}--bg`}
                    id={props?.id}>
                    {props?.break === true && <span style={{ paddingLeft: '0.9rem' }}>Break</span>}
                    <div className={`booking_eventBorder service-color-${props.bgcolor}--bd`}></div>
                    {props?.break === true ? (
                        ''
                    ) : (
                        <div className={`hide`} style={{ gap: '0.2rem' }}>
                            <i className="fas fa-edit pointer"></i>
                            {duration >= '60' ? (
                                <div style={{ cursor: 'pointer' }}>{t('Edit')}</div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        border: props?.is_waiting ? `2px solid ${palette.secondary}` : 'none',
                    }}
                    className={`booking_event ${
                        props.is_blocked === true ? 'block_bg' : ''
                    } service-color-${props.service?.color}--bg`}
                    onMouseEnter={() => {
                        if (props?.appointmentId && props?.is_blocked === false) {
                            let element: any = document?.getElementById(props?.appointmentId);
                            tooltipInstance = new Tooltip(element, {
                                title: `<div>${
                                    props?.is_waiting ? 'Waiting list appointment' : ''
                                }</div><div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Customer'
                                )}</div>
                                <div fw="light">${props?.customer?.fullname}</div></div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Service Provider'
                                )}</div>
                                <div fw="light">${
                                    props?.appointment?.serviceProvider?.name
                                }</div></div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Service'
                                )}</div>
                                <div fw="light">${props?.appointment?.service?.name}</div></div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Phone'
                                )}</div>
                                <div fw="light">${props?.customer?.phone}</div></div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Status'
                                )}</div>
                                <div fw="light">${props?.status}</div></div>`,
                                html: true,
                                placement: 'top',
                                trigger: 'hover',
                                container: 'body',
                            });

                            tooltipInstance.show();
                        }
                    }}
                    onMouseLeave={() => {
                        tooltipInstance?.dispose();
                        tooltipInstance = null;
                    }}
                    id={props?.appointmentId}>
                    <div
                        className={`booking_eventBorder service-color-${props.service?.color}--bd`}></div>
                    {duration <= 15 &&
                    (checkView === 'timeGridDay' || checkView === 'timeGridWeek') ? (
                        <div className={`booking_eventDetails`}>
                            <CustomerDiv className="booking_serviceCustomer">
                                <>
                                    {props.is_vip == true && <>{t('vip')} - </>}
                                    {props?.is_waiting && <>{t('w')} - </>}
                                    {props.is_blocked === true ? t('Block') : ''}{' '}
                                    {props.customer
                                        ? props.customer?.firstname?.substring(0, 10)
                                        : props.is_blocked === false && t('customer removed')}{' '}
                                    {props.is_vip == true ||
                                    props?.is_waiting ||
                                    props?.status === 'cancelled'
                                        ? ''
                                        : props.customer?.lastname?.substring(0, 10)}{' '}
                                </>
                                <div>
                                    {props?.is_reported && (
                                        <i
                                            className="fad fa-exclamation-triangle"
                                            style={{ color: palette.primary }}></i>
                                    )}
                                    {props?.is_video && (
                                        <i
                                            className="fal fa-video"
                                            onClick={e => {
                                                if (hours <= 10 && appointmentDate > currentDate) {
                                                    router.setParams({ appointment: '' });
                                                    let newWindow: any = window.open(
                                                        `/live-video-appointment/${props?.appointmentId}/${_userData?.accessToken}`,
                                                        'newwindow',
                                                        `width=1440, height=768`
                                                    );
                                                    newWindow.handleScreenSize =
                                                        function handleScreenSize(size: any) {
                                                            if (size == 'max') {
                                                                windowContainer?.dispatchEvent(
                                                                    new Event('click')
                                                                );
                                                            } else if (size == 'min') {
                                                                windowContainer?.dispatchEvent(
                                                                    new Event('click')
                                                                );
                                                            }
                                                        };
                                                    return false;
                                                } else {
                                                    setReminderModel(true);
                                                }
                                            }}></i>
                                    )}
                                </div>
                            </CustomerDiv>
                        </div>
                    ) : checkView === 'timeGridWeek' || checkView === 'dayGridMonth' ? (
                        <div className={`booking_eventDetails`}>
                            <div className="booking_serviceCustomer">
                                <CustomerDiv>
                                    <>
                                        {props.is_blocked === true ? t('Block') : ''}{' '}
                                        {props.is_vip == true && <>{t('vip')} - </>}
                                        {props?.is_waiting && <>{t('w')} - </>}
                                        {props.customer
                                            ? props.customer?.firstname?.substring(0, 10)
                                            : props.is_blocked === false &&
                                              t('customer removed')}{' '}
                                        {props.is_vip == true ||
                                        props?.is_waiting ||
                                        props?.status === 'cancelled'
                                            ? ''
                                            : props.customer?.lastname?.substring(0, 10)}{' '}
                                        {checkView !== undefined && checkView === 'dayGridMonth' ? (
                                            <br />
                                        ) : (
                                            ''
                                        )}
                                        {props.is_blocked === true
                                            ? ''
                                            : props?.service == null
                                            ? props?.appointmentId &&
                                              '(' + t('service removed') + ')'
                                            : props?.is_waiting
                                            ? ''
                                            : '(' + props?.service?.name?.substring(0, 10) + ')'}
                                        {props?.is_reported && (
                                            <i
                                                className="fad fa-exclamation-triangle"
                                                style={{ color: palette.primary }}></i>
                                        )}
                                    </>
                                    {props?.is_video && (
                                        <i
                                            className="fal fa-video"
                                            onClick={e => {
                                                if (hours <= 10 && appointmentDate > currentDate) {
                                                    router.setParams({ appointment: '' });
                                                    let newWindow: any = window.open(
                                                        `/live-video-appointment/${props?.appointmentId}/${_userData?.accessToken}`,
                                                        'newwindow',

                                                        `width=1440, height=768`
                                                    );
                                                    newWindow.handleScreenSize =
                                                        function handleScreenSize(size: any) {
                                                            if (size == 'max') {
                                                                windowContainer?.dispatchEvent(
                                                                    new Event('click')
                                                                );
                                                            } else if (size == 'min') {
                                                                windowContainer?.dispatchEvent(
                                                                    new Event('click')
                                                                );
                                                            }
                                                        };
                                                    return false;
                                                } else {
                                                    setReminderModel(true);
                                                }
                                            }}></i>
                                    )}
                                </CustomerDiv>

                                {props?.status === 'cancelled' && (
                                    <div
                                        className="booking_serviceCustomer"
                                        style={{ color: palette.primary }}>
                                        {props?.status}
                                    </div>
                                )}
                            </div>

                            <div
                                className={`${
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'booking_week_time'
                                        : 'booking_time'
                                } `}>
                                {moment(arg.event.startStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}{' '}
                                {props?.is_waiting ? (
                                    ''
                                ) : (
                                    <>
                                        {' '}
                                        -
                                        {moment(arg.event.endStr).format(
                                            _userData?.user?.business_id?.time_select === '12'
                                                ? 'hh:mm a'
                                                : 'HH:mm'
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={`booking_eventDetails`}>
                            <div className="booking_serviceCustomer" style={{ display: 'flex' }}>
                                <CustomerDiv>
                                    {props.is_blocked === true ? t('Block') : ''}
                                    {props.is_vip == true && <>{t('vip')} - </>}
                                    {props?.is_waiting && <>{t('w')} - </>}
                                    {props.customer
                                        ? props.customer?.firstname
                                        : props.is_blocked === false && t('customer removed')}{' '}
                                    {props.customer?.lastname}{' '}
                                    {checkView !== undefined && checkView === 'dayGridMonth' ? (
                                        <br />
                                    ) : (
                                        ''
                                    )}
                                    {props.is_blocked === true
                                        ? ''
                                        : props?.service == null
                                        ? props?.appointmentId && '(' + t('service removed') + ')'
                                        : '(' + props?.service?.name + ')'}
                                    {props?.is_reported && (
                                        <i
                                            className="fad fa-exclamation-triangle"
                                            style={{ color: palette.primary }}></i>
                                    )}
                                    {props?.is_video && (
                                        <i
                                            className="fal fa-video"
                                            onClick={e => {
                                                if (hours <= 10 && appointmentDate > currentDate) {
                                                    router.setParams({ appointment: '' });
                                                    let newWindow: any = window.open(
                                                        `/live-video-appointment/${props?.appointmentId}/${_userData?.accessToken}`,
                                                        'newwindow',
                                                        `width=1440, height=768`
                                                    );
                                                    newWindow.handleScreenSize =
                                                        function handleScreenSize(size: any) {
                                                            if (size == 'max') {
                                                                windowContainer?.dispatchEvent(
                                                                    new Event('click')
                                                                );
                                                            } else if (size == 'min') {
                                                                windowContainer?.dispatchEvent(
                                                                    new Event('click')
                                                                );
                                                            }
                                                        };
                                                    return false;
                                                } else {
                                                    setReminderModel(true);
                                                }
                                            }}></i>
                                    )}
                                </CustomerDiv>
                                &nbsp;
                                {props?.status === 'cancelled' && (
                                    <div
                                        className="booking_serviceCustomer"
                                        style={{ color: palette.primary }}>
                                        {props?.status}
                                    </div>
                                )}
                            </div>

                            <div className={`${'booking_time-daily'}`}>
                                {moment(arg.event.startStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}{' '}
                                -{' '}
                                {moment(arg.event.endStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        };

        const deleteBlock = () => {
            setLoading(true);
            api.delete(`/appointments/${appointmentId}`)
                .then(() => {
                    setLoading(false);
                    openSnackbar(t('Block deleted successfully!'));
                    setBlockDeleteModel(false);
                    if (calendarRef.current !== null) {
                        const calendarApi = calendarRef.current.getApi();
                        if (calendarApi.view.type === 'timeGridDay') {
                            getDailyAppointments();
                        } else if (calendarApi.view.type === 'timeGridWeek') {
                            getWeeklyAppointments();
                        } else if (calendarApi.view.type === 'dayGridMonth') {
                            getMonthlyAppointments();
                        }
                    }
                })
                .catch((res: any) => {
                    openSnackbar('Failed to delete Block!');
                });
        };

        $('.fc-timegrid-slot-lane,.fc-timegrid-slot-label').hover(function () {
            var getDefaultTime = $(this).attr('data-time');
            var formatedTime = moment(
                moment().format() + ' ' + getDefaultTime,
                'YYYY-MM-DD HH:mm:ss'
            ).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm a');
            $(this).attr('data-time', formatedTime);
        });

        const removeEventFromPusher = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event._id)?.remove();
            }
        };

        if (typeof window !== 'undefined') {
            let defaultSlots: any = document.getElementsByClassName(
                'fc-timegrid-event-harness-inset'
            );
            for (let i = 0; i < defaultSlots?.length; i++) {
                let eventInset = defaultSlots[i]?.style?.inset;
                if (defaultSlots[i]?.style?.zIndex == 2 && eventInset?.includes('50%') == true) {
                    const insetChecksecond = defaultSlots[i]?.style?.inset?.replace('50%', '30%');
                    defaultSlots[i]?.setAttribute('style', `inset:${insetChecksecond}`);
                }
                if (
                    defaultSlots[i]?.style?.zIndex == 3 &&
                    eventInset?.includes('66.6667%') == true
                ) {
                    const insetChecksecond = defaultSlots[i]?.style?.inset?.replace(
                        '66.6667%',
                        '55%'
                    );
                    defaultSlots[i]?.setAttribute('style', `inset:${insetChecksecond}`);
                }
            }
            $('.fc-day-today > .fc-timegrid-col-frame').addClass('today_calendar');
            $('.fc-col-header-cell.fc-day-today').css({
                background: `linear-gradient(350.99deg,#333333 -62.86%,${palette.secondary} 125.32%)`,
                color: 'white',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
            });
            $('.fc-timegrid-slot-label-cushion').css({ fontWeight: '400' });
            let tooltipOpend = $('.show.tooltip');
            if (tooltipInstance == null && tooltipOpend.length > 0) {
                $('.show.tooltip').css({
                    visibility: 'hidden',
                });
            }
        }

        return (
            <Wrapper>
                {loading && <LoaderOverlay />}
                <Flex>
                    <FullCalendar
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            momentTimezonePlugin,
                        ]}
                        initialView={initialView}
                        slotDuration={`00:${calendar_size !== undefined ? calendar_size : '15'}:00`}
                        allDaySlot={false}
                        nowIndicator={true}
                        slotLabelInterval="1:00"
                        height="100%"
                        headerToolbar={{
                            left: '',
                            center: '',
                            right: '',
                        }}
                        dayHeaderFormat={{
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            omitCommas: false,
                        }}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: _userData.user.business_id.time_select === '12' ? true : false,
                        }}
                        scrollTime={moment().subtract(2, 'h').format('HH') + ':00'}
                        editable={true}
                        timeZone={
                            _userData?.user?.business_id
                                ? _userData?.user?.business_id?.timezone
                                : 'America/Chicago'
                        }
                        eventDurationEditable={true}
                        displayEventTime={true}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short',
                        }}
                        selectable={true}
                        selectMirror={true}
                        longPressDelay={0}
                        select={args => {
                            const id = uuid();
                            if (customBusinessHours === true) {
                                setCustomSlots({
                                    show: true,
                                    id: id,
                                    start: moment(args.startStr).format(),
                                    end: moment(args.endStr).format(),
                                });
                            } else {
                                setNewAppointment({
                                    show: true,
                                    id: id,
                                    start: moment(args.startStr).format(),
                                    end: moment(args.endStr).format(),
                                });
                            }

                            setBlockDate(args.startStr);
                        }}
                        selectConstraint={
                            vipAppointment === true || waitingList
                                ? undefined
                                : customBusinessHours === true
                                ? undefined
                                : 'businessHours'
                        }
                        businessHours={
                            vipAppointment === true || waitingList
                                ? undefined
                                : customBusinessHours === true
                                ? undefined
                                : getWorkingHours()
                        }
                        eventContent={renderEventContent}
                        eventClassNames={function (arg) {
                            return arg.event.extendedProps.classNames;
                        }}
                        eventClick={arg => {
                            if (arg.event._def.extendedProps.break === true) {
                                setCustomSlots({
                                    show: false,
                                });
                            } else if (
                                arg.event._def.extendedProps.workingHours === true &&
                                arg.event._def.extendedProps.break !== true
                            ) {
                                if (customBusinessHours) {
                                    setCustomSlots({
                                        show: true,
                                        id: arg.event.id,
                                        start: moment(arg.event.startStr).format(),
                                        end: moment(arg.event.endStr).format(),
                                    });
                                    router.setParams({ workingHours: arg.event.id });
                                }
                            } else {
                                arg.event._def.extendedProps.is_blocked === true
                                    ? setBlockDeleteModel(true)
                                    : router.setParams({ appointment: arg.event.id });
                                setAppointmentId(arg.event.id);
                                setNewAppointment({
                                    show: false,
                                });
                            }
                        }}
                        eventDrop={(info: any) => {
                            tooltipInstance?.dispose();
                            tooltipInstance = null;
                            if (info.event._def.extendedProps.break === true) {
                                info.revert();
                            } else if (
                                info.event._def.extendedProps.workingHours === true &&
                                info.event._def.extendedProps.break !== true
                            ) {
                                info.revert();
                                setConfirmation((prevState: any) => ({
                                    ...prevState,
                                    show: false,
                                    appointment: {},
                                }));
                            } else {
                                setConfirmation({
                                    show: true,
                                    id: info.event.id,
                                    start: info.event.start,
                                    end: info.event.end,
                                    is_blocked: info?.event?._def.extendedProps?.is_blocked,
                                    callback: info.revert,
                                    appointment: info?.event?.extendedProps?.appointment,
                                });
                            }
                        }}
                        eventResize={(info: any) => {
                            tooltipInstance?.dispose();
                            tooltipInstance = null;
                            if (info.event._def.extendedProps.break === true) {
                                info.revert();
                            } else if (
                                info.event._def.extendedProps.workingHours === true &&
                                info.event._def.extendedProps.break !== true
                            ) {
                                info.revert();
                                setConfirmation((prevState: any) => ({
                                    ...prevState,
                                    show: false,
                                    appointment: {},
                                }));
                            } else {
                                setConfirmation({
                                    show: true,
                                    id: info.event.id,
                                    start: info.event.start,
                                    end: info.event.end,
                                    is_blocked: info?.event?._def.extendedProps?.is_blocked,
                                    callback: info.revert,
                                    appointment: info?.event?.extendedProps?.appointment,
                                });
                            }
                        }}
                        ref={calendarRef}
                    />
                </Flex>

                {newAppointment.show && (
                    <NewAppointmentDrawer
                        serviceProviderId={serviceProviderId}
                        id={newAppointment.id}
                        startDate={newAppointment.start}
                        parent={'customise'}
                        is_vip={vipAppointment}
                        waitingList={waitingList}
                        endDate={newAppointment.end}
                        conflictDate={val => {
                            setConflictDates(val);
                        }}
                        conflictModel={(val: any) => {
                            setShowConflictModal(val);
                        }}
                        setBlockModel={() => {
                            setBlockModel(true);
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        onClose={(isAppointmentAdded: any, event: any) => {
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            router.setParams({ appointment: '' });
                            if (!isAppointmentAdded) {
                                removeEvent(event);
                            }
                        }}
                        onSave={(event: any, appointment: IAppointment) => {
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                            removeEvent(event);
                        }}
                        onChange={(event: IEvent) => {}}
                    />
                )}

                {customSlots.show && (
                    <CustomiseBusinessHoursDrawer
                        serviceProviderId={serviceProviderId}
                        id={router.query.workingHours as string}
                        startDate={customSlots.start}
                        endDate={customSlots.end}
                        onClose={() => {
                            router.navigate('');
                            setCustomSlots((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        onSave={(event: any) => {
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                            setCustomSlots((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            removeEvent(event);
                        }}
                    />
                )}

                {newAppointment.show == true
                    ? ''
                    : router.query.appointment && (
                          <EditAppointmentDrawer
                              getAppointments={(event: IAppointment) => {
                                  router.navigate('');
                                  if (calendarRef.current !== null) {
                                      const calendarApi = calendarRef.current.getApi();
                                      if (calendarApi.view.type === 'timeGridDay') {
                                          getDailyAppointments();
                                      } else if (calendarApi.view.type === 'timeGridWeek') {
                                          getWeeklyAppointments();
                                      } else if (calendarApi.view.type === 'dayGridMonth') {
                                          getMonthlyAppointments();
                                      }
                                  }
                              }}
                              id={router.query.appointment as string}
                              waitingList={waitingList}
                              parent={'customise'}
                              onClose={isUpdate => {
                                  router.navigate('');
                                  if (isUpdate) {
                                      if (calendarRef.current !== null) {
                                          const calendarApi = calendarRef.current.getApi();
                                          if (calendarApi.view.type === 'timeGridDay') {
                                              getDailyAppointments();
                                          } else if (calendarApi.view.type === 'timeGridWeek') {
                                              getWeeklyAppointments();
                                          } else if (calendarApi.view.type === 'dayGridMonth') {
                                              getMonthlyAppointments();
                                          }
                                      }
                                  }
                              }}
                              onChange={(event: IAppointment) => {
                                  editEvent(event);
                              }}
                          />
                      )}

                {confirmation.show && (
                    <ModifyAppointmentModal
                        id={confirmation.id}
                        startDate={confirmation.start}
                        endDate={confirmation.end}
                        onDragDate={''}
                        serviceProvider={false}
                        serviceProvierData={''}
                        is_blocked={confirmation?.is_blocked}
                        appointment={confirmation?.appointment}
                        onSave={() => {
                            setConfirmation((prevState: any) => ({
                                ...prevState,
                                show: false,
                                is_blocked: false,
                                appointment: {},
                            }));
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                        }}
                        onClose={() => {
                            confirmation.callback();
                            setConfirmation((prevState: any) => ({
                                ...prevState,
                                show: false,
                                is_blocked: false,
                                appointment: {},
                            }));
                        }}
                    />
                )}

                {blockModel && (
                    <BlockModel
                        serviceProviderId={serviceProviderId}
                        blockDate={blockDate}
                        startTime={newAppointment.start}
                        endTime={newAppointment.end}
                        time_select={_userData?.user?.business_id?.time_select}
                        onClose={is_added => {
                            router.navigate('');
                            if (calendarRef.current !== null && is_added === true) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                            setBlockModel(false);
                        }}
                    />
                )}

                {blockDeleteModel && (
                    <DeleteModel
                        id={''}
                        onDelete={() => {
                            setBlockDeleteModel(false);
                        }}
                        onClose={() => {
                            setBlockDeleteModel(false);
                        }}
                        name={t('Block')}
                        content={t('Are you sure you want to delete this Block?')}
                        loading={loading}
                        onClick={() => {
                            deleteBlock();
                        }}
                    />
                )}

                {showConflictModal == true && (
                    <ConflictModal
                        dates={conflictDates}
                        recurringConflict={showConflictModal}
                        onContinue={() => {
                            setShowConflictModal(false);
                        }}
                        onClose={() => setShowConflictModal(false)}
                    />
                )}
                {reminderModel && <ReminderModel onClose={() => setReminderModel(false)} />}
            </Wrapper>
        );
    }
);

const Wrapper = styled.div`
    flex-direction: column !important;
    flex: 1 1 0% !important;
    display: flex !important;
`;

const Flex = styled.div`
    flex: 1 1 0% !important;
`;

const CustomerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    ${media.xs`
    font-size: 0.4rem;
  `}
    ${media.sm`
    font-size: 0.7rem;
  `}
	${media.md`
    font-size: 0.7rem;
  `}
	${media.lg`
    font-size: 0.8rem;
  `}
`;

export default CustomisedBookingsCalendar;
