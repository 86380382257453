/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Team Component
 * Description: This component serves as the main entry point for the team section. It includes the TeamMemberList and TeamCard components for managing and displaying team members.
 *
 */

import React, { useRef } from 'react';
import { Layout, TeamCard, TeamMemberList } from '@components/team';
import styled from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';
import { Routes, Route } from 'react-router-dom';

interface IProps {
    getTeamMember: () => void;
}

const Team = () => {
    const _useRef = useRef<IProps>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const callTeam = () => {
        if (_useRef?.current) {
            _useRef?.current?.getTeamMember();
        }
    };

    return (
        <Wrapper>
            {_userData.user_login === 'user' ? <TeamMemberList ref={_useRef} /> : ''}

            <Routes>
                <Route path=":id" element={<TeamCard callTeam={callTeam} />} />
            </Routes>

            <style>
                {`
                .customer-listing--main {
                    height: 100%;
                }
            `}
            </style>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0px;
    height: 100%;
    ${media.xs`
  display: block !important;
`}
    ${media.sm`
display: block !important;
`}
${media.md`
display: flex !important;
margin:0;
min-width:100%;
`}
${media.lg`
display: flex !important;
`}
`;

Team.Layout = Layout;
export default Team;
