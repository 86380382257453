/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Spinner
 * Description: Displays a spinner/loading indicator.
 * Purpose:
 * - Accepts an optional 'color' prop to customize the spinner color.
 * - Uses the 'spinner' class for styling, and optionally 'spinner--[color]' if a color is provided.
 *
 * Props:
 * - color?: string - Color for the spinner.
 */

import React from 'react';

const Spinner = ({ color }: { color?: string }) => {
    return <div className={`spinner ${color ? 'spinner--' + color : ''}`}></div>;
};

export default Spinner;
