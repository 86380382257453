/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomersList Component
 * Description: This component displays a list of customers, allowing users to search, view details, and perform actions such as exporting to PDF or sending messages to all customers. It supports pagination for loading more customers and provides a create customer modal for adding new customers.
 *
 * Props:
 * - hasId: any - A prop indicating whether a specific customer ID is present in the URL.
 *
 * Purpose: The CustomersList component enhances user interactions with customer data, providing a user-friendly interface for managing and interacting with customer information. It integrates features such as searching, exporting, and messaging to streamline user workflows.
 */

import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ICustomer, IGetCustomersResponse } from 'interfaces/customer.interface';
import useRouter from 'hooks/router';
import _ from 'lodash';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import SearchField from '@components/common/search/SearchField';
import ContentList from '@components/common/contentList/ContentList';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay } from '@components/common';
import Button from '@components/Button';
import { palette } from 'styled/common';
import { useTranslation } from 'react-i18next';
import { CloseButton, CustomDiv } from '../../team/list/serviceProviderList/ServiceProviderList';
import jsPDF from 'jspdf';
import SendMessage from '@components/common/SendMessage';
import CreateCustomer from '../createCustomer';

interface IProps {
    hasId: any;
}
const CustomersList = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({
        getCustomers() {
            getAllCustomers();
        },
    }));
    const navigate = useNavigate();
    const router = useRouter();
    const { t }: any = useTranslation();
    const [pageNumber, setPageNumber] = useState(0);
    const [customersData, setCustomersData] = useState<IGetCustomersResponse>();
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState<any>('');
    const [customer, setCustomer] = useState<any[]>([]);
    const observer = useRef<IntersectionObserver>();
    const [isSendMessage, setIsSendMessage] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [createModel, setCreateModel] = useState(false);
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user;

    useEffect(() => {
        if (customersData !== undefined && customersData.total !== 0) {
            setPageNumber(customersData && customersData.page);
            setValue((customersData !== undefined && customersData.query) ?? null);
            setQuery((customersData !== undefined && customersData.query) ?? null);
            setHasMore(true);
            return;
        }

        getAllCustomers();
    }, []);

    useEffect(() => {
        if (pageNumber > 0) {
            loadCustomers();
        }
    }, [pageNumber]);

    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {});
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllCustomers = (id = null) => {
        setLoading(true);
        api.get(`/customers?page=${0}&pageSize=${pageSize}`).then((customers: any) => {
            setCustomer(customers.data.customers);
            setCustomersData(customers.data);
            setTotal(customers.data.total);
            if (
                customers.data.customers?.length &&
                router.query[Object.keys(router.query)[0]] === '' &&
                window.innerWidth > 767
            ) {
                navigate(`/customers/${id ? id : customers.data.customers[0]._id}`);
            }

            setHasMore(customers.data.customers?.length > 0);
            setLoading(false);
        });
    };

    const loadCustomers = () => {
        setLoadMoreLoading(true);
        api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}`).then((customers: any) => {
            setCustomer((prevCustomers: any) => [...prevCustomers, ...customers.data.customers]);
            setCustomersData(customers.data);
            setTotal(customers.data.total);
            setHasMore(customers.data.customers?.length > 0);
            setLoadMoreLoading(false);
        });
    };

    const searchCustomers = (query: string) => {
        let value: any = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value.length <= 3) {
                value = value.replace(/[- .]/g, '');
            } else if (value.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}&query=${value}`).then(
                (customers: any) => {
                    setCustomer(customers.data.customers);
                    setLoading(false);
                    setHasMore(customers.data.customers.length > 0);
                    setCustomersData(customers.data);
                }
            );
        } else {
            setLoading(true);
            api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}&query=${value}`).then(
                (customers: any) => {
                    setCustomer(customers.data.customers);
                    setCustomersData(customers.data);
                    setLoading(false);
                    setHasMore(customers.data.customers?.length > 0);
                }
            );
        }
    };
    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };

    const searchCustomerDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(0);

            searchCustomers(searchVal);
        }, 300);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchCustomerDebounce(event.target.value);
    };

    const exportPDF = async () => {
        return api.post(`/customers/export_pdf`).then(async (res: any) => {
            let data: any[] = [];
            if (res.data?.length) {
                res?.data?.map((e: any) =>
                    data.push([e.fullname, e.email, e.phone, e.address ? e.address : '--'])
                );
            }
            await preparePdf(data);
        });
    };

    const preparePdf = async (data: any) => {
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'portrait'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = 'Customers';
        const headers = [['Customer Name', 'email', 'Phone', 'Address']];
        let content = {
            startY: 50,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        (doc as any)?.autoTable(content);
        doc.save('customers.pdf');
    };

    return (
        <>
            {createModel ? (
                <CreateCustomer
                    onClose={() => {
                        setCreateModel(false);
                        customer[0]?._id && navigate(`/customers/${customer[0]?._id}`);
                    }}
                    onSave={(id: any) => {
                        setCreateModel(false);
                        getAllCustomers(id);
                    }}
                />
            ) : (
                <Wrapper>
                    {loading && <LoaderOverlay />}
                    <CustomRow>
                        <CustomCol lg={12}>
                            <HeadingWrapper>
                                <CustomDiv>
                                    {_userData?.business_id?.leftMenu === true && (
                                        <CloseButton to="/customer">
                                            <i className="fal fa-chevron-left"></i>
                                        </CloseButton>
                                    )}
                                    <Header>{t('Customers')}</Header>
                                </CustomDiv>
                                <AddButton
                                    onClick={() => {
                                        navigate(`/customers`);
                                        setCreateModel(true);
                                    }}>
                                    <i className="fal fa-plus"></i>
                                </AddButton>
                            </HeadingWrapper>
                            {customer?.length ? (
                                <HeadingWrapper>
                                    <Button
                                        bgtype={'secondary'}
                                        ifClicked={() => exportPDF()}
                                        label={'Export Customers'}></Button>
                                    <Button
                                        bgtype={'secondary'}
                                        ifClicked={() => setIsSendMessage(true)}
                                        label={'Send Everyone'}></Button>
                                </HeadingWrapper>
                            ) : (
                                ''
                            )}
                            <OnSamllViewPort hasId={router.query['*']}>
                                <SearchField
                                    value={value}
                                    handleChange={handleChange}
                                    name={t(`Customer`)}
                                    setValue={() => {
                                        setValue('');
                                        if (value) {
                                            searchCustomers('');
                                        }
                                    }}
                                />
                                <CustomerList>
                                    {customer?.length
                                        ? customer.map((customer: ICustomer, i: any) => {
                                              const queryId =
                                                  router.query[Object.keys(router.query)[0]];
                                              return (
                                                  <ContentList
                                                      key={i}
                                                      queryId={`${
                                                          (queryId as string) === customer._id
                                                              ? 'isActivelist'
                                                              : ''
                                                      }`}
                                                      link={`/customers/${customer._id}`}
                                                      icon={customer?.photo}
                                                      name={
                                                          customer.firstname +
                                                          ' ' +
                                                          customer.lastname
                                                      }
                                                      phone={customer.phone}
                                                      email={customer?.email}
                                                  />
                                              );
                                          })
                                        : loading === false && (
                                              <NotFound>No customer found</NotFound>
                                          )}
                                    <div ref={loaderRef}></div>
                                    {query
                                        ? ''
                                        : total > (pageNumber + 1) * pageSize &&
                                          loading === false && (
                                              <LoadMore>
                                                  <Button
                                                      bgtype={'secondary'}
                                                      ifClicked={loadMore}
                                                      disabled={loadMoreLoading}
                                                      label={`${t('Load more customers')} (${
                                                          total - (pageNumber + 1) * pageSize
                                                      })`}></Button>
                                              </LoadMore>
                                          )}
                                </CustomerList>
                            </OnSamllViewPort>
                        </CustomCol>
                    </CustomRow>
                </Wrapper>
            )}
            {isSendMessage && (
                <SendMessage
                    parent={'sendAll'}
                    name={''}
                    phone={''}
                    senderEmail={_userData?.email}
                    onClose={() => {
                        setIsSendMessage(false);
                    }}
                />
            )}
        </>
    );
});

const Wrapper = styled(Container)`
    flex: 0 0 25rem;
    margin-left: 0rem;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    ${media.xs`
    padding: auto;
    flex: 0 0 25rem;
    margin-bottom: 1rem;
    `}
    ${media.sm`
    margin-top: 1rem;
    flex: 0 0 25rem;
    margin-bottom: 1rem;
    `}
	${media.md`
    margin-top: 0rem;
    padding: 0;
    flex: 0 0 27rem;
    `}
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 27rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
    `}
    ${media.xl`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
`}
`;

const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
`;

const CustomCol = styled(Col)`
    padding: 0;
`;

const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
    margin: 0px;
`;

const HeadingWrapper = styled.div`
    padding: 1.42rem 1rem 1rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-top: 0rem; */
`;

export const AddButton = styled.div<any>`
    color: ${({ color }) => (color ? 'black' : 'white')} !important;
    border-radius: 9999px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    opacity: 1;
    text-align: center;
    position: relative;
    background-color: ${palette.secondary};
    font-size: 1.3rem;
    :hover {
        background: ${palette.hover};
    }
`;

const CustomerList = styled.div`
    margin-top: 1rem;
    height: 40rem;
    overflow: auto;
`;

const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;

const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    ${media.xs`
	margin-bottom: 0.5rem;
	`}
`;

const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;

export default CustomersList;
