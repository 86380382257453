/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CallModal Component
 * Description: This component represents a modal for handling live calls. It uses AgoraRTC for real-time communication, allowing users to join and leave calls, mute/unmute audio, and disable/enable video.
 *
 * Props:
 * - openModal: boolean - Flag indicating whether the modal is open.
 * - onClose: () => void - Callback function triggered when the modal is closed.
 * - options: object - Configuration options for AgoraRTC, including `appId`, `app_name`, and `token`.
 * - setIsCallStarted: (isStarted: boolean) => void - Callback function triggered when the call is started or ended.
 *
 */

import React, { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { LoaderOverlay, useSnackbar } from '@components/common';

const CallModal = ({ openModal, onClose, options, setIsCallStarted }) => {
    const [isJoin, setIsJoin] = useState(false);
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        startLiveCall();
    }, [openModal]);

    useEffect(() => {
        if (isJoin === true) {
            openSnackbar('Invalid Token');
            setLoading(false);
        }
    }, [isJoin]);
    let mediaPlayer = document.createElement('div');
    let smallPlayer = document.createElement('div');
    let bottomControls = document.createElement('div');

    const rtc = {
        // For the local client.
        client: null,
        // For the local audio and video tracks.
        localAudioTrack: null,
        localVideoTrack: null,
    };

    // local user small ui on remote user join
    const createSmallPlayer = () => {
        let smallPlayer = document.createElement('div');
        smallPlayer.classList += 'smallPlayer';
        return smallPlayer;
    };

    const createBottomControls = () => {
        let _bottomControls = document.createElement('div');
        _bottomControls.classList += `row m-0 p-0 video-container-bottom new-div2 mb-2 gap-3 d-flex justify-content-center hide-on-small`;

        let muteControl = document.createElement('div');
        muteControl.classList += `bg-danger text-white d-flex justify-content-center align-items-center rounded-circle pointer control-btn hide-on-small col-1`;
        let muteIcon = document.createElement('i');
        muteIcon.classList += 'fal fa-microphone-slash fs-5';
        muteControl.append(muteIcon);
        muteControl.addEventListener('click', () => {
            if (muteIcon.classList == 'fal fa-volume-full fs-5') {
                muteIcon.classList = 'fal fa-microphone-slash fs-5';
                rtc.localAudioTrack.setEnabled(true);
            } else {
                muteIcon.classList = 'fal fa-volume-full fs-5';
                rtc.localAudioTrack.setEnabled(false);
            }
        });

        let leaveControl = document.createElement('div');
        leaveControl.classList += `bg-danger text-white d-flex justify-content-center align-items-center rounded-circle  pointer control-btn hide-on-small col-1`;
        let leaveIcon = document.createElement('i');
        leaveIcon.classList += 'fal fa-phone fs-5 rotate';
        leaveControl.append(leaveIcon);
        leaveControl.addEventListener('click', () => {
            leaveCall();
        });

        let muteVideoControl = document.createElement('div');
        muteVideoControl.classList += `bg-danger text-white d-flex justify-content-center align-items-center rounded-circle  pointer control-btn hide-on-small col-1`;
        let muteVideoIcon = document.createElement('i');
        muteVideoIcon.classList += 'fal fa-video-slash fs-5';
        muteVideoControl.append(muteVideoIcon);
        muteVideoControl.addEventListener('click', () => {
            if (muteVideoIcon.classList == 'fal fa-video fs-5') {
                muteVideoIcon.classList = 'fal fa-video-slash fs-5';
                rtc.localVideoTrack.setEnabled(true);
            } else {
                muteVideoIcon.classList = 'fal fa-video fs-5';
                rtc.localVideoTrack.setEnabled(false);
            }
        });

        _bottomControls.append(muteControl);
        _bottomControls.append(leaveControl);
        _bottomControls.append(muteVideoControl);

        return _bottomControls;
    };

    const startLiveCall = async () => {
        setLoading(true);
        rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
        console.log('person joined ', 1, options.token);
        let uid = '';
        try {
            uid = await rtc.client.join(options.appId, options.app_name, options.token);
        } catch (error) {
            setIsJoin(true);
            console.log('person joined 0000');
        }

        console.log('person joined ', 2, uid);
        if (uid) {
            setLoading(false);
            // Create an audio track from the audio sampled by a microphone.
            rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            // Create a video track from the video captured by a camera.
            rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
            console.log(3);
            // Publish the local audio and video tracks to the channel.
            await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
            // Dynamically create a container in the form of a DIV element for playing the remote video track.
            console.log(4);

            mediaPlayer.classList += 'row m-0 p-0 mediaPlayer w-100';
            bottomControls = createBottomControls();
            let wrapper = document.createElement('div');
            wrapper.classList += 'm-0 p-0';
            wrapper.append(mediaPlayer);

            let parentDiv = document.getElementById('mediaContainer');
            if (parentDiv != null) {
                parentDiv.appendChild(wrapper);
            }
            rtc.localVideoTrack.play(mediaPlayer);

            setIsCallStarted(true);

            console.log('publish success!', rtc.client, 'uid', uid);
            rtc.client.on('user-published', async (user, mediaType) => {
                // Subscribe to a remote user.
                await rtc.client.subscribe(user, mediaType);
                // If the subscribed track is video.
                if (mediaType === 'video') {
                    // Get `RemoteVideoTrack` in the `user` object.
                    const remoteVideoTrack = user.videoTrack;

                    smallPlayer = createSmallPlayer();

                    remoteVideoTrack.play(mediaPlayer);

                    rtc.localVideoTrack.play(smallPlayer);

                    mediaPlayer.append(smallPlayer);
                    wrapper.append(mediaPlayer);
                    wrapper.append(bottomControls);
                }

                // If the subscribed track is audio.
                if (mediaType === 'audio') {
                    // Get `RemoteAudioTrack` in the `user` object.
                    const remoteAudioTrack = user.audioTrack;
                    // Play the audio track. No need to pass any DOM element.
                    remoteAudioTrack.play();
                }
                rtc.client.on('user-unpublished', user => {
                    smallPlayer.style.display = 'none';
                    mediaPlayer.classList +=
                        'mediaPlayer row m-0 p-0 border-left border-right video-container-medium d-flex justify-content-center align-items-center';
                    mediaPlayer.innerHTML =
                        'Ending up the call. This window will close automatically';
                    leaveCall();
                });
            });
        }
        setLoading(false);
    };

    async function leaveCall() {
        // leaveChannel
        mediaPlayer.classList = 'd-none';
        smallPlayer.classList = 'd-none';
        bottomControls.classList = 'd-none';
        // Destroy the local audio and video tracks.
        rtc.localAudioTrack.close();
        rtc.localVideoTrack.close();

        // Traverse all remote users.
        rtc.client.remoteUsers.forEach(user => {
            // Destroy the dynamically created DIV container.
            const playerContainer = document.getElementById(user.uid);
            playerContainer && playerContainer.remove();
        });

        // Leave the channel.
        await rtc.client.leave();
        setIsCallStarted(false);
        onClose();
    }

    return <>{loading && <LoaderOverlay />}</>;
};
export default CallModal;
