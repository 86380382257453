/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: SelectService
 * Description: This component represents the service selection modal, allowing users to search and select a service for a new appointment.
 * It uses React, styled-components for styling, and interacts with the backend through API calls to fetch and display service information.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the modal is closed.
 * - onSelect: (service: IService) => void - Callback function triggered when a service is selected. Receives the selected service as a parameter.
 * - id: string - The identifier for the service provider to fetch information.
 * - selectedService: any - Represents the currently selected service. Used to highlight the selected service in the UI.
 *
 */

import { IService } from 'interfaces/service.interface';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import s from './SelectServices.module.scss';
import { useTranslation } from 'react-i18next';
import { api } from 'helpers/auth-axios';
import { Spinner } from '@components/common';
import { palette } from 'styled/common';
const SelectService = ({
    onClose,
    onSelect,
    selectedService,
    id,
}: {
    onClose(): void;
    onSelect(service: IService): void;
    id: string;
    selectedService: any;
}) => {
    const { t }: any = useTranslation();
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [loading, setLoading] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        if (id !== undefined) {
            setLoading(true);
            api.get(`/serviceProvider/${id}`)
                .then((res: any) => {
                    setLoading(false);
                    if (res.data) {
                        setServiceProvider(res.data);
                    }
                })
                .catch(e => {
                    setLoading(false);
                });
        }
    }, [id]);

    const select = (service: IService) => {
        onSelect(service);
        onClose();
    };

    return (
        <>
            <Container>
                <Row>
                    <ServicesContent>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-chevron-left"></Icon>
                            </CloseButton>
                            <Header>{t('Select Service')}</Header>
                        </HeaderContainer>
                        {id === undefined ? (
                            <div>{t('Please Select Service Provider First')}</div>
                        ) : (
                            <ServicesBody>
                                {loading ? (
                                    <Spinner color={'black'} />
                                ) : (
                                    serviceProvider &&
                                    (serviceProvider?.services?.length ? (
                                        <Wrapper>
                                            {serviceProvider.services.map(
                                                (service: any, i: any) => (
                                                    <ServicesList
                                                        key={i}
                                                        className={`service-color-${
                                                            service?.color
                                                        }--bg ${s.services_service} ${
                                                            selectedService !== undefined &&
                                                            selectedService?._id ===
                                                                service?.service_id
                                                                ? s.services_selectedService
                                                                : ''
                                                        }  cursor-pointer service-color-${
                                                            service?.color
                                                        }--bd`}
                                                        onClick={() => select(service)}>
                                                        <Label>
                                                            &nbsp;
                                                            {_userData.user.business_id.currency}
                                                            {service?.my_price}
                                                        </Label>
                                                        <CustomDiv>
                                                            <Name>{service.name}</Name>
                                                            {service?.is_video && (
                                                                <i className="fal fa-video"></i>
                                                            )}
                                                        </CustomDiv>
                                                        <ServiceDuration>
                                                            {`${
                                                                service?.my_duration >= 60
                                                                    ? Math.floor(
                                                                          service?.my_duration / 60
                                                                      ) + 'h'
                                                                    : ''
                                                            } ${service.my_duration % 60}${t(
                                                                'min'
                                                            )}`}
                                                        </ServiceDuration>
                                                    </ServicesList>
                                                )
                                            )}
                                        </Wrapper>
                                    ) : (
                                        <div>
                                            {t('No Service available for this serviceProvider')}
                                        </div>
                                    ))
                                )}
                            </ServicesBody>
                        )}
                    </ServicesContent>
                </Row>
            </Container>
        </>
    );
};

const ServicesContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;

const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    line-height: 2.5rem;
`;

const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

const ServicesBody = styled.div`
    padding: 0 1.75rem;
    overflow-y: auto;
    height: 44rem;
`;
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem /* 16px */;
`;

const ServicesList = styled.div`
    border-radius: 0.5rem !important;
    padding: 26px 20px !important;
    position: relative;
    cursor: pointer !important;
`;

const Label = styled.div`
    background-color: ${palette.green};
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${palette.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    font-size: 0.9rem;
`;

const CustomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default SelectService;
