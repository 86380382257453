/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateNewBusiness Component
 * Description: This component allows users to create a new business with various settings, including business details, location on the map, notifications.
 *
 * Purpose:
 * - Enables users to create a new business with specific settings.
 * - Utilizes Mapbox for location selection.
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, LoaderOverlay, Select, Switch, useSnackbar } from '@components/common';
import { bookingWindow, businessTypes } from 'constants/data';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import states from 'states-us';
import Compressor from 'compressorjs';
import useRouter from 'hooks/router';
import { CustomRow } from '@components/team/create/createTeamMember/CreateTeamMember';
import { CardHead, CustomCard, Header, Para } from '../bookingSettings/BookingSettings';
import { Heading } from '../businessServices/EditService';

if (process.env.REACT_APP_MAPBOX_TOKEN !== undefined) {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
}

const CreateNewBusiness = () => {
    const { t }: any = useTranslation();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [business, setBusiness] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [sms, setSms] = useState<any>();
    const [push_notification, setPush_notification] = useState<any>();
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [leftMenu, setLeftMenu] = useState(false);
    const mapContainer: any = useRef(null);
    const map: any = useRef(null);
    const geocoder: any = useRef(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const [openSnackbar] = useSnackbar();
    const [isLoyaltyDiscountFocused, setLoyaltyDiscountFocus] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),

        loyality_discount: isLoyaltyDiscountFocused
            ? Yup.string()
                  .required(t('This field is required'))
                  .test(
                      'is-less-than-100',
                      t('Value must not be greater than 100%'),
                      (value: any) => {
                          const numericValue = parseFloat(value);
                          return isNaN(numericValue) || numericValue <= 100;
                      }
                  )
            : Yup.string(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger, getValues } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const router = useRouter();
    const [invoice, setInvoice] = useState(false);
    const [useCustomCalendar, setUseCustomCalendar] = useState<any>(false);
    const [ignoreWorkingHours, setIgnoreWorkingHours] = useState(false);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [0, 0],
            zoom: 9,
        });
        geocoder.current = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            // @ts-ignore
            mapboxgl: mapboxgl,
            placeholder: t('Search on map'),
        });
        map.current.addControl(geocoder.current);
        if (!map.current) return;
        geocoder.current.on('result', (e: any) => {
            const result = e.result;
            if (typeof result.geometry.coordinates == 'object') {
                setLat(result.geometry.coordinates[1]);
                setLng(result.geometry.coordinates[0]);
            }
            setAddress(result?.place_name);
            if (result.context) {
                result.context.forEach((v: any, i: any) => {
                    if (v.id.indexOf('place') >= 0) {
                        setCity(v.text);
                    }
                    if (v.id.indexOf('postcode') >= 0) {
                        setPostcode(v.text);
                    }
                    if (v.id.indexOf('region') >= 0) {
                        setState(v.text);
                    }
                });
            }
        });
    });

    let languageDropDown = [
        {
            label: t('English'),
            value: 'en',
        },
        { label: t('French'), value: 'fr' },
        { label: t('Arabic'), value: 'rs' },
    ];

    let location = {
        zipcode: postcode,
        address: address,
        address2: address2,
        city: city,
        state: state,
        coordinates: {
            latitude: lat,
            longitude: lng,
        },
    };

    const onSubmit = (data: any) => {
        setLoading(true);
        const notifications = {
            email: email,
            sms: sms,
            push_notification: push_notification,
        };
        const businessDetails = {
            ...data,
            newPhoto: uploadImage == true ? profileImage : null,
            notifications: notifications,
            leftMenu: leftMenu,
            location: location,
            invoice: invoice,
            ignore_workingHours: ignoreWorkingHours,
            customCalendar: useCustomCalendar,
        };
        api.post(
            `businesses/add`,
            uploadImage == true
                ? businessDetails
                : {
                      ...data,
                      notifications: notifications,
                      leftMenu: leftMenu,
                      location: location,
                      invoice: invoice,
                      ignore_workingHours: ignoreWorkingHours,
                      customCalendar: useCustomCalendar,
                  }
        )
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t('Business created successfully!'));
                router.navigate('/settings/details');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone?.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                setUploadImage(true);
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    const States: { label: string; value: any }[] = [];
    states?.length &&
        states.map(x => {
            States.push({ label: x.name, value: x.name });
        });
    return (
        <>
            {loading && <LoaderOverlay />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Wrapper>
                    <Row>
                        <Col lg={12}>
                            <BusniessCard
                                htmlFor="faceImage"
                                aria-label="upload picture"
                                style={{
                                    backgroundImage: `url(${
                                        uploadImage === true
                                            ? profileImage
                                            : profileImage &&
                                              `${process.env.REACT_APP_PROFILE_URL}${profileImage}`
                                    })`,
                                }}>
                                {' '}
                                {profileImage ? '' : <LogoDiv>{t('Upload Business Logo')}</LogoDiv>}
                            </BusniessCard>
                            <FileInput
                                accept="image/jpeg/png"
                                id="faceImage"
                                type="file"
                                onChange={handleCapture}
                            />
                        </Col>
                        <CustomCol lg={6}>
                            <Card>
                                <InputGroup>
                                    <Input
                                        label={t('What is the name of your business?')}
                                        name="name"
                                        value={getValues('name')}
                                        onChange={onChange}
                                        error={errors.name as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label={t('What is your business phone number?')}
                                        name="phone"
                                        value={getValues('phone')}
                                        allowPhoneNumberOnly={true}
                                        onChange={onChange}
                                        maxLength={12}
                                        error={errors.phone as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label={t('What is your business website URL?')}
                                        name="business_url"
                                        value={business?.business_url}
                                        onChange={onChange}
                                        error={errors.business_url as any}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Select
                                        disabled={true}
                                        value={
                                            getValues('business_type')
                                                ? getValues('business_type')
                                                : businessTypes[0]?.label
                                        }
                                        name="business_type"
                                        label={t('What type of business are you running?')}
                                        options={businessTypes}
                                        onChange={(e: any) =>
                                            onChange({ name: 'business_type', value: e })
                                        }
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Select
                                        disabled={true}
                                        value={
                                            getValues('timezone')
                                                ? getValues('timezone')
                                                : 'America/Chicago'
                                        }
                                        label={t('Time Zone')}
                                        onChange={(val: string) =>
                                            onChange({ value: val, name: 'timezone' })
                                        }
                                        options={moment.tz.names().map(timezone => {
                                            return { label: timezone };
                                        })}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Select
                                        disabled={true}
                                        value={
                                            getValues('language') === 'en'
                                                ? t('English')
                                                : getValues('language') === 'fr'
                                                ? t('French')
                                                : getValues('language') === 'rs'
                                                ? t('Arabic')
                                                : t('English')
                                        }
                                        label={t('Language')}
                                        onChange={(val: string) =>
                                            onChange({ value: val, name: 'language' })
                                        }
                                        options={languageDropDown}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        name={'loyality_discount'}
                                        label={`${t('Loyality Discount (%)')}`}
                                        allowOnlyNumbersAndDecimal={true}
                                        onChange={onChange}
                                        maxLength={3}
                                        value={getValues('loyality_discount')}
                                        onFocus={() => setLoyaltyDiscountFocus(true)}
                                        error={errors.loyality_discount as any}
                                    />
                                </InputGroup>
                                <CardList>
                                    <div>{t('Left Menu')}</div>
                                    <Switch
                                        onChange={(val: boolean) => setLeftMenu(val)}
                                        value={leftMenu}
                                    />
                                </CardList>

                                <Divider />
                                <CardList>
                                    <div>{t('Email Notification')}</div>
                                    <Switch
                                        onChange={(val: boolean) => setEmail(val)}
                                        value={email}
                                    />
                                </CardList>
                                <Divider />
                                <CardList>
                                    <div>{t('SMS Notification')}</div>
                                    <Switch onChange={(val: boolean) => setSms(val)} value={sms} />
                                </CardList>
                                <Divider />
                                <CardList>
                                    <div>{t('Push Notification')}</div>
                                    <Switch
                                        onChange={(val: boolean) => setPush_notification(val)}
                                        value={push_notification}
                                    />
                                </CardList>
                                <Divider />
                            </Card>
                        </CustomCol>
                        <CustomCol lg={6}>
                            <SettingCard style={{ paddingTop: '0.17rem !important' }}>
                                <Map ref={mapContainer} />
                                <style>{`
                                            .mapboxgl-ctrl-top-right {
                                                width: 100% !important;
                                                padding: 0.71rem !important;
                                                top: 0;
                                                right: 0;
                                            }
                                            .mapboxgl-ctrl-geocoder {
                                                max-width: 100% !important;
                                                width: 100% !important;
                                                margin: 0 !important;
                                            }
                                        `}</style>
                                <InputGroup>
                                    <Input
                                        label={t('Street address & number')}
                                        value={address}
                                        onChange={({ value }: { value: any }) => {
                                            setAddress(value);
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label={t('Suite number (optional)')}
                                        value={address2}
                                        onChange={({ value }: { value: any }) => {
                                            setAddress2(value);
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label={t('City')}
                                        value={city}
                                        onChange={({ value }: { value: any }) => {
                                            setCity(value);
                                        }}
                                    />
                                </InputGroup>
                                <Wrapper>
                                    <Row>
                                        <CustomCol lg={7} margin={true}>
                                            <InputGroup>
                                                <Select
                                                    label={t('State')}
                                                    disabled={true}
                                                    value={state}
                                                    options={States}
                                                    onChange={val => setState(val)}
                                                />
                                            </InputGroup>
                                        </CustomCol>
                                        <Col lg={5}>
                                            <InputGroup>
                                                <Input
                                                    label={t('Postal Code')}
                                                    value={postcode}
                                                    onChange={({ value }: { value: any }) => {
                                                        setPostcode(value);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Wrapper>
                            </SettingCard>
                        </CustomCol>
                    </Row>
                </Wrapper>

                <CustomRow>
                    <Col lg={12}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '1rem 0rem',
                                width: '100%',
                            }}>
                            <Header>{t('Calendar Settings')}</Header>
                        </div>
                        <Row>
                            <CustomCol lg={6}>
                                <CustomCard>
                                    <CardHead>
                                        <Heading>{t('Booking Rules')}</Heading>
                                        <Para>
                                            {t(
                                                'Write your own rules regarding booking, cancellation and rescheduling.'
                                            )}
                                        </Para>
                                    </CardHead>
                                    <InputGroup>
                                        <Input
                                            label={t('Cancellation Policy - Coming Soon')}
                                            name="cancellation_policy"
                                            value={business?.cancellation_policy}
                                            onChange={onChange}
                                            error={errors?.cancellation_policy as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label={t('Rescheduling Policy - Coming Soon')}
                                            name="rescheduling_policy"
                                            value={business?.rescheduling_policy}
                                            onChange={onChange}
                                            error={errors.rescheduling_policy as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            label={t('Booking Leeway -  Coming Soon')}
                                            disabled={true}
                                            value={business?.booking_reschedule_time}
                                            options={bookingWindow.map((val: any) => {
                                                return {
                                                    value: val,
                                                    label: `${t('No less than')} ${val} ${t(
                                                        'in advance'
                                                    )}`,
                                                };
                                            })}
                                            onChange={(val: string) => {
                                                onChange({
                                                    name: 'booking_reschedule_time',
                                                    value: val,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            label={t('Calendar size')}
                                            disabled={true}
                                            value={
                                                getValues('calendar_size')
                                                    ? getValues('calendar_size')
                                                    : '15'
                                            }
                                            options={[
                                                {
                                                    label: '15',
                                                },
                                                {
                                                    label: '30',
                                                },
                                                {
                                                    label: '60',
                                                },
                                            ]}
                                            onChange={(val: string) => {
                                                onChange({
                                                    name: 'calendar_size',
                                                    value: val,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            label={t('Currency')}
                                            disabled={true}
                                            value={
                                                getValues('currency') === '€'
                                                    ? 'Euro (Euro)'
                                                    : getValues('currency') === '£'
                                                    ? 'Pound (Pound)'
                                                    : 'Dollar (USD)'
                                            }
                                            options={[
                                                {
                                                    value: '$',
                                                    label: t('Dollar (USD)'),
                                                },
                                                {
                                                    value: '£',
                                                    label: t('Pound (Pound)'),
                                                },
                                                {
                                                    value: '€',
                                                    label: t('Euro (Euro)'),
                                                },
                                            ]}
                                            onChange={(val: string) => {
                                                onChange({
                                                    name: 'currency',
                                                    value: val,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            label={t('Military time vs Standard Time')}
                                            value={
                                                getValues('time_select')
                                                    ? getValues('time_select')
                                                    : '12'
                                            }
                                            disabled={true}
                                            options={[
                                                {
                                                    value: '12',
                                                    label: '12',
                                                },
                                                {
                                                    value: '24',
                                                    label: '24',
                                                },
                                            ]}
                                            onChange={(val: string) => {
                                                onChange({
                                                    name: 'time_select',
                                                    value: val,
                                                });
                                            }}
                                        />
                                    </InputGroup>

                                    <CustomButtom>
                                        <Button
                                            type="submit"
                                            bgtype="secondary"
                                            label={t('Add')}></Button>
                                    </CustomButtom>
                                </CustomCard>
                            </CustomCol>
                            <CustomCol lg={6}>
                                <SettingCard style={{ paddingTop: '0.17rem !important' }}>
                                    <CardList>
                                        <div>{t('Book by First Available')}</div>
                                        <Switch
                                            value={business?.booking_without_payment}
                                            onChange={(val: any) => {
                                                onChange({
                                                    name: 'booking_mode',
                                                    value: val,
                                                });
                                            }}
                                        />
                                    </CardList>
                                    <Divider />
                                    <CardList>
                                        <div>{t('Show Invoice')}</div>
                                        <Switch
                                            onChange={(val: boolean) => setInvoice(val)}
                                            value={invoice}
                                        />
                                    </CardList>
                                </SettingCard>
                            </CustomCol>
                        </Row>
                    </Col>
                </CustomRow>
            </form>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const LogoDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
`;
const CustomCol = styled(Col)<any>`
    margin-top: ${({ margin }) => (margin ? '' : '1rem')};
`;

const Card = styled.div`
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const BusniessCard = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: end;
    border-radius: 0.5rem;
    color: ${palette.white};
    height: 15.71rem;
    width: 100%;
    background-position: 50%;
    background-size: cover;
    position: relative;
    padding: 1.71rem 1.71rem 1.14rem 1.14rem;
    z-index: 0;
    overflow: hidden;
    cursor: pointer;
`;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;

const SettingCard = styled.div`
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 1.26rem 2rem !important;
`;

const CardList = styled.div`
    align-items: center !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: space-between;
`;

const Divider = styled.hr`
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border: 0.1rem none none none solid ${palette.grey.lightest};
    border-top: none;
    border-right: none;
    border-left: none;
`;

const FileInput = styled.input`
    display: none;
`;

export const CustomButtom = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Map = styled.div`
    margin-top: 0rem;
    height: 24.57rem;
    font: 0.85rem/1.42rem Helvetica Neue, Arial, Helvetica, sans-serif !important;
    overflow: hidden !important;
    position: relative !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 1rem;
    ${media.xs`
    margin-top: 1.8rem;
  `}
    ${media.sm`
    margin-top: 1.8rem;
  `}
    ${media.md`
    margin-top: 1.8rem;
  `}
    ${media.lg`
    margin-top: 0rem;
  `}
`;

export default CreateNewBusiness;
