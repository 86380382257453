/**
 * Utility Functions
 *
 * - isDocumentElement(el: HTMLElement): boolean
 *   Checks if the provided element is the document element, body, or window.
 *
 * - scrollIntoView(menuEl: HTMLElement, focusedEl: HTMLElement): void
 *   Scrolls the menu element to bring the focused element into view.
 *
 * - scrollTo(el: HTMLElement, top: number): void
 *   Scrolls the provided element to the specified top position.
 *
 * - round(number: number | string, places: number = 2): number
 *   Rounds the given number to the specified decimal places.
 *
 * - calculateHoursDifference(from: any, to: any): Promise<number>
 *   Calculates the time difference in hours between two time values.
 *
 * - checkHoursValue(from: any, to: any): Promise<moment.Moment>
 *   Checks if the start time is less than the end time and returns the start time.
 *   Used for validating time values.
 *
 */

import moment from 'moment';

export function isDocumentElement(el: HTMLElement) {
    return [document.documentElement, document.body, window].indexOf(el) > -1;
}

export function scrollIntoView(menuEl: HTMLElement, focusedEl: HTMLElement): void {
    const menuRect = menuEl.getBoundingClientRect();
    const focusedRect = focusedEl.getBoundingClientRect();
    const overScroll = focusedEl.offsetHeight;

    if (focusedRect.bottom + overScroll > menuRect.bottom) {
        scrollTo(
            menuEl,
            Math.min(
                focusedEl.offsetTop + focusedEl.clientHeight - menuEl.offsetHeight + overScroll,
                menuEl.scrollHeight
            )
        );
    } else if (focusedRect.top - overScroll < menuRect.top) {
        scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
    }
}

export function scrollTo(el: HTMLElement, top: number): void {
    if (isDocumentElement(el)) {
        window.scrollTo(0, top);
        return;
    }

    el.scrollTop = top;
}

export function round(number: number | string, places: number = 2): number {
    return Number(Math.round(parseFloat(number + 'e' + places)) + 'e-' + places);
}

export async function calculateHoursDifference(from: any, to: any) {
    let Start_Time = moment(`02-01-2021 ${from}`, 'DD-MM-YYYY hh:mm:ss');
    let End_Time = moment(`02-01-2021 ${to}`, 'DD-MM-YYYY hh:mm:ss');
    return End_Time.diff(Start_Time, 'hours');
}

export async function checkHoursValue(from: any, to: any) {
    let Start_Time = moment(`${from}`, 'hh:mm:ss');
    let End_Time = moment(`${to}`, 'hh:mm:ss');
    if (Start_Time < End_Time) {
        return Start_Time;
    }
}
