/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SearchField Component
 *
 * Description:
 * - Renders a search input field with an icon.
 * - Allows users to search based on the provided name.
 *
 * Props:
 * - value: string - The current value of the search input.
 * - handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void - Callback function triggered on input change and return value to parent component.
 * - name: string - The name used for the search placeholder.
 * - setValue: () => void - Callback function triggered to set/clear the search input.
 */

import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export interface SearchProps {
    value?: string;
    handleChange?: any;
    name?: string;
    setValue?: any;
}

const SearchField = ({ value, handleChange, name, setValue }: SearchProps) => {
    return (
        <Container>
            <Search>
                <SearchWrapper md={12} lg={12}>
                    <SearchIcon>
                        <i className="fal fa-search"></i>
                    </SearchIcon>
                    <Input
                        type="text"
                        value={value ?? ''}
                        onChange={handleChange}
                        placeholder={`Search ${name}...`}
                    />
                    <SearchCrossIcon onClick={setValue}>
                        <i className="fal fa-times"></i>
                    </SearchCrossIcon>
                </SearchWrapper>
            </Search>
        </Container>
    );
};

const Search = styled(Row)`
    margin-top: 1rem;
    padding: 0 24px;
`;

const SearchWrapper = styled(Col)`
    position: relative;
    padding: 0;
`;

const SearchIcon = styled.span`
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    color: #b0b3bf;
`;

const SearchCrossIcon = styled.span`
    position: absolute;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    color: #7a7b80;
    cursor: pointer;
`;

const Input = styled.input`
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 500;
    line-height: 1.5;
    background-color: #f4f5f6;
    border: 1px solid #f4f5f6;
    appearance: none;
    border-radius: 0.475rem;
    outline: none;
    padding-left: 3.25rem;
    &::placeholder {
        font-weight: 400;
        color: #b0b3bf;
    }
    &:focus {
        background-color: #ebeff3;
        border-color: #ebeff3;
        color: #3a3b3f;
    }
`;

export default SearchField;
