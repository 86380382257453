/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Business Service
 * Description: This service handles API requests related to Business and to create/update/update working hours Business.
 */

import { api } from '../helpers/auth-axios';
import { IBusiness, ILocation, IOpenHours, IBookingSettings } from 'interfaces/business.interface';

export const businessService = {
    getBusiness,
    updateBusiness,
    updateOpenHours,
    updateLocation,
    updateBookingSettings,
};

function getBusiness(businessId: string) {
    return api.get(`businesses/${businessId}`).then(res => {
        return res.data;
    });
}

function updateBusiness(businessId: string, business: IBusiness) {
    return api.put(`businesses/${businessId}`, business).then(res => {
        return res.data;
    });
}

function updateOpenHours(businessId: string, openHours: IOpenHours) {
    return api.put(`businesses/${businessId}/open-hours`, openHours).then(res => {
        return res.data;
    });
}

function updateLocation(businessId: string, location: ILocation) {
    return api.put(`businesses/${businessId}/location`, location).then(res => {
        return res.data;
    });
}

function updateBookingSettings(businessId: string, settings: IBookingSettings) {
    return api.put(`businesses/${businessId}`, settings).then(res => {
        return res.data;
    });
}
