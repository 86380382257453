/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Payment Options
 * Description: This file defines an array of payment options with their respective icons, codes, and labels for sale.
 */

export const paymentOptions = [
    {
        icon: 'fab fa-cc-amex',
        code: 'american_express',
        label: 'Credit Card',
    },
    {
        icon: 'fal fa-money-bill',
        code: 'cash',
        label: 'Cash',
    },
    // {
    //     icon: 'fal fa-money-check',
    //     code: 'cheque',
    //     label: 'Cheque',
    // },
    // {
    //     icon: 'fab fa-cc-paypal',
    //     code: 'paypal',
    //     label: 'Paypal',
    // },
    // {
    //     icon: 'fal fa-gift-card',
    //     code: 'gift_card',
    //     label: 'Gift Card',
    // },
];
