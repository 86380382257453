/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ForgotPassword Component
 * Description: This component handles the process of sending a password reset email.
 * It includes functionalities such as clearing user and URL data from local storage, displaying snackbar messages, and sending a POST request to the server for password reset.
 *
 * Props:
 * - setForgotPassword: (value: boolean) => void - Callback function triggered when the forgot password state is changed.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';

const ForgotPassword = ({ setForgotPassword }: { setForgotPassword: any }) => {
    const { t }: any = useTranslation();

    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    }, []);

    const [openSnackbar] = useSnackbar();
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(t('This field is required')),
        password: Yup.string().required(t('This field is required')),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { formState } = useForm(formOptions);
    const { errors } = formState;

    const onForgotPassword = () => {
        setLoading(true);
        api.post('/auth/forgot_password', {
            email: email,
            role: 'customer',
            businessId: window.location.pathname.slice(1, 25),
        })
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t('Email sent to your email address Successfully'));
                setForgotPassword(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e.response) {
                    openSnackbar(t('Incorrect email'));
                }
            });
    };

    return (
        <>
            <InputGroup>
                <Input
                    label={t('Email')}
                    name="email"
                    type="email"
                    onChange={(e: any) => setEmail(e.value)}
                    error={errors.email as any}
                />
            </InputGroup>

            <Button
                ifClicked={() => onForgotPassword()}
                label={t('Log In')}
                width={'100% !important'}
                bgtype={'secondary'}
                disabled={loading}
            />
        </>
    );
};

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

export default ForgotPassword;
