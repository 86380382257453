/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateTeam Component for creating team member user.
 *
 * Component Description:
 * - Imports necessary components and dependencies from react.
 * - Renders the CreateTeam component within the specified Layout.
 * - Exports the CreateTeam component to use for routing.
 */
import { Layout, CreateTeam as Team } from '@components/team';

const CreateTeam = () => {
    return <Team />;
};

CreateTeam.Layout = Layout;
export default CreateTeam;
