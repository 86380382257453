/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: BrandInfo
 * Description: This component represents the brand information including the logo and brand name.
 *
 * Dependencies:
 * - React
 * - styled-components
 * - styled-bootstrap-grid (media)
 * - react-i18next
 *
 * Usage:
 * - Import this component to display the brand logo and name.
 */

import logo from '../../../images/Rocket.png';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const BrandInfo = () => {
    const { t } = useTranslation();

    return (
        <>
            {' '}
            <BrandContent>
                <Logo src={logo} alt="Logo" />
            </BrandContent>
            <BrandName>{t('app_name')}</BrandName>
        </>
    );
};

const BrandContent = styled.div`
    padding: 0.8rem 0rem 0.8rem 1.8rem;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    color: ${palette.white};
    font-size: 2rem;
`;

const BrandName = styled.div`
    padding: 0.8rem 0rem 0.8rem 0rem;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    color: ${palette.white};
    font-size: 2rem;
    ${media.xs`
    font-size: 1.5rem;
  `}
    ${media.sm`
    font-size: 1.5rem;
  `}
    ${media.md`
    font-size: 1.5rem;
  `}
    ${media.lg`
    font-size: 1.7rem;
  `}
  ${media.xl`
  font-size: 2rem;
  `}
`;

const Logo = styled.img`
    width: 3rem;
`;

export default BrandInfo;
