/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Default variables used commonly in web app like application name and logo of the application.
 */

export const CommonEnum = Object.freeze({
    AppName: 'eBookingSystem',
    logo: '/logo.png',
});
