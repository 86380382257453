/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * EditCustomer Component
 * Description: React component for editing customer details, including updating profile information and uploading a new profile image.
 * This component fetches customer data based on the provided customer ID and allows users to modify various details such as email, name, phone, address, and profile image.
 *
 * Props:
 * - callBack: () => void - Callback function triggered after successfully updating customer details.
 *
 */

import React, { useEffect, useState } from 'react';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ICustomer } from 'interfaces/customer.interface';
import useRouter from 'hooks/router';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import { palette } from 'styled/common';

const EditCustomer = ({ callBack }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [customer, setCustomer] = useState<ICustomer>();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape(
        {
            email: Yup.string().email().required(t('This field is required')),
            firstname: Yup.string().required(t('This field is required')),
            lastname: Yup.string().required(t('This field is required')),
            phone: Yup.string()
                .min(10, t('Phone must be a valid phone'))
                .required(t('This field is required')),
            updatePassword: Yup.string().when('updatePassword', (val: any, schema: any) => {
                if (val?.length > 0) {
                    return Yup.string()
                        .required(t('This field is required'))
                        .min(8, t('Password must be at least 8 characters'));
                } else {
                    return Yup.string().notRequired();
                }
            }),
        },
        [['updatePassword', 'updatePassword']]
    );

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        setUploadImage(false);
        api.get(`/customers/${router.query.id as string}`)
            .then((customer: any) => {
                setCustomer(customer.data.customer);
                setValue('firstname', customer.data.customer.firstname);
                setValue('lastname', customer.data.customer.lastname);
                setValue('phone', customer.data.customer.phone);
                setValue('address', customer.data.customer.address);
                setValue('address2', customer.data.customer.address2);
                setValue('city', customer.data.customer.city);
                setValue('zipcode', customer.data.customer.zipcode);
                setValue('email', customer.data.customer.email);
                setValue('updatePassword', '');
                setProfileImage(customer.data.customer.photo);
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(e?.response?.data?.message);
            });
    }, [router.query.id]);

    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: ICustomer) => {
        setLoading(true);
        api.put(`/customers/${router.query.id as string}`, {
            ...data,
            newPhoto: uploadImage === true ? profileImage : null,
        })
            .then((customer: any) => {
                openSnackbar(t('Customer updated successfully'));
                setLoading(false);
                setUploadImage(false);
                setProfileImage(customer.data.photo);
                callBack();
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                setUploadImage(true);
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    <Col lg={12}>
                        {customer && (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <TitleContainer>
                                    <TitleBody></TitleBody>
                                </TitleContainer>
                                <Card>
                                    <FormContent>
                                        <Left>
                                            <Circle>
                                                <CircleLabel
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    <NameCircle
                                                        src={
                                                            uploadImage === true
                                                                ? profileImage
                                                                : `${process.env.REACT_APP_PROFILE_URL}${profileImage}`
                                                        }></NameCircle>
                                                </CircleLabel>
                                                <FileInput
                                                    accept="image/jpeg/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Circle>
                                            <InputField>
                                                <Input
                                                    label={t('Email')}
                                                    name="email"
                                                    readOnly={true}
                                                    value={getValues('email')?.toLowerCase()}
                                                    onChange={onChange}
                                                    error={errors.email as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Password')}
                                                    type={t('Password')}
                                                    name="updatePassword"
                                                    value={getValues('updatePassword')}
                                                    onChange={onChange}
                                                    error={errors.updatePassword as any}
                                                />
                                            </InputField>
                                            <CityField display={'flex'}>
                                                <Input
                                                    label={t('First Name')}
                                                    name="firstname"
                                                    value={getValues('firstname')}
                                                    onChange={onChange}
                                                    error={errors.firstname as any}
                                                />
                                                <Input
                                                    label={t('Last Name')}
                                                    name="lastname"
                                                    value={getValues('lastname')}
                                                    onChange={onChange}
                                                    error={errors.lastname as any}
                                                />
                                            </CityField>
                                            <InputField>
                                                <Input
                                                    allowPhoneNumberOnly={true}
                                                    maxLength={12}
                                                    label={t('Phone')}
                                                    name="phone"
                                                    value={getValues('phone')}
                                                    onChange={onChange}
                                                    error={errors.phone as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Street address & number')}
                                                    name="address"
                                                    allowAddess={true}
                                                    value={customer.address}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Suite number (optional)')}
                                                    name="address2"
                                                    allowOnlyNumbers={true}
                                                    value={customer.address2}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                            <CityField display={'flex'}>
                                                <Input
                                                    label={t('City')}
                                                    name="city"
                                                    allowText={true}
                                                    value={customer.city}
                                                    onChange={onChange}
                                                />
                                                <Input
                                                    label={t('Postal Code')}
                                                    name="zipcode"
                                                    allowOnlyNumbers={true}
                                                    value={customer.zipcode}
                                                    onChange={onChange}
                                                />
                                            </CityField>
                                            <FooterButton>
                                                <Button
                                                    label={t('Update')}
                                                    bgtype={'secondary'}></Button>
                                            </FooterButton>
                                        </Left>
                                    </FormContent>
                                </Card>
                            </Form>
                        )}
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    justify-content: center;
    display: flex;
`;

const Form = styled.form``;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.75rem;
    align-items: center;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
`;

export const AddButton = styled.div`
    padding: 1rem;
`;

const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
    margin-top: 1.5rem;
`;

const FormContent = styled.div`
    height: 100%;
    display: flex;
    ${media.xs`
display: block !important;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`}
${media.lg`
  display: flex;
`}
`;

const Left = styled.div`
    padding: 1.71rem;
    padding-top: 0;
    flex-basis: 35rem;
`;

const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;

const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const CityField = styled.div<any>`
    margin-bottom: 1.71rem;
    display: ${({ display }) => (display ? display : 'grid')};
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
`;

const FileInput = styled.input`
    display: none;
`;

export default EditCustomer;
