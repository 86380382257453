/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TimeOff Component
 * Description: Component for managing time-off entries, allowing users to add, view, and delete time-off records.
 * Purpose:
 * - Allows users to add new time-off entries by specifying the 'from' and 'to' dates.
 * - Displays a list of existing time-off entries with 'from' and 'to' dates, and provides an option to delete service provider timeoff.
 * - Uses react-hook-form for form handling and validation.
 *
 * Props:
 * - timeOffData: any - Data representing existing time-off entries.
 * - parent: string - Parent component identifier (e.g., 'teamMember', 'service provider').
 */

import { Input, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ITimeOff } from 'interfaces/timeoff.interface';
import useRouter from 'hooks/router';
import moment from 'moment';
import { palette } from 'styled/common';
import DeleteModel from '../deleteModel/DeleteModel';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { CustomSpan } from '../breaks/Breaks';
import { useTranslation } from 'react-i18next';
import LoaderOverlay from '../loaderOverlay';

const TimeOff = ({ timeOffData, parent }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const router = useRouter();
    const [timeoff, setTimeOff] = useState<any>([]);

    const validationSchema = Yup.object().shape({
        from_iso: Yup.string().required(t('This field is required')),
        to_iso: Yup.string().required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, trigger } = useForm<ITimeOff>({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        setTimeout(() => {
            setValue('from_iso', moment().format('YYYY-MM-DD'));
            setValue('to_iso', moment().format('YYYY-MM-DD'));
        });
    }, [setValue]);

    useEffect(() => {
        if (parent == 'teamMember') {
            getTimeOff();
        }
        if (timeOffData) {
            setTimeOff(timeOffData);
        }
    }, [timeOffData, router.query.id]);

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }
    const getTimeOff = async () => {
        setLoading(true);
        await api
            .get(`/timeoff/all/${router.query.id as string}`)
            .then((timeoff: any) => {
                setLoading(false);
                setTimeOff(timeoff.data);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const onSubmit = async (data: ITimeOff) => {
        const payload = {
            from_iso: moment(data?.from_iso).format(),
            to_iso: moment(data?.to_iso).format(),
        };
        setLoading(true);
        await api
            .post(`/timeoff/${router.query.id as string}`, payload)
            .then((res: any) => {
                setLoading(false);
                timeoff.push(res.data);
                openSnackbar(t('Time off saved successfully!'));
                getTimeOff();
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };

    const deleteTeamTimeOff = async (id: any) => {
        setLoading(true);
        await api.delete(`/timeoff/${id}`).then(() => {
            openSnackbar(t('Time Off deleted successfully!'));
            setDeleteModel(false);
            const deleteTimeoff: any = timeoff.filter((item: any) => {
                return item._id !== id;
            });
            setTimeOff(deleteTimeoff);
        });
        setLoading(false);
        setDeleteModel(false);
    };

    return (
        <Container style={{ padding: '0px' }}>
            {loading && <LoaderOverlay />}
            <Row>
                <Col lg={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container>
                            <Row>
                                <Col lg={12} style={{ marginBottom: '0.5rem' }}>
                                    <h3>{t('Add Time Off')}</h3>
                                </Col>
                                <Flex xs={8} sm={5} md={5} lg={4}>
                                    <Input
                                        label={t('from')}
                                        type="date"
                                        name="from_iso"
                                        min={new Date().toISOString().split('T')[0]}
                                        value={
                                            getValues('from_iso')
                                                ? getValues('from_iso')
                                                : moment().format('YYYY-MM-DD')
                                        }
                                        onChange={onChange}
                                    />
                                </Flex>
                                <Flex xs={8} sm={5} md={5} lg={4}>
                                    <Input
                                        label={t('to')}
                                        name="to_iso"
                                        type="date"
                                        min={new Date().toISOString().split('T')[0]}
                                        value={
                                            getValues('to_iso')
                                                ? getValues('to_iso')
                                                : moment().format('YYYY-MM-DD')
                                        }
                                        onChange={onChange}
                                    />
                                </Flex>
                                <FlexEnd xs={4} sm={2} md={2} lg={4} padding={'0'}>
                                    <Button
                                        type={'submit'}
                                        bgtype={'secondary'}
                                        label={t('Add Time Off')}></Button>
                                </FlexEnd>
                            </Row>
                        </Container>
                    </form>
                    <CustomCol lg={12}>
                        {timeoff !== undefined &&
                            timeoff.map((time: ITimeOff, i: any) => (
                                <div key={i}>
                                    <Container key={i} style={{ marginBottom: '0.5rem' }}>
                                        <Row>
                                            <Flex xs={8} sm={5} md={5} lg={5}>
                                                <CustomDiv>
                                                    <CustomSpan>{t('From')}&nbsp;</CustomSpan>
                                                    <span>
                                                        {time.from_iso
                                                            ? moment(time.from_iso).format(
                                                                  'MM/DD/YYYY'
                                                              )
                                                            : t('No Time Off')}{' '}
                                                    </span>
                                                </CustomDiv>
                                            </Flex>
                                            <Flex xs={8} sm={5} md={5} lg={5}>
                                                <CustomDiv>
                                                    <CustomSpan>{t('To')}&nbsp;</CustomSpan>
                                                    <span>
                                                        {time.from_iso
                                                            ? moment(time.to_iso).format(
                                                                  'MM/DD/YYYY'
                                                              )
                                                            : t('No Time Off')}
                                                    </span>
                                                </CustomDiv>
                                            </Flex>
                                            <FlexEnd xs={4} sm={2} md={2} lg={2}>
                                                <DeletIcon
                                                    onClick={() => openDeleteModel(time._id)}>
                                                    <i className="fal fa-trash-alt"></i>
                                                </DeletIcon>
                                            </FlexEnd>
                                        </Row>
                                    </Container>
                                </div>
                            ))}
                    </CustomCol>
                    {deleteModel && (
                        <DeleteModel
                            id={deleteId}
                            onDelete={() => {
                                setDeleteModel(false);
                            }}
                            name={t('Time Off')}
                            content={t('Are you sure you want to delete?')}
                            loading={loading}
                            onClick={() => deleteTeamTimeOff(deleteId)}
                            onClose={() => {
                                setDeleteModel(false);
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

const CustomDiv = styled.div`
    margin-left: 1rem;
`;
const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;
const CustomCol = styled(Col)`
    padding: 0;
    margin-top: 1rem;
`;
const Flex = styled(Col)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0rem;
    ${media.xs`
    justify-content: start;
    margin-bottom:1rem;
	`}
    ${media.sm`
     justify-content: flex-start;
	`}
	${media.md`
     justify-content: flex-start;
	`}
	${media.lg`
     justify-content: flex-start;
	`}
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        padding-right: 0rem;
    }
`;
const FlexEnd = styled(Col)<any>`
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: ${({ padding }) => padding};
`;
export default TimeOff;
