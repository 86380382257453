/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Teams Component
 * Description: This component serves as the main entry point for the teams section. It includes the TeamOptions component for managing and displaying team-related options like service provider, team and other.
 *
 */
import { Layout } from '@components/common';
import { TeamOptions } from '@components/team';

const Teams = () => {
    return (
        <>
            <TeamOptions />
        </>
    );
};

Teams.Layout = Layout;
export default Teams;
