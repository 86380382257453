/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * NewCreditCard Component
 * Description: This component is responsible for capturing credit card details for payment processing.
 * It includes functionalities such as updating state values for card details, handling form inputs, creating payment methods through API calls, and triggering a callback when a payment method is successfully created.
 *
 * Props:
 * - buttonTitle: string - The title for the button (e.g., 'Save Card').
 * - stripeAccountId: string - The Stripe account ID for processing payments.
 * - onSave: (paymentMethodId: string) => void - Callback function triggered when a payment method is successfully created.
 *
 */

import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import Card from './Card';
import CardForm from './CardForm';
import { api } from 'helpers/auth-axios';
import { useSnackbar } from '@components/common';
import { palette } from 'styled/common';

const initialState: any = {
    cardNumber: '',
    cardHolder: 'FULL NAME',
    cardMonth: '',
    cardYear: '',
    cardCVV: '',
    isCardFlipped: false,
};

const NewCreditCard = ({
    buttonTitle,
    stripeAccountId,
    onSave,
}: {
    buttonTitle: any;
    stripeAccountId: any;
    onSave(paymentMethodId: string): void;
}) => {
    const [state, setState] = useState(initialState);
    const [currentFocusedEl, setCurrentFocusedEl] = useState(null);
    const [openSnackbar] = useSnackbar();

    const updateStateValues = useCallback(
        (keyName: string, value: any) => {
            console.log(keyName, value);
            setState({
                ...state,
                [keyName]: value || initialState[keyName],
            });
        },
        [state]
    );

    const createPaymentMethod = useCallback(() => {
        api.post('/billing/payment-method', {
            stripe_account_id: stripeAccountId,
            number: state.cardNumber,
            exp_month: state.cardMonth,
            exp_year: state.cardYear,
            cvc: state.cardCVV,
            customer_email: null,
        })
            .then(res => {
                onSave((res.data as any).id);
                console.log('payment method-------', res);
            })
            .catch((e: any) => {
                console.log(e.response.data.message);
                if (e?.response) {
                    const data = e.response;
                    openSnackbar(e?.response?.data?.message);
                }
            });
    }, [state]);

    const cardElementsRef: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
    };

    return (
        <Wrapper>
            <Row>
                <Col lg={6}>
                    <CardContent>
                        <CardBody>
                            <Card
                                cardNumber={state.cardNumber}
                                cardHolder={state.cardHolder}
                                cardMonth={state.cardMonth}
                                cardYear={state.cardYear}
                                cardCVV={state.cardCVV}
                                isCardFlipped={state.isCardFlipped}
                                currentFocusedEl={currentFocusedEl}
                                onCardElClick={(el: string) => console.log(el)}
                                cardNumberRef={cardElementsRef.cardNumber}
                                cardHolderRef={cardElementsRef.cardHolder}
                                cardDateRef={cardElementsRef.cardDate}
                            />
                            <CardForm
                                cardMonth={state.cardMonth}
                                cardYear={state.cardYear}
                                onUpdateState={updateStateValues}
                                cardNumberRef={cardElementsRef.cardNumber}
                                cardHolderRef={cardElementsRef.cardHolder}
                                cardDateRef={cardElementsRef.cardDate}
                            />
                            <Button onClick={() => createPaymentMethod()}>{buttonTitle}</Button>
                        </CardBody>
                    </CardContent>
                </Col>
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.white};
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
`;

const CardBody = styled.div`
    padding: 1.75rem 2rem;
`;

const Button = styled.button`
    padding: 13px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
    width: 100% !important;
    line-height: inherit;
    cursor: pointer;
`;

export default NewCreditCard;
