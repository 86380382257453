/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Calendar Current View Reducer
 * Description: This reducer handles the state related to the current view in the calendar.
 *
 * Interfaces:
 * - ICalendarCurrentView: Interface for the calendar current view, including the current view string.
 *
 * Constants:
 * - None
 *
 * Initial State:
 * - INITIAL_STATE: Initial state for the calendar current view reducer, derived from user data in local storage.
 *
 * Reducer:
 * - calenderCurrentView: Reducer function for handling calendar current view actions and updating the state.
 *
 */

export interface ICalenderCurrentView {
    current_view: string;
}
const user_Data: any = JSON.parse(localStorage.getItem('user') || '{}');
const INITIAL_STATE: ICalenderCurrentView = {
    current_view: user_Data?.user?.current_view,
};
export function calenderCurrentView(state = INITIAL_STATE, action: any) {
    if (action.type === 'current_view') {
        return {
            current_view: action.current_view,
        };
    } else {
        return {
            current_view: state.current_view,
        };
    }
}
