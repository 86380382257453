/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TeamCard Component
 * Description: This component displays information about a team member, including details such as working hours, breaks, time-off, and permissions. It also allows editing, deleting, and sending messages to the team member.
 *
 * Props:
 * - callTeam: () => void - Callback function triggered after updating team information to load team member again.
 */

import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import { ITeam } from 'interfaces/teamMember.interface';
import { LoaderOverlay, Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import TabContext from '@components/common/tabContext';
import TimeOff from '@components/common/timeOff/TimeOff';
import Permissions from '@components/common/permissions/Permissions';
import WorkingHours from '@components/common/workingHours/WorkingHours';
import Breaks from '@components/common/breaks/Breaks';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import { palette } from 'styled/common';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import EditTeamMember from '@components/team/edit/editTeamMember';
import CardHeader from '@components/common/cardHeader/CardHeader';
import SendMessage from '@components/common/SendMessage';
import { MobileViewDiv } from '../serviceProviderCard/ServiceProviderCard';
import { useTranslation } from 'react-i18next';

const TeamCard = ({ callTeam }: any) => {
    const { t }: any = useTranslation();
    const [team, setTeam] = useState<ITeam>();
    const [tabValue, setTabValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const router = useRouter();
    const [businessId, setBusinessId] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [isSendMessage, setIsSendMessage] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        data();
    }, [router.query.id]);

    const data = () => {
        setLoading(true);
        api.get(`/team/${router.query.id as string}`)
            .then((res: any) => {
                setLoading(false);
                if (res.data !== 0) {
                    setTeam(res.data);
                    setBusinessId(res.data.business_id);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const onChange = (day: any) => {};
    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    const deleteTeamMember = (id: any) => {
        setLoading(true);
        api.delete(`/team/${id}`)
            .then((res: any) => {
                setLoading(false);
                if (res.statusText === 'OK') {
                    router.navigate('/team/team-list');
                    openSnackbar(t('Team Member deleted successfully!'));
                    setOpenDeleteModel(false);
                    callTeam();
                } else {
                    openSnackbar(t('Failed to delete Team!'));
                    setOpenDeleteModel(false);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const tabsNameList = [
        {
            name: 'About',
            content: (
                <>
                    <MobileViewDiv>
                        <CardHeader
                            parent={'team'}
                            userData={team}
                            deleteModel={() => deleteModel(router.query.id)}
                            messageModel={() => setIsSendMessage(true)}
                        />
                    </MobileViewDiv>
                    <EditTeamMember
                        clickCallBack={() => {
                            data();
                            callTeam();
                        }}
                        defaultData={team}
                    />
                </>
            ),
        },
        {
            name: t('Working Hours'),
            content: (
                <WorkingHours
                    active={true}
                    onChange={onChange}
                    business_id={businessId}
                    defaultWorkingHours={team?.workingHours}
                    updateCallBack={() => {
                        data();
                    }}
                />
            ),
        },
        {
            name: t('Breaks'),
            content: (
                <Breaks
                    day={{ day: 1, from: '10:00', to: '19:00' }}
                    onChang={onChange}
                    parent={'teamMember'}
                    defaultBreak={team?.breaks}
                />
            ),
        },
        {
            name: t('Time Off'),
            content: <TimeOff parent={'teamMember'} timeOffData={team?.time_off} />,
        },
        {
            name: t('Permissions'),
            content: <Permissions businessId={businessId} defaultPermissions={team?.permissions} />,
        },
    ];

    return (
        <>
            <MainContainer>
                {loading && <LoaderOverlay />}
                <Row>
                    {team && (
                        <>
                            <WebView lg={12}>
                                <CardHeader
                                    parent={'team'}
                                    userData={team}
                                    deleteModel={() => deleteModel(router.query.id)}
                                    messageModel={() => setIsSendMessage(true)}
                                />
                            </WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    <TabContext value={tabValue}>
                                        <Tabs
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                            }}>
                                            <Tab label={tabsNameList[0].name} />
                                            <Tab label={tabsNameList[1].name} />
                                            <Tab label={tabsNameList[2].name} />
                                            <Tab label={tabsNameList[3].name} />
                                            <Tab label={tabsNameList[4].name} />
                                        </Tabs>
                                        <TabPanel value={0} preload={true}>
                                            <TabContent>{tabsNameList[0].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={1} preload={true}>
                                            <TabContent>{tabsNameList[1].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={2} preload={true}>
                                            <TabContent>{tabsNameList[2].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={3} preload={true}>
                                            <TabContent>{tabsNameList[3].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={4} preload={true}>
                                            <TabContent>{tabsNameList[4].content}</TabContent>
                                        </TabPanel>
                                    </TabContext>
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion tabsNameList={tabsNameList} />
                            </MobileView>

                            {openDeleteModel && team && (
                                <DeleteModel
                                    id={team._id}
                                    onDelete={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    onClose={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    name={t('Team Member')}
                                    content={t(
                                        'If you delete this team member you will lose all information connected to their profile including working hours, break hours, time off and permissions. This action cannot be undone.'
                                    )}
                                    loading={loading}
                                    onClick={() => deleteTeamMember(deleteId)}
                                />
                            )}
                        </>
                    )}
                    {isSendMessage && (
                        <SendMessage
                            name={team?.name}
                            phone={team?.phone}
                            senderEmail={_userData.user.email}
                            reciverEmail={team?.email}
                            onClose={() => {
                                setIsSendMessage(false);
                            }}
                        />
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    min-height: 100%;
    ${media.xs`
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	`}
	${media.lg`
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;

const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;

const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
`;

const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;

export default TeamCard;
