/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Redux Hooks
 * Description: Provides custom Redux hooks for using dispatch and selector hooks with typed definitions.
 *
 * Hooks:
 * - useAppDispatch: Custom hook for accessing the Redux dispatch function with typed definition (ReduxDispatch).
 * - useAppSelector: Custom hook for using the Redux useSelector hook with typed definition (TypedUseSelectorHook<RootState>).
 */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, ReduxDispatch } from '../helpers/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<ReduxDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
