/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Accordion Component
 * Description: This component represents an accordion that allows toggling and displaying content for different tabs.
 *
 * Props:
 * - tabsNameList: Array - List of objects containing information about each tab, including name and content.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from 'styled/common';

const Accordion = ({ tabsNameList }) => {
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    };

    return (
        <AccordionSection>
            <Container>
                {tabsNameList.map((item, index) => {
                    return (
                        <div key={index}>
                            <Wrap onClick={() => toggle(index)}>
                                <h1>{item.name}</h1>
                                <span style={{ fontSize: '2rem' }}>
                                    {clicked === index ? '-' : '+'}
                                </span>
                            </Wrap>
                            {clicked === index ? <Dropdown>{item.content}</Dropdown> : null}
                        </div>
                    );
                })}
            </Container>
        </AccordionSection>
    );
};

const AccordionSection = styled.div`
    padding: 1rem;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
`;

const Container = styled.div`
    width: 100%;
`;

const Wrap = styled.div`
    color: ${palette.dark};
    border: 0.1rem solid ${palette.grey.lightest};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;
    h1 {
        padding: 1rem;
        font-weight: normal;
        font-size: 1.1rem;
    }
    span {
        margin-right: 1.5rem;
    }
`;

const Dropdown = styled.div`
    background-color: ${palette.light};
    color: ${palette.dark};
    width: 100%;
    border: 0.1rem solid ${palette.grey.lightest};
`;

export default Accordion;
