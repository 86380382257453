/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SelectAssets Component
 * Description: React component for selecting and managing assets during the checkout process.
 *
 * Purpose: This component allows users to select, edit, and delete assets during from sale page.
 */

import { useEffect, useState } from 'react';
import s from './Checkout.module.scss';
import { IService } from 'interfaces/service.interface';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '@components/settings/businessServices/BusinessServices';
import DeleteModel from '@components/common/deleteModel/DeleteModel';

interface IProps {
    selectedAsset: any[];
    onClose(): void;
    onSelect(services: any[]): void;
    onEdit(services?: any[]): void;
    setLoadAssets?: any;
    loadAssets?: any;
}

const SelectAssets = ({ selectedAsset, onSelect, onEdit, setLoadAssets, loadAssets }: IProps) => {
    const { t }: any = useTranslation();
    const [selected, setSelected] = useState<any[]>(selectedAsset);
    const [services, setServices] = useState<any[]>([]);
    const [deleteModel, setDeleteModel] = useState<any>(false);
    const [assetId, setAssetId] = useState<any>('');
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!services.length) getAssets();
    }, [loadAssets]);

    const getAssets = async () => {
        await api.get('/assets-service').then((res: any) => {
            if (res.data) {
                setServices(res.data);
            }
            if (loadAssets) {
                setLoadAssets(false);
            }
        });
    };

    const toggleSelect = (service: IService) => {
        if (selected?.find(item => item._id === service._id)) {
            let newSelected = selected.filter(item => item._id !== service._id);
            setSelected(newSelected);
            onSelect(newSelected);
        } else {
            setSelected([...selected, service]);
            onSelect([...selected, service]);
        }
    };
    const deleteAssets = async (id = null) => {
        try {
            if (id) {
                setLoading(true);
                await api
                    .delete(`/assets-service/${id}`)
                    .then((res: any) => {
                        setLoading(false);
                        setDeleteModel(false);
                        setAssetId('');
                        if (res.data) {
                            getAssets();
                        }
                    })
                    .catch(e => {
                        setLoading(false);
                    });
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <Container>
                <Row>
                    {services.map((service, i) => (
                        <ServicesBody xs={12} sm={4} md={6} lg={4} key={i}>
                            <div
                                className={`${s.services_service} ${
                                    selected?.find(item => item._id === service._id)
                                        ? s.services_selectedService
                                        : ''
                                } service-color-${service.color}--bd`}>
                                <DeleteIcon style={{ background: 'transparent', width: 'auto' }}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            gap: '0.5rem',
                                        }}>
                                        <i
                                            className="fal fa-trash-alt"
                                            style={{ color: 'red' }}
                                            onClick={() => {
                                                setAssetId(service._id);
                                                setDeleteModel(true);
                                            }}></i>
                                        <i
                                            className="fal fa-edit"
                                            style={{ color: 'black' }}
                                            onClick={() => onEdit(service)}></i>
                                    </div>
                                </DeleteIcon>

                                <Services
                                    onClick={() => toggleSelect(service)}
                                    // className={`service-color-${service.color}--bg`}
                                    style={{
                                        boxShadow:
                                            'inset -1.80118px -2.70178px 9.00592px rgba(0,0,0,0.25)',
                                    }}>
                                    <Label>
                                        &nbsp;
                                        {_userData.user.business_id.currency}
                                        {service.price}
                                    </Label>
                                    <Name>{service.name}</Name>
                                    <ServiceDuration>
                                        Quantity: {`${service.quantity}`}
                                    </ServiceDuration>
                                </Services>
                            </div>
                        </ServicesBody>
                    ))}

                    {services?.length == 0 && <div>{t('No Asset found')}</div>}
                </Row>
            </Container>
            {deleteModel && (
                <DeleteModel
                    onDelete={() => {
                        setDeleteModel(false);
                    }}
                    onClose={() => {
                        setDeleteModel(false);
                    }}
                    name={t('Asset')}
                    content={t('Are you sure you want to delete this asset?')}
                    loading={loading}
                    onClick={() => assetId && deleteAssets(assetId)}
                />
            )}
        </>
    );
};

const ServicesBody = styled(Col)`
    padding: 0rem 0rem 0.5rem 0.5rem;
`;

const Services = styled.div`
    border-radius: 0.5rem;
    padding: 26px 20px;
    position: relative;
    cursor: pointer;
`;

const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 195px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

export default SelectAssets;
