/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ServiceProvider Component
 * Description: This component provides a list view of service providers for team management.
 * It includes the ability to fetch and display service providers, and to view detailed information about a specific service provider.
 *
 * Props:
 * - getServiceProvider: () => void - Callback function to fetch service provider data again.
 */

import React, { useRef } from 'react';
import { Layout } from '@components/team';
import { ServiceProviderList, ServiceProviderCard } from '@components/team';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';

interface IProps {
    getServiceProvider: () => void;
}

const ServiceProvider = () => {
    const _useRef = useRef<IProps>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const callServiceProvider = () => {
        if (_useRef?.current) {
            _useRef?.current?.getServiceProvider();
        }
    };

    return (
        <Wrapper>
            {_userData.user_login == 'user' ? <ServiceProviderList ref={_useRef} /> : ''}

            <Routes>
                <Route
                    path=":id"
                    element={<ServiceProviderCard callServiceProvider={callServiceProvider} />}
                />
            </Routes>

            <style>
                {`
                .customer-listing--main {
                    height: 100%;
                }
            `}
            </style>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0px;
    height: 100%;
    ${media.xs`
	display: block !important;
`}
    ${media.sm`
  display: block !important;
`}
  ${media.md`
  display: flex !important;
  margin:0;
  min-width:100%;
`}
  ${media.lg`
  display: flex !important;
`}
`;

ServiceProvider.Layout = Layout;
export default ServiceProvider;
