/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Others Component
 * Description: This component serves as a page for managing others user of a business.
 * It includes the OthersList component to display a list of others and the OthersCard component to display details for a specific other user.
 *
 */

import React, { useRef } from 'react';
import { Layout, OthersCard, OthersList } from '@components/team';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';

interface IProps {
    getOthers: () => void;
}

const Others = () => {
    const _useRef = useRef<IProps>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const callOther = () => {
        if (_useRef?.current) {
            _useRef?.current?.getOthers();
        }
    };

    return (
        <Wrapper>
            {_userData.user_login == 'user' ? <OthersList ref={_useRef} /> : ''}

            <Routes>
                <Route path=":id" element={<OthersCard callOther={callOther} />} />
            </Routes>

            <style>
                {`
                .customer-listing--main {
                    height: 100%;
                }
            `}
            </style>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0px;
    height: 100%;
    ${media.xs`
  display: block !important;
`}
    ${media.sm`
display: block !important;
`}
${media.md`
display: flex !important;
margin:0;
min-width:100%;
`}
${media.lg`
display: flex !important;
`}
`;

Others.Layout = Layout;
export default Others;
