/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerNotifications Component
 * Description: This component allows users to manage notification preferences (email and SMS) for a customer.
 *
 * Props:
 * - customer: ICustomer - The customer data for whom the notifications are managed.
 * - callBack: () => void - Callback function triggered after updating notification preferences.
 */

import Button from '@components/Button';
import { LoaderOverlay, Switch, useSnackbar } from '@components/common';
import { api } from 'helpers/auth-axios';
import { ICustomer } from 'interfaces/customer.interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const CustomerNotifications = ({ customer, callBack }: { customer: ICustomer; callBack?: any }) => {
    const { t }: any = useTranslation();

    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [emailSwitch, setEmailSwitch] = useState(customer.notifications.email);
    const [smsSwitch, setSmsSwitch] = useState(customer.notifications.sms);
    const [openSnakebar] = useSnackbar();

    useEffect(() => {
        setEmailSwitch(customer.notifications.email);
        setSmsSwitch(customer.notifications.sms);
    }, [customer]);

    const onSubmit = () => {
        setLoading(true);
        api.put(`/customers/${customer._id}`, {
            notifications: { email: emailSwitch, sms: smsSwitch },
        }).then(res => {
            openSnakebar(t('Notification saved successfully!'));
            setLoading(false);
            setShowActions(false);
            callBack();
        });
    };

    const onSwitch = (val: boolean) => {
        setShowActions(true);
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <Col lg={12} style={{ padding: '0' }}>
                        <Options>
                            <div>
                                <Icon className="fas fa-mail-bulk"></Icon>
                                {t('Email Notifications')}
                            </div>
                            <Switch
                                onChange={(val: boolean) => {
                                    onSwitch(val);
                                    setEmailSwitch(val);
                                }}
                                value={emailSwitch}
                            />
                        </Options>
                        <Hr />
                        <Options>
                            <div>
                                <Icon className="fas fa-sms"></Icon>
                                {t('SMS Notifications')}
                            </div>
                            <Switch
                                onChange={(val: boolean) => {
                                    onSwitch(val);
                                    setSmsSwitch(val);
                                }}
                                value={smsSwitch}
                            />
                        </Options>
                    </Col>
                </Row>
            </Container>

            {showActions && (
                <ButtonModel>
                    <Button ifClicked={onSubmit} bgtype="secondary" label={t('Update')}></Button>
                </ButtonModel>
            )}
        </>
    );
};

const Options = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const Icon = styled.i`
    color: rgba(181, 181, 195);
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    margin-right: 0.5rem /* 8px */;
`;

const Hr = styled.hr`
    margin-top: 0.75rem /* 12px */;
    margin-bottom: 0.75rem /* 12px */;
`;

const ButtonModel = styled.div`
    border-top-width: 1px;
    padding-top: 1.5rem /* 24px */;
    margin-top: 1.25rem /* 20px */;
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem /* 12px */;
`;

export default CustomerNotifications;
