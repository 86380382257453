/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Register Component
 * Description: This component provides user registration functionality with form inputs for name, business name, email, and password.
 * It includes features for form validation using react-hook-form, dispatching user registration actions, and redirects based on registration success.
 * Also there picked default timezone of user and return wiith create register api to set user default timezone using his location.
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { userActions } from 'actions/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/sideCover/SideCover';
import { businessActions } from 'actions/business';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
moment.tz.setDefault();

const Register = () => {
    const { t }: any = useTranslation();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [authMethod, setAuthMethod] = useState('email');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [userTimezone, setUserTimezone] = useState('');

    useEffect(() => {
        const getUserTimezone = () => {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setUserTimezone(userTimeZone);
        };

        getUserTimezone();
    }, []); //

    useEffect(() => {
        if (router.query.success !== undefined) {
            if (router.query.provider) setAuthMethod(router.query.provider as string);
            if (router.query.accessToken) {
                dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then(
                    (res: any) => {
                        console.log(res);
                        navigate('/');
                    }
                );
            }
        }
    }, [dispatch, router.query]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        business_name: Yup.string().required(t('This field is required')),
        email: Yup.string().email().required(t('This field is required')),
        password: Yup.string()
            .required(t('This field is required'))
            .min(8, t('Password must be at least 8 characters')),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm(formOptions);

    const { errors } = formState;

    const onSubmit = (data: any) => {
        setLoading(true);
        return dispatch(userActions.register({ ...data, timezone: userTimezone }))
            .then((res: any) => {
                setLoading(false);
                if (res) {
                    dispatch(businessActions.getBusiness(res.user.business_id._id)).then(
                        (res: any) => {
                            openSnackbar(t('Account creation successful.'));
                            navigate('/');
                        }
                    );
                } else {
                    setLoading(false);
                    openSnackbar(t('Account creation failed!'));
                }
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(t('Account creation failed!'));
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name == 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col md={6} lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>Create Account</Heading>
                            </Inner>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <InputGroup>
                                    <Input
                                        label={t('Name')}
                                        name="name"
                                        onChange={onChange}
                                        error={errors.name as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label={t('Business Name')}
                                        name="business_name"
                                        onChange={onChange}
                                        error={errors.business_name as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label={t('Email')}
                                        name="email"
                                        type="email"
                                        onChange={onChange}
                                        error={errors.email as any}
                                        autoComplete="on"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type={t('Password')}
                                        name="password"
                                        label="Password"
                                        onChange={onChange}
                                        error={errors.password as any}
                                    />
                                </InputGroup>
                                <div>
                                    <AgreeText>
                                        {t('By clicking the Create Account you agree to the')}{' '}
                                        <a href="#" target="_blank">
                                            <Highlight>{t('Privacy Policy')}</Highlight>
                                        </a>{' '}
                                        {t('and')}{' '}
                                        <a href="#" target="_blank">
                                            <Highlight>{t('Terms and Conditions')}</Highlight>
                                        </a>
                                        .
                                    </AgreeText>
                                </div>
                                <Button
                                    type={'submit'}
                                    label={t('Create Account')}
                                    width={'100% !important'}
                                    bgtype={'secondary'}
                                    disabled={loading}
                                />
                            </form>

                            <SignInText>
                                {t('Already have an account?')}
                                <Link to="/login">
                                    <SignUpText className="text-primary-light">
                                        {t('Log In')}
                                    </SignUpText>
                                </Link>
                            </SignInText>
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: #fff;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Inner = styled.div`
    margin-bottom: 1rem;
`;
const Heading = styled.h3`
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const SignInText = styled.span`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    justify-content: center;
    margin-top: 1rem;
`;
const SignUpText = styled.span`
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const AgreeText = styled.div`
    font-size: 0.85rem;
    max-width: 340px;
    margin-right: auto;
    color: rgba(157, 160, 164) !important;
    font-weight: 100 !important;
    text-align: start !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
`;
const Highlight = styled.span`
    color: rgba(125, 128, 131) !important;
    font-weight: 500 !important;
`;
export default Register;
