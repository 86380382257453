/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Enums
 * Description: This file contains enums for booking mode, notification type, notification event, repeat booking, and end repeat booking.
 */

export enum BookingModeEnum {
    FIRST_AVAILABLE = 'F',
    NEXT_AVAILABLE = 'N',
}

export enum NotificationTypeEnum {
    APPOINTMENT = 'appointment',
    CUSTOMER = 'customer',
    PAYMENT = 'payment',
}

export enum NotificationEventEnum {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export enum RepeatBookingEnum {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    EVERY_2_WEEKS = 'every_2_weeks',
    MONTHLY = 'monthly',
}

export enum EndRepeatBookingEnum {
    NEVER = 'never',
}
