/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateServiceProvider Component
 * Description: This component allows the user to create a new service provider, providing details such as name, email, phone, password, short description, and assigned services. It also supports uploading a profile image.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the component is closed.
 * - onSave: (id: string) => void - Callback function triggered when the service provider is successfully saved, providing the ID of the saved provider.
 *
 */

import React, { useEffect, useState } from 'react';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SelectServices from '../../selectServices/SelectServices';
import { IService } from 'interfaces/service.interface';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import ModelHeader from '@components/common/modelHeader/ModelHeader';
import { useTranslation } from 'react-i18next';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import Compressor from 'compressorjs';
import { palette } from 'styled/common';

const CreateServiceProvider = ({ onClose, onSave }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [showSelect, setShowSelect] = useState(false);
    const [assignedServices, setAssignedServices] = useState<IService[]>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        email: Yup.string().email().required(t('This field is required')),
        phone: Yup.string()
            .min(10, t('Phone must be a valid phone'))
            .required(t('This field is required')),
        password: Yup.string()
            .min(8, t('Password must be at least 8 characters'))
            .required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<IServiceProvider>({
        resolver: yupResolver(validationSchema),
    });
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        setTimeout(() => {
            setValue('name', '');
            setValue('email', '');
            setValue('phone', '');
            setValue('password', '');
        });
    }, [setValue]);

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone?.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: IServiceProvider) => {
        setLoading(true);
        api.post('/serviceProvider', {
            ...data,
            photo: profileImage,
            email: data.email.toLowerCase(),
            services: assignedServices.map(service => {
                return {
                    price: service.price,
                    service_id: service._id,
                    my_price: service.my_price,
                    my_duration: service.my_duration,
                    duration: service.duration,
                    name: service.name,
                    color: service.color,
                    available_for_boooking: service.available_for_boooking,
                    vip_price: service.price,
                    is_video: service.is_video,
                };
            }),
        })
            .then((res: any) => {
                setLoading(false);
                onSave(res?.data?._id);
                if (res.error) {
                    openSnackbar(t('Failed to add ServiceProvider'));
                } else {
                    openSnackbar(t('ServiceProvider added successfully'));
                    return res.data;
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <CustomCol>
                    <Col lg={6}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <ModelHeader
                                title={t('Add New Service Provider')}
                                path={'/team/service-provider-list'}
                                onClose={() => !loading && onClose()}
                            />
                            <Card>
                                <FormContent>
                                    <Circle>
                                        <CircleLabel
                                            htmlFor="faceImage"
                                            aria-label="upload picture">
                                            {profileImage ? (
                                                <Data src={profileImage}></Data>
                                            ) : (
                                                <CameraIcon className="fa fa-camera" />
                                            )}
                                        </CircleLabel>

                                        <FileInput
                                            accept="image/jpeg/png"
                                            id="faceImage"
                                            type="file"
                                            onChange={handleCapture}
                                        />
                                    </Circle>
                                    <InputField>
                                        <Input
                                            label={t('Name')}
                                            name="name"
                                            value={getValues('name')}
                                            onChange={onChange}
                                            error={errors.name as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            label={t('Email')}
                                            name="email"
                                            value={getValues('email')?.toLowerCase()}
                                            onChange={onChange}
                                            error={errors.email as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            allowPhoneNumberOnly={true}
                                            maxLength={12}
                                            label={t('Phone')}
                                            name="phone"
                                            value={getValues('phone')}
                                            onChange={onChange}
                                            error={errors.phone as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            label={t('Password')}
                                            type={t('Password')}
                                            name="password"
                                            value={getValues('password')}
                                            onChange={onChange}
                                            error={errors.password as any}
                                        />
                                    </InputField>
                                    <InputField>
                                        <Input
                                            label={t('Short Description')}
                                            name="description"
                                            value={getValues('description')}
                                            onChange={onChange}
                                        />
                                    </InputField>
                                    <TabsContent>
                                        {assignedServices?.length ? (
                                            <ServicesContainer>
                                                <ServicesWrapper>
                                                    {assignedServices.map((service, i) => (
                                                        <Services
                                                            key={i}
                                                            className={`service-color-${service.color}--bg`}>
                                                            <Label>
                                                                &nbsp;
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {service.price}
                                                            </Label>
                                                            <CustomDiv>
                                                                <Name>{service.name}</Name>
                                                                {service?.is_video && (
                                                                    <i className="fal fa-video"></i>
                                                                )}
                                                            </CustomDiv>
                                                            <ServiceDuration>{`${
                                                                service.duration >= 60
                                                                    ? Math.floor(
                                                                          service.duration / 60
                                                                      ) + 'h'
                                                                    : ''
                                                            } ${service.duration % 60}${t(
                                                                'min'
                                                            )}`}</ServiceDuration>
                                                        </Services>
                                                    ))}
                                                </ServicesWrapper>
                                            </ServicesContainer>
                                        ) : (
                                            <AddService></AddService>
                                        )}
                                    </TabsContent>
                                    <FooterButton>
                                        <Button
                                            type={'button'}
                                            bgtype={'secondary'}
                                            ifClicked={() => (loading ? '' : setShowSelect(true))}
                                            label={
                                                assignedServices?.length
                                                    ? t('Edit Services')
                                                    : t('Assign services')
                                            }></Button>
                                        <Button
                                            bgtype={'secondary'}
                                            type={'submit'}
                                            label={t('Add Service Provider')}></Button>
                                    </FooterButton>
                                </FormContent>
                            </Card>
                        </Form>
                        {showSelect && (
                            <SelectServices
                                selectedServices={assignedServices}
                                modelStatus={'createservice'}
                                onClose={() => setShowSelect(false)}
                                onSelect={(services: IService[]) => {
                                    setAssignedServices(services);
                                    setShowSelect(false);
                                }}
                            />
                        )}
                    </Col>
                </CustomCol>
            </MainContainer>
        </>
    );
};

export const CameraIcon = styled.i`
    font-size: 3rem;
    border: 1px solid gray;
    padding: 1rem;
    border-radius: 5rem;
    cursor: pointer;
`;
const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;

const CustomCol = styled(Row)`
    display: flex;
    justify-content: center;
`;

const Form = styled.form``;

const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    background-clip: border-box;
    border: 0;
`;

const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.71rem;
    ${media.xs`
display: block;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`}
${media.lg`
  display: flex;
`}
`;

const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;

const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const Data = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const TabsContent = styled.div`
    padding: 1.71rem;
    flex: 1;
`;

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServicesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem /* 16px */;
`;

const Services = styled.div`
    border-radius: 0.5rem;
    padding: 1.85rem 1.42rem;
    position: relative;
    cursor: pointer !important;
`;

const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

const AddService = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const FileInput = styled.input`
    display: none;
`;

export const FooterButton = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;
export default CreateServiceProvider;
