/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: NewAppointmentDrawerForCustomer
 * Description: This component represents the drawer for creating new appointments from the customer's perspective.
 * It includes functionalities such as selecting a service provider, service, date, and time, handling conflicts,
 * managing VIP appointments, and processing payments. The component integrates with various UI elements and supports
 * features like overbooking prevention, time slot availability.
 * Developed with React and utilizes styled-components for styling.
 */

import { Input, LoaderOverlay, Select, Switch, useSnackbar } from '@components/common';
import { useEffect, useState } from 'react';
import SelectService from '../newAppointmentDrawer/SelectService';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import SelectServiceProvider from '../newAppointmentDrawer/SelectServiceProvider';
import ConflictModal from '../modal/ConflictModal';
import { api } from 'helpers/auth-axios';
import CompletePayment from './CompletePayment';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import { palette } from 'styled/common';

const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};

const NewAppointmentDrawerForCustomer = () => {
    const { t }: any = useTranslation();
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [loading, setLoading] = useState(false);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();
    const [serviceView, setServiceView] = useState(false);
    const [serviceProviderView, setServiceProviderView] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [customerNote, setCustomerNote] = useState<string>();
    const [start, setStart] = useState<any>();
    const [end, setEnd] = useState<any>();
    const [overbooking, setOverbooking] = useState(false);
    const [providerId, setProviderId] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [durationField, setDurationFieldOpen] = useState<boolean>(false);
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [durationHours, setDurationHours] = useState<any>();
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>();
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();
    const [chargeModel, setChargeModel] = useState<boolean>(false);
    const [is_vip, setIs_vip] = useState<any>(false);
    const [business, setBusiness] = useState<any>();
    const [is_video, setIs_video] = useState(false);
    const [appointmentSlots, setAppointmentSlots] = useState<any[]>([]);
    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const appointmentTime: { label: string; value: any }[] = [];
    let time = moment().format('HH:mm');
    let date = moment(startDate ? startDate : start).format('YYYY-MM-DD');
    let todayDate = moment().format('YYYY-MM-DD');

    useEffect(() => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            setBusiness(res.data);
            moment.tz.setDefault(_userData?.user?.business_id?.timezone);
        });
    }, []);

    useEffect(() => {
        if (overbooking) onSubmit();
    }, [overbooking]);

    useEffect(() => {
        getAvailableSlot();
    }, [
        startDate,
        defaultDuration,
        customDuration,
        start,
        is_vip,
        selectedService,
        selectedServiceProvider,
    ]);

    useEffect(() => {
        dropDownValue();
    }, [
        startDate,
        defaultDuration,
        customDuration,
        start,
        selectedService,
        dropDownFilter,
        selectedServiceProvider,
    ]);

    appointmentTime.push({
        label: is_vip === true ? t('Select VIP Time') : t('Select Time'),
        value: '',
    });

    if (is_vip === true) {
        let vipTime: any[] = [];
        let duration = durationField === false ? defaultDuration : customDuration;
        for (let i: any = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                vipTime.push(moment({ hour: i, minute: j * 15 }).format('HH:mm'));
            }
        }
        let tmpTime: any[] = [];
        vipTime.map((res: any, index: any) => {
            let customTime = moment(res, 'HH:mm').add(5, 'minute').format('HH:mm');
            let tmpTime1 = moment(res, 'HH:mm').add(duration, 'minute').format('HH:mm');
            let format = 'HH:mm';
            if (appointmentSlots?.includes(res) || appointmentSlots?.includes(customTime)) {
                for (let i = 0; i < appointmentSlots?.length; i++) {
                    let mTime = moment(appointmentSlots[i], format),
                        beforeTime = moment(res, format),
                        afterTime = moment(tmpTime1, format);
                    if (
                        mTime.isBetween(beforeTime, afterTime) &&
                        mTime.isBetween(beforeTime, afterTime)
                    ) {
                        if (tmpTime[index - 1] != -1) {
                            tmpTime.splice(index - 1, 1);
                        }
                    }
                }
            } else {
                tmpTime.push(res);
            }
        });
        tmpTime.forEach((item: any) => {
            if (date === todayDate) {
                item >= time &&
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
            } else {
                appointmentTime.push({
                    label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        'HH:mm'
                    ),
                });
            }
        });
    } else {
        availableSlot?.length &&
            availableSlot.forEach((item: any) => {
                if (date === todayDate) {
                    item >= time &&
                        appointmentTime.push({
                            label: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format(
                                _userData?.user?.business_id.time_select === '12'
                                    ? 'hh:mm a'
                                    : 'HH:mm'
                            ),
                            value: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format('HH:mm'),
                        });
                } else {
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
                }
            });
    }

    const booked_from =
        moment(startDate ? startDate : start).format('YYYY-MM-DD') +
        'T' +
        moment(start).format('HH:mm') +
        ':00';

    const endTime =
        moment(enddate ? enddate : end).format('YYYY-MM-DD') +
        'T' +
        moment(start)
            .add(durationField === false ? defaultDuration : customDuration, 'minutes')
            .format('HH:mm') +
        ':00';

    const addAppointment = {
        booked_from_iso: booked_from ? moment(booked_from).format() : moment(start).format(),
        booked_till_iso: endTime ? moment(endTime).format() : moment(end).format(),
        booked_from: booked_from ? moment(booked_from).format() : moment(start).format(),
        booked_till: endTime ? moment(endTime).format() : moment(end).format(),
        appointment_note: '',
        serviceProvider: selectedServiceProvider?._id,
        customer: _userData?.user?._id,
        service: selectedService?._id,
        total: business?.currency + selectedService?.my_price,
        total_value: parseInt(selectedService?.my_price!),
        customer_note: customerNote ? customerNote : '',
        duration: durationField === false ? defaultDuration : customDuration,
        is_vip: is_vip,
        payment_method_id: '',
        payment_intent_id: '',
        is_video_appointment: is_video,
    };

    const onSubmit = () => {
        setLoading(true);
        api.post(`appointments`, addAppointment)
            .then((res: any) => {
                setLoading(false);
                if (res.error) {
                    return;
                }
                openSnackbar(t('Appointment  booked!'));
                navigate(`/customers/${_userData?.user?._id}`);
            })
            .catch(e => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    if (response) {
                        openSnackbar(response?.message);
                    }
                    if (response?.dates) {
                        setConflictDates(response?.dates);
                        setShowConflictModal(true);
                    }
                }
            });
    };

    const hiddenDateCalendar = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const getAvailableSlot = () => {
        if (selectedServiceProvider !== undefined && selectedService) {
            let date: any = moment(startDate ? startDate : start).format();
            let duration =
                durationField === false
                    ? defaultDuration == 0
                        ? '10'
                        : defaultDuration
                    : customDuration;
            api.post(`appointments/available_slots/${providerId}`, {
                date: date,
                duration: duration,
                calendar: business?.customCalendar ? 'customise' : undefined,
            }).then((res: any) => {
                setAvailableSlot(res.data?.slots);
                let unique = [...new Set(res.data?.appointmentSlots)];
                setAppointmentSlots(unique.sort());
            });
        }
    };

    const dropDownValue = () => {
        let time = '';
        appointmentTime.forEach((val: any) => {
            const value = moment(start).format(
                _userData?.user?.business_id?.time_select === '12' ? 'hh:mm a' : 'HH:mm'
            );

            if (value.indexOf('am') != -1 || value.indexOf('pm') != -1) {
                time = val.label;
            } else {
                time = val.value;
            }
            if (time === value) {
                let vals = [];
                if (val.value >= value) {
                    vals.push(val.value);
                }

                setDropdownFilter(val.value);
                return;
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    const disabledBtn = !selectedService || !dropDownFilter || !selectedServiceProvider;
    const paymentButtons =
        (business?.booking_without_payment === false &&
            selectedServiceProvider?.stripe_account_id !== null &&
            selectedServiceProvider?.stripe_account_verified === true) ||
        (business?.booking_without_payment === false && business?.stripe_account_verified === true);

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    <Col>
                        <DrawerModel>
                            {!serviceView && !serviceProviderView && !chargeModel && (
                                <AppointmentContent>
                                    <HeaderContainer>
                                        {_userData?.user_login == 'customer' && (
                                            <Back
                                                to={
                                                    loading
                                                        ? ''
                                                        : `/customers/${_userData?.user?._id}`
                                                }>
                                                <i className="fal fa-chevron-left"></i>
                                            </Back>
                                        )}
                                        <Header>{t('Appointment')}</Header>
                                    </HeaderContainer>
                                    <DrawerBody>
                                        {_userData?.user ? (
                                            <div>
                                                <AppointmentCustomer>
                                                    <Circle>
                                                        <NameCircle
                                                            src={`${process.env.REACT_APP_PROFILE_URL}${_userData?.user?.photo}`}></NameCircle>
                                                    </Circle>
                                                    <CustomerContent>
                                                        <Name>
                                                            {_userData?.user?.firstname +
                                                                ' ' +
                                                                _userData?.user?.lastname}
                                                        </Name>
                                                        <Email>{_userData?.user?.email}</Email>
                                                    </CustomerContent>
                                                    {_userData?.user_login == 'customer' ? (
                                                        ''
                                                    ) : (
                                                        <div>
                                                            <Icons className="fal fa-chevron-right"></Icons>
                                                        </div>
                                                    )}
                                                </AppointmentCustomer>
                                            </div>
                                        ) : (
                                            <div>
                                                <AppointmentCustomer>
                                                    <Circle>
                                                        <NameCircle></NameCircle>
                                                    </Circle>
                                                    <Label>{t('Select a client')}</Label>
                                                    <div>
                                                        {t('New Appointment')}
                                                        <Icons className="fal fa-plus"></Icons>
                                                    </div>
                                                </AppointmentCustomer>
                                            </div>
                                        )}

                                        {selectedServiceProvider ? (
                                            <div>
                                                <ServiceProviderContent
                                                    onClick={() => {
                                                        setServiceProviderView(true);
                                                    }}>
                                                    <Circle>
                                                        <NameCircle
                                                            src={`${process.env.REACT_APP_PROFILE_URL}${selectedServiceProvider?.photo}`}></NameCircle>
                                                    </Circle>
                                                    <CustomerContent>
                                                        <Name>{selectedServiceProvider.name}</Name>
                                                        <Email>
                                                            {selectedServiceProvider.email}
                                                        </Email>
                                                    </CustomerContent>
                                                    <div>
                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                    </div>
                                                </ServiceProviderContent>
                                            </div>
                                        ) : (
                                            <div>
                                                <ServiceProviderContent
                                                    onClick={() => {
                                                        setServiceProviderView(true);
                                                    }}>
                                                    <Circle>
                                                        <NameCircle></NameCircle>
                                                    </Circle>
                                                    <Label>{t('Select a Professional')}</Label>
                                                    <div>
                                                        <Icons className="fal fa-plus"></Icons>
                                                    </div>
                                                </ServiceProviderContent>
                                            </div>
                                        )}
                                        {selectedService ? (
                                            <SelectServiceContent
                                                onClick={() => {
                                                    setServiceView(true);
                                                }}>
                                                <ServiceBorder
                                                    className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                <ServiceDetails>
                                                    <NameBody>
                                                        <ServiceName>
                                                            {selectedService.name}
                                                        </ServiceName>
                                                        <ServiceDuration>
                                                            {selectedService.my_duration}
                                                            min
                                                        </ServiceDuration>
                                                    </NameBody>
                                                    <PriceBody>
                                                        <ServicePrice>
                                                            {_userData?.user?.business_id?.currency}
                                                            {selectedService.my_price}
                                                        </ServicePrice>
                                                        <div>
                                                            <Icons className="fal fa-chevron-right"></Icons>
                                                        </div>
                                                    </PriceBody>
                                                </ServiceDetails>
                                            </SelectServiceContent>
                                        ) : (
                                            <SelectServiceBody
                                                onClick={() => {
                                                    setServiceView(true);
                                                }}>
                                                <ServiceHeader>
                                                    {t('Select a Service')}
                                                </ServiceHeader>
                                                <div>
                                                    <Icons className="fal fa-chevron-right"></Icons>
                                                </div>
                                            </SelectServiceBody>
                                        )}
                                        {selectedService?.is_video && (
                                            <CustomDiv style={{ marginTop: '0.3rem' }}>
                                                <CustomSpan>
                                                    {t('Are you sure you want video appointment?')}
                                                </CustomSpan>
                                                <Switch
                                                    value={is_video}
                                                    onChange={() => {
                                                        setIs_video(!is_video);
                                                    }}
                                                />
                                            </CustomDiv>
                                        )}
                                        <FormContent>
                                            <FormDetails>
                                                <DatesBody>
                                                    <FormDate>
                                                        <DateLabel>{t('Select a Date')}</DateLabel>
                                                        <CalenderDate>
                                                            {startDate
                                                                ? moment(startDate).format(
                                                                      'ddd, MMM DD'
                                                                  )
                                                                : moment(start).format(
                                                                      'ddd, MMM DD'
                                                                  )}
                                                        </CalenderDate>
                                                        <input
                                                            type="date"
                                                            min={
                                                                new Date()
                                                                    .toISOString()
                                                                    .split('T')[0]
                                                            }
                                                            value={moment(startDate).format(
                                                                'YYYY-MM-DD'
                                                            )}
                                                            onChange={(e: any) =>
                                                                hiddenDateCalendar(e)
                                                            }
                                                        />
                                                    </FormDate>
                                                </DatesBody>
                                                <ToggleButton>
                                                    {selectedServiceProvider?.vip == true && (
                                                        <>
                                                            {' '}
                                                            <CustomSpan>
                                                                {t('Vip Appointment?')}
                                                            </CustomSpan>
                                                            <Switch
                                                                value={is_vip}
                                                                onChange={(val: any) => {
                                                                    setIs_vip(val);
                                                                }}
                                                                isSmall={true}
                                                            />
                                                        </>
                                                    )}
                                                </ToggleButton>
                                                <InputGroup>
                                                    <InputContent>
                                                        <Col lg={12}>
                                                            <Select
                                                                label={t('Start')}
                                                                disabled={true}
                                                                value={moment(
                                                                    moment().format() +
                                                                        ' ' +
                                                                        dropDownFilter,
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                ).format(
                                                                    _userData?.user?.business_id
                                                                        ?.time_select === '12'
                                                                        ? 'hh:mm a'
                                                                        : 'HH:mm'
                                                                )}
                                                                options={appointmentTime}
                                                                onChange={(val: string) => {
                                                                    const newStart =
                                                                        moment(start).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                        'T' +
                                                                        val +
                                                                        ':00';

                                                                    setStart(newStart);
                                                                }}
                                                            />
                                                        </Col>
                                                    </InputContent>
                                                </InputGroup>

                                                <InputGroup>
                                                    <Input
                                                        label={t(`Appointment Notes`)}
                                                        value={customerNote}
                                                        onChange={({
                                                            value,
                                                        }: {
                                                            value: string;
                                                        }) => {
                                                            setCustomerNote(value);
                                                        }}
                                                    />
                                                </InputGroup>
                                                <PlatformDiv>
                                                    <TotalPriceBody></TotalPriceBody>
                                                    <TotalPriceBody>
                                                        <PriceHeader>{t('Total')}</PriceHeader>
                                                        <TotalPrice>
                                                            {business?.currency}
                                                            {selectedService?.my_price}
                                                        </TotalPrice>
                                                    </TotalPriceBody>
                                                </PlatformDiv>

                                                <FormFooter>
                                                    {disabledBtn ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}>
                                                            <Button
                                                                bgtype={'secondary'}
                                                                disabled={true}
                                                                // ifClicked={onSubmit}
                                                                width="8rem !important"
                                                                label={t('Reserve')}></Button>
                                                        </div>
                                                    ) : paymentButtons === true ? (
                                                        <FormButtons>
                                                            <Button
                                                                bgtype={'discard'}
                                                                ifClicked={() => {
                                                                    setChargeModel(true);
                                                                }}
                                                                label={`${t('Reserve & Pay')}
                                                                    ${
                                                                        business?.currency +
                                                                        selectedService?.my_price
                                                                    }`}></Button>
                                                            <Button
                                                                bgtype={'secondary'}
                                                                ifClicked={() => {
                                                                    setChargeModel(true);
                                                                }}
                                                                width="8rem !important"
                                                                label={t('Reserve')}></Button>
                                                        </FormButtons>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}>
                                                            {' '}
                                                            <Button
                                                                bgtype={'secondary'}
                                                                ifClicked={onSubmit}
                                                                width="8rem !important"
                                                                label={t('Reserve')}></Button>
                                                        </div>
                                                    )}
                                                </FormFooter>
                                            </FormDetails>
                                        </FormContent>
                                    </DrawerBody>
                                </AppointmentContent>
                            )}
                            {chargeModel === true && (
                                <CompletePayment
                                    newAppointmentModel={true}
                                    stripeId={_userData?.user?.stripe_id}
                                    onClose={() => setChargeModel(false)}
                                    onSubmit={() => {
                                        setChargeModel(false);
                                    }}
                                    selectedService={selectedService}
                                    tipValue={addAppointment?.total_value}
                                    platForm={'web'}
                                    selectedProfessional={selectedServiceProvider}
                                    customerDetails={_userData?.user}
                                    calendarDate={addAppointment?.booked_from_iso}
                                    appointment={addAppointment}
                                />
                            )}
                            {serviceView && (
                                <SelectService
                                    id={providerId}
                                    onClose={() => {
                                        setServiceView(false);
                                    }}
                                    selectedService={selectedService}
                                    onSelect={(service: any) => {
                                        const newEnd = formatDate(
                                            moment(start).add(service.duration, 'm').toDate()
                                        );

                                        const data = {
                                            price: service.price,
                                            _id: service.service_id,
                                            my_price: service.my_price,
                                            my_duration: service.my_duration,
                                            duration: service.duration,
                                            name: service.name,
                                            color: service.color,
                                            available_for_boooking: service.available_for_boooking,
                                            vip_price: service?.vip_price,
                                            is_video: service?.is_video,
                                        };
                                        setSelectedService(data);
                                        setEnd(newEnd);
                                        setServiceDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setIs_video(service?.is_video);
                                        setDurationMinutes(service.my_duration % 60);
                                        setServiceDurationMinutes(service.my_duration % 60);
                                        getAvailableSlot();
                                        setServiceView(false);
                                    }}
                                />
                            )}
                            {serviceProviderView && (
                                <SelectServiceProvider
                                    onClose={() => {
                                        setServiceProviderView(false);
                                    }}
                                    onSelect={(serviceProvider: IServiceProvider) => {
                                        setSelectedService(null);
                                        setSelectedServiceProvider(serviceProvider);
                                        setProviderId(serviceProvider._id);
                                    }}
                                />
                            )}
                            {showConflictModal && (
                                <ConflictModal
                                    dates={conflictDates}
                                    onContinue={() => {
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => setShowConflictModal(false)}
                                />
                            )}
                        </DrawerModel>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};
const MainContainer = styled(Container)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;
const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const DrawerModel = styled.div`
    background-color: ${palette.white};
    z-index: 100;
    box-shadow: ${palette.customer_drawer_shadow} 0px 0px 48px 0px;
    min-width: 360px;
    border-radius: 0.75rem;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    ${media.xs`
    width: 100%;
    height: 98%;
    display: block;
	`}
    ${media.sm`
    width: 100%;
    height: 98%;
    display: block;
	`}
    ${media.md`
    width: 100%;
    height: 98%;
    display: block;
	`}
	${media.lg`
    width: 400px;
	`}
`;
const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    margin-bottom: 1rem;
`;
const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
`;
const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
    margin-top: 0.5rem;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 1rem;
    height: calc(100% - 84px);
    // overflow: scroll;
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0.5rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const SelectServiceContent = styled.div`
    border: 1px dashed ${palette.select_box};
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
    background: ${palette.white};
`;
const ServiceHeader = styled.div`
    color: ${palette.grey.grey_20};
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const ToggleButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;

const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;
const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;
const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: ${palette.grey.grey_20};
`;
const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;
const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;
const SelectServiceBody = styled.div`
    border: 1px dashed ${palette.select_box};
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    margin-top: 0.5rem /* 32px */;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const Icons = styled.i`
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const PlatformDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TotalPriceBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem /* 16px */;
`;
const PriceHeader = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: ${palette.grey.grey_20};
    text-align: right;
`;
const TotalPrice = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: right;
`;
const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: ${palette.white};
`;

const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem /* 24px */;
    justify-content: space-between;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;
const Name = styled.div`
    font-weight: 500;
`;
const Email = styled.div`
    color: ${palette.grey.grey_20};
    font-size: 0.9rem;
`;

const CalenderDate = styled.div`
    cursor: pointer;
`;

const Back = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.5rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;
const DateLabel = styled.div`
    color: ${palette.grey.grey_60};
    font-size: 12px;
    font-weight: bold;
`;
export default NewAppointmentDrawerForCustomer;
