/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: DeleteRecurringModal
 * Description: This component represents a modal for deleting recurring appointments. Users can choose to delete a single appointment, all appointments in the series,
 * or all future appointments in the series. The modal includes options to confirm or cancel the deletion and provides loading indicators during the deletion process.
 * The component integrates with various UI elements such as buttons and icons for a user-friendly experience.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - deleteAll: any - Function to delete all recurring appointments.
 * - deleteOne: any - Function to delete only the current appointment in the series.
 * - futureDelete: any - Function to delete all future recurring appointments.
 * - onClose: () => void - Callback function triggered when the delete recurring modal is closed.
 * - onDelete: () => void - Callback function triggered when the delete action is confirmed.
 * - getAppointments: any - Function to get appointments after deletion.
 *
 */

import { LoaderOverlay, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { palette } from 'styled/common';
const DeleteRecurringModal = ({
    deleteAll,
    deleteOne,
    futureDelete,
    onClose,
    onDelete,
    getAppointments,
}: {
    deleteAll: any;
    deleteOne: any;
    futureDelete: any;
    onClose(): void;
    onDelete(): void;
    getAppointments: any;
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [multipleLoading, setMultipleLoading] = useState(false);
    const [futureLoading, setFutureLoading] = useState(false);
    const [openSnackbar] = useSnackbar();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const allAppointmentDelete = () => {
        setMultipleLoading(true);
        api.delete(`/appointments/recurring/${deleteAll}`)
            .then(() => {
                setMultipleLoading(false);
                onDelete();
                openSnackbar(t('Recurrings deleted successfullly!'));
                getAppointments();
            })
            .catch((res: any) => {
                setMultipleLoading(false);
                openSnackbar(t('Failed to delete recurring!'));
            });
    };
    const singleAppointmentDelete = () => {
        setLoading(true);
        api.delete(`/appointments/${deleteOne}`)
            .then(() => {
                setLoading(false);
                onDelete();
                openSnackbar(t('Recurring deleted successfully!'));
                getAppointments();
            })
            .catch((res: any) => {
                setLoading(false);
                openSnackbar(t('Failed to delete recurring!'));
            });
    };
    const futureAppointmentsDelete = () => {
        setFutureLoading(true);
        api.delete(`/appointments/future_recurring/${futureDelete}`)
            .then(() => {
                setFutureLoading(false);
                onDelete();
                openSnackbar(t('Recurring deleted successfully!'));
                getAppointments();
            })
            .catch((res: any) => {
                setFutureLoading(false);
                openSnackbar(t('Failed to delete recurring!'));
            });
    };
    return (
        <Model
            tabIndex={-1}
            onClick={e => (!multipleLoading || !loading || !futureLoading) && closeModal(e)}>
            <ModelBody style={{ width: '440px' }}>
                {(multipleLoading || loading || futureLoading) && <LoaderOverlay />}
                <ModelHeading>
                    <Heading>{t('Delete recurring appointment?')}</Heading>
                    <Cover
                        onClick={() =>
                            (!multipleLoading || !loading || !futureLoading) && onClose()
                        }>
                        <i className="fal fa-times"></i>
                    </Cover>
                </ModelHeading>
                <ModelContent>
                    {t(
                        'This will remove appointments associated with this series from your calendar.'
                    )}
                </ModelContent>
                <Footer>
                    <Button
                        bgtype={'primary'}
                        ifClicked={() => singleAppointmentDelete()}
                        label={'Delete'}
                    />
                    &nbsp;
                    <Button
                        bgtype={'primary'}
                        ifClicked={() => allAppointmentDelete()}
                        label={'Delete All'}
                    />
                    &nbsp;
                    <Button
                        bgtype={'primary'}
                        ifClicked={() => futureAppointmentsDelete()}
                        label={'Delete Future'}
                    />
                </Footer>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
    max-width: 82vw;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.5rem !important;
    line-height: 2.25rem !important;
`;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default DeleteRecurringModal;
