/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Switch
 * Description: A customizable switch/toggle component.
 * Purpose:
 * - Accepts various props for customization, including 'name', 'value', 'className', 'disabled', and 'onChange'.
 * - Renders a visually styled switch with an optional label indicating 'On' or 'Off'.
 * - Uses styled-components for styling.
 *
 * Props:
 * - name?: string - The name attribute for the switch input.
 * - value?: boolean - The current value of the switch.
 * - className?: string - Additional class names for styling purposes.
 * - disabled?: boolean - If true, the switch is disabled and appears with reduced opacity.
 * - onChange?(value: boolean): void - Callback function triggered when the switch value changes.
 * - isSmall?: boolean - If true, renders a smaller version of the switch.
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { palette } from '../../../styled/common';

export interface SwitchProps {
    name?: string;
    value?: boolean;
    className?: string;
    disabled?: boolean;
    onChange?(value: boolean): void;
    isSmall?: boolean;
}

const Switch = ({ name, value, className, disabled, onChange, isSmall, ...props }: SwitchProps) => {
    const [switchValue, setSwitchValue] = useState(value);

    useEffect(() => {
        setSwitchValue(value);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : false;
        setSwitchValue(value);
        if (onChange) onChange(value);
    };

    return (
        <SwitchContainer disabled={disabled}>
            <SwitchInput
                disabled={disabled}
                type="checkbox"
                name={name}
                onChange={event => handleChange(event)}
                checked={switchValue}
            />
            <SwitchCore isSmall={isSmall}></SwitchCore>
            {switchValue ? <On isSmall={isSmall}>On</On> : <Off isSmall={isSmall}>Off</Off>}
        </SwitchContainer>
    );
};

const SwitchContainer = styled.label<SwitchProps>`
    width: ${({ isSmall }) => (isSmall ? `58px` : '64px')};
    height: ${({ isSmall }) => (isSmall ? `24px` : '30px')};
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    font-size: 11px;
    cursor: pointer;
    margin: 2px;
    opacity: ${({ disabled }) => (disabled ? `0.5` : '1')};
    cursor: ${({ disabled }) => (disabled ? `no-drop` : 'pointer')};
`;

const SwitchCore = styled.div<any>`
    background-color: #e0e0e0;
    width: ${({ isSmall }) => (isSmall ? `58px` : '64px')};
    height: ${({ isSmall }) => (isSmall ? `24px` : '30px')};
    margin: 0;
    display: inline-block;
    position: relative;
    outline: 0;
    box-sizing: border-box;
    transition: border-color 0.3s, background-color 0.3s;
    user-select: none;
    border-radius: 999px;
    &::before {
        width: ${({ isSmall }) => (isSmall ? `19px` : '24px')};
        height: ${({ isSmall }) => (isSmall ? `19px` : '24px')};

        display: block;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 0;
        transform: translate(3px, 3px);
        transition: transform 0.3s;
        border-radius: 100%;
        background-color: #fff;
        content: '';
    }
`;

const SwitchInput = styled.input`
    position: absolute;
    left: 0;
    opacity: 0;
    outline: none;
    z-index: -1;
    &:checked + ${SwitchCore} {
        background-color: ${palette.secondary};
        &::before {
            transform: translate(37px, 3px);
        }
    }
`;

const On = styled.span<any>`
    text-transform: capitalize;
    font-weight: 700;
    color: white;
    position: absolute;
    top: 0;
    height: ${({ isSmall }) => (isSmall ? `30px` : '24px')};
    line-height: ${({ isSmall }) => (isSmall ? `25px` : '30px')};
    left: 0.65rem;
`;

const Off = styled.span<any>`
    text-transform: capitalize;
    font-weight: 700;
    color: white;
    position: absolute;
    top: 0;
    height: ${({ isSmall }) => (isSmall ? `30px` : '24px')};
    line-height: ${({ isSmall }) => (isSmall ? `25px` : '30px')};
    right: ${({ isSmall }) => (isSmall ? ` 0.8rem` : '0.65rem')};
`;

export default Switch;
