/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Service Reducer
 * Description: This reducer handles the state related to services, including fetching, creating, and updating service information.
 *
 * Constants:
 * - serviceConstants: Constants for service actions.
 *
 * Initial State:
 * - INITIAL_STATE: Initial state for the service reducer.
 *
 * Reducer:
 * - service: Reducer function for handling service-related actions and updating the state.
 */

import { serviceConstants } from 'actions/service';
import { IService } from 'interfaces/service.interface';

const INITIAL_STATE = {
    services: [] as IService[],
};

export function service(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case serviceConstants.GET_SUCCESS:
            return {
                services: state.services?.concat(action.services),
            };
        case serviceConstants.CREATE_SUCCESS:
            return {
                services: state.services?.concat(action.service),
            };
        case serviceConstants.UPDATE_SUCCESS:
            const prev: any = state.services.find(service => service._id === action.service._id);
            Object.assign(prev, action.service);
            return state;
        default:
            return state;
    }
}
