/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Layout Component
 * Description: React component for the overall layout of the web app. It includes a header, aside (if the user is not a customer), and the main content area.
 *
 * Props:
 * - children: React.ReactNode - The content to be rendered inside the layout.
 */

import React, { FC } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Aside, Header } from '@components/common';
import { routes } from 'constants/routes';
import useRouter from 'hooks/router';
import { HeaderContent } from '@components/common/layout/Layout';

const Layout: FC<any> = ({ children, ...props }) => {
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const router = useRouter();
    const activeRoute = () => {
        for (let i = 0; i < routes?.length; i++) {
            if (routes[i].patterns) {
                for (let j = 0; j < routes[i].patterns!.length; j++) {
                    if (router.pathname === routes[i].patterns![j].path) {
                        return routes[i].patterns![j];
                    }
                }
            }
            if (router.pathname === routes[i].path) {
                return routes[i];
            }
        }
    };

    return (
        <Wrapper flexDirection={_userData?.user?.business_id?.leftMenu === true && true}>
            {_userData?.user_login !== 'customer' ? <Aside /> : ''}
            {_userData?.user_login !== 'customer' ? (
                <PageContent margin={_userData?.user?.business_id?.leftMenu === true && true}>
                    <HeaderContent>
                        <Header activeRoute={activeRoute()!} />
                    </HeaderContent>
                    <Content>{children}</Content>
                </PageContent>
            ) : (
                <CustomDiv>
                    <Header activeRoute={activeRoute()!} />
                    <Content>{children}</Content>
                </CustomDiv>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div<any>`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: ${({ flexDirection }) => (flexDirection ? 'row' : 'column')} !important;
    display: flex !important;
`;

const PageContent = styled.div<any>`
    margin-left: ${({ margin }) => (margin ? ' 18.6rem' : '')};
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    ${media.xs`
    margin-left: 0rem;
  `}
    ${media.sm`
    margin-left: 0rem;
  `}
    ${media.md`
	margin-left: 0rem;
  `}
    ${media.lg`
    margin-left: ${({ margin }: any) => (margin ? ' 18.6rem' : '')};
  `}
`;

const CustomDiv = styled.div`
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    ${media.xs`
margin-left: 0rem;
`}
    ${media.sm`
margin-left: 0rem;
`}
${media.md`
margin-left: 0rem;
`}
`;

const Content = styled.div`
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

export default Layout;
