/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CompletePayment
 * Description: This component handles the complete payment process for appointments. It includes the logic for creating payment intents, payment methods, and handling the payment flow.
 * Developed with React and integrates with various components for a seamless user experience.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the complete payment process is closed.
 * - stripeId?: string - Stripe ID for customer identification.
 * - selectedService?: any - Details of the selected service for the appointment.
 * - tipValue?: any - Tip amount for the appointment.
 * - platForm?: any - Platform information for the appointment.
 * - selectedProfessional?: any - Details of the selected professional for the appointment.
 * - customerDetails?: any - Details of the customer for the appointment.
 * - calendarDate?: any - Selected date for the appointment.
 * - appointment?: any - Details of the appointment being processed.
 * - onSubmit: () => any - Callback function triggered when the payment process is successfully completed.
 * - newAppointmentModel?: any - Indicates whether it's a new appointment model or edit appointment model.
 */

import s from '../newAppointmentDrawer/NewAppointmentDrawer.module.scss';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import { api } from 'helpers/auth-axios';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import NewCreditCard from '@components/appointments/modal/creditCard/NewCreditCard';
import moment from 'moment';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
interface IProps {
    onClose(): void;
    stripeId?: string;
    selectedService?: any;
    platForm?: any;
    tipValue?: any;
    selectedProfessional?: any;
    customerDetails?: any;
    calendarDate?: any;
    appointment?: any;
    onSubmit(): any;
    newAppointmentModel?: any;
}
const CompletePayment = ({
    onClose,
    stripeId,
    selectedService,
    tipValue,
    platForm,
    selectedProfessional,
    customerDetails,
    calendarDate,
    appointment,
    onSubmit,
    newAppointmentModel,
}: IProps) => {
    const { t }: any = useTranslation();
    const tip =
        parseFloat(tipValue) -
        parseFloat(appointment?.total.slice(1)) -
        (parseFloat(appointment?.total.slice(1)) == parseFloat(appointment?.total_value)
            ? 0
            : appointment?.payment_intent_id && appointment?.payment_method_id
            ? 1
            : 0);
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [paymentIntentId, setPaymentIntentId] = useState<any>('');
    const [paymentMethodId, setPaymentMethodId] = useState<any>('');
    const [tipLabel, setTipLabel] = useState<any>();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [selectedGratuity, setSelectedGratuity] = useState<any>(tip);
    const [customTip, setCustomTip] = useState<any>(null);
    const [business, setBusiness] = useState<any>();
    const [reserve, setReserve] = useState<boolean>();
    const [reserveLoading, setReserveLoading] = useState<boolean>(false);
    const [cardState, setCardState] = useState({
        cardNumber: '',
        cardHolder: t('FULL NAME'),
        cardMonth: '',
        cardYear: '',
        cardCVV: '',
        isCardFlipped: false,
    });
    const [onCardResponse, setOnCardResponse] = useState<boolean>(false);
    const [differentCard, setDifferentCard] = useState<any>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (customTip !== undefined || null) {
            customTip !== null && setSelectedGratuity(customTip);
            customTip !== null && setTipLabel('custom tip');
        }
    }, [customTip]);

    useEffect(() => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            if (res) {
                setBusiness(res.data);
                const customerId: any = {
                    accountId:
                        selectedProfessional?.stripe_account_id !== null &&
                        selectedProfessional?.stripe_account_verified === true
                            ? selectedProfessional?.stripe_account_id
                            : res?.data?.stripe_account_id,
                    customerId: stripeId,
                };
                if (
                    (stripeId !== '' &&
                        selectedProfessional?.stripe_account_id !== null &&
                        selectedProfessional?.stripe_account_verified === true) ||
                    (stripeId !== '' &&
                        res?.data?.stripe_account_id !== null &&
                        res?.data?.stripe_account_verified)
                ) {
                    api.post(`/billing/stripe-customer`, customerId)
                        .then((res: any) => {
                            setCustomerData(res.data);
                        })
                        .catch((e: any) => {
                            if (e?.esponse) {
                                openSnackbar(e?.response?.data?.message);
                            }
                        });
                }
            }
        });
    }, []);

    useEffect(() => {
        if (paymentIntentId !== '' && paymentMethodId !== '' && reserve === true) {
            reserveWithoutPayment();
        } else if (paymentIntentId !== '' && paymentMethodId !== '') {
            completePayment();
        }
    }, [paymentIntentId, paymentMethodId, reserve]);

    const updateCardState = useCallback(
        (keyName: string, value: any) => {
            setCardState({
                ...cardState,
                [keyName]: value,
            });
        },
        [cardState]
    );

    const subtotal = appointment?.total.slice(1);
    const gratuityOptions = [
        {
            label: t('No Tip'),
            value: 0,
        },
        {
            label: '20%',
            value: subtotal * 0.2,
        },
        {
            label: '25%',
            value: subtotal * 0.25,
        },
        {
            label: '30%',
            value: subtotal * 0.3,
        },
    ];

    const totalCharges =
        parseFloat(appointment?.total.slice(1)) +
        1 +
        parseFloat(selectedGratuity ? selectedGratuity : 0);

    const createPaymentIntent = (
        customerData: any,
        total: any,
        selectedProfessional: any,
        calendarDate: any,
        cardState: any,
        reserver?: any
    ) => {
        let totalAmount: any = parseFloat(total) * 100;
        const payment_intent = {
            stripe_account_id:
                selectedProfessional?.stripe_account_id !== null &&
                selectedProfessional?.stripe_account_verified === true
                    ? selectedProfessional?.stripe_account_id
                    : business?.stripe_account_id,
            amount: parseInt(totalAmount),
            customer_details: customerData,
            description: {
                service_name: selectedService.name,
                staff_name: selectedProfessional?.name,
                date: moment(calendarDate).format('YYYY-MM-DD'),
            },
        };
        if (reserver === true) {
            setReserveLoading(true);
        } else {
            setLoading(true);
        }

        api.post('/billing/payment-intent', payment_intent)
            .then((res: any) => {
                const data = res.data as any;
                if (reserver === true) {
                    setReserveLoading(false);
                } else {
                    setLoading(false);
                }
                setPaymentIntentId(data.payment_intent_id);
                createPaymentMethod(cardState, reserver);
            })
            .catch((e: any) => {
                if (reserver === true) {
                    setReserveLoading(false);
                } else {
                    setLoading(false);
                }
            });
    };

    const createPaymentMethod = (cardState: any, reserver?: any) => {
        if (reserver === true) {
            setReserveLoading(true);
        } else {
            setLoading(true);
        }
        api.post('/billing/payment-method', {
            stripe_account_id:
                selectedProfessional?.stripe_account_id !== null &&
                selectedProfessional?.stripe_account_verified === true
                    ? selectedProfessional?.stripe_account_id
                    : business?.stripe_account_id,
            number: cardState.cardNumber,
            exp_month: cardState.cardMonth,
            exp_year: cardState.cardYear,
            cvc: cardState.cardCVV,
            customer_email: null,
        })
            .then((res: any) => {
                setPaymentMethodId(res?.data?.id);
                onSubmit();
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                }
                if (reserver == true) {
                    setReserveLoading(false);
                } else {
                    setLoading(false);
                }
                setOnCardResponse(true);
            });
    };

    let newAppointment: any = {
        booking_without_payment: business?.booking_without_payment,
        stripe_account_verified:
            selectedProfessional?.stripe_account_id !== null &&
            selectedProfessional?.stripe_account_verified === true
                ? selectedProfessional?.stripe_account_verified
                : business?.stripe_account_verified,
        total: business?.currency + selectedService?.my_price,
        total_value: totalCharges ? totalCharges : selectedService?.my_price,
        id: appointment?._id,
        intent_id:
            differentCard === true
                ? null
                : appointment?.payment_intent_id !== ''
                ? appointment?.payment_intent_id
                : null,
    };

    const totalValue =
        parseFloat(appointment?.total_value) +
        1 +
        parseFloat(selectedGratuity ? selectedGratuity : 0);

    const appointmentData = {
        id: null,
        intent_id: null,
        booking_without_payment: business?.booking_without_payment,
        stripe_account_verified:
            selectedProfessional?.stripe_account_verified == true
                ? selectedProfessional?.stripe_account_verified
                : business?.stripe_account_verified,
        startTime: moment(appointment?.booked_from_iso).format('HH:mm'),
        booked_from_iso: moment(appointment?.booked_from_iso).format('YYYY-MM-DD'),
        booked_till_iso: moment(appointment?.booked_till_iso).format('YYYY-MM-DD'),
        booked_from: moment(appointment?.booked_from).format('YYYY-MM-DD'),
        booked_till: moment(appointment?.booked_till).format('YYYY-MM-DD'),
        customer: '',
        service: selectedService?._id,
        serviceProvider: selectedProfessional?._id,
        total: appointment?.total,
        total_value: totalValue,
        duration: appointment?.duration,
        platform: 'web',
        customer_note: appointment?.customer_note,
        is_vip: appointment?.is_vip,
    };

    const services = {
        price: business?.currency + selectedService?.my_price,
        price_value: selectedService?.my_price,
        quantity: 1,
        service_name: selectedService?.name,
        service_id: selectedService?._id,
        tax_rate: selectedService?.tax_rate,
    };

    const transaction_details = {
        customer: '',
        total: business?.currency + selectedService?.my_price,
        total_value: totalCharges,
        items: services,
        payments: [
            {
                amount: business?.currency + totalCharges.toFixed(2),
                amount_value: totalCharges.toFixed(2),
                payment_method: { code: 'paypal', label: 'Paypal' },
                tip: selectedGratuity,
            },
        ],
        payment_method: { code: 'paypal', label: 'Paypal' },
        tip: {
            amount: business?.currency + parseFloat(selectedGratuity).toFixed(2),
            amount_value: parseFloat(selectedGratuity).toFixed(2),
            label: selectedGratuity == 0 ? 'no tip' : tipLabel,
        },
    };

    const completePayment = () => {
        api.post('/appointments/payment/pay', {
            stripe_account_id:
                selectedProfessional?.stripe_account_id !== null &&
                selectedProfessional?.stripe_account_verified === true
                    ? selectedProfessional?.stripe_account_id
                    : business?.stripe_account_id,
            payment_intent_id: paymentIntentId,
            payment_method_id: paymentMethodId,
            appointment_details: newAppointmentModel == true ? appointmentData : newAppointment,
            customer_details: customerDetails,
            transaction_details: transaction_details,
        })
            .then((res: any) => {
                if (res.data && res.data.status == 'succeeded') {
                    setLoading(false);
                    openSnackbar(t('Payment successful!'));
                    if (_userData?.user_login === 'customer') {
                        navigate(`/customers/${_userData?.user?._id}`);
                    }
                }
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                    onClose();
                }

                setLoading(false);
                setOnCardResponse(true);
            });
    };

    const reserveWithoutPayment = () => {
        api.post('/appointments/payment/pay', {
            stripe_account_id:
                selectedProfessional?.stripe_account_id !== null &&
                selectedProfessional?.stripe_account_verified === true
                    ? selectedProfessional?.stripe_account_id
                    : business?.stripe_account_id,
            payment_intent_id: paymentIntentId,
            payment_method_id: paymentMethodId,
            appointment_details: newAppointmentModel == true ? appointmentData : newAppointment,
            customer_details: customerDetails,
            transaction_details: { reserve_Without_Payment: true },
        })
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t('Payment successful!'));
                navigate(`/customers/${_userData?.user?._id}`);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                    onClose();
                }

                setLoading(false);
                setOnCardResponse(true);
            });
    };

    const directPayment = () => {
        setLoading(true);
        api.post('/appointments/payment/pay', {
            stripe_account_id:
                selectedProfessional?.stripe_account_id !== null &&
                selectedProfessional?.stripe_account_verified === true
                    ? selectedProfessional?.stripe_account_id
                    : business?.stripe_account_id,
            payment_intent_id: appointment?.payment_intent_id,
            payment_method_id: appointment?.payment_method_id,
            appointment_details: newAppointment,
            customer_details: customerData,
            transaction_details: transaction_details,
        })
            .then((res: any) => {
                if (res.data && res.data.status == 'succeeded') {
                    setLoading(false);
                    openSnackbar(t('Payment successful!'));
                    // onClose();
                    onSubmit();

                    if (_userData?.user_login === 'customer') {
                        navigate(`/customers/${_userData?.user?._id}`);
                    }
                }
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                    onClose();
                }

                setLoading(false);
                setOnCardResponse(true);
            });
    };

    const disabledBtn =
        !cardState.cardCVV ||
        !cardState.cardHolder ||
        !cardState.cardNumber ||
        !cardState.cardMonth ||
        !cardState.cardYear;

    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <ClientContent lg={12}>
                        <HeaderContainer>
                            <CloseButton onClick={() => (loading ? '' : onClose)}>
                                <Icon className="fal fa-chevron-left"></Icon>
                            </CloseButton>
                            <Header>{t('Pay')}</Header>
                        </HeaderContainer>
                        {(selectedProfessional?.stripe_account_id !== null &&
                            selectedProfessional?.stripe_account_verified === true) ||
                        business?.stripe_account_verified === true ? (
                            <CustomersBody>
                                <Wrapper>
                                    <CustomerDetails>
                                        <Input
                                            label={t('Firstname')}
                                            name="firstName"
                                            allowNumber={false}
                                            readOnly={true}
                                            value={
                                                customerData?.name
                                                    ? customerData?.name.split(' ', 1)
                                                    : customerDetails?.firstname
                                            }
                                        />
                                        <Input
                                            label={t('Lastname')}
                                            name="lastName"
                                            allowNumber={false}
                                            readOnly={true}
                                            value={
                                                customerData?.name
                                                    ? customerData?.name.substring(
                                                          customerData?.name.indexOf(' ') + 1
                                                      )
                                                    : customerDetails?.lastname
                                            }
                                        />
                                    </CustomerDetails>
                                    <DivGroup>
                                        <Input
                                            required={true}
                                            label={t('Email')}
                                            name="email"
                                            type="email"
                                            readOnly={true}
                                            value={
                                                customerData?.email
                                                    ? customerData?.email
                                                    : customerDetails?.email
                                            }
                                        />
                                    </DivGroup>
                                    <DivGroup>
                                        <Input
                                            allowNumber={true}
                                            label={t('Phone')}
                                            name="phoneNumber"
                                            readOnly={true}
                                            value={
                                                customerData?.phone
                                                    ? customerData?.phone
                                                    : customerDetails?.phone
                                            }
                                        />
                                    </DivGroup>

                                    {differentCard == true ? (
                                        <NewCreditCard
                                            initialState={cardState}
                                            loading={false}
                                            onChange={updateCardState}
                                            cardDisable={true}
                                        />
                                    ) : customerDetails &&
                                      customerDetails?.stripe_id !== '' &&
                                      appointment?.payment_method_id !== '' &&
                                      appointment?.payment_intent_id !== '' ? (
                                        ''
                                    ) : (
                                        <NewCreditCard
                                            initialState={cardState}
                                            loading={false}
                                            onChange={updateCardState}
                                            cardDisable={true}
                                        />
                                    )}
                                    <div>
                                        <div className={`${s.gratuity}`}>
                                            <Title>{t('Tip')}</Title>
                                            {differentCard === false &&
                                                appointment?.payment_method_id !== '' &&
                                                appointment?.payment_intent_id !== '' && (
                                                    <Span onClick={() => setDifferentCard(true)}>
                                                        {t('try another card?')}
                                                    </Span>
                                                )}
                                            {differentCard === true &&
                                                appointment?.payment_method_id !== '' &&
                                                appointment?.payment_intent_id !== '' && (
                                                    <Span onClick={() => setDifferentCard(false)}>
                                                        {t('use default one?')}
                                                    </Span>
                                                )}
                                        </div>
                                        <div
                                            className="gratuity-items"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                            }}>
                                            {gratuityOptions.map((option: any, i: any) => {
                                                return (
                                                    <div>
                                                        {loading == true ? (
                                                            <div
                                                                key={i}
                                                                className={`${s.gratuity_tip} ${
                                                                    selectedGratuity ===
                                                                    option.value
                                                                        ? s.gratuity_tipActive
                                                                        : ''
                                                                }`}
                                                                onClick={() => {
                                                                    setSelectedGratuity(
                                                                        option.value
                                                                    );
                                                                    setCustomTip(null);
                                                                    setTipLabel(option.label);
                                                                }}>
                                                                <div>
                                                                    {
                                                                        _userData?.user?.business_id
                                                                            ?.currency
                                                                    }
                                                                    {option.value.toFixed(2)}
                                                                </div>
                                                                <GratuatyLabel>
                                                                    {t(option.label)}
                                                                </GratuatyLabel>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                key={i}
                                                                className={`${s.gratuity_tip} ${
                                                                    selectedGratuity ===
                                                                    option.value
                                                                        ? s.gratuity_tipActive
                                                                        : ''
                                                                }`}
                                                                onClick={() => {
                                                                    setTipLabel(option.label);
                                                                    setSelectedGratuity(
                                                                        option.value
                                                                    );
                                                                    setCustomTip(null);
                                                                }}>
                                                                <div>
                                                                    {
                                                                        _userData?.user?.business_id
                                                                            ?.currency
                                                                    }
                                                                    {option.value.toFixed(2)}
                                                                </div>
                                                                <GratuatyLabel>
                                                                    {t(option.label)}
                                                                </GratuatyLabel>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <InputField>
                                            <Input
                                                label={t('Custom Tip')}
                                                name="Custom Tip"
                                                value={customTip}
                                                type={'number'}
                                                step="any"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={(e: any) => {
                                                    setCustomTip(e.value);
                                                }}
                                            />
                                        </InputField>

                                        <div style={{ padding: '1rem' }} className="subTotal-div">
                                            <CustomDiv>
                                                <Left></Left>{' '}
                                                <Right>
                                                    <GratuatyContent>
                                                        <Items>{t('Service')}</Items>
                                                        <Amount>{t('Amount')}</Amount>
                                                    </GratuatyContent>

                                                    <BasketItem>
                                                        <ItemsName>
                                                            {selectedService?.name}
                                                        </ItemsName>
                                                        <ItemsAmount>
                                                            {appointment?.is_vip == true
                                                                ? appointment?.total
                                                                : business?.currency +
                                                                  parseFloat(
                                                                      appointment?.total.slice(1)
                                                                  ).toFixed(2)}
                                                        </ItemsAmount>
                                                    </BasketItem>
                                                </Right>
                                            </CustomDiv>
                                            <TotalPriceContainer>
                                                <Left></Left>
                                                <Right>
                                                    <GratuatyContent>
                                                        <Gratuaty>{t('Tip')}</Gratuaty>
                                                        <Gratuaty>
                                                            {business?.currency}
                                                            {selectedGratuity
                                                                ? parseFloat(
                                                                      selectedGratuity
                                                                  ).toFixed(2)
                                                                : '0.00'}
                                                        </Gratuaty>
                                                    </GratuatyContent>
                                                    <DiscountWrapper>
                                                        <SubTotalContent>
                                                            {t('Booking Fee')}
                                                        </SubTotalContent>
                                                        <SubTotalContent>
                                                            {business?.currency}
                                                            1.00
                                                        </SubTotalContent>
                                                    </DiscountWrapper>
                                                    <SubTotalWrapper>
                                                        <SubTotalContent>
                                                            {t('Total Charges')}
                                                        </SubTotalContent>
                                                        <SubTotalContent>
                                                            {business?.currency}
                                                            {totalCharges.toFixed(2)}
                                                        </SubTotalContent>
                                                    </SubTotalWrapper>
                                                </Right>
                                            </TotalPriceContainer>
                                        </div>
                                    </div>

                                    {differentCard == true && disabledBtn ? (
                                        <Button
                                            bgtype={'secondary'}
                                            disabled={disabledBtn}
                                            ifClicked={() => {
                                                openSnackbar(t('Please enter your card details!'));
                                            }}
                                            width="100% !important"
                                            label={`${
                                                loading == true
                                                    ? `${t('Processing Please wait')} ${
                                                          business?.currency
                                                      }
                                                    ${totalCharges?.toFixed(2)}`
                                                    : `${
                                                          _userData.user_login === 'customer'
                                                              ? `${t('Pay')} ${business?.currency}
                                                              ${totalCharges?.toFixed(2)}`
                                                              : t('Charge')
                                                      }`
                                            }`}></Button>
                                    ) : disabledBtn &&
                                      appointment?.payment_method_id == '' &&
                                      appointment?.payment_intent_id == '' ? (
                                        <>
                                            <Button
                                                bgtype={'secondary'}
                                                disabled={disabledBtn}
                                                ifClicked={() => {
                                                    openSnackbar(
                                                        t('Please enter your card details!')
                                                    );
                                                }}
                                                width="100% !important"
                                                label={`${
                                                    loading === true
                                                        ? `${t('Processing Please wait')} ${
                                                              business?.currency
                                                          }
                                                    ${totalCharges?.toFixed(2)}`
                                                        : `${
                                                              _userData.user_login === 'customer'
                                                                  ? `${t('Pay')} ${
                                                                        business?.currency +
                                                                        totalCharges?.toFixed(2)
                                                                    }`
                                                                  : t('Charge')
                                                          }`
                                                }`}></Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                bgtype={'secondary'}
                                                ifClicked={() => {
                                                    setReserve(false);
                                                    differentCard === true
                                                        ? createPaymentIntent(
                                                              customerDetails,
                                                              totalCharges,
                                                              selectedProfessional,
                                                              calendarDate,
                                                              cardState
                                                          )
                                                        : customerDetails &&
                                                          customerDetails?.stripe_id !== '' &&
                                                          appointment?.payment_method_id !== '' &&
                                                          appointment?.payment_intent_id !== ''
                                                        ? directPayment()
                                                        : createPaymentIntent(
                                                              customerDetails,
                                                              totalCharges,
                                                              selectedProfessional,
                                                              calendarDate,
                                                              cardState
                                                          );
                                                }}
                                                width="100% !important"
                                                label={`${
                                                    loading === true
                                                        ? `${t('Processing Please wait')} ${
                                                              business?.currency +
                                                              totalCharges?.toFixed(2)
                                                          }`
                                                        : `${
                                                              _userData.user_login === 'customer' &&
                                                              newAppointmentModel === true
                                                                  ? `${t('Reserve & Pay')} ${
                                                                        business?.currency +
                                                                        totalCharges?.toFixed(2)
                                                                    }`
                                                                  : _userData.user_login ===
                                                                    'customer'
                                                                  ? `${t('Pay')} ${
                                                                        business?.currency +
                                                                        totalCharges?.toFixed(2)
                                                                    }`
                                                                  : t('Charge')
                                                          }`
                                                }`}></Button>
                                            {_userData.user_login === 'customer' &&
                                                newAppointmentModel === true && (
                                                    <div style={{ marginTop: '0.5rem' }}>
                                                        <Button
                                                            bgtype={'secondary'}
                                                            ifClicked={() => {
                                                                setReserve(true);
                                                                createPaymentIntent(
                                                                    customerDetails,
                                                                    totalCharges,
                                                                    selectedProfessional,
                                                                    calendarDate,
                                                                    cardState,
                                                                    true
                                                                );
                                                            }}
                                                            width="100% !important"
                                                            label={`${
                                                                reserveLoading === true
                                                                    ? `${t(
                                                                          'Processing Please wait'
                                                                      )}
                                                    ${
                                                        business?.currency +
                                                        totalCharges?.toFixed(2)
                                                    }`
                                                                    : `${
                                                                          _userData.user_login ===
                                                                          'customer'
                                                                              ? `${t('Reserve')} ${
                                                                                    business?.currency +
                                                                                    totalCharges?.toFixed(
                                                                                        2
                                                                                    )
                                                                                }`
                                                                              : t('Charge')
                                                                      }`
                                                            }`}></Button>
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </Wrapper>
                            </CustomersBody>
                        ) : (
                            t(`Please connect ebookingsystem to a valid Stripe account.`)
                        )}
                    </ClientContent>
                </Row>
            </Container>
        </>
    );
};
const ClientContent = styled(Col)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    ${media.xs`
    margin-left: 1.5rem;
  `}
    ${media.sm`
`}
    ${media.md`
`}
    ${media.lg`
    `}
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 2rem 0.3rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;

const CustomersBody = styled.div`
    flex: 0 0 25rem;
    background-color: ${palette.white};
    margin-left: 0rem;
    min-height: 100vh;
`;
const Wrapper = styled.div`
    padding-bottom: 100px;
`;
const CustomerDetails = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 1.3rem;
`;
const DivGroup = styled.div`
    margin-bottom: 1.3rem;
`;
const Items = styled.div`
    grid-column: span 6 / span 6 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;
const Amount = styled.div`
    grid-column: span 3 / span 3 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: right !important;
    color: rgba(157, 160, 164) !important;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
`;
const Span = styled.div`
    font-size: 1rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    color: ${palette.blue.defaultBlue};
    cursor: pointer;
`;
const GratuatyLabel = styled.div`
    color: rgba(157, 160, 164);
`;

const BasketItem = styled.ul`
    display: flex !important;
    justify-content: space-between;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    margin-top: 0.5rem;
`;

const ItemsName = styled.div`
    font-size: 0.92rem;
    display: flex;
    align-items: center;
    grid-column: span 6 / span 6;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ItemsAmount = styled.div`
    font-size: 13.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: span 3 / span 3;
    text-align: right;
`;
const CustomDiv = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const TotalPriceContainer = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const Left = styled.div`
    grid-column: span 3 / span 3 !important;
`;
const Right = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 1px solid rgba(223, 226, 230);
`;
const SubTotalWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
`;
const SubTotalContent = styled.div`
    font-size: 0.9rem;
`;
const DiscountWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 0.5rem !important;
`;
const GratuatyContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
`;
const Gratuaty = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;
const InputField = styled.div`
    margin-left: 1rem;
    margin-top: 1.5rem;
    width: 35%;
    ${media.xs`
    margin-top: 1.5rem;
    width: 42%;
  `}
    ${media.sm`
    margin-top: 1.5rem;
    width: 40%;
`}
    ${media.md`
    margin-top: 1rem;
  width: 35%;
`}
    ${media.lg`
    width: 35%;
    `}
`;
export default CompletePayment;
