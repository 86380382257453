/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * EditTeamMembers Component
 * Description: This component allows the user to edit details of a team member, including name, email, phone, password, short description, and profile image. It supports updating the password and provides a preview of the current profile image. It uses the react-hook-form library for form handling, Compressor library for image compression, and various UI components for input fields, buttons, and a custom toggle.
 *
 * Props:
 * - clickCallBack: () => void - Callback function triggered after successfully updating the team member and reload team members data.
 * - defaultData: ITeam - Default data for pre-filling the form when editing an existing team member.
 *
 */

import React, { useEffect, useState } from 'react';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import { ITeam } from 'interfaces/teamMember.interface';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import { palette } from 'styled/common';

const EditTeamMembers = ({ clickCallBack, defaultData }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState<ITeam>();

    useEffect(() => {
        setUploadImage(false);
        if (defaultData) {
            setTeam(defaultData);
            setValue('name', defaultData?.name);
            setValue('email', defaultData?.email);
            setValue('phone', defaultData?.phone);
            setValue('updatePassword', '');
            setValue('position', defaultData?.position);
            setValue('description', defaultData?.description);
            setProfileImage(defaultData?.photo);
        }
    }, [defaultData]);

    const validationSchema = Yup.object().shape(
        {
            name: Yup.string().required(t('This field is required')),
            email: Yup.string().email().required(t('This field is required')),
            updatePassword: Yup.string().when('updatePassword', (val: any, schema: any) => {
                if (val?.length > 0) {
                    return Yup.string()
                        .required(t('This field is required'))
                        .min(8, t('Password must be at least 8 characters'));
                } else {
                    return Yup.string().notRequired();
                }
            }),
        },
        [['updatePassword', 'updatePassword']]
    );

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<ITeam>({
        resolver: yupResolver(validationSchema),
    });

    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: ITeam) => {
        setLoading(true);
        api.put(`/team/${router.query.id as string}`, {
            ...data,
            newPhoto: uploadImage == true ? profileImage : null,
        })
            .then((res: any) => {
                setLoading(false);
                setUploadImage(false);
                clickCallBack();
                openSnackbar(t('Team Member updated successfully!'));
                router.navigate(`/team/team-list/${res.data._id}`);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getTeamById = () => {
        api.get(`/team/${router.query.id as string}`)
            .then((team: any) => {
                setTeam(team.data);
                setValue('name', team.data.name);
                setValue('email', team.data.email);
                setValue('phone', team.data.phone);
                setValue('updatePassword', '');
                setValue('position', team.data.position);
                setValue('description', team.data.description);
                setProfileImage(team.data.photo);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                setUploadImage(true);
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    <Col lg={12}>
                        {team && (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Card>
                                    <FormContent>
                                        <Left>
                                            <Circle>
                                                <CircleLabel
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    <Data
                                                        src={
                                                            uploadImage == true
                                                                ? profileImage
                                                                : `${process.env.REACT_APP_PROFILE_URL}${profileImage}`
                                                        }></Data>
                                                </CircleLabel>
                                                <FileInput
                                                    accept="image/jpeg/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Circle>
                                            <InputField>
                                                <Input
                                                    label={t('Name')}
                                                    name="name"
                                                    value={team.name}
                                                    onChange={onChange}
                                                    error={errors.name as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Email')}
                                                    name="email"
                                                    readOnly={true}
                                                    value={getValues('email')?.toLowerCase()}
                                                    onChange={onChange}
                                                    error={errors.email as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Phone')}
                                                    name="phone"
                                                    value={team.phone}
                                                    onChange={onChange}
                                                    allowPhoneNumberOnly={true}
                                                    maxLength={12}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Password')}
                                                    type={t('Password')}
                                                    name="updatePassword"
                                                    value={getValues('updatePassword')}
                                                    onChange={onChange}
                                                    error={errors.updatePassword as any}
                                                />
                                            </InputField>

                                            <InputField>
                                                <Input
                                                    label={t('Short Description')}
                                                    name="description"
                                                    value={team.description}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                            <FooterButton>
                                                <Button
                                                    bgtype={'secondary'}
                                                    type={'submit'}
                                                    label={t('Update')}></Button>
                                            </FooterButton>
                                        </Left>
                                    </FormContent>
                                </Card>
                            </Form>
                        )}
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
`;

const Form = styled.form``;

const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
`;

const FormContent = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    ${media.xs`
display: block !important;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`}
${media.lg`
  display: flex;
`}
`;

const Left = styled.div`
    padding: 1.71rem;
    flex-basis: 28.57rem;
`;

const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;

const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const Data = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const FileInput = styled.input`
    display: none;
`;

export const AddButtonCustom = styled.div`
    padding: 1rem;
    position: absolute;
    bottom: 0;
`;

export default EditTeamMembers;
