/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: SideCover
 * Description: Represents the side cover content for the registration page.
 * Purpose:
 * - Displays a logo and additional information encouraging users to register to the eBookingSystem.
 *
 * Usage:
 * - Included this component in the registration/login page.
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Col, media } from 'styled-bootstrap-grid';
import logo from '../../../images/Rocket.png';
import { useTranslation } from 'react-i18next';

const SideCover = () => {
    const { t } = useTranslation();
    return (
        <>
            <SideImage md={6} lg={7}>
                <CardLeft>
                    <FlexCenter>
                        <Logo src={logo} alt="Logo" />
                    </FlexCenter>
                    <AppContent>
                        <h2>{t('Register yourself to eBookingSystem')}</h2>
                        <Para>
                            {
                                'Buy secure and relaible customized booking software to power your brand with 24/7 automated online booking, reminders, payments, and much more features.'
                            }
                        </Para>
                    </AppContent>
                </CardLeft>
            </SideImage>
        </>
    );
};
const SideImage = styled(Col)`
    padding: 0;
    display: flex;
    ${media.xs`
    display:none;
	`}
    ${media.sm`
    display:none;
	`}
	${media.md`
    display:none;
	`}
	${media.lg`
    display: flex;
    flex-direction: column;
    justify-content: center;
	`}
`;

const Logo = styled.img`
    display: flex;
    justify-content: center;
    background-color: black;
    border-radius: 10%;
`;

const CardLeft = styled.div`
    background-repeat: no-repeat;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    height: 100%;
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
`;

const AppContent = styled.div`
    padding-top: 3.5rem !important;
    display: block;
    justify-content: center;
    text-align: center;
`;

const Para = styled.p`
    color: black !important;
    font-weight: 100 !important;
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
    text-align: center !important;
    padding: 1rem 7rem;
`;

export default SideCover;
