/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BusinessServices Component
 * Description: Component for managing business services, including listing, selecting, and deleting services.
 *
 * Props:
 * - onSelectService: (service: IService) => void - Callback function triggered when a service is selected to edit.
 *
 * Purpose:
 * - Allows users to view and manage their business services.
 * - Provides the ability to add, select, and delete services.
 *
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import s from './BusinessService.module.scss';
import { IService } from 'interfaces/service.interface';
import { palette } from '../../../styled/common';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { NotFound } from '../../team/list/serviceProviderList/ServiceProviderList';
import { useTranslation } from 'react-i18next';

const BusinessServices = ({ onSelectService }: { onSelectService: any }) => {
    const { t } = useTranslation();
    const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [service, setService] = useState<any[]>([]);
    const [openSnackbar] = useSnackbar();
    const navigate = useNavigate();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        setLoading(true);
        api.get('/services')
            .then((res: any) => {
                setLoading(false);
                if (res.data?.length) {
                    setService(res.data);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    }, []);

    const selectService = (service: IService) => {
        onSelectService(service);
    };

    const deleteService = (id: any) => {
        setLoading(true);
        api.delete(`/services/${id}`).then((res: any) => {
            if (res.statusText === 'OK') {
                openSnackbar(t('Service deleted successfully!'));
                setOpenDeleteModel(false);
                const deleteServices: any = service.filter((item: any) => {
                    return item._id !== id;
                });
                setService(deleteServices);
            } else {
                openSnackbar(t('Failed to delete Service!'));
                setOpenDeleteModel(false);
            }
        });
        setLoading(false);
        setOpenDeleteModel(false);
    };

    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <Row>
                    <HeaderCustom col={12} style={{ marginBottom: '1rem' }}>
                        <Heading>{t('Services')}</Heading>
                        <HeaderText></HeaderText>
                        <Button
                            label={t('Add service')}
                            bgtype="secondary"
                            ifClicked={() => navigate('/services/create')}></Button>
                    </HeaderCustom>
                    <Col lg={12}>
                        <Card>
                            <Wrapper>
                                <Row>
                                    {service?.length
                                        ? service.map((service, i) => (
                                              <Col key={i} sm={6} md={6} lg={4}>
                                                  <DeleteIcon
                                                      onClick={() => deleteModel(service._id)}>
                                                      <i className="fal fa-trash-alt"></i>
                                                  </DeleteIcon>
                                                  <ServiceCard
                                                      onClick={() => selectService(service)}
                                                      className={`${s.service} service-color-${service.color}--bg cursor-pointer`}>
                                                      <PriceTag>
                                                          <PriceField>
                                                              &nbsp;
                                                              {_userData.user.business_id.currency}
                                                              {service.price}
                                                          </PriceField>
                                                      </PriceTag>
                                                      <CustomDiv>
                                                          <ServiceName>{service.name}</ServiceName>
                                                          <div>
                                                              {service?.is_video && (
                                                                  <i className="fal fa-video"></i>
                                                              )}
                                                          </div>
                                                      </CustomDiv>

                                                      <Time>{`${
                                                          service.duration >= 60
                                                              ? Math.floor(service.duration / 60) +
                                                                'h'
                                                              : ''
                                                      } ${service.duration % 60}${t('min')}`}</Time>
                                                  </ServiceCard>
                                              </Col>
                                          ))
                                        : loading === false && (
                                              <NotFound>{t('No Service Found')}</NotFound>
                                          )}
                                </Row>
                                {openDeleteModel && (
                                    <DeleteModel
                                        id={''}
                                        onDelete={() => {
                                            setOpenDeleteModel(false);
                                        }}
                                        onClose={() => {
                                            setOpenDeleteModel(false);
                                        }}
                                        name={t('Service')}
                                        content={t(
                                            'If you delete this service provider you will lose all information connected to their service including assigned staff service, appointment service. This action cannot be undone.'
                                        )}
                                        loading={loading}
                                        onClick={() => deleteService(deleteId)}
                                    />
                                )}
                            </Wrapper>
                        </Card>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
    z-index: 0;
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
    cursor: pointer;
`;

const ServiceCard = styled.div`
    margin-bottom: 1.5rem;
`;

const PriceTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0px;
    // position: absolute;
    // top: 0.4rem;
    // right: 0.4rem;
`;

const ServiceName = styled.h1`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const Time = styled.div`
    font-size: 0.9rem !important;
`;

export const DeleteIcon = styled.button`
    padding: 0;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0.4rem;
    left: 1.3rem;
    z-index: 2;
    &:active {
        border: none !important;
        outline: none;
    }
    &:focus {
        border: none !important;
        outline: none;
    }
`;

const PriceField = styled.div`
    background-color: ${palette.green};
    padding: 0.15rem 0.4rem 0.15rem 0.15rem;
    display: inline-block;
    border-radius: 2rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${palette.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
`;

const HeaderCustom = styled(Col)`
    display: flex;
    justify-content: space-between;
`;

const HeaderText = styled.div`
    ${media.xs`
	display: flex;
  `}
    ${media.sm`
	display: flex;
  `}
	${media.md`
	display: flex;
  `}
	${media.lg`
	display: none;
  `}
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
    ${media.xs`
	display: none;
  `}
    ${media.sm`
	display: none;
  `}
	${media.md`
	display: none;
  `}
	${media.lg`
	display: flex;
  `}
`;

export const CustomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default BusinessServices;
