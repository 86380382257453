/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * VideoCallPage Component
 * Description: This component represents the page for handling video calls. It includes functionality for verifying users, managing video call dialogs, displaying video elements, and handling various screen sizes.
 *
 */

import { LoaderOverlay, useSnackbar } from '@components/common';
import Timer from '@components/common/Timer';
import CallDialog from '@components/video/CallDialog';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewScreen from '@components/video/documentShare';
import styled from 'styled-components';
import { Col, media, Row } from 'styled-bootstrap-grid';

function VideoCallPage() {
    const { t } = useTranslation('common');
    const [openSnakbar] = useSnackbar();
    const Router = useRouter();
    const accessToken = Router?.query?.token;
    const [appointmentId, setAppointmentId] = useState(Router?.query?.id);
    const [openCallDialog, setOpenCallDialog] = useState(true);
    const [openCustomerCallDialog, setCustomerOpenCallDialog] = useState(true);
    const [openPreviewScreen, setOpenPreviewScreen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [isServiceProviderVerified, setIsServiceProviderVerified] = useState(false);
    const [isCustomerVerified, setIsCustomerVerified] = useState(false);
    const [appointmentInfo, setAppointmentInfo] = useState();
    const [conflict, setConflict] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isMiniScreen, setIsMiniScreen] = useState(false);
    const [isCallStarted, setIsCallStarted] = useState(false);
    const [agoraOptions, setAgoraOptions] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
        if (window !== undefined) {
            window.onresize = handelResize();
        }
    }, [isFullScreen, isMiniScreen]);

    useEffect(() => {
        setAppointmentId(Router?.query?.id);
        if (accessToken) {
            verifyUser(Router?.query?.id);
        }
        if (Router?.query?.id) {
            getAppointmentData(Router?.query?.id);
        }
    }, [Router?.query?.id, Router?.query?.token]);

    useEffect(() => {
        window.addEventListener('beforeunload', e => {
            e.preventDefault();
            handleClose();
        });
    });

    useEffect(() => {
        if (openCallDialog === false) {
            handleClose();
        }
    }, [openCallDialog]);

    const handelResize = () => {
        if (!isFullScreen && !isMiniScreen) {
            window.resizeTo(448, 768);
        } else if (isMiniScreen) {
            window.resizeTo(332, 368);
        } else if (isFullScreen) {
            window.resizeTo(1440, 800);
        }
    };

    const verifyUser = appointment_id => {
        setIsFetching(true);
        api.post(`/auth/success`, { accessToken: accessToken })
            .then(res => {
                if (res?.data?.user_login === 'user') {
                    setRole('serviceProvider');
                    api.post('/video-call', {
                        appointment_id: appointment_id,
                    })
                        .then(response => {
                            if (response?.data) {
                                setAgoraOptions(response.data?.agora_credentials);
                                setIsServiceProviderVerified(true);
                                setIsFetching(false);
                            }
                            setIsFetching(false);
                        })
                        .catch(e => {
                            setIsFetching(false);
                            setConflict(true);
                            setRole('');
                            if (e?.response) {
                                openSnakbar(e?.response?.data?.message);
                            }
                        });
                } else if (res?.data?.user_login === 'customer') {
                    api.get(`/video-call/${appointment_id}`)
                        .then(response => {
                            if (response?.data === '') {
                                setIsCustomerVerified(false);
                            } else {
                                setAgoraOptions(response.data?.agora_credentials);
                                setIsCustomerVerified(true);
                            }
                            setIsFetching(false);
                        })
                        .catch(e => {
                            if (e.response) {
                                openSnakbar(e?.response?.data?.message);
                            }
                        });
                }
            })
            .catch(e => {
                if (e?.response) {
                    openSnakbar(e?.response?.data?.message);
                }

                return false;
            });
    };

    const handleClose = () => {
        if (appointmentId !== '' && appointmentId !== undefined) {
            api.put('/video-call', {
                appointment_id: appointmentId,
                is_active: false,
                role: role,
            })
                .then(response => {
                    setRole('');
                    if (response) {
                        setOpenCallDialog(false);
                        setTimeout(() => {
                            window.close();
                        }, 3000);
                    }
                })
                .catch(error => {
                    openSnakbar(t('Something went wrong!'));
                });
        }
    };

    const handleCloseCustomer = () => {
        if (appointmentId !== '' && appointmentId !== undefined) {
            api.put('/video-call', {
                appointment_id: appointmentId,
                is_active: false,
            })
                .then(response => {
                    setRole('');
                    if (response) {
                        setCustomerOpenCallDialog(false);
                        setTimeout(() => {
                            window.close();
                        }, 3000);
                    }
                })
                .catch(error => {
                    openSnakbar(t('Something went wrong!'));
                });
        }
    };

    const getAppointmentData = id => {
        api.get(`appointments/get/${id}`)
            .then(response => {
                setAppointmentInfo(response?.data);
            })
            .catch(e => {
                if (e?.response) {
                    openSnakbar(e?.response?.data?.message);
                }
            });
    };

    return (
        <>
            {conflict ? (
                <HeadingWrapper>
                    <LoaderOverlay />
                    <Heading>
                        {t('Another video appointment already active. Please wait...')}
                    </Heading>
                </HeadingWrapper>
            ) : isFetching ? (
                <HeadingWrapper>
                    <LoaderOverlay />
                    <Heading>{t('Fetching appointment details. Please wait...')}</Heading>
                </HeadingWrapper>
            ) : isServiceProviderVerified ? (
                <MainWrapper>
                    <CallDialog
                        openModal={openCallDialog}
                        onClose={() => handleClose()}
                        options={agoraOptions}
                        setIsCallStarted={setIsCallStarted}
                    />
                    <WrapperContent
                        col={openPreviewScreen === true && isFullScreen && 4}
                        className={`modal-content video-modal-content${
                            openPreviewScreen === false && isFullScreen
                                ? 'video-modal-dialog-full-screen'
                                : isMiniScreen
                                ? 'video-modal-dialog-small'
                                : 'video-modal-dialog-normal'
                        }`}>
                        <ContentText display={(isFullScreen || isMiniScreen) && true}>
                            <VideoCallText>Live video call</VideoCallText>
                        </ContentText>
                        <ContentBody col={12}>
                            <div className={`${!isMiniScreen && 'video-modal-body'}`}>
                                <CustomDiv
                                    col={12}
                                    className={`${!isMiniScreen && 'scrolling-area'}`}>
                                    <div className="video-container">
                                        <MiniVideoContainer
                                            display={isMiniScreen && true}
                                            className={`video-container-top`}>
                                            <MiniScreenContainer col={6}>
                                                {!isFullScreen ? (
                                                    <ControlsContainer
                                                        display={isMiniScreen && true}
                                                        col={
                                                            isFullScreen ? 1 : isMiniScreen ? 0 : 2
                                                        }
                                                        onClick={() => {
                                                            setIsFullScreen(true);
                                                            setIsMiniScreen(false);
                                                        }}>
                                                        <i
                                                            className="fal fa-expand"
                                                            style={{ fontSize: '1rem' }}></i>
                                                    </ControlsContainer>
                                                ) : (
                                                    <ControlsContainer
                                                        display={isMiniScreen && true}
                                                        col={
                                                            isFullScreen ? 1 : isMiniScreen ? 0 : 2
                                                        }
                                                        onClick={() => {
                                                            setIsFullScreen(false);
                                                            setIsMiniScreen(false);
                                                        }}>
                                                        <i className="fal fa-window-restore"></i>
                                                    </ControlsContainer>
                                                )}
                                                <ControlsContainer
                                                    display={isMiniScreen && true}
                                                    col={isFullScreen ? 1 : isMiniScreen ? 0 : 2}
                                                    onClick={() => {
                                                        setIsMiniScreen(true);
                                                        setIsFullScreen(false);
                                                        setOpenPreviewScreen(false);
                                                    }}>
                                                    <i
                                                        className="fal fa-external-link rotate-90"
                                                        style={{ fontSize: '1rem' }}></i>
                                                </ControlsContainer>
                                            </MiniScreenContainer>
                                            <TimerConainer col={5}>
                                                <TimerWrapper
                                                    col={
                                                        !openPreviewScreen && isFullScreen ? 12 : 6
                                                    }>
                                                    {isCallStarted && <Timer />}
                                                </TimerWrapper>
                                            </TimerConainer>
                                        </MiniVideoContainer>
                                        <MediaScreenRow
                                            className={`row m-0 ${
                                                openPreviewScreen == false && isFullScreen == true
                                                    ? 'media-container-large'
                                                    : isMiniScreen
                                                    ? 'media-container-small'
                                                    : 'media-container-medium'
                                            }`}
                                            id="mediaContainer"></MediaScreenRow>
                                        {isMiniScreen && (
                                            <RestoreScreen>
                                                <i
                                                    className="fal fa-external-link"
                                                    onClick={() => {
                                                        if (openPreviewScreen) {
                                                        }
                                                        setIsMiniScreen(false);
                                                    }}></i>
                                            </RestoreScreen>
                                        )}
                                        {!isCallStarted && (
                                            <CallStartedDiv
                                                display={isMiniScreen && true}
                                                className={`video-container-bottom`}>
                                                <PhoneIconWrapper
                                                    display={isMiniScreen && true}
                                                    className={`${
                                                        isFullScreen ? 'control-btn' : ''
                                                    } `}
                                                    onClick={() => {
                                                        setOpenCallDialog(false);
                                                    }}>
                                                    <i className="fal fa-phone rotate"></i>
                                                </PhoneIconWrapper>
                                            </CallStartedDiv>
                                        )}

                                        <div
                                            style={{
                                                display:
                                                    !openPreviewScreen &&
                                                    (isFullScreen || isMiniScreen) &&
                                                    'none',
                                            }}>
                                            <NameWrapper col={12}>
                                                <span>Customer Name</span>
                                                <span>
                                                    {appointmentInfo?.customer?.fullname !=
                                                    undefined
                                                        ? appointmentInfo?.customer?.fullname
                                                        : t('Customer name')}
                                                </span>
                                            </NameWrapper>

                                            <NameWrapper col={12}>
                                                <span>Duration</span>

                                                <span>
                                                    {appointmentInfo?.duration
                                                        ? appointmentInfo?.duration
                                                        : t('Appointment duration')}
                                                </span>
                                            </NameWrapper>
                                        </div>
                                    </div>
                                </CustomDiv>
                            </div>
                        </ContentBody>
                    </WrapperContent>

                    {openPreviewScreen && (
                        <PreviewScreen
                            onCloseCB={() => {
                                setOpenPreviewScreen(false);
                                setIsFullScreen(false);
                                setIsMiniScreen(false);
                            }}
                            onSubmitCB={() => {
                                setOpenPreviewScreen(false);
                                setIsFullScreen(false);
                                setIsMiniScreen(false);
                            }}
                            data={uploadedFile}
                        />
                    )}
                </MainWrapper>
            ) : isCustomerVerified ? (
                <>
                    <MediaScreenRow
                        className={`row m-0 ${
                            openPreviewScreen === false && isFullScreen === true
                                ? 'media-container-large'
                                : isMiniScreen
                                ? 'media-container-small'
                                : 'media-container-medium'
                        }`}
                        id="mediaContainer"></MediaScreenRow>
                    <CallDialog
                        openModal={openCustomerCallDialog}
                        onClose={() => handleCloseCustomer()}
                        options={agoraOptions}
                        setIsCallStarted={setIsCallStarted}
                    />
                </>
            ) : (
                <HeadingWrapper>
                    <i className="fal fa-warning" />
                    <LoaderOverlay />
                    <Heading style={{ color: 'red' }}>
                        {t(
                            'ServiceProvider did not start the video call. Please wait until your ServiceProvider joins the call.'
                        )}
                    </Heading>
                </HeadingWrapper>
            )}
        </>
    );
}

const HeadingWrapper = styled.div`
    margin: 3rem !important;
`;

const Heading = styled.h4`
    color: #007bff;
    text-align: center !important;
    line-height: 1 !important;
`;

const MainWrapper = styled(Row)``;

const WrapperContent = styled(Col)`
    background-color: transparent !important;
    border: none;
`;

const ContentText = styled(Row)`
    padding: 0.5rem !important;
    width: 100%;
    display: ${({ display }) => (display ? 'none' : 'block')};
`;

const VideoCallText = styled.p`
    text-align: center;
`;

const ContentBody = styled(Col)`
    ${media.xs`
    display: block;
  `}
    ${media.sm`
    display: block;
  `}
    ${media.md`
	display: grid;
  `}
    ${media.lg`
    display: flex;
  `}
`;

const CustomDiv = styled(Col)`
    padding: 1rem;
    padding-top: 0 !important;
`;

const MiniVideoContainer = styled(Col)`
    display: flex;
    justify-content: space-between;
    display: ${({ display }) => (display ? 'none' : 'flex')};
`;

const MiniScreenContainer = styled(Col)`
    display: flex;
    margin: 0.5rem !important;
    margin-top: 1rem !important;
`;

const ControlsContainer = styled(Col)`
    display: flex;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    border-radius: 50% !important;
    background-color: white;
    cursor: pointer;
    display: ${({ display }) => (display ? 'none' : 'flex')};
`;

const TimerConainer = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
`;

const TimerWrapper = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border-radius: 50rem !important;
`;

const MediaScreenRow = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin: 0px;
    padding: 0px;
`;

const RestoreScreen = styled.div`
    display: flex;
    justify-content: center;
    i {
        font-size: 1.5rem !important;
        cursor: pointer;
    }
`;

const CallStartedDiv = styled.div`
    display: flex;
    justify-content: center;
    display: ${({ display }) => (display ? 'none' : 'flex')};
`;

const PhoneIconWrapper = styled(Col)`
    display: flex;
    justify-content: center;
    background-color: red;
    color: white;
    border-radius: 50rem !important;
    cursor: pointer;
    display: ${({ display }) => (display ? 'none' : 'flex')};
    i {
        font-size: 1.25rem !important;
        cursor: pointer;
    }
`;

const NameWrapper = styled(Col)`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6 !important;
    padding: 0.5rem !important;
`;

export default VideoCallPage;
