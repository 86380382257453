/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Input Component
 *
 * Description:
 * - Customizable input field with support for different input types and validation.
 * - Provides options for handling various input scenarios, including numeric input, phone numbers, addresses, etc.
 * - Supports label animation for a better user experience.
 * - Ability to toggle password visibility for password input.
 * - Utilizes styled-components for styling.
 *
 *  Props:
 * - name?: string - The name attribute of the input.
 * - type?: string - The type of the input (e.g., "text", "number", "password").
 * - id?: string - The id attribute of the input.
 * - value?: string | number - The value of the input.
 * - label?: string - The label for the input.
 * - className?: string - Additional CSS class for styling.
 * - readOnly?: boolean - Indicates whether the input is read-only.
 * - disabled?: boolean - Indicates whether the input is disabled.
 * - min?: any - The minimum value for number inputs.
 * - autoComplete?: string - The autocomplete attribute for the input.
 * - error?: { message: string } - An object containing an error message.
 * - maxLength?: any - The maximum length of the input value.
 * - allowNumber?: boolean - Allows only numeric input.
 * - slotLeft?: React.ReactNode - Content to be displayed to the left of the input.
 * - onBlur?(): void - Callback function triggered when the input loses focus.
 * - onFocus?(): void - Callback function triggered when the input gains focus.
 * - onChange?: any - Callback function triggered when the input value changes.
 * - required?: any - Indicates whether the input is required.
 * - step?: any - The step to increment or decrement the input value for number inputs.
 * - pattern?: any - A regular expression pattern for validating the input value.
 * - onInput?: any - Callback function triggered when there is input in the field.
 * - allowPhoneNumberOnly?: boolean - Allows only phone number input.
 * - inputMode?: any - The inputmode attribute for controlling on-screen keyboards.
 * - allowText?: any - Allows only text input.
 * - allowAddess?: any - Allows only address input.
 * - allowOnlyNumbers?: any - Allows only numeric input.
 * - allowOnlyNumbersAndDecimal?: boolean - Allows only numeric input with decimals.
 *
 * Usage:
 * - Import this component and use it where input field is needed.
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface InputProps {
    name?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: string;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    min?: any;
    autoComplete?: string;
    error?: {
        message: string;
    };
    maxLength?: any;
    allowNumber?: boolean;
    slotLeft?: React.ReactNode;
    onBlur?(): void;
    onFocus?(): void;
    onChange?: any;
    required?: any;
    step?: any;
    pattern?: any;
    onInput?: any;
    allowPhoneNumberOnly?: boolean;
    inputMode?: any;
    allowText?: any;
    allowAddess?: any;
    allowOnlyNumbers?: any;
    allowOnlyNumbersAndDecimal?: boolean;
}

const Input = ({
    name,
    type,
    id,
    value,
    label,
    className,
    readOnly,
    disabled,
    autoComplete,
    error,
    slotLeft,
    maxLength,
    allowNumber,
    onBlur,
    onFocus,
    onChange,
    min,
    step,
    required,
    pattern,
    onInput,
    allowPhoneNumberOnly,
    inputMode,
    allowText,
    allowAddess,
    allowOnlyNumbers,
    allowOnlyNumbersAndDecimal,
    ...props
}: InputProps) => {
    const { t }: any = useTranslation();
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value ?? '');
    const inputRef = useRef<HTMLInputElement>(null);
    const [keyValue, setKeyValue] = useState<any>('');

    useEffect(() => {
        if (inputRef.current && inputRef.current.value?.length > 0) setActive(true);
    }, []);

    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    useEffect(() => {
        if (inputValue) setActive(true);
    }, [inputValue]);

    const focusInput = () => {
        setActive(true);
        setFocused(true);
        if (inputRef.current) inputRef.current.focus();
    };

    const handleFocus = () => {
        if (onFocus) onFocus();
    };

    const unfocusInput = () => {
        setFocused(false);
        if (inputRef.current && inputRef.current.value?.length === 0) setActive(false);
        if (onBlur) onBlur();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (allowNumber) {
            const re = /^[0-9 ]+/;
            let val = event.target.value.replace(' ', '');
            if (re.test(val) || val?.length == 0) {
                setInputValue(event.target.value.replace(/[\s]/g, ''));
                if (onChange)
                    onChange({
                        value: event.target.value.replace(/[\s]/g, ''),
                        name: event.target.name,
                    });
            }
        } else if (allowPhoneNumberOnly) {
            const re = /^[0-9 -]+$/;
            let val = event.target.value.replace(' ', '');
            if (re.test(val) || val?.length == 0) {
                setInputValue(event.target.value.replace(/[\s]/g, ''));
                if (onChange)
                    onChange({
                        value: event.target.value.replace(/[\s]/g, ''),
                        name: event.target.name,
                    });
            }
        } else if (allowAddess) {
            const re = /^[#.0-9a-zA-Z\s,-]+$/;
            let val = event.target.value;
            if (re.test(val) || val?.length == 0) {
                setInputValue(event.target.value);
                if (onChange)
                    onChange({
                        value: event.target.value,
                        name: event.target.name,
                    });
            }
        } else if (allowText) {
            const re = /^[a-z]+$/;
            let val = event.target.value;
            if (re.test(val) || val?.length == 0) {
                setInputValue(event.target.value);
                if (onChange)
                    onChange({
                        value: event.target.value,
                        name: event.target.name,
                    });
            }
        } else if (allowOnlyNumbers) {
            const re = /^\d+$/;
            let val = event.target.value.replace(' ', '');
            if (keyValue == 'Backspace' || keyValue == 'Delete') {
                onChange({
                    value: event.target.value,
                    name: event.target.name,
                });
            } else {
                if (re.test(val) || val?.length == 0) {
                    setInputValue(event.target.value.replace(/[\s]/g, ''));
                    if (onChange)
                        onChange({
                            value: event.target.value.replace(/[\s]/g, ''),
                            name: event.target.name,
                        });
                }
            }
        } else if (allowOnlyNumbersAndDecimal) {
            const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;
            let val = event.target.value.replace(' ', '');
            if (keyValue == 'Backspace' || keyValue == 'Delete') {
                onChange({
                    value: event.target.value,
                    name: event.target.name,
                });
            } else {
                if (re.test(val) || val?.length == 0) {
                    setInputValue(event.target.value.replace(/[\s]/g, ''));
                    console.log('enter', event.target.value.replace(/[\s]/g, ''));
                    if (onChange)
                        onChange({
                            value: event.target.value.replace(/[\s]/g, ''),
                            name: event.target.name,
                        });
                }
            }
        } else {
            setInputValue(event.target.value);
            if (onChange) onChange({ value: event.target.value, name: event.target.name });
        }
    };
    const onKeyDown = (e: any) => {
        e.preventDefault();
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <InputContainer onClick={() => focusInput()}>
            <Wrapper error={error}>
                <div>
                    <InputField
                        ref={inputRef}
                        placeholder={!isActive ? label : ''}
                        id={name}
                        name={name}
                        min={min}
                        type={
                            type === t('Password') && showPassword
                                ? 'text'
                                : (type ? type : 'text') ||
                                  (type ? type : 'date') ||
                                  (type ? type : 'email')
                        }
                        onChange={event => handleChange(event)}
                        value={inputValue}
                        className={className}
                        disabled={disabled}
                        readOnly={readOnly}
                        autoComplete={autoComplete ? autoComplete : 'off'}
                        onBlur={() => unfocusInput()}
                        onFocus={handleFocus}
                        maxLength={maxLength}
                        required={required}
                        step={step}
                        pattern={pattern}
                        onInput={onInput}
                        inputMode={inputMode}
                        onKeyDown={e => {
                            if (type == 'date') {
                                type == 'date' && onKeyDown(e);
                            } else {
                                setKeyValue(e.key);
                            }
                        }}
                    />

                    {type === t('Password') && (
                        <TogglePasswordVisibility
                            onClick={togglePasswordVisibility}
                            showPassword={showPassword}>
                            {showPassword ? 'Hide' : 'Show'}
                        </TogglePasswordVisibility>
                    )}

                    <LabelWrapper value={inputValue} error={error}>
                        <label>{label}</label>
                    </LabelWrapper>
                </div>
            </Wrapper>
            {error && <Message>{error.message}</Message>}
        </InputContainer>
    );
};

const TogglePasswordVisibility = styled.span<any>`
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    color: #007bff;

    &:hover {
        text-decoration: underline;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    position: relative;
    display: block;
`;

const Wrapper = styled.div<InputProps>`
    padding: 0.75rem 1.15rem;
    align-items: stretch;
    border-radius: 0.325rem;
    border-style: solid !important;
    border-width: 0.5px !important;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out;
    border-color: ${({ error }) => (error ? `#eb5353` : 'rgba(223, 226, 230)')};
`;

const LabelWrapper = styled.div<InputProps>`
    position: absolute;
    max-width: calc(100% - 24px);
    top: -1px;
    label {
        color: ${({ error }) => (error ? `#eb5353` : '#8C8B88')};
        font-size: 10px;
        display: block;
        padding: 0 6px;
        margin-left: -6px;
        font-weight: 300;
        text-transform: capitalize;
        line-height: 1;
        opacity: ${({ value }) => (value ? `1` : '0')};
        transform: ${({ value }) => (value ? `translateY(-50%)` : 'translateY(50%)')};
        transition: ${({ value }) =>
            value
                ? `transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;`
                : 'all 0.15s ease-in-out'};
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: white;
        &::before,
        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 50%;
            z-index: -1;
            left: 0;
        }
        &::before {
            top: 50%;
        }
        &::after {
            top: 0;
            background-color: transparent;
        }
    }
`;

const InputField = styled.input`
    display: block;
    background-color: transparent;
    width: 100%;
    border: none;
    min-width: 0;
    color: #263446;
    line-height: 24px;
    &::placeholder {
        font-weight: 300;
        color: #8c8b88;
    }
    &:focus {
        outline: 0;
    }
    &:focus + ${LabelWrapper} {
        label {
            transform: translateY(-50%) !important;
            opacity: 1 !important;
            transition: color 0.15s ease-in-out, transform 0.15s ease-in-out,
                opacity 0.15s ease-in-out !important;
        }
    }
`;

const Message = styled.div`
    color: #fff;
    background-color: #eb5353;
    border: 1px solid #eb5353;
    border-radius: 0px 0px 0.325rem 0.325rem;
    padding: 2px 10px;
    margin-top: -3px;
    font-size: 0.75rem;
    font-weight: 300;
`;

export default Input;
