/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Tabs Component
 * Description: A tabbed interface component that manages a set of tabs and their interactions.
 * Purpose:
 * - Dynamic Tabs: Supports dynamic creation of tabs based on the provided children.
 * - Tab Selection: Allows selecting a specific tab programmatically based on the provided 'value'.
 * - Indicator: Displays an indicator for the active tab.
 * - Theme Support: Provides optional theming for styling, including 'default' and 'bordered'.
 * - onChange Callback: Triggers a callback when a tab is changed.
 *
 * Props:
 * - children: React.ReactNode - The child components representing the tabs.
 * - theme?: 'default' | 'bordered' - Optional theme for styling the tabs (default is 'default').
 * - onChange?(event: React.SyntheticEvent, value: any): void - Callback function triggered when the tab is changed/selected.
 */

import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useTabContext } from '../tabContext';
import { useTranslation } from 'react-i18next';

export interface TabsProps {
    children?: React.ReactNode;
    theme?: 'default' | 'bordered';
    onChange?(event: React.SyntheticEvent, value: any): void;
}

const Tabs = ({ children, theme, onChange }: TabsProps) => {
    const { t } = useTranslation();
    const valueToIndex = new Map();
    const tabsRef = useRef<HTMLDivElement>(null);
    const tabListRef = useRef<HTMLUListElement>(null);

    const [indicatorStyle, setIndicatorStyle] = useState({});

    const themeClass = theme ? `tabs--${theme}` : 'tabs--default';
    const context = useTabContext();

    const updateIndicatorState = useCallback(() => {
        const { tabsMeta, activeTabMeta } = getTabsMeta();
        let startValue = 0;

        if (activeTabMeta && tabsMeta) {
            const correction = tabsMeta.scrollLeft;
            startValue = activeTabMeta.left - tabsMeta.left + correction;
        }

        const newIndicatorStyle = {
            left: startValue,
            width: activeTabMeta ? activeTabMeta.width : 0,
        };

        setIndicatorStyle(newIndicatorStyle);
    }, [context.value]);

    useEffect(() => {
        updateIndicatorState();
    }, [updateIndicatorState]);

    if (context === null) {
        throw new TypeError(t('No TabContext provided') || 'No TabContext provided');
    }

    const getTabsMeta = () => {
        const tabsNode = tabsRef.current;

        let tabsMeta;
        if (tabsNode) {
            const rect = tabsNode.getBoundingClientRect();
            tabsMeta = {
                clientWidth: tabsNode.clientWidth,
                scrollLeft: tabsNode.scrollLeft,
                scrollTop: tabsNode.scrollTop,
                scrollWidth: tabsNode.scrollWidth,
                top: rect.top,
                bottom: rect.bottom,
                left: rect.left,
                right: rect.right,
            };
        }

        let activeTabMeta;
        if (tabsNode && context.value !== false) {
            const children = tabListRef.current?.children;
            if (children && children?.length > 0) {
                const tab = children[valueToIndex.get(context.value)];
                activeTabMeta = tab ? tab.getBoundingClientRect() : null;
            }
        }
        return { tabsMeta, activeTabMeta };
    };

    let childIndex = 0;
    const childrenProps = React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return null;
        }

        const childValue = child.props.value ?? childIndex;
        valueToIndex.set(childValue, childIndex);
        let selected: any = childValue === context.value;

        childIndex += 1;

        return React.cloneElement<any>(child, {
            selected,
            value: childValue,
            theme: theme,
            onChange,
            ...(childIndex === 1 && context.value === false && !child.props.tabIndex
                ? { tabIndex: 0 }
                : {}),
        });
    });

    return (
        <div className="tabs--container" ref={tabsRef}>
            <div className={`tabs ${themeClass}`}>
                <ul className="tabs--list" ref={tabListRef}>
                    {childrenProps}
                </ul>
                <div
                    className={`tabs--indicator ${themeClass}`}
                    style={{
                        ...indicatorStyle,
                    }}></div>
            </div>
        </div>
    );
};

export default Tabs;
