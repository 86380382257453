/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Root Reducer
 * Description: This root reducer combines individual reducers to manage the overall state of the application.
 *
 * Reducers:
 * - auth: Manages authentication-related state.
 * - user: Manages user-related state.
 * - business: Manages business-related state.
 * - service: Manages service-related state.
 * - calendarCurrentView: Manages the current view of the calendar.
 * - appointment: Manages appointment-related state.
 */

import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { user } from './user.reducer';
import { business } from './business.reducer';
import { calenderCurrentView } from './calender.reducer';
import { service } from './service.reducer';
import { appointment } from './appointment.reducer';
const rootReducer = combineReducers({
    auth,
    user,
    business,
    service,
    calenderCurrentView,
    appointment,
});

export default rootReducer;
