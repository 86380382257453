/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerNotes Component
 * Description: This component allows editing and saving of notes for a customer profile.
 *
 * Props:
 * - customer: ICustomer - The customer data for whom the notes are displayed and edited.
 */

import { useEffect, useState } from 'react';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import { ICustomer } from 'interfaces/customer.interface';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';

const CustomerNotes = ({ customer }: { customer: ICustomer }) => {
    const { t }: any = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [note, setNote] = useState(customer.secret_note);
    const [openSnakebar] = useSnackbar();

    useEffect(() => {
        setNote(customer.secret_note);
    }, [customer]);

    const onSubmit = () => {
        setLoading(true);
        api.put(`/customers/${customer._id}`, {
            secret_note: note,
        }).then(res => {
            openSnakebar(t('Notes saved successfully!'));
            setLoading(false);
            setShowActions(false);
        });
    };

    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <Row style={{ padding: '0' }}>
                    <CustomCol lg={12}>
                        <Input
                            label={t('Customer Profile Notes')}
                            value={note}
                            onChange={({ value }: { value: string }) => {
                                setShowActions(true);
                                setNote(value);
                            }}
                        />
                    </CustomCol>
                </Row>
                {showActions && (
                    <ButtonsModel>
                        <Button
                            ifClicked={onSubmit}
                            bgtype="secondary"
                            label={t('Update')}></Button>
                    </ButtonsModel>
                )}
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const CustomCol = styled(Col)`
    position: relative;
    display: block;
`;

const ButtonsModel = styled.div`
    padding-top: 1.5rem !important;
    border-top-width: 1px !important;
    margin-top: 1.25rem !important;
    display: flex;
    justify-content: flex-end;
`;

export default CustomerNotes;
