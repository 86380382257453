/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: AsideList
 * Description: This component represents the sidebar/top menu navigation list, including links, icons, and dropdowns based on user roles and permissions.
 *
 * Dependencies:
 * - React
 * - react-router-dom
 * - styled-components
 * - hooks/router
 * - moment-timezone
 * - styled-bootstrap-grid (media)
 * - react-i18next
 *
 * Interfaces:
 * - RouteLink
 * - AsideProps
 *
 * Usage:
 * - Import this component and use it to render the navigation list in the sidebar or top menu.
 * - Pass an array of routes to determine the links, titles, and icons.
 * - Optionally, provide a callback function to handle opening the sidebar or menu on mobile.
 */

import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useRouter from 'hooks/router';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { media } from 'styled-bootstrap-grid';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

interface RouteLink {
    path: string;
    title: string;
    icon: string;
    patterns?: RouteLink[];
}

interface AsideProps {
    routes?: RouteLink[];
    openAsidNew?: any;
}

const AsideList: FC<AsideProps> = ({ routes, openAsidNew }) => {
    const router = useRouter();
    const { t } = useTranslation();
    let _user = JSON.parse(localStorage.getItem('user') || '{}');
    const [teamCheck, setTeamCheck] = useState<any>();
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [settingSidebar, setSettingSidebar] = useState(false);
    let permission = _user?.user?.permissions;

    useEffect(() => {
        if (_user?.user?.business_id) {
            moment.tz.setDefault(_user?.user?.business_id?.timezone);
        }
    }, []);

    const settingDropDown = [
        {
            path: '/settings/details',
            activeLink: '/settings',
            title: 'Business Settings',
            icon: 'far fa-cogs',
        },
        {
            path: '/settings/business-hours',
            activeLink: '/settings',
            title: 'Business Hours',
            icon: 'far fa-cogs',
        },
        {
            path: '/settings/online-booking',
            activeLink: '/settings',
            title: 'Online Booking',
            icon: 'far fa-cogs',
        },
        {
            path: '/settings/booking-settings',
            activeLink: '/settings',
            title: 'Calendar Settings',
            icon: 'far fa-cogs',
        },
        {
            path: '/settings/promotions',
            activeLink: '/settings',
            title: 'Promotion, discount and gift vouchers',
            icon: 'far fa-cogs',
        },
    ];

    return (
        <>
            {_user?.user?.role === 'ADMIN'
                ? ''
                : routes?.map((link: RouteLink, i: number) => {
                      var pathName = router.pathname.split('/');
                      const isActive =
                          (link.patterns &&
                              link.patterns.find(
                                  linkPattern => router.pathname === linkPattern.path
                              )) ||
                          '/' + pathName[1] === link.path;
                      return (
                          <div key={i}>
                              {(permission?.dashboard === true && link.title === 'Dashboard') ||
                              (_user?.user_login === 'customer'
                                  ? link.title === 'Calendar'
                                  : permission?.calendar === true && link.title === 'Calendar') ||
                              (permission?.team === true && link.title === 'Team') ||
                              (_user?.user_login === 'customer'
                                  ? link.title === 'Customers'
                                  : permission?.customers === true && link.title === 'Customers') ||
                              (permission?.sales === true && link.title === 'Sales') ||
                              (permission?.business === true &&
                                  link.title === 'Business Settings') ||
                              link.title === 'Services' ? (
                                  <Link
                                      to={
                                          link.title === 'Team' &&
                                          _user?.user?.business_id?.leftMenu === false &&
                                          _user?.user_login == 'user'
                                              ? link.title === 'Team' &&
                                                _user?.user?.business_id?.leftMenu === false &&
                                                _user?.user_login == 'serviceProvider'
                                                  ? '/team'
                                                  : ''
                                              : link.title === 'Business Settings' &&
                                                _user?.user?.business_id?.leftMenu === false
                                              ? ''
                                              : link.title === 'Customers' &&
                                                _user?.user?.business_id?.leftMenu === false
                                              ? ''
                                              : link.title === 'Calendar' &&
                                                _user?.user?.business_id?.customCalendar == true
                                              ? '/customise-calendar'
                                              : link.path
                                      }>
                                      {link.title === 'Customers' &&
                                      _user?.user?.business_id?.leftMenu === false ? (
                                          <div
                                              className="dropdown-nav"
                                              onClick={() => {
                                                  if (
                                                      window.innerWidth <= 995 &&
                                                      _user?.user?.business_id?.leftMenu === false
                                                  ) {
                                                      setSettingSidebar(false);
                                                      setMobileSidebar(!mobileSidebar);
                                                      setTeamCheck(link.title);
                                                  }
                                              }}>
                                              <Routes
                                                  padding={
                                                      _user?.user?.business_id?.leftMenu ===
                                                          false && true
                                                  }
                                                  style={{
                                                      background:
                                                          '/' + pathName[1] ===
                                                              '/customer-reports' ||
                                                          '/' + pathName[1] === '/customers'
                                                              ? palette.secondary
                                                              : '',
                                                  }}
                                                  className={`${
                                                      '/' + pathName[1] === '/customer-reports'
                                                          ? 'activeTab'
                                                          : '/' + pathName[1] === '/customers'
                                                          ? `activeTab`
                                                          : 'activeNavBar'
                                                  }`}>
                                                  <ListIcon
                                                      className={`${
                                                          _user?.user_login !== 'user' &&
                                                          link.icon === 'far fa-cut'
                                                              ? 'far fa-user-circle'
                                                              : link.icon
                                                      } ${
                                                          isActive
                                                              ? `activeIcon`
                                                              : 'activeIconColor'
                                                      } `}></ListIcon>
                                                  {link.title}
                                              </Routes>
                                              {mobileSidebar === true &&
                                                  teamCheck === 'Customers' && (
                                                      <CustomDiv>
                                                          <CustomRoutes
                                                              to={'/customers'}
                                                              style={{
                                                                  background:
                                                                      '/' + pathName[1] ===
                                                                      '/customers'
                                                                          ? palette.secondary
                                                                          : '',
                                                              }}
                                                              className={
                                                                  '/' + pathName[1] === '/customers'
                                                                      ? `activeLinkCutom`
                                                                      : ''
                                                              }
                                                              onClick={openAsidNew}>
                                                              {' '}
                                                              {t('Customers')}
                                                          </CustomRoutes>
                                                          <CustomRoutes
                                                              to={'/customer-reports'}
                                                              style={{
                                                                  background:
                                                                      '/' + pathName[1] ===
                                                                      '/customer-reports'
                                                                          ? palette.secondary
                                                                          : '',
                                                              }}
                                                              className={
                                                                  '/' + pathName[1] ===
                                                                  '/customer-reports'
                                                                      ? `activeLinkCutom`
                                                                      : ''
                                                              }
                                                              onClick={openAsidNew}>
                                                              {' '}
                                                              {t('Customer Reports')}
                                                          </CustomRoutes>
                                                      </CustomDiv>
                                                  )}

                                              <div className="dropdown-content-nav">
                                                  <Link
                                                      to={'/customers'}
                                                      style={{
                                                          textDecoration: 'none',
                                                          background:
                                                              '/' + pathName[1] === '/customers'
                                                                  ? palette.secondary
                                                                  : '',
                                                      }}
                                                      className={
                                                          '/' + pathName[1] === '/customers'
                                                              ? `activeDropDown`
                                                              : ''
                                                      }>
                                                      {t('Customers')}
                                                  </Link>
                                                  <Link
                                                      style={{
                                                          textDecoration: 'none',
                                                          background:
                                                              '/' + pathName[1] ===
                                                              '/customer-reports'
                                                                  ? palette.secondary
                                                                  : '',
                                                      }}
                                                      to={'/customer-reports'}
                                                      className={
                                                          '/' + pathName[1] === '/customer-reports'
                                                              ? `activeDropDown`
                                                              : ''
                                                      }>
                                                      {t('Customer Reports')}
                                                  </Link>
                                              </div>
                                          </div>
                                      ) : link.title === 'Team' &&
                                        _user?.user?.business_id?.leftMenu === false &&
                                        _user?.user_login == 'user' ? (
                                          <div
                                              className="dropdown-nav"
                                              onClick={() => {
                                                  if (
                                                      window.innerWidth <= 995 &&
                                                      _user?.user?.business_id?.leftMenu === false
                                                  ) {
                                                      setSettingSidebar(false);
                                                      setMobileSidebar(!mobileSidebar);
                                                      setTeamCheck(link.title);
                                                  }
                                              }}>
                                              <Routes
                                                  padding={
                                                      _user?.user?.business_id?.leftMenu ===
                                                          false && true
                                                  }
                                                  className={`${
                                                      teamCheck === 'Team'
                                                          ? 'activeTab'
                                                          : isActive
                                                          ? `activeTab`
                                                          : 'activeNavBar'
                                                  }`}>
                                                  <ListIcon
                                                      className={`${
                                                          _user?.user_login !== 'user' &&
                                                          link.icon === 'far fa-cut'
                                                              ? 'far fa-user-circle'
                                                              : link.icon
                                                      } ${
                                                          isActive
                                                              ? `activeIcon`
                                                              : 'activeIconColor'
                                                      } `}></ListIcon>
                                                  {link.title}
                                              </Routes>
                                              {mobileSidebar === true && teamCheck === 'Team' && (
                                                  <CustomDiv>
                                                      <CustomRoutes
                                                          to={'/team/service-provider-list'}
                                                          style={{
                                                              textDecoration: 'none',
                                                              background:
                                                                  router.pathname ===
                                                                      '/team/service-provider/create' ||
                                                                  '/' + pathName[2] ===
                                                                      '/service-provider-list'
                                                                      ? palette.secondary
                                                                      : '',
                                                          }}
                                                          className={
                                                              '/' + pathName[2] ===
                                                              '/service-provider-list'
                                                                  ? `activeLinkCutom`
                                                                  : router.pathname ===
                                                                    '/team/service-provider/create'
                                                                  ? `activeLinkCutom`
                                                                  : ''
                                                          }
                                                          onClick={openAsidNew}>
                                                          {' '}
                                                          {t('Service Providers')}
                                                      </CustomRoutes>
                                                      <CustomRoutes
                                                          to={'/team/team-list'}
                                                          style={{
                                                              textDecoration: 'none',
                                                              background:
                                                                  '/' + pathName[2] ===
                                                                      '/team-list' ||
                                                                  router.pathname ===
                                                                      '/team/team-member/create'
                                                                      ? palette.secondary
                                                                      : '',
                                                          }}
                                                          className={
                                                              '/' + pathName[2] === '/team-list'
                                                                  ? `activeLinkCutom`
                                                                  : router.pathname ===
                                                                    '/team/team-member/create'
                                                                  ? `activeLinkCutom`
                                                                  : ''
                                                          }
                                                          onClick={openAsidNew}>
                                                          {' '}
                                                          {t('Team Members')}
                                                      </CustomRoutes>
                                                      <CustomRoutes
                                                          to={'/team/other-list'}
                                                          style={{
                                                              textDecoration: 'none',
                                                              background:
                                                                  '/' + pathName[2] ===
                                                                      '/other-list' ||
                                                                  router.pathname ===
                                                                      '/team/other/create'
                                                                      ? palette.secondary
                                                                      : '',
                                                          }}
                                                          className={
                                                              '/' + pathName[2] === '/other-list'
                                                                  ? `activeLinkCutom`
                                                                  : router.pathname ===
                                                                    '/team/other/create'
                                                                  ? `activeLinkCutom`
                                                                  : ''
                                                          }
                                                          onClick={openAsidNew}>
                                                          {' '}
                                                          {t('Others')}
                                                      </CustomRoutes>
                                                  </CustomDiv>
                                              )}

                                              <div className="dropdown-content-nav">
                                                  <Link
                                                      to={'/team/service-provider-list'}
                                                      style={{
                                                          textDecoration: 'none',
                                                          background:
                                                              '/' + pathName[2] ===
                                                                  '/service-provider-list' ||
                                                              router.pathname ===
                                                                  '/team/service-provider/create'
                                                                  ? palette.secondary
                                                                  : '',
                                                      }}
                                                      className={
                                                          '/' + pathName[2] ===
                                                          '/service-provider-list'
                                                              ? `activeDropDown`
                                                              : router.pathname ===
                                                                '/team/service-provider/create'
                                                              ? `activeDropDown`
                                                              : ''
                                                      }>
                                                      {t('Service Providers')}
                                                  </Link>
                                                  <Link
                                                      style={{
                                                          textDecoration: 'none',
                                                          background:
                                                              '/' + pathName[2] === '/team-list' ||
                                                              router.pathname ===
                                                                  '/team/team-member/create'
                                                                  ? palette.secondary
                                                                  : '',
                                                      }}
                                                      to={'/team/team-list'}
                                                      className={
                                                          '/' + pathName[2] === '/team-list'
                                                              ? `activeDropDown`
                                                              : router.pathname ===
                                                                '/team/team-member/create'
                                                              ? `activeDropDown`
                                                              : ''
                                                      }>
                                                      {t('Team Members')}
                                                  </Link>
                                                  <Link
                                                      style={{
                                                          textDecoration: 'none',
                                                          background:
                                                              '/' + pathName[2] === '/other-list' ||
                                                              router.pathname ===
                                                                  '/team/other/create'
                                                                  ? palette.secondary
                                                                  : '',
                                                      }}
                                                      to={'/team/other-list'}
                                                      className={
                                                          '/' + pathName[2] === '/other-list'
                                                              ? `activeDropDown`
                                                              : router.pathname ===
                                                                '/team/other/create'
                                                              ? `activeDropDown`
                                                              : ''
                                                      }>
                                                      {t('Others')}
                                                  </Link>
                                              </div>
                                          </div>
                                      ) : link.title === 'Business Settings' &&
                                        _user?.user?.business_id?.leftMenu === false ? (
                                          <div
                                              className="dropdown-nav"
                                              onClick={() => {
                                                  if (
                                                      window.innerWidth <= 995 &&
                                                      _user?.user?.business_id?.leftMenu === false
                                                  ) {
                                                      setMobileSidebar(false);
                                                      setSettingSidebar(!settingSidebar);
                                                      setTeamCheck(link.title);
                                                  }
                                              }}>
                                              <Routes
                                                  padding={
                                                      _user?.user?.business_id?.leftMenu ===
                                                          false && true
                                                  }
                                                  className={`${
                                                      isActive ? `activeTab` : 'activeNavBar'
                                                  }`}>
                                                  <ListIcon
                                                      className={`${
                                                          _user?.user_login !== 'user' &&
                                                          link.icon === 'far fa-cut'
                                                              ? 'far fa-user-circle'
                                                              : link.icon
                                                      } ${
                                                          isActive
                                                              ? `activeIcon`
                                                              : 'activeIconColor'
                                                      } `}></ListIcon>
                                                  {link.title}
                                              </Routes>
                                              {settingSidebar === true &&
                                                  teamCheck === 'Business Settings' && (
                                                      <CustomDiv>
                                                          {settingDropDown.map(e => {
                                                              return (
                                                                  <CustomRoutes
                                                                      to={e.path}
                                                                      className={
                                                                          router.pathname === e.path
                                                                              ? `activeLinkCutom`
                                                                              : ''
                                                                      }
                                                                      onClick={openAsidNew}>
                                                                      {' '}
                                                                      {t(e.title)}
                                                                  </CustomRoutes>
                                                              );
                                                          })}
                                                      </CustomDiv>
                                                  )}
                                              <div className="dropdown-content-nav">
                                                  {settingDropDown.map(e => {
                                                      return (
                                                          <Link
                                                              style={{
                                                                  textDecoration: 'none',
                                                                  background:
                                                                      router.pathname === e.path
                                                                          ? palette.secondary
                                                                          : '',
                                                              }}
                                                              to={e.path}
                                                              className={
                                                                  router.pathname === e.path
                                                                      ? `activeDropDown`
                                                                      : ''
                                                              }>
                                                              {t(e.title)}
                                                          </Link>
                                                      );
                                                  })}
                                              </div>
                                          </div>
                                      ) : (
                                          <Routes
                                              padding={
                                                  _user?.user?.business_id?.leftMenu === false &&
                                                  true
                                              }
                                              className={`${
                                                  link.title === 'Calendar' &&
                                                  '/' + pathName[1] === '/customise-calendar'
                                                      ? 'activeTab'
                                                      : teamCheck === undefined && isActive
                                                      ? `activeTab`
                                                      : 'activeNavBar'
                                              }`}
                                              onClick={openAsidNew}>
                                              <ListIcon
                                                  className={`${
                                                      _user?.user_login !== 'user' &&
                                                      link.icon === 'far fa-cut'
                                                          ? 'far fa-user-circle'
                                                          : link.icon
                                                  } ${
                                                      isActive ? `activeIcon` : 'activeIconColor'
                                                  } `}></ListIcon>
                                              {(_user?.user_login !== 'user' &&
                                                  link.title === 'Team') ||
                                              (_user?.user_login === 'customer' &&
                                                  link.title === 'Customers')
                                                  ? 'Profile'
                                                  : t(link.title)}
                                          </Routes>
                                      )}
                                  </Link>
                              ) : (
                                  ''
                              )}
                          </div>
                      );
                  })}
        </>
    );
};

const ListIcon = styled.i`
    margin-right: 0.5rem;
    font-size: 1.25rem !important;
    ${media.xs`
    font-size: 1.25rem !important;
  `}
    ${media.sm`
    font-size: 1rem !important;
  `}
    ${media.md`
	font-size: 1rem !important;
  `}
    ${media.lg`
    font-size: 1rem !important;
  `}
  ${media.xl`
    font-size: 1.25rem !important;
  `}
`;

const Routes = styled.div<any>`
    font-weight: 500;
    padding: 16px 24px;
    width: 100%;
    display: inline-block;
    ${media.xs`
    padding: ${({ padding }: any) => padding && '16px 24px'};
  `}
    ${media.sm`
    padding:${({ padding }: any) => padding && '16px 24px'}
  `}
    ${media.md`
    padding: ${({ padding }: any) => padding && '16px 10px'}
  `}
    ${media.lg`
    padding: ${({ padding }: any) => padding && '16px 10px'}
  `}
  ${media.xl`
    padding: ${({ padding }: any) => padding && '16px 20px'}
  `}
  @media screen and (min-width: 930px)and (max-width: 1060px) {
        font-size: 0.8rem;
    }
`;

const CustomRoutes = styled(Link)<any>`
    padding: 0.4rem;
    width: 100%;
    display: inline-block;
    text-decoration: none !important;
    color: white !important;
    @media screen and (min-width: 930px) and (max-width: 1060px) {
        font-size: 0.8rem;
    }
`;

const CustomDiv = styled.div`
    padding: 5px 3.5rem;
`;

export default AsideList;
