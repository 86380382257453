/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Drawer Component
 * Description: This component provides a flexible drawer for displaying custom or default content. It supports opening and closing animations, with options for left or right placement. The drawer can be controlled through the 'active' prop, and callback functions 'onOpen' and 'onClose' can be used to handle state changes.
 *
 * Props:
 * - active: boolean - Determines whether the drawer is currently active (open).
 * - custom: boolean - When set to true, enables custom content within the drawer; otherwise, it renders a default header and body.
 * - left: boolean - When true, positions the drawer on the left side; otherwise, it's on the right.
 * - onOpen: () => void - Callback function triggered when the drawer is opened.
 * - onClose: () => void - Callback function triggered when the drawer is closed.
 * - children: React.ReactNode - Content to be rendered within the drawer.
 *
 * Purpose:
 * - Provides a customizable drawer that can be opened or closed
 * - Supports left or right positioning of the drawer
 * - Utilizes React state to manage drawer open/close and rendering
 * - Handles open and close events with optional callback functions
 * - Renders a default header with a close button if not in custom mode
 * - Supports custom content when the 'custom' prop is true
 *
 */
import React, { useEffect, useState } from 'react';

export interface DrawerProps {
    active?: boolean;
    custom?: boolean;
    left?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    children?: React.ReactNode;
}

const Drawer = ({ active, custom, left, onOpen, onClose, children }: DrawerProps) => {
    const [open, setOpen] = useState(active);
    const [rendered, setRendered] = useState(active);

    useEffect(() => {
        if (active && !open) openDrawer();
        if (!active && open) closeDrawer();
    });

    const closeDrawer = () => {
        setRendered(true);
        setOpen(false);
        setTimeout(() => {
            setRendered(false);
            if (onClose) onClose();
        }, 500);
    };

    const openDrawer = () => {
        setRendered(true);
        setOpen(false);

        setTimeout(() => {
            setOpen(true);
        }, 20);
    };

    return rendered ? (
        <div
            className={`${left ? 'drawer-left' : 'drawer-right'} drawer ${
                open ? 'drawer--open' : ''
            }`}>
            {custom ? (
                children
            ) : (
                <>
                    <div className="drawer--header">
                        <button className="drawer--closeBtn" onClick={() => closeDrawer()}>
                            <i className="fal fa-times"></i>
                        </button>
                        <div className="drawer--heading">Requested Appointments</div>
                    </div>
                    <div className="drawer--body">{children}</div>
                </>
            )}
        </div>
    ) : null;
};

export default Drawer;
