/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Booking Component for managing booking calendar settings.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { Layout } from '@components/common';
import { BookingSettings } from '@components/settings';

export default function Booking() {
    return (
        <Wrapper>
            <BookingSettings />
        </Wrapper>
    );
}

const Wrapper = styled(Container)``;
Booking.Layout = Layout;
