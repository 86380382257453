/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerAppointments Component
 * Description: This component displays upcoming and past bookings for a customer. It includes tabs for switching between upcoming and past bookings.
 *
 * Props:
 * - customerId: string - The ID of the customer for whom appointments are displayed.
 */

import { useEffect, useState } from 'react';
import UpcomingBookings from './UpcomingBookings';
import PastBookings from './PastBookings';
import { Col, Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
import { useTranslation } from 'react-i18next';

const CustomerAppointments = ({ customerId }: { customerId: string }) => {
    const { t } = useTranslation();
    const [upcoming, setUpcoming] = useState(0);
    const [done, setDone] = useState(0);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        setUpcoming(0);
        setDone(0);
        setTabValue(0);
    }, [customerId]);

    return (
        <MainContainer>
            <Wrapper>
                <Tab
                    col={6}
                    active={tabValue === 0 && true}
                    onClick={() => {
                        setTabValue(0);
                    }}>{`${t('Upcoming')} (${upcoming})`}</Tab>
                <Tab
                    col={6}
                    active={tabValue === 1 && true}
                    onClick={() => {
                        setTabValue(1);
                    }}>{`${t('Past')} (${done})`}</Tab>
            </Wrapper>
            {tabValue === 0 ? (
                <UpcomingBookings
                    customerId={customerId}
                    onInitialLoad={(total: number) => setUpcoming(total)}
                    doneInitialLoad={(total: number) => setDone(total)}
                />
            ) : (
                <PastBookings
                    customerId={customerId}
                    onInitialLoad={(total: number) => setDone(total)}
                />
            )}
        </MainContainer>
    );
};

const MainContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const Wrapper = styled.div`
    color: #8c8b88;
    background-color: #fafafa;
    min-height: 36px;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: stretch;
    cursor: pointer;
    position: relative;
    font-size: 13px;
    justify-content: space-evenly;
    margin-bottom: 1rem;
`;

const Tab = styled(Col)<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9rem;
    border-radius: 0.2rem;
    font-weight: 500;
    background-color: ${({ active }) => (active ? palette.secondary : '')};
    color: ${({ active }) => (active ? palette.light : '')};
`;

export default CustomerAppointments;
