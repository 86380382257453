/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Service Actions
 * Description: This file contains action creators related to services, including creating, fetching, and updating services.
 *
 * Constants:
 * - serviceConstants: Constants for service actions.
 *
 * Actions:
 * - createService: Action creator for creating a new service.
 * - getServices: Action creator for fetching services.
 * - updateService: Action creator for updating a service.
 */

import { IService } from 'interfaces/service.interface';
import { Dispatch } from 'redux';
import { serviceService } from 'services';

export const serviceConstants = {
    CREATE_REQUEST: 'SERVICE_CREATE_REQUEST',
    CREATE_SUCCESS: 'SERVICE_CREATE_SUCCESS',
    CREATE_FAILURE: 'SERVICE_CREATE_FAILURE',

    GET_REQUEST: 'SERVICE_GET_REQUEST',
    GET_SUCCESS: 'SERVICE_GET_SUCCESS',
    GET_FAILURE: 'SERVICE_GET_FAILURE',

    UPDATE_REQUEST: 'SERVICE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SERVICE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SERVICE_UPDATE_FAILURE',
};

const createService =
    (serviceData: IService) =>
    (dispatch: Dispatch): Promise<IService> => {
        dispatch({
            type: serviceConstants.CREATE_REQUEST,
        });

        return serviceService.create(serviceData).then(service => {
            dispatch({
                type: serviceConstants.CREATE_SUCCESS,
                service,
            });

            return service;
        });
    };

const getServices =
    () =>
    (dispatch: Dispatch): Promise<IService[]> => {
        return serviceService.getServices().then(services => {
            dispatch({
                type: serviceConstants.GET_SUCCESS,
                services,
            });

            return services;
        });
    };

const updateService =
    (id: string, service: IService) =>
    (dispatch: Dispatch): Promise<IService> => {
        dispatch({
            type: serviceConstants.UPDATE_REQUEST,
        });

        return serviceService.updateService(id, service).then(service => {
            dispatch({
                type: serviceConstants.UPDATE_SUCCESS,
                service,
            });

            return service;
        });
    };
export const serviceActions = {
    createService,
    getServices,
    updateService,
};
