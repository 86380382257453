/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CalendarLayout
 * Description: This component defines the layout for the calendar view, including the main page content and the aside menu. The layout adjusts its structure based on whether the left menu is enabled or disabled for the user.
 *
 * Props:
 * - children: ReactNode - The content to be rendered within the layout.
 */

import React, { FC } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Aside } from '@components/common';
const CalendarLayout: FC<any> = ({ children, ...props }) => {
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    return (
        <Wrapper flexDirection={_userData?.leftMenu === true && true}>
            <Aside />
            <PageContent margin={_userData?.leftMenu === true && true}>{children}</PageContent>
        </Wrapper>
    );
};

const Wrapper = styled.div<any>`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: ${({ flexDirection }) => (flexDirection ? 'row !important' : 'column')};
    display: flex !important;
`;

const PageContent = styled.div<any>`
    margin-left: ${({ margin }) => (margin ? '18.6rem' : '')};
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    justify-content: center;
    ${media.xs`
  margin-left: 0rem;
`}
    ${media.sm`
  margin-left: 0rem;
`}
  ${media.md`
  margin-left: 0rem;
`}
  ${media.lg`
  margin-left: ${({ margin }: any) => (margin ? '18.6rem' : '')};
`}
`;

export default CalendarLayout;
