/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CustomisedTeamCalendar
 * Description: This component represents a customized team calendar view. It includes features such as navigating between days, changing views, and displaying appointments for multiple service providers. The layout adjusts based on the user's configuration, including custom business hours and VIP appointments.
 *
 * Props:
 * - setDateRange: (range: any) => void - Callback function triggered when the date range is changed.
 * - teamCalendarDate: any - The date for the team calendar.
 * - setTeamCalendarDate: (date: any) => void - Callback function triggered when the team calendar date is changed.
 * - serviceProviderId: any - The ID of the service provider for the team calendar.
 * - viewMode: any - The view mode for the team calendar.
 * - pickDate: any - Callback function triggered when a date is picked on the team calendar.
 * - calendar_size: any - The size of the team calendar.
 * - customBusinessHours: any - Custom business hours information for the team calendar.
 * - vipAppointment: VipAppointmentInfo - Value of the VIP appointment creation flow to enable working hours, allowing 24 business hours of the day, and ignoring already scheduled appointment times.
 * - waitingList: boolean - Indicates whether to create a waiting list appointment if the service provider accepts waiting list appointments.
 *
 */

import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { NewAppointmentDrawer, EditAppointmentDrawer } from '@components/appointments';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { v4 as uuid } from 'uuid';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import useTimeIndicator from '../bookingsCalendar/useTimeIndicator';
import moment from 'moment';
import { IAppointment } from 'interfaces/appointment.interface';
import { LoaderOverlay, useSnackbar } from '@components/common';
import useRouter from 'hooks/router';
import ModifyAppointmentModal from '../../appointments/modal/ModifyAppointmentModal';
import './Calender.css';
import jquery from 'jquery';
import { api } from 'helpers/auth-axios';
import BlockModel from '../../appointments/modal/BlockModel';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import ConflictModal from '@components/appointments/modal/ConflictModal';
import Pusher from 'pusher-js';
import { useTranslation } from 'react-i18next';
import CustomiseBusinessHoursDrawer from '@components/appointments/modal/CustomiseBusinessHoursDrawer';
import { Tooltip } from 'bootstrap';
import { media } from 'styled-bootstrap-grid';
import ReminderModel from '@components/appointments/modal/ReminderModel';
import { palette } from 'styled/common';
let tooltipInstance: any = null;
let serviceProviderIds: any = [];

export interface IEvent {
    id: string;
    start: string;
    end: string;
    service: IService;
    customer: ICustomer;
}

interface IProps {
    setDateRange: any;
    teamCalendarDate: any;
    setTeamCalendarDate: any;
    serviceProviderId: any;
    viewMode: any;
    pickDate: any;
    calendar_size: any;
    customBusinessHours: any;
    vipAppointment: any;
    waitingList: any;
}

const CustomisedTeamCalendar = forwardRef(
    (
        {
            viewMode,
            setDateRange,
            serviceProviderId,
            teamCalendarDate,
            setTeamCalendarDate,
            pickDate,
            calendar_size,
            customBusinessHours,
            vipAppointment,
            waitingList,
        }: IProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            next() {
                next();
            },
            prev() {
                prev();
            },
            today() {
                today();
            },
            changeView(view?: string) {
                changeView(view);
            },
        }));
        if (viewMode == 'teamGridWeek') {
            viewMode = 'timeGridDay';
        }
        const $: JQueryStatic = jquery;
        const { t }: any = useTranslation();
        const router = useRouter();
        const [serviceProvider, setServiceProvider] = useState<any>([]);
        const weekClasses = [
            '.fc-day-sun',
            '.fc-day-mon',
            '.fc-day-tue',
            '.fc-day-wed',
            '.fc-day-thu',
            '.fc-day-fri',
            '.fc-day-sat',
        ];
        const [preDay, setPreDay] = useState<any>('');
        const [nextMove, setNextMove] = useState(1);
        const [loading, setLoading] = useState(false);
        const [selectedServiceProvider, setSelectedServiceProvider] = useState<any>();
        const [confirmation, setConfirmation] = useState({
            show: false,
            id: '',
            start: moment().format(),
            end: moment().format(),
            is_blocked: false,
            callback: () => {},
            appointment: {},
        });
        const calendarRef = useRef<FullCalendar>(null);
        const [total, setTotal] = useState<any>();
        const [pageNo, setPageNo] = useState<any>(1);
        const [noOfEntry, setNoOfEntry] = useState<any>(7);
        const [providerId, setServiceProviderId] = useState<any>();
        const [workingHours, setWorkingHours] = useState<any>([]);
        const [id, setId] = useState<any>();
        const [newAppointment, setNewAppointment] = useState<any>({
            show: false,
            id: '',
            start: preDay !== undefined && preDay,
            end: preDay !== undefined && preDay,
        });
        const [indicate] = useTimeIndicator();
        const [blockDeleteModel, setBlockDeleteModel] = useState<any>(false);
        const [appointmentId, setAppointmentId] = useState<any>();
        const [blockModel, setBlockModel] = useState<any>(false);
        const [openSnackbar] = useSnackbar();
        const [blockDate, setBlockDate] = useState<any>();
        const [conflictDates, setConflictDates] = useState<string[]>([]);
        const [showConflictModal, setShowConflictModal] = useState(false);
        const [appointmentData, setAppointmentData] = useState<any>([]);
        const [reminderModel, setReminderModel] = useState(false);
        const [customSlots, setCustomSlots] = useState<any>({
            show: false,
            serviceProviderId: serviceProviderId,
            id: '',
            start: moment().format(),
            end: moment().format(),
        });
        let _userData = JSON.parse(localStorage.getItem('user') || '{}');
        const windowContainer: any = useRef(null);

        useEffect(() => {
            indicate();
        });

        useEffect(() => {
            changeView('timeGridWeek');
        }, []);

        useEffect(() => {
            if (serviceProvider?.length !== undefined) {
                teamsProfileShow();
                showArrows();
            }
        }, [serviceProvider]);

        useEffect(() => {
            getServiceProviderAppointments(pageNo);
        }, [preDay]);

        useEffect(() => {
            calendarDate(teamCalendarDate);
        }, [teamCalendarDate]);

        useEffect(() => {
            if (customBusinessHours) {
                removeAllEvent();
                appointmentData?.serviceProviderWorkingHours?.map((customiseWorkingHours: any) => {
                    customiseWorkingHours?.map((workingHours: any) => {
                        if (workingHours?.timeOff === true) {
                        } else {
                            addEvent({
                                id: workingHours.id,
                                start: moment(workingHours?.start).format(),
                                end: moment(workingHours?.end).format(),
                                repeat: workingHours?.repeat,
                                bgcolor: workingHours?.color ? workingHours?.color : '30',
                                // display: 'background',
                                workingHours: true,
                                break: workingHours?.cutomBreak ? true : false,
                                data: workingHours,
                            });
                        }
                    });
                });
            } else if (appointmentData?.serviceProviderAppointments?.length) {
                removeAllEvent();
                appointmentData?.serviceProviderAppointments?.map((appointment: any) => {
                    addEvent({
                        id: appointment?._id,
                        start: moment(appointment?.booked_from_iso).format(),
                        end: moment(appointment?.booked_till_iso).format(),
                        service: appointment?.service,
                        customer: appointment?.customer,
                        serviceProvider: appointment?.serviceProvider,
                        is_blocked: appointment?.is_blocked,
                        is_vip: appointment?.is_vip,
                        status: appointment?.status,
                        is_reported: appointment?.reportId ? true : false,
                        is_video: appointment?.is_video_appointment,
                        appointmentId: appointment?._id,
                        appointment: appointment,
                        is_waiting: appointment?.is_waiting,
                    });
                });
                appointmentData?.serviceProviderWorkingHours?.map((customiseWorkingHours: any) => {
                    customiseWorkingHours?.map((workingHours: any) => {
                        if (workingHours?.timeOff === true) {
                        } else {
                            addEvent({
                                id: workingHours.id,
                                start: moment(workingHours?.start).format(),
                                end: moment(workingHours?.end).format(),
                                repeat: workingHours?.repeat,
                                bgcolor: workingHours?.color,
                                display: workingHours?.cutomBreak ? undefined : 'background',
                                workingHours: true,
                                break: workingHours?.cutomBreak ? true : false,
                                data: workingHours,
                            });
                        }
                    });
                });
            }
        }, [appointmentData, customBusinessHours]);

        useEffect(() => {
            $('.arrowRight').on('click', function () {
                nextPage();
            });

            $('.arrowLeft').on('click', function () {
                prevPage();
            });
        });

        //Pusher
        useEffect(() => {
            let PusherId = '';
            if (process.env.REACT_APP_PUSHER !== undefined) {
                PusherId = process.env.REACT_APP_PUSHER;
            }
            var pusher = new Pusher(PusherId, {
                cluster: 'ap2',
            });
            let businessId = _userData?.user?.business_id?._id;
            const channel = pusher.subscribe(businessId);

            channel.bind('eBookingSystem', function (data: any) {
                if (calendarRef.current !== null) {
                    const calendarApi = calendarRef.current.getApi().getEvents();
                    const eventsData: any = calendarApi.map((data: any) => data._def.publicId);
                    if (data?.cancelledAppointment?.length) {
                        let obj = eventsData.find(
                            (x: any) => x === data?.cancelledAppointment[0]?._id
                        );
                        data?.cancelledAppointment?.map((event: any) => {
                            obj === event?._id &&
                                editEvent({
                                    id: event?._id,
                                    start: moment(event?.booked_from_iso).format(),
                                    end: moment(event?.booked_till_iso).format(),
                                    service: event?.service,
                                    customer: event?.customer,
                                    is_blocked: event?.is_blocked,
                                    serviceProvider: event?.serviceProvider?._id,
                                    is_vip: event?.is_vip,
                                    status: event?.status,
                                });
                        });
                    }
                }
                if (data?.deleteAppointment) {
                    data?.deleteAppointment?.map((data: any) => {
                        removeEventFromPusher(data);
                    });
                }
            });

            channel.bind('teamCalender', function (data: any) {
                if (calendarRef.current !== null) {
                    const calendarApi = calendarRef.current.getApi().getEvents();
                    const eventsData: any = calendarApi.map((data: any) => data._def.publicId);
                    const calendar = calendarRef.current.getApi();
                    if (data?.teamCalender?.length > 0) {
                        data?.teamCalender?.map((event: any) => {
                            let obj = eventsData.find((x: any) => x === data?.teamCalender[0]?._id);
                            if (obj === event?._id) {
                                return editEvent({
                                    id: event?._id,
                                    start: moment(event?.booked_from_iso).format(),
                                    end: moment(event?.booked_till_iso).format(),
                                    service: event?.service,
                                    customer: event?.customer,
                                    is_blocked: event?.is_blocked,
                                    serviceProvider: event?.serviceProvider?._id,
                                    is_vip: event?.is_vip,
                                    status: event?.status,
                                });
                            } else if (obj === undefined && serviceProviderIds?.length > 0) {
                                let id = event?.serviceProvider._id;
                                if (serviceProviderIds.includes(id)) {
                                    return (
                                        moment(calendar.getDate()).format('YYYY-MM-DD') ===
                                            moment(event?.booked_from).format('YYYY-MM-DD') &&
                                        addEvent({
                                            id: event?._id,
                                            start: moment(event?.booked_from_iso).format(),
                                            end: moment(event?.booked_till_iso).format(),
                                            service: event?.service,
                                            customer: event?.customer,
                                            serviceProvider: event?.serviceProvider?._id,
                                            is_vip: event?.is_vip,
                                            is_blocked: event?.is_blocked,
                                            status: event?.status,
                                        })
                                    );
                                }
                            }
                        });
                    }
                }
            });
        }, []);

        useEffect(() => {
            getWorkingHours();
        }, [vipAppointment]);

        const calendarDate = (teamCalendarDate: any) => {
            setPreDay(teamCalendarDate);
            if (teamCalendarDate) {
                const calendarApi = calendarRef?.current?.getApi();
                if (calendarApi?.view.type === 'timeGridWeek' && teamCalendarDate != '') {
                    calendarApi.gotoDate(moment(teamCalendarDate).format('YYYY-MM-DD'));
                }
                changeView('timeGridWeek');
            }
        };

        const getWorkingHours = () => {
            let businessHours: any[] = [];
            if (workingHours[0] !== undefined) {
                workingHours[0].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [0],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[1] !== undefined) {
                workingHours[1].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [1],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[2] !== undefined) {
                workingHours[2].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [2],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[3] !== undefined) {
                workingHours[3].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [3],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[4] !== undefined) {
                workingHours[4].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [4],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[5] !== undefined) {
                workingHours[5].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [5],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[6] !== undefined) {
                workingHours[6].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [6],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }

            const finalWorkingHours = businessHours.reduce((accumulator, current) => {
                const existingEntry = accumulator.find(
                    (entry: any) =>
                        entry.daysOfWeek[0] === current.daysOfWeek[0] &&
                        entry.startTime === current.startTime &&
                        entry.endTime === current.endTime
                );
                if (!existingEntry) {
                    accumulator.push(current);
                }

                return accumulator;
            }, []);

            if (vipAppointment) {
                serviceProvider?.map((e: any, i: any) => {
                    if (e.vip) {
                        finalWorkingHours[i].startTime = '00:00';
                        finalWorkingHours[i].endTime = '23:59';
                    }
                });
            }
            return finalWorkingHours;
        };

        const removeAllEvent = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.removeAllEvents();
            }
        };

        const getServiceProviderAppointments = (_pageNo: any) => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();

                if (preDay !== '') {
                    setServiceProvider([]);
                    setLoading(true);
                    setAppointmentData([]);
                    let date = moment(teamCalendarDate).format();
                    console.log('date', date);
                    api.post(
                        `/appointments/serviceprovider/daily?page=${_pageNo}&pageSize=${noOfEntry}`,
                        { date: date, calendar: 'customise' }
                    )
                        .then((res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            setTotal(res.data !== undefined && res.data.serviceProviders.total);
                            setAppointmentData(res.data.appointments);
                            setWorkingHours(res.data.appointments.serviceProviderWorkingHours);
                            setId(res.data.serviceProviders.serviceProvider.map((d: any) => d));
                            let array: any = [];
                            serviceProviderIds = [];
                            res.data.serviceProviders.serviceProvider.map((d: any) => {
                                array.push(d);
                                serviceProviderIds.push(d._id);
                            });
                            // console.log('api', serviceProviderIds);
                            setServiceProvider(array);
                            setLoading(false);
                        })
                        .catch(e => {
                            if (e?.message) {
                                openSnackbar(e?.message);
                            }

                            setLoading(false);
                        });
                }
            }
        };

        const showArrows = () => {
            if (serviceProvider?.length != 0) {
                var TotelPage = Math.ceil(total / noOfEntry);
                var tableHead: any = $(`table.fc-col-header`);
                $('.arrowRight,.arrowLeft').remove();
                if (pageNo == 1 && TotelPage == 1) {
                    $('.arrowRight,.arrowLeft').remove();
                } else if (pageNo < TotelPage) {
                    if (pageNo == 1) {
                        tableHead.prepend(` <span class="arrowRight"></span>`);
                    } else {
                        tableHead.prepend(
                            `<span class="arrowLeft"></span> <span class="arrowRight"></span>`
                        );
                    }
                } else if (pageNo > 1 && pageNo == TotelPage) {
                    tableHead.prepend(`<span class="arrowLeft"></span>`);
                }
            }
        };

        const teamsProfileShow = () => {
            var week = 0;
            var i = 0;
            for (i; i < weekClasses?.length * nextMove; i++) {
                var teamHeader: any = $(`th${weekClasses[week]} div a`);
                teamHeader.css({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                });

                $(`th.fc-day-today`).css('border-bottom', '1px solid #dddddd');
                $(`td.fc-timegrid-col`).addClass('fc-day-today');

                $(`.fc-timegrid-now-indicator-container`).addClass('fullLine');
                $(`.fc-timegrid-now-indicator-line`).addClass('fullLionWidth');

                teamHeader.text('');
                if (serviceProvider?.length != 0 ? serviceProvider[i] != undefined : '') {
                    teamHeader.prepend(
                        `<img style="width:3rem;height:3rem;border-radius: 100%;" src=${process.env.REACT_APP_PROFILE_URL}${serviceProvider[i].photo} /> <span id=${serviceProvider[i]._id} class="providerName">${serviceProvider[i].name}</span>`
                    );
                }

                week = week + 1;
            }
        };

        const nextPage = () => {
            setPageNo(1 + pageNo);
            getServiceProviderAppointments(1 + pageNo);
        };

        const prevPage = () => {
            setPageNo(pageNo - 1);
            getServiceProviderAppointments(pageNo - 1);
        };

        const next = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                var day: any = '';
                if (preDay != '') {
                    day = moment(preDay).format();
                } else {
                    day = moment().format();
                }
                var nextDay: any = moment(day).add(1, 'd').format();

                setPreDay(nextDay);
                setTeamCalendarDate(nextDay);
                setDateRange(moment(nextDay).format('ddd, MMMM DD, YYYY'));
                if (nextDay != '') {
                    calendarApi.gotoDate(moment(nextDay).format('YYYY-MM-DD'));
                }
                setTimeout(function () {
                    teamsProfileShow();
                }, 0);
            }
        };

        const prev = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                var day: any = '';
                if (preDay != '') {
                    day = moment(preDay).format();
                } else {
                    day = moment().format();
                }
                var newDay: any = moment(day).subtract(1, 'd').format();
                setPreDay(newDay);
                setTeamCalendarDate(newDay);
                setDateRange(moment(newDay).format('ddd, MMMM DD, YYYY'));
                if (newDay != '') {
                    calendarApi.gotoDate(moment(newDay).format('YYYY-MM-DD'));
                }
                setTimeout(function () {
                    teamsProfileShow();
                }, 0);
            }
        };

        const today = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                var newDay = moment().format();
                setPreDay(newDay);
                setTeamCalendarDate(newDay);
                setDateRange(moment(newDay).format('ddd, MMMM DD, YYYY'));
                if (newDay != '') {
                    calendarApi.gotoDate(moment(newDay).format('YYYY-MM-DD'));
                }
                setTimeout(function () {
                    teamsProfileShow();
                }, 0);
            }
        };

        const changeView = (view?: string) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                if (view) calendarApi.changeView(view);
                const d = moment(teamCalendarDate).format();
                setDateRange(moment(d).format('ddd, MMMM DD, YYYY'));
            }
            getServiceProviderAppointments(pageNo);
        };

        const addEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.addEvent(event);
            }
        };

        const editEvent = (event: any) => {
            removeEvent(event);
            addEvent(event);
        };

        const removeEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event.id)?.remove();
            }
        };

        const getIndex = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
            }
        };

        const deleteBlock = () => {
            setLoading(true);
            api.delete(`/appointments/${appointmentId}`)
                .then(() => {
                    setLoading(false);
                    openSnackbar(t('Block deleted successfully!'));
                    setBlockDeleteModel(false);
                })
                .catch((res: any) => {
                    openSnackbar(t('Failed to delete Block!'));
                });
        };

        $('.fc-timegrid-slot-lane,.fc-timegrid-slot-label').hover(function () {
            var getDefaultTime = $(this).attr('data-time');
            var formatedTime = moment(
                moment().format() + ' ' + getDefaultTime,
                'YYYY-MM-DD HH:mm:ss'
            ).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm');
            $(this).attr('data-time', formatedTime);
        });

        const removeEventFromPusher = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event._id)?.remove();
            }
        };

        const renderEventContent = (arg: any) => {
            const props = arg.event.extendedProps;
            let currentDate: any = moment();
            let appointmentDate: any = moment(props?.appointment?.booked_from_iso);
            let appointmentDuration = moment.duration(appointmentDate?.diff(currentDate));
            let hours = appointmentDuration.asMinutes();
            const duration: any = moment
                .duration((arg.event.end as any) - (arg.event.start as any))
                .asMinutes();
            return props?.workingHours === true ? (
                <div
                    className={`slot_booking_event  ${
                        props?.break === true
                            ? 'breakTime'
                            : customBusinessHours && 'slots_event eventBg'
                    } service-color-${props?.break ? '' : props?.bgcolor}--bg`}
                    id={props?.id}>
                    {props?.break === true && <span style={{ paddingLeft: '0.9rem' }}>Break</span>}
                    <div className={`booking_eventBorder service-color-${props.bgcolor}--bd`}></div>
                    {props?.break === true ? (
                        ''
                    ) : (
                        <div className={`hide`} style={{ gap: '0.2rem' }}>
                            <i className="fas fa-edit pointer"></i>
                            {duration >= '60' ? (
                                <div style={{ cursor: 'pointer' }}>{t('Edit')}</div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        border: props?.is_waiting ? `2px solid ${palette.secondary}` : 'none',
                    }}
                    className={`booking_event ${
                        props.is_blocked == true ? 'block_bg' : ''
                    } service-color-${props.service?.color}--bg`}
                    onMouseEnter={() => {
                        let element: any = document?.getElementById(props?.appointmentId);
                        if (props?.appointmentId && props?.is_blocked === false) {
                            tooltipInstance = new Tooltip(element, {
                                title: `<div>${
                                    props?.is_waiting ? 'Waiting list appointment' : ''
                                }</div><div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Customer'
                                )}</div>
                                <div>${props?.customer?.fullname}</div></div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Service Provider'
                                )}</div>
                                <div>${props?.appointment?.serviceProvider?.name}</div>
                                </div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Service'
                                )}</div>
                                <div>${props?.appointment?.service?.name}</div>
                                </div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Phone'
                                )}</div>
                                <div>${props?.customer?.phone}</div>
                                </div>
                                <div style="display: flex; justifyContent: space-between;  padding: 6px 0;"><div>${t(
                                    'Status'
                                )}</div>
                                <div>${props?.status}</div>
                                </div>`,
                                html: true,
                                placement: 'top',
                                trigger: 'hover',
                                container: 'body',
                            });
                            tooltipInstance.show();
                        }
                    }}
                    onMouseLeave={() => {
                        if (tooltipInstance) {
                            tooltipInstance.dispose();
                            tooltipInstance = null;
                        }
                    }}
                    id={props?.appointmentId}>
                    <div
                        className={`booking_eventBorder service-color-${props?.service?.color}--bd`}></div>
                    <div className={`booking_eventDetails`}>
                        <CustomerDiv
                            className="booking_serviceCustomer"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}>
                            {props.is_vip == true && <>{t('vip')} - </>}
                            {props?.is_waiting && <>{t('w')} - </>}
                            {props.is_blocked === true ? t('Block') : ''}{' '}
                            {props.customer
                                ? props.customer?.firstname?.substring(0, 10)
                                : props.is_blocked === false && t('customer removed')}{' '}
                            {props.customer?.lastname?.substring(0, 10)}{' '}
                            {props.is_blocked === true
                                ? ''
                                : props?.service === null
                                ? props?.appointmentId && '(' + t('service removed') + ')'
                                : duration <= 15
                                ? ''
                                : props?.is_waiting
                                ? ''
                                : props?.appointmentId &&
                                  '(' + props?.service?.name?.substring(0, 10) + ')'}
                            {props?.status === 'cancelled' && (
                                <div
                                    className="booking_serviceCustomer"
                                    style={{ color: palette.primary }}>
                                    {props?.status}
                                </div>
                            )}
                            {props?.is_reported && (
                                <i
                                    className="fad fa-exclamation-triangle"
                                    style={{ color: palette.primary }}></i>
                            )}
                            {props?.is_video && (
                                <i
                                    className="fal fa-video"
                                    onClick={e => {
                                        if (hours <= 10 && appointmentDate > currentDate) {
                                            router.setParams({ appointment: '' });
                                            let newWindow: any = window.open(
                                                `/live-video-appointment/${props?.appointmentId}/${_userData?.accessToken}`,
                                                'newwindow',
                                                // `width=448, height=768`
                                                `width=1440, height=768`
                                                // 'toolbar=0,location=0,menubar=0'
                                            );
                                            newWindow.handleScreenSize = function handleScreenSize(
                                                size: any
                                            ) {
                                                if (size == 'max') {
                                                    windowContainer?.dispatchEvent(
                                                        new Event('click')
                                                    );
                                                } else if (size == 'min') {
                                                    windowContainer?.dispatchEvent(
                                                        new Event('click')
                                                    );
                                                }
                                            };
                                            // onCloseCB();
                                            return false;
                                        } else {
                                            setReminderModel(true);
                                        }
                                    }}></i>
                            )}
                        </CustomerDiv>
                        {duration <= 15 ? (
                            <div
                                className={`${
                                    _userData.user?.business_id?.time_select === '12'
                                        ? 'booking_week_time'
                                        : 'booking_time'
                                } `}>
                                {/* {moment(arg.event.startStr).format('hh:mm a')} -{' '}
                            {moment(arg.event.endStr).format(
                                _userData?.user?.business_id?.time_select === '12'
                                    ? 'hh:mm a'
                                    : 'HH:mm'
                            )} */}
                            </div>
                        ) : (
                            <div
                                className={`${
                                    _userData.user?.business_id?.time_select === '12'
                                        ? 'booking_week_time'
                                        : 'booking_time'
                                } `}>
                                {moment(arg.event.startStr).format(
                                    _userData.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}{' '}
                                {props?.is_waiting ? (
                                    ''
                                ) : (
                                    <>
                                        {' '}
                                        -
                                        {moment(arg.event.endStr).format(
                                            _userData?.user?.business_id?.time_select === '12'
                                                ? 'hh:mm a'
                                                : 'HH:mm'
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        };

        if (typeof window !== 'undefined') {
            let defaultSlots: any = document.getElementsByClassName(
                'fc-timegrid-event-harness-inset'
            );
            for (let i = 0; i < defaultSlots?.length; i++) {
                let eventInset = defaultSlots[i]?.style?.inset;
                if (defaultSlots[i]?.style?.zIndex == 2 && eventInset?.includes('50%') == true) {
                    const insetChecksecond = defaultSlots[i]?.style?.inset?.replace('50%', '30%');
                    defaultSlots[i]?.setAttribute('style', `inset:${insetChecksecond}`);
                }
                if (
                    defaultSlots[i]?.style?.zIndex == 3 &&
                    eventInset?.includes('66.6667%') == true
                ) {
                    const insetChecksecond = defaultSlots[i]?.style?.inset?.replace(
                        '66.6667%',
                        '55%'
                    );
                    defaultSlots[i]?.setAttribute('style', `inset:${insetChecksecond}`);
                }
                let tooltipOpend = $('.show.tooltip');
                if (tooltipInstance == null && tooltipOpend.length > 0) {
                    $('.show.tooltip').css({
                        visibility: 'hidden',
                    });
                }
            }
        }

        return (
            <Wrapper>
                {loading && <LoaderOverlay />}
                <Flex id="teamCalender">
                    <FullCalendar
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            momentTimezonePlugin,
                        ]}
                        initialView="timeGridWeek"
                        //slotDuration={`00:${business.calendar_size}:00`}
                        slotDuration={`00:${calendar_size !== undefined ? calendar_size : '15'}:00`}
                        allDaySlot={false}
                        nowIndicator={true}
                        slotLabelInterval="1:00"
                        height="100%"
                        headerToolbar={{
                            left: '',
                            center: '',
                            right: '',
                        }}
                        dayHeaderFormat={{
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            omitCommas: false,
                        }}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: _userData.user.business_id.time_select === '12' ? true : false,
                        }}
                        scrollTime={moment().subtract(2, 'h').format('HH') + ':00'}
                        editable={true}
                        timeZone={
                            _userData?.user?.business_id
                                ? _userData.user.business_id?.timezone
                                : 'America/Chicago'
                        }
                        eventDurationEditable={true}
                        selectable={true}
                        selectMirror={true}
                        longPressDelay={0}
                        select={args => {
                            let now = moment(args.startStr);
                            let sunday = now.clone().weekday(0);
                            let saturday = now.clone().weekday(6);
                            let start = moment(sunday); //today date
                            //let end = moment(saturday); // another date
                            let duration = moment.duration(now.diff(start));
                            let days = duration.asDays();
                            let indexNumber: any = days;
                            setServiceProviderId(indexNumber);
                            let from_hour: any = moment(args.startStr).format('HH');
                            let from_mint: any = moment(args.startStr).format('mm');
                            let till_hours: any = moment(args.endStr).format('HH');
                            let till_mint: any = moment(args.endStr).format('mm');
                            let today: any =
                                preDay !== ''
                                    ? moment(preDay).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD');
                            let day: any = today !== '' ? moment(today).format('DD') : '';
                            let year: any = today !== '' ? moment(today).format('YYYY') : '';
                            let month: any = today !== '' ? moment(today).format('MM') : '';

                            let booked_from_iso = moment(
                                year + month + day + from_hour + from_mint,
                                'YYYY MM DD HH mm'
                            );
                            let booked_till_iso = moment(
                                year + month + day + till_hours + till_mint,
                                'YYYY MM DD HH mm'
                            );
                            const id = uuid();
                            // console.log('args', indexNumber, providerId, moment(start).weekday());
                            if (indexNumber != providerId) {
                                setNewAppointment({
                                    show: false,
                                });
                            }
                            if (customBusinessHours === true) {
                                setCustomSlots({
                                    show: true,
                                    id: id,
                                    start: booked_from_iso.format(),
                                    end: booked_till_iso.format(),
                                });
                            } else {
                                setNewAppointment({
                                    show: true,
                                    id: id,
                                    start: booked_from_iso.format(),
                                    end: booked_till_iso.format(),
                                });
                            }

                            setBlockDate(booked_from_iso);
                        }}
                        selectConstraint={
                            customBusinessHours === true ? undefined : 'businessHours'
                        }
                        businessHours={customBusinessHours === true ? undefined : getWorkingHours()}
                        eventContent={renderEventContent}
                        eventClassNames={function (arg) {
                            return arg.event.extendedProps.classNames;
                        }}
                        eventClick={arg => {
                            tooltipInstance?.dispose();
                            tooltipInstance = null;
                            if (arg.event._def.extendedProps.break === true) {
                                setCustomSlots({
                                    show: false,
                                });
                            } else if (arg.event._def.extendedProps.workingHours === true) {
                                setCustomSlots({
                                    show: true,
                                    id: arg.event.id,
                                    start: moment(arg.event.startStr).format(),
                                    end: moment(arg.event.endStr).format(),
                                });
                                router.setParams({ workingHours: arg.event.id });
                            } else {
                                arg.event._def.extendedProps.is_blocked === true
                                    ? setBlockDeleteModel(true)
                                    : router.setParams({ appointment: arg.event.id });
                                setAppointmentId(arg.event.id);
                                setNewAppointment({
                                    show: false,
                                });
                            }
                        }}
                        eventDrop={(info: any) => {
                            tooltipInstance?.dispose();
                            tooltipInstance = null;
                            let now = moment(info.event.start);
                            let sunday = now.clone().weekday(0);
                            let saturday = now.clone().weekday(6);
                            let start = moment(sunday); //todays date
                            //let end = moment(saturday); // another date
                            let duration = moment.duration(now.diff(start));
                            let days = duration.asDays();
                            let indexNumber: any = days;
                            setSelectedServiceProvider(
                                id[JSON.parse(indexNumber)]?._id !== undefined
                                    ? id?.length !== undefined && id[JSON.parse(indexNumber)]?._id
                                    : ''
                            );
                            if (info.event._def.extendedProps.workingHours === true) {
                                info.revert();
                                setConfirmation((prevState: any) => ({
                                    ...prevState,
                                    show: false,
                                    appointment: {},
                                }));
                            } else if (
                                id[JSON.parse(indexNumber)]?._id != '' &&
                                id[JSON.parse(indexNumber)]?._id != undefined
                            ) {
                                setConfirmation({
                                    show: true,
                                    id: info.event.id,
                                    start: moment(info.event.start).format(),
                                    end: moment(info.event.end).format(),
                                    is_blocked: info?.event?._def.extendedProps?.is_blocked,
                                    callback: info.revert,
                                    appointment: info?.event?.extendedProps?.appointment,
                                });
                            } else {
                                confirmation.callback();
                                getServiceProviderAppointments(pageNo);
                                getIndex();
                                setConfirmation(prevState => ({
                                    ...prevState,
                                    show: false,
                                    appointment: {},
                                }));
                            }
                        }}
                        eventResize={(info: any) => {
                            tooltipInstance?.dispose();
                            tooltipInstance = null;
                            setConfirmation({
                                show: true,
                                id: info.event.id,
                                start: moment(info.event.start).format(),
                                end: moment(info.event.end).format(),
                                is_blocked: info?.event?._def.extendedProps?.is_blocked,
                                callback: info.revert,
                                appointment: info?.event?.extendedProps?.appointment,
                            });
                        }}
                        ref={calendarRef}
                    />
                </Flex>

                {/* for new workingHours */}
                {customSlots.show && id[providerId]?._id !== undefined && (
                    <CustomiseBusinessHoursDrawer
                        serviceProviderId={
                            providerId !== undefined && id !== undefined && id[providerId]?._id
                        }
                        id={router.query.workingHours as string}
                        startDate={customSlots.start}
                        endDate={customSlots.end}
                        onClose={() => {
                            router.navigate('');
                            setCustomSlots((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        onSave={(event: any) => {
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                getServiceProviderAppointments(pageNo);
                            }
                            setCustomSlots((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            removeEvent(event);
                        }}
                    />
                )}

                {/* for update workingHours */}
                {customSlots.show && router.query.workingHours && (
                    <CustomiseBusinessHoursDrawer
                        serviceProviderId={
                            providerId !== undefined && id !== undefined && id[providerId]?._id
                        }
                        id={router.query.workingHours as string}
                        startDate={customSlots.start}
                        endDate={customSlots.end}
                        onClose={() => {
                            router.navigate('');
                            setCustomSlots((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        onSave={(event: any) => {
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                getServiceProviderAppointments(pageNo);
                            }
                            setCustomSlots((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            removeEvent(event);
                        }}
                    />
                )}

                {newAppointment.show && id[providerId]?._id !== undefined && (
                    <NewAppointmentDrawer
                        serviceProviderId={
                            providerId !== undefined && id !== undefined && id[providerId]?._id
                        }
                        id={newAppointment.id}
                        conflictDate={val => {
                            setConflictDates(val);
                        }}
                        parent={'customise'}
                        is_vip={vipAppointment && serviceProvider[providerId]?.vip}
                        waitingList={waitingList}
                        conflictModel={(val: any) => {
                            setShowConflictModal(val);
                        }}
                        startDate={newAppointment.start}
                        endDate={newAppointment.end}
                        setBlockModel={() => {
                            setBlockModel(true);
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        isTeam={true}
                        onClose={(isAppointmentAdded: any, event: any) => {
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            router.setParams({ appointment: '' });
                            if (!isAppointmentAdded) {
                                //  removeEvent(event);
                            }
                        }}
                        onSave={(event: any, appointment: IAppointment) => {
                            router.navigate('');
                            //removeEvent(event);
                            getServiceProviderAppointments(pageNo);
                        }}
                        onChange={(event: IEvent) => {
                            // editEvent(event);
                        }}
                    />
                )}

                {newAppointment.show == true
                    ? ''
                    : router.query.appointment && (
                          <EditAppointmentDrawer
                              getAppointments={() => {
                                  router.navigate('');
                                  getServiceProviderAppointments(pageNo);
                              }}
                              id={router.query.appointment as string}
                              onClose={isUpdate => {
                                  router.navigate('');
                                  if (isUpdate) {
                                      getServiceProviderAppointments(pageNo);
                                  }
                              }}
                              onChange={(event: IAppointment) => {
                                  // editEvent(event);
                              }}
                          />
                      )}

                {confirmation.show && (
                    <ModifyAppointmentModal
                        id={confirmation.id}
                        onDragDate={preDay !== undefined ? preDay : moment().format()}
                        startDate={confirmation.start}
                        endDate={confirmation.end}
                        serviceProvider={true}
                        serviceProvierData={selectedServiceProvider}
                        is_blocked={confirmation?.is_blocked}
                        appointment={confirmation?.appointment}
                        onSave={() => {
                            setConfirmation(prevState => ({
                                ...prevState,
                                show: false,
                                is_blocked: false,
                                appointment: {},
                            }));
                            router.navigate('');
                            getServiceProviderAppointments(pageNo);
                        }}
                        onClose={() => {
                            confirmation.callback();
                            // getServiceProviderAppointments(pageNo);
                            getIndex();
                            setConfirmation(prevState => ({
                                ...prevState,
                                show: false,
                                is_blocked: false,
                                appointment: {},
                            }));
                        }}
                    />
                )}

                {blockModel && (
                    <BlockModel
                        startTime={newAppointment.start}
                        endTime={newAppointment.end}
                        serviceProviderId={
                            providerId !== undefined && id != undefined && id[providerId]._id
                        }
                        time_select={_userData?.user?.business_id?.time_select}
                        blockDate={blockDate}
                        onClose={is_added => {
                            router.navigate('');
                            if (is_added) {
                                getServiceProviderAppointments(pageNo);
                            }
                            setBlockModel(false);
                        }}
                    />
                )}

                {blockDeleteModel && (
                    <DeleteModel
                        id={''}
                        onDelete={() => {
                            setBlockDeleteModel(false);
                        }}
                        onClose={() => {
                            setBlockDeleteModel(false);
                        }}
                        name={t('Block')}
                        content={t('Are you sure you want to delete this Block?')}
                        loading={loading}
                        onClick={() => {
                            deleteBlock();
                            router.navigate('');
                            getServiceProviderAppointments(pageNo);
                        }}
                    />
                )}

                {showConflictModal == true && (
                    <ConflictModal
                        dates={conflictDates}
                        recurringConflict={showConflictModal}
                        onContinue={() => {
                            setShowConflictModal(false);
                        }}
                        onClose={() => setShowConflictModal(false)}
                    />
                )}
                {reminderModel && <ReminderModel onClose={() => setReminderModel(false)} />}
            </Wrapper>
        );
    }
);

const Wrapper = styled.div`
    flex-direction: column !important;
    flex: 1 1 0% !important;
    display: flex !important;
`;

const Flex = styled.div`
    flex: 1 1 0% !important;
`;

const CustomerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    ${media.xs`
    font-size: 0.4rem;
  `}
    ${media.sm`
    font-size: 0.7rem;
  `}
	${media.md`
    font-size: 0.7rem;
  `}
	${media.lg`
    font-size: 0.8rem;
  `}
`;

export default CustomisedTeamCalendar;
