/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Time Indicator Hook
 * Description: This hook provides functionality to display a time indicator in the calendar's time grid, showing the current time while hovering over the grid.
 *
 * Functions:
 * - getClosestChoice: (x: number, y: number, elements: any) => any - Finds the closest element based on coordinates (x, y) from a given set of elements.
 * - useTimeIndicator: () => [indicate: () => void] - Hook function that sets up and returns a function to display the time indicator while hovering over the time grid.
 *
 */

/**
 * Finds the closest element based on coordinates (x, y) from a given set of elements.
 * @param x - The x-coordinate.
 * @param y - The y-coordinate.
 * @param elements - The set of elements to find the closest one.
 * @returns The closest element.
 */

const getClosestChoice = (x: number, y: number, elements: any): any => {
    let closestEl;
    let minDist: number;
    let offset;

    elements.forEach((el: any) => {
        offset = { left: el.offsetLeft, top: el.offsetTop };
        offset.left += el.offsetWidth / 2;
        offset.top += el.offsetHeight / 2;
        const dist = Math.sqrt(
            (offset.left - x) * (offset.left - x) + (offset.top - y) * (offset.top - y)
        );
        if (!minDist || dist < minDist) {
            minDist = dist;
            closestEl = el;
        }
    });
    return closestEl;
};

const useTimeIndicator = () => {
    const indicate = () => {
        const timegridCols = document.querySelectorAll('.fc-timegrid-col.fc-day');
        timegridCols.forEach((col: any) => {
            if (!col.querySelector('.timegrid_hover')) {
                const d = document.createElement('div');
                d.className = 'timegrid_hover';
                col.appendChild(d);
            }
        });

        const grid = document.querySelector('.fc-timegrid-body');
        const timeGrids = document.querySelectorAll('.timegrid_hover');
        grid?.addEventListener('mousemove', (event: any) => {
            const bounds = grid.getBoundingClientRect();
            let x = event.clientX - bounds.left;
            let y = event.clientY - bounds.top;
            timeGrids.forEach((el: any) => {
                el.style.display = 'none';
            });
            const row = event.target;
            if (!row.dataset.time) return;
            let nearestCol = getClosestChoice(x, y, timegridCols);
            let timerGrid = nearestCol.querySelector('.timegrid_hover');
            timerGrid.innerHTML = row.dataset.time.substring(0, 5);
            timerGrid.style.top = row.offsetTop + 'px';
            timerGrid.style.display = 'flex';
        });
        grid?.addEventListener('mouseleave', () => {
            document.querySelectorAll<HTMLElement>('.timegrid_hover').forEach(el => {
                el.style.display = 'none';
            });
        });
    };

    return [indicate];
};

export default useTimeIndicator;
