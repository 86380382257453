/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: NewAppointmentDrawer
 * Description: This component represents the drawer for creating a new appointment. It includes the selection of a customer, service, appointment time, and additional details. Users can also customize the duration, mark as VIP, add notes, and choose recurring options.
 * The component uses React, styled-components for styling, and interacts with the backend through API calls.
 *
 * Props:
 * - id: string - Identifier for the new appointment drawer.
 * - startDate: any - Start date of the appointment.
 * - endDate: any - End date of the appointment.
 * - onChange: (event: any) => void - Callback function triggered when the date or time is changed for the appointment.
 * - onClose: (isAppointmentAdded: any, event: any) => void - Callback function triggered when the drawer is closed. Receives parameters indicating whether an appointment was added and the triggering event.
 * - onSave: (event: any, appointment: IAppointment) => void - Callback function triggered when the "Save" button is clicked. Receives the triggering event and the appointment details.
 * - serviceProviderId: any - Identifier for the service provider associated with the appointment.
 * - setBlockModel: any - Function to set the block model if the user wants to create a block.
 * - conflictDate: (val: any) => void - Callback function triggered when a conflict in date is detected. Receives the conflicting date value.
 * - conflictModel: (val: any) => void - Callback function triggered when a conflict in the model is detected. Receives the conflicting model value.
 * - parent: any - Parameter representing the parent component.
 * - is_vip: any - Parameter indicating whether the appointment is for a VIP customer.
 * - waitingList: any - Parameter representing the waiting list appointment information.
 * - isTeam: any - Parameter indicating whether the appointment involves a team.
 *
 */

import { Drawer, Input, LoaderOverlay, Select, Switch, useSnackbar } from '@components/common';
import { useEffect, useState } from 'react';
import SelectClient from './SelectClient';
import SelectService from './SelectService';
import { ICustomer } from 'interfaces/customer.interface';
import moment from 'moment';
import { appointmentActions } from 'actions/appointment';
import { useAppDispatch } from 'hooks/redux';
import { IAppointment } from 'interfaces/appointment.interface';
import NewRecurringModal from '../modal/NewRecurringModal';
import { IRecurring } from 'interfaces/recurring.interface';
import ConflictModal from '../modal/ConflictModal';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import { api } from 'helpers/auth-axios';
import logo from '../../../images/userDefault.png';
import CustomerModal from '../modal/CustomerModal';
import { useTranslation } from 'react-i18next';
import AppointmentInvoice from './AppointmentInvoice';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import CompletePayment from '../editAppointmentDrawer/CompletePayment';
import { palette } from 'styled/common';
const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};

const NewAppointmentDrawer = ({
    id,
    startDate,
    endDate,
    onChange,
    onClose,
    onSave,
    serviceProviderId,
    setBlockModel,
    conflictDate,
    conflictModel,
    parent,
    is_vip,
    waitingList,
    isTeam,
}: {
    id: string;
    startDate: any;
    endDate: any;
    onChange(event: any): void;
    onClose(isAppointmentAdded: any, event: any): void;
    onSave(event: any, appointment: IAppointment): void;
    serviceProviderId: any;
    setBlockModel: any;
    conflictDate(val: any): void;
    conflictModel(val: any): void;
    parent?: any;
    is_vip?: any;
    waitingList?: any;
    isTeam?: any;
}) => {
    useEffect(() => {
        setStart(startDate);
        setEnd(endDate);
        updateEvent(event);
    }, [startDate, endDate]);
    const { t }: any = useTranslation();
    const [isAppointmentAdded, setIsAppointmentAdded] = useState(false);
    const [active, setActive] = useState(false);
    const [customerView, setCustomerView] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();
    const [serviceView, setServiceView] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [appointmentNote, setAppointmentNote] = useState<string>();
    const [customerNote, setCustomerNote] = useState<string>();
    const [start, setStart] = useState(formatDate(startDate));
    const [end, setEnd] = useState(formatDate(endDate));
    const [overbooking, setOverbooking] = useState(false);
    const [recurring, setRecurring] = useState<IRecurring>();
    const [showRecurringModal, setShowRecurringModal] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [durationField, setDurationFieldOpen] = useState<any>(false);
    const [vipAppointment, setVipAppointment] = useState<any>(is_vip);
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [durationHours, setDurationHours] = useState<any>(0);
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>(0);
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();
    const [chargeModel, setChargeModel] = useState<boolean>(false);
    const [customerModel, setCustomerModel] = useState(false);
    const [customerEditModel, setCustomerEditModel] = useState(false);
    const [appointmentInvoiceModel, setAppointmentInvoiceModel] = useState(false);
    const [is_video, setIs_video] = useState(false);
    const [appointmentSlots, setAppointmentSlots] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();
    const event = {
        id,
        start,
        end,
        customer: selectedCustomer,
        service: selectedService,
        serviceProvider: selectedServiceProvider,
    };

    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const appointmentTime: { label: string; value: any }[] = [];
    let time = moment().format('HH:mm');
    let date = moment(startdate ? startdate : start).format('YYYY-MM-DD');
    let todayDate = moment().format('YYYY-MM-DD');

    useEffect(() => {
        getServiceProvider();
        if (_userData?.user_login === 'customer') {
            getCustomer();
        }
    }, []);

    useEffect(() => {
        getAvailableSlot();
    }, [startdate, defaultDuration, customDuration, start, selectedService, vipAppointment]);

    useEffect(() => {
        setTimeout(() => setActive(true), 20);
    }, []);

    useEffect(() => {
        if (overbooking) onSubmit();
    }, [overbooking]);

    useEffect(() => {
        dropDownValue();
    }, [startdate, defaultDuration, customDuration, start, selectedService]);

    if (is_vip === true || waitingList === true || vipAppointment) {
        let vipTime: any[] = [];
        let duration =
            durationField === false
                ? defaultDuration == 0
                    ? '10'
                    : defaultDuration
                : customDuration;
        for (let i: any = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                vipTime.push(moment({ hour: i, minute: j * 15 }).format('HH:mm'));
            }
        }
        let tmpTime: any[] = [];
        vipTime.map((res: any, index: any) => {
            let customTime = moment(res, 'HH:mm').add(5, 'minute').format('HH:mm');
            let tmpTime1 = moment(res, 'HH:mm').add(duration, 'minute').format('HH:mm');
            let format = 'HH:mm';
            if (appointmentSlots?.includes(res) || appointmentSlots?.includes(customTime)) {
                for (let i = 0; i < appointmentSlots?.length; i++) {
                    let mTime = moment(appointmentSlots[i], format),
                        beforeTime = moment(res, format),
                        afterTime = moment(tmpTime1, format);
                    if (
                        mTime.isBetween(beforeTime, afterTime) &&
                        mTime.isBetween(beforeTime, afterTime)
                    ) {
                        if (tmpTime[index - 1] != -1) {
                            tmpTime.splice(index - 1, 1);
                        }
                    }
                }
            } else {
                tmpTime.push(res);
            }
        });

        tmpTime?.forEach((item: any) => {
            if (date === todayDate) {
                item >= time &&
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
            } else {
                appointmentTime.push({
                    label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        'HH:mm'
                    ),
                });
            }
        });
    } else {
        availableSlot?.length &&
            availableSlot?.forEach((item: any) => {
                if (date === todayDate) {
                    item >= time &&
                        appointmentTime.push({
                            label: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format(
                                _userData?.user?.business_id.time_select === '12'
                                    ? 'hh:mm a'
                                    : 'HH:mm'
                            ),
                            value: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format('HH:mm'),
                        });
                } else {
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
                }
            });
    }

    const disabledBtn = !selectedService || !selectedCustomer || !selectedServiceProvider;
    const updateEvent = (data: any) => {
        onChange({
            ...event,
            ...data,
        });
    };

    const startTime =
        moment(startdate ? startdate : start).format('YYYY-MM-DD') +
        'T' +
        moment(start).format('HH:mm') +
        ':00';
    const endTime =
        moment(enddate ? enddate : end).format('YYYY-MM-DD') +
        'T' +
        moment(start)
            .add(durationField === false ? defaultDuration : customDuration, 'minutes')
            .format('HH:mm') +
        ':00';

    const newAppointment = {
        booked_from_iso: startTime ? moment(startTime).format() : moment(start).format(),
        booked_till_iso: endTime ? moment(endTime).format() : moment(end).format(),
        booked_from: startTime ? moment(startTime).format() : moment(start).format(),
        booked_till: endTime ? moment(endTime).format() : moment(end).format(),
        appointment_note: appointmentNote ? appointmentNote : '',
        customer: selectedCustomer && selectedCustomer?._id,
        service: selectedService && selectedService!._id,
        serviceProvider: selectedServiceProvider && selectedServiceProvider?._id,
        total:
            selectedService && vipAppointment == true
                ? _userData.user.business_id.currency + selectedService?.vip_price
                : _userData.user.business_id.currency + selectedService?.my_price,
        total_value: parseInt(
            selectedService
                ? vipAppointment == true
                    ? selectedService!.vip_price
                    : selectedService!.my_price
                : 0
        ),
        customer_note: customerNote ? customerNote : '',
        recurring: recurring ? recurring : '',
        overbooking: overbooking,
        duration: durationField === false ? defaultDuration : customDuration,
        is_vip: vipAppointment,
        payment_method_id: '',
        payment_intent_id: '',
        is_video_appointment: is_video,
        is_waiting: waitingList,
        calendar: parent === 'customise' ? 'customise' : null,
    };

    const onSubmit = () => {
        setLoading(true);

        if (appointmentTime?.length) {
            dispatch(appointmentActions.createAppointment(newAppointment))
                .then((res: any) => {
                    setLoading(false);
                    if (res.error) {
                        return;
                    }
                    onSave(event, res as IAppointment);
                    setActive(false);
                    if (res?.conflicts?.length) {
                        conflictModel(true);
                        conflictDate(res?.conflicts);
                    }
                    setIsAppointmentAdded(true);
                    openSnackbar(t('Appointment added successfully'));
                })
                .catch(e => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data?.response;
                        openSnackbar(response?.message);
                        if (response?.dates) {
                            setConflictDates(response?.dates);
                            setShowConflictModal(true);
                            setIsAppointmentAdded(false);
                        }
                    }
                });
        } else {
            setLoading(false);
            openSnackbar(t('No slot available at this time!'));
        }
    };

    const getServiceProvider = () => {
        api.get(`/serviceProvider/${serviceProviderId}`).then((res: any) => {
            if (res.data !== 0) {
                setSelectedServiceProvider(res.data);
            }
        });
    };

    const getCustomer = () => {
        api.get(`/customers/${_userData?.user?._id}`).then((res: any) => {
            if (res.data !== 0) {
                setSelectedCustomer(res.data.customer);
            }
        });
    };

    const getAvailableSlot = () => {
        if (serviceProviderId !== undefined) {
            let date: any = moment(startdate ? startdate : start).format();
            let duration =
                durationField === false
                    ? defaultDuration == 0
                        ? '10'
                        : defaultDuration
                    : customDuration;
            api.post(`appointments/available_slots/${serviceProviderId}`, {
                date: date,
                duration: duration,
                calendar: parent === 'customise' ? 'customise' : undefined,
            }).then((res: any) => {
                setAvailableSlot(res.data?.slots);
                let unique = [...new Set(res.data?.appointmentSlots)];
                setAppointmentSlots(unique.sort());
            });
        }
    };

    const calendarDate = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const dropDownValue = () => {
        appointmentTime.forEach((val: any, i: any) => {
            const value = moment(start).format('HH:mm');
            if (val.value === value) {
                return setDropdownFilter(val.value);
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    const paymentButtons =
        (_userData.user.business_id?.booking_without_payment === false &&
            selectedServiceProvider?.stripe_account_id !== null &&
            selectedServiceProvider?.stripe_account_verified === true) ||
        (_userData.user.business_id?.booking_without_payment === false &&
            _userData.user.business_id?.stripe_account_verified === true);
    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Drawer
                            custom={true}
                            left={false}
                            active={active}
                            onClose={() => {
                                if (!loading) {
                                    onClose(isAppointmentAdded, event);
                                }
                            }}>
                            {!customerView &&
                                !serviceView &&
                                !chargeModel &&
                                !appointmentInvoiceModel && (
                                    <AppointmentContent>
                                        <HeaderContainer>
                                            <Header>{t('New Appointment')}</Header>
                                            <CloseButton
                                                onClick={() => {
                                                    if (!loading) {
                                                        setActive(false);
                                                    }
                                                }}>
                                                <Icon className="fal fa-times"></Icon>
                                            </CloseButton>
                                        </HeaderContainer>
                                        <DrawerBody>
                                            {selectedServiceProvider && (
                                                <div>
                                                    <ServiceProviderContent>
                                                        <Circle>
                                                            <NameCircle
                                                                src={`${process.env.REACT_APP_PROFILE_URL}${selectedServiceProvider.photo}`}></NameCircle>
                                                        </Circle>
                                                        <CustomerContent>
                                                            <Name>
                                                                {selectedServiceProvider.name}
                                                            </Name>
                                                            <Email>
                                                                {selectedServiceProvider.email}
                                                            </Email>
                                                        </CustomerContent>
                                                    </ServiceProviderContent>
                                                </div>
                                            )}
                                            {selectedService ? (
                                                <SelectServiceContent
                                                    onClick={() => {
                                                        !loading && setServiceView(true);
                                                    }}>
                                                    <ServiceBorder
                                                        className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                    <ServiceDetails>
                                                        <NameBody>
                                                            <ServiceName>
                                                                {selectedService.name}
                                                            </ServiceName>
                                                            <ServiceDuration>
                                                                {selectedService.my_duration}
                                                                min
                                                            </ServiceDuration>
                                                        </NameBody>
                                                        <PriceBody>
                                                            <ServicePrice>
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {vipAppointment == true
                                                                    ? selectedService.vip_price
                                                                    : selectedService.my_price}
                                                            </ServicePrice>
                                                            <div>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </div>
                                                        </PriceBody>
                                                    </ServiceDetails>
                                                </SelectServiceContent>
                                            ) : (
                                                <SelectServiceBody
                                                    onClick={() => {
                                                        setServiceView(true);
                                                    }}>
                                                    <ServiceHeader>
                                                        {t('Select service')}
                                                    </ServiceHeader>
                                                    <div>
                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                    </div>
                                                </SelectServiceBody>
                                            )}
                                            {selectedService?.is_video && (
                                                <CustomDiv style={{ marginTop: '0.3rem' }}>
                                                    <CustomSpan>
                                                        {t(
                                                            'Are you sure you want video appointment?'
                                                        )}
                                                    </CustomSpan>
                                                    <Switch
                                                        value={is_video}
                                                        disabled={loading}
                                                        onChange={() => {
                                                            setIs_video(!is_video);
                                                        }}
                                                        isSmall={true}
                                                    />
                                                </CustomDiv>
                                            )}
                                            {selectedCustomer ? (
                                                <div>
                                                    <SelectCustomer>
                                                        <div
                                                            style={{ display: 'flex' }}
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    !loading &&
                                                                    setCustomerView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={`${process.env.REACT_APP_PROFILE_URL}${selectedCustomer.photo}`}></NameCircle>
                                                            </Circle>
                                                            <CustomerContent>
                                                                <Name>
                                                                    {selectedCustomer.firstname +
                                                                        ' ' +
                                                                        selectedCustomer.lastname}
                                                                </Name>
                                                                <Email>
                                                                    {selectedCustomer.phone}
                                                                </Email>
                                                                <Email>
                                                                    {selectedCustomer.email}
                                                                </Email>
                                                            </CustomerContent>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                            <Icons
                                                                className="fal fa-edit"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerEditModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-plus"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-chevron-right"
                                                                onClick={() => {
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                        setCustomerView(true);
                                                                }}></Icons>
                                                        </div>
                                                    </SelectCustomer>
                                                </div>
                                            ) : (
                                                <div>
                                                    <AppointmentCustomer>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    !loading &&
                                                                    setCustomerView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={logo}
                                                                    alt="logo"></NameCircle>
                                                            </Circle>
                                                            <Label>{t('Select a client')}</Label>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                            <Icons
                                                                className="fal fa-plus"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    !loading &&
                                                                    setCustomerModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-chevron-right"
                                                                onClick={() => {
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                        !loading &&
                                                                        setCustomerView(true);
                                                                }}></Icons>
                                                        </div>
                                                    </AppointmentCustomer>
                                                </div>
                                            )}
                                            <FormContent>
                                                <FormDetails>
                                                    <DatesBody>
                                                        <FormDate>
                                                            <CalenderDate>
                                                                {startdate
                                                                    ? moment(startdate).format(
                                                                          'ddd, MMM DD'
                                                                      )
                                                                    : moment(startDate).format(
                                                                          'ddd, MMM DD'
                                                                      )}
                                                            </CalenderDate>
                                                            <input
                                                                type="date"
                                                                min={
                                                                    new Date()
                                                                        .toISOString()
                                                                        .split('T')[0]
                                                                }
                                                                value={moment(startdate).format(
                                                                    'YYYY-MM-DD'
                                                                )}
                                                                onChange={(e: any) =>
                                                                    calendarDate(e)
                                                                }
                                                            />
                                                        </FormDate>
                                                        {_userData.user_login !== 'customer' && (
                                                            <RepeateButton
                                                                className={`${
                                                                    recurring
                                                                        ? 'border-success bg-success text-white'
                                                                        : ''
                                                                }`}
                                                                onClick={() =>
                                                                    !loading &&
                                                                    setShowRecurringModal(true)
                                                                }>
                                                                {recurring
                                                                    ? t('Recurring')
                                                                    : t('Repeat')}{' '}
                                                                <RepeateIcon className="fas fa-repeat"></RepeateIcon>
                                                            </RepeateButton>
                                                        )}
                                                    </DatesBody>
                                                    <InputGroup>
                                                        <InputContent>
                                                            <Col lg={12}>
                                                                <Select
                                                                    label={t('Start')}
                                                                    disabled={true}
                                                                    readOnly={loading}
                                                                    value={
                                                                        appointmentTime?.length
                                                                            ? moment(
                                                                                  moment().format() +
                                                                                      ' ' +
                                                                                      dropDownFilter,
                                                                                  'YYYY-MM-DD HH:mm:ss'
                                                                              ).format(
                                                                                  _userData?.user
                                                                                      ?.business_id
                                                                                      .time_select ===
                                                                                      '12'
                                                                                      ? 'hh:mm a'
                                                                                      : 'HH:mm'
                                                                              )
                                                                            : t('No slot available')
                                                                    }
                                                                    options={appointmentTime}
                                                                    onChange={(val: string) => {
                                                                        const newStart =
                                                                            moment(start).format(
                                                                                'YYYY-MM-DD'
                                                                            ) +
                                                                            'T' +
                                                                            val +
                                                                            ':00';

                                                                        setStart(newStart);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </InputContent>
                                                        {_userData.user_login !== 'customer' && (
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <InputGroup>
                                                                        <Select
                                                                            label={t('Hour(s)')}
                                                                            disabled={true}
                                                                            readOnly={
                                                                                !durationField
                                                                            }
                                                                            options={DurationHours}
                                                                            value={
                                                                                durationField ===
                                                                                false
                                                                                    ? serviceDurationHours ===
                                                                                      ''
                                                                                        ? '0h'
                                                                                        : serviceDurationHours +
                                                                                          'h'
                                                                                    : durationHours ===
                                                                                      ''
                                                                                    ? '0h'
                                                                                    : durationHours +
                                                                                      'h'
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                setDurationHours(
                                                                                    parseInt(val)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <InputGroup>
                                                                        <Select
                                                                            label={t('Minutes')}
                                                                            disabled={true}
                                                                            readOnly={
                                                                                !durationField
                                                                            }
                                                                            options={
                                                                                DurationMinutes
                                                                            }
                                                                            value={
                                                                                durationField ===
                                                                                false
                                                                                    ? serviceDurationMinutes +
                                                                                      t('min')
                                                                                    : durationMinutes +
                                                                                      t('min')
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                setDurationMinutes(
                                                                                    parseInt(val)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <ToggleButton>
                                                            {_userData.user_login !==
                                                                'customer' && (
                                                                <div>
                                                                    <CustomSpan>
                                                                        {t('Custom Duration')}
                                                                    </CustomSpan>
                                                                    <Switch
                                                                        value={is_video}
                                                                        onChange={() => {
                                                                            setIs_video(!is_video);
                                                                        }}
                                                                        isSmall={true}
                                                                    />
                                                                </div>
                                                            )}
                                                        </ToggleButton>
                                                    </InputGroup>
                                                    {_userData.user_login !== 'customer' && (
                                                        <InputGroup>
                                                            <Input
                                                                label={t(
                                                                    'Appointment note (only visible to staff)'
                                                                )}
                                                                value={appointmentNote}
                                                                onChange={({
                                                                    value,
                                                                }: {
                                                                    value: string;
                                                                }) => {
                                                                    setAppointmentNote(value);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    )}
                                                    <InputGroup>
                                                        <Input
                                                            label={`${
                                                                _userData.user_login !== 'customer'
                                                                    ? t('Message from client')
                                                                    : t(
                                                                          'Message to Service Provider'
                                                                      )
                                                            }`}
                                                            value={customerNote}
                                                            readOnly={true}
                                                            onChange={({
                                                                value,
                                                            }: {
                                                                value: string;
                                                            }) => {
                                                                setCustomerNote(value);
                                                            }}
                                                        />
                                                    </InputGroup>
                                                    <TotalPriceDiv
                                                        style={{
                                                            justifyContent: `${
                                                                _userData.user_login ===
                                                                    'customer' && 'flex-end'
                                                            }`,
                                                        }}>
                                                        {_userData.user_login !== 'customer' &&
                                                        is_vip === false ? (
                                                            <TotalPriceBody>
                                                                <DeleteButton
                                                                    onClick={setBlockModel}
                                                                    disabled={loading}>
                                                                    {' '}
                                                                    {t('Add Block')}
                                                                </DeleteButton>
                                                            </TotalPriceBody>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <TotalPriceBody>
                                                            <PriceHeader>{t('Total')}</PriceHeader>
                                                            <TotalPrice>
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {vipAppointment == true
                                                                    ? selectedService?.vip_price
                                                                    : selectedService?.my_price}
                                                            </TotalPrice>
                                                        </TotalPriceBody>
                                                    </TotalPriceDiv>

                                                    <FormFooter>
                                                        <FormButtons>
                                                            {disabledBtn
                                                                ? ''
                                                                : paymentButtons === true && (
                                                                      <Button
                                                                          bgtype={'discard'}
                                                                          ifClicked={() => {
                                                                              setChargeModel(true);
                                                                          }}
                                                                          label={t(
                                                                              'Charge & Save'
                                                                          )}></Button>
                                                                  )}
                                                            <Button
                                                                bgtype={'discard'}
                                                                ifClicked={() =>
                                                                    !loading && setActive(false)
                                                                }
                                                                width="10rem !important"
                                                                label={t('Cancel')}></Button>
                                                            <Button
                                                                bgtype={'secondary'}
                                                                disabled={disabledBtn}
                                                                ifClicked={onSubmit}
                                                                width="10rem !important"
                                                                label={t('Add')}></Button>
                                                        </FormButtons>
                                                    </FormFooter>
                                                </FormDetails>
                                            </FormContent>
                                        </DrawerBody>
                                    </AppointmentContent>
                                )}

                            {appointmentInvoiceModel && (
                                <AppointmentInvoice
                                    onClose={() => {
                                        setAppointmentInvoiceModel(false);
                                        setActive(false);
                                    }}
                                    appointmentData={{
                                        appointment: {
                                            ...newAppointment,
                                            service: selectedService,
                                            customer: selectedCustomer,
                                            serviceProvider: selectedServiceProvider,
                                        },
                                    }}
                                />
                            )}

                            {customerView && (
                                <SelectClient
                                    onClose={() => {
                                        setCustomerView(false);
                                    }}
                                    onSelect={(customer: ICustomer) => {
                                        setSelectedCustomer(customer);
                                        updateEvent({ customer });
                                    }}
                                />
                            )}

                            {serviceView && (
                                <SelectService
                                    id={serviceProviderId}
                                    onClose={() => {
                                        setServiceView(false);
                                    }}
                                    selectedService={selectedService}
                                    onSelect={(service: any) => {
                                        const newEnd = formatDate(
                                            moment(start).add(service.duration, 'm').toDate()
                                        );
                                        setIs_video(service?.is_video);
                                        const data = {
                                            price: service.price,
                                            _id: service.service_id,
                                            my_price: service.my_price,
                                            my_duration: service.my_duration,
                                            duration: service.duration,
                                            name: service.name,
                                            color: service.color,
                                            available_for_boooking: service.available_for_boooking,
                                            vip_price: service?.vip_price,
                                            is_video: service?.is_video,
                                        };
                                        setSelectedService(data);
                                        setEnd(newEnd);
                                        setServiceDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationMinutes(service.my_duration % 60);
                                        setServiceDurationMinutes(service.my_duration % 60);
                                        updateEvent({ service, end: newEnd });
                                        getAvailableSlot();
                                    }}
                                />
                            )}

                            {showRecurringModal && (
                                <NewRecurringModal
                                    start={moment(start)}
                                    end={moment(end)}
                                    onSave={(recurring: any) => {
                                        setRecurring(recurring);
                                        setShowRecurringModal(false);
                                    }}
                                    recurring={recurring}
                                    onClose={() => {
                                        setShowRecurringModal(false);
                                    }}
                                />
                            )}

                            {showConflictModal == true && (
                                <ConflictModal
                                    dates={conflictDates}
                                    onContinue={() => {
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => setShowConflictModal(false)}
                                />
                            )}

                            {chargeModel === true && (
                                <CompletePayment
                                    newAppointmentModel={true}
                                    stripeId={selectedCustomer?.stripe_id}
                                    onClose={() => setChargeModel(false)}
                                    onSubmit={() => {
                                        if (_userData?.user?.business_id?.invoice == true) {
                                            setAppointmentInvoiceModel(true);
                                        } else {
                                            setActive(false);
                                        }
                                    }}
                                    selectedService={selectedService}
                                    tipValue={newAppointment?.total_value}
                                    platForm={'web'}
                                    selectedProfessional={selectedServiceProvider}
                                    customerDetails={selectedCustomer}
                                    calendarDate={newAppointment?.booked_from_iso}
                                    appointment={newAppointment}
                                />
                            )}
                        </Drawer>
                    </Col>
                </Row>

                {(customerEditModel === true || customerModel) && (
                    <CustomerModal
                        onSave={(data: any) => {
                            setSelectedCustomer(data);
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                        onEdit={selectedCustomer}
                        editModel={customerEditModel}
                        title={
                            customerEditModel === true ? t('Edit Customer') : t('Add New Customer')
                        }
                        onClose={() => {
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                    />
                )}
            </Container>
        </>
    );
};

const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
`;
const CloseButton = styled.div`
    /* margin-right: 1.5rem; */
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.7rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    overflow-y: auto;
`;
const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;
const SelectCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 0.2rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;
const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-bottom: 0.5rem;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 2rem;
    height: calc(100% - 84px);
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const CalenderDate = styled.div`
    cursor: pointer;
`;
const RepeateButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    background: ${palette.dark};
    border-radius: 0.57rem;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    background-color: rgba(255, 255, 255);
    color: rgba(38, 52, 70);
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;
const RepeateIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
`;

const SelectServiceContent = styled.div`
    border: 1px solid rgba(223, 226, 230);
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    width: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
`;
const ServiceHeader = styled.div`
    color: rgba(125, 128, 131);
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const ToggleButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;

const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;
const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;
const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: rgba(157, 160, 164);
`;
const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;
const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;
const SelectServiceBody = styled.div`
    border: 1px solid rgba(223, 226, 230);
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const Icons = styled.i`
    font-size: 1rem /* 18px */;
    line-height: 1.5rem !important /* 28px */;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: #7e8299;
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const TotalPriceDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TotalPriceBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
`;
const PriceHeader = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: rgba(188, 192, 197);
    text-align: right;
`;
const TotalPrice = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: right;
`;
export const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: ${palette.white};
`;

export const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem /* 24px */;
    justify-content: space-between;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;
const Name = styled.div`
    font-weight: 500;
`;
const Email = styled.div`
    color: rgba(157, 160, 164);
    font-size: 0.9rem;
`;
const DeleteButton = styled.button`
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    padding: 0.7rem 0.8rem;
    color: ${palette.white};
    background-color: rgb(172, 172, 172);
    box-shadow: none;
    margin: undefined;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    -webkit-transition: all ease 0.25s;
    transition: all ease 0.25s;
    ${media.xs`
	padding: 0.7rem 0.5rem;
    font-size: 0.7rem;
	`}
    ${media.sm`
    padding: 0.7rem 0.8rem;
	`}
    ${media.md`
    padding: 0.7rem 0.8rem;
	`}
	${media.lg`
    padding: 0.7rem 0.8rem;
	`}
`;

export default NewAppointmentDrawer;
