/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: ServiceAssets
 * Description: This component represents the modal for managing service assets. It allows the user
 * to view, edit, and update the consumed assets associated with a service. The component integrates
 * with various UI elements, such as input fields and buttons, to facilitate asset management.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the service assets component is closed.
 * - id: string - Identifier for the service.
 * - selectedService: any - The selected service details.
 *
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useTranslation } from 'react-i18next';
import { api } from 'helpers/auth-axios';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import { InputGroup } from '@components/settings/bookingSettings/BookingSettings';
import { AddAssets } from '@components/settings/businessServices/EditService';
import { palette } from 'styled/common';

const ServiceAssets = ({
    onClose,
    selectedService,
    id,
}: {
    onClose(): void;
    id: string;
    selectedService: any;
}) => {
    const { t }: any = useTranslation();
    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState<any>([]);
    const [addedAssets, setAddedAssets] = useState<any>([]);
    const [editingAssetIndex, setEditingAssetIndex] = useState<any>(null);
    const [editAssetQuantity, setEditAssetQuantity] = useState<any>(0);
    const [editAssetName, setEditAssetName] = useState<any>();
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        getBusinessAssets();
        if (selectedService) setAddedAssets(selectedService?.assets);
    }, [selectedService]);

    const handleEditAsset = (index: number) => {
        setEditingAssetIndex(index);
        setEditAssetName(addedAssets[index]?.name);
        setEditAssetQuantity(addedAssets[index]?.quantity);
    };

    const handleUpdateAsset = async (index: number) => {
        const updatedAssets = [...addedAssets];
        updatedAssets[index] = {
            name: editAssetName,
            quantity: editAssetQuantity,
        };
        setAddedAssets(updatedAssets);

        // Reset the editing state
        setEditingAssetIndex(null);
        setEditAssetName('');
        setEditAssetQuantity('');

        let assetsData = editAssetName ? assets?.find((e: any) => e?.label == editAssetName) : '';
        if (assetsData?.value?._id) {
            await onSubmit({ quantity: editAssetQuantity }, assetsData?.value?._id);
        }
    };

    const handleCancelEdit = () => {
        setEditingAssetIndex(null);
        setEditAssetName('');
        setEditAssetQuantity('');
    };

    const getBusinessAssets = async () => {
        try {
            setLoading(true);
            await api
                .get('/assets-service')
                .then((res: any) => {
                    setLoading(false);
                    if (res.data?.length) {
                        let data = res.data?.map((e: any) => ({
                            label: `${e?.name}`,
                            value: e,
                        }));
                        setAssets(data);
                    }
                })
                .catch(e => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const onSubmit = (data: any, id?: any) => {
        const serviceData = {
            ...data,
        };
        setLoading(true);
        let url = api.put(`/assets-service/${id}`, serviceData);
        url.then(() => {
            setLoading(false);
            onClose();
            openSnackbar(t('Asset updated successfully!'));
        }).catch(e => {
            setLoading(false);
            if (e?.response) {
                const response = e?.response?.data;
                if (response) {
                    openSnackbar(response?.message);
                }
            }
        });
    };

    return (
        <>
            <Container>
                {loading && <LoaderOverlay />}
                <Row>
                    <ServicesContent>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-chevron-left"></Icon>
                            </CloseButton>
                            <Header>{t('Service Assets')}</Header>
                        </HeaderContainer>
                        {addedAssets?.length
                            ? addedAssets?.map((e: any, index: number) => (
                                  <div
                                      key={index}
                                      style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          border: `1px solid ${palette.grey.grey_20}`,
                                          padding: '10px',
                                          marginBottom: '10px',
                                          borderRadius: '0.325rem',
                                      }}>
                                      {editingAssetIndex === index ? (
                                          <Container>
                                              <Row alignItems="center">
                                                  <Col lg={6}>
                                                      <InputGroup
                                                          style={{
                                                              gap: '0.3rem',
                                                          }}>
                                                          <Input
                                                              label={t('Assets Name')}
                                                              name="assets"
                                                              disabled={true}
                                                              value={editAssetName}
                                                              readOnly={true}
                                                          />
                                                      </InputGroup>
                                                  </Col>
                                                  <Col lg={6}>
                                                      <InputGroup>
                                                          <Input
                                                              name={'quantity'}
                                                              label={`${t('Quantity')}`}
                                                              allowOnlyNumbersAndDecimal={true}
                                                              onChange={(e: any) => {
                                                                  setEditAssetQuantity(e?.value);
                                                              }}
                                                              value={editAssetQuantity}
                                                          />
                                                      </InputGroup>
                                                  </Col>
                                              </Row>{' '}
                                              <div>
                                                  {t('Update consumed assets')}
                                                  <Col
                                                      lg={2}
                                                      style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          gap: '0.2rem',
                                                      }}>
                                                      {' '}
                                                      <AddAssets
                                                          style={{
                                                              padding: '0.2rem 0.85rem',
                                                          }}
                                                          onClick={() => {
                                                              if (
                                                                  editAssetName &&
                                                                  editAssetQuantity
                                                              )
                                                                  handleUpdateAsset(index);
                                                          }}>
                                                          Update
                                                      </AddAssets>
                                                      <AddAssets
                                                          style={{
                                                              padding: '0.2rem 0.85rem',
                                                          }}
                                                          onClick={() => handleCancelEdit()}>
                                                          Cancel
                                                      </AddAssets>
                                                  </Col>
                                              </div>
                                          </Container>
                                      ) : (
                                          <>
                                              <div>
                                                  <span
                                                      style={{
                                                          fontWeight: 'bold',
                                                      }}>
                                                      Name:
                                                  </span>{' '}
                                                  {e?.name}
                                                  <br />
                                                  <span
                                                      style={{
                                                          fontWeight: 'bold',
                                                      }}>
                                                      Quantity:
                                                  </span>{' '}
                                                  {e?.quantity}
                                              </div>
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      gap: '0.5rem',
                                                  }}>
                                                  <div
                                                      style={{
                                                          cursor: 'pointer',
                                                      }}
                                                      onClick={() => handleEditAsset(index)}>
                                                      <i className="fal fa-edit"></i>{' '}
                                                  </div>
                                              </div>
                                          </>
                                      )}
                                  </div>
                              ))
                            : ''}
                    </ServicesContent>
                </Row>
            </Container>
        </>
    );
};

const ServicesContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;

const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    line-height: 2.5rem;
`;

const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

export default ServiceAssets;
