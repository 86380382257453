/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DropdownItem Component
 * Description: Represents an item within a dropdown menu.
 * Purpose:
 * - Renders a clickable item with optional onClick handler
 * - Styled as a part of a dropdown menu with the class 'dropdown--item'
 * - Accepts children to render content within the item
 * - Suitable for use in dropdown menus and similar components
 *
 * Props:
 * - onClick: () => void - Optional onClick handler for the item.
 * - children: React.ReactNode - The content to be rendered within the item.
 *
 */
const DropdownItem = ({ onClick, children }: { onClick?(): void; children?: React.ReactNode }) => {
    return (
        <div className="dropdown--item" onClick={onClick}>
            {children}
        </div>
    );
};

export default DropdownItem;
