/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Customers Component
 * Description: This component represents the main entry point for customer-related features.
 * It includes the layout, navigation, and rendering of customer-specific options through the 'CustomersList' and 'CustomerCard' components.
 * The layout is provided by the 'Layout' component from '@components/common'.
 * When the user is logged in as a customer, it redirects to the customer's specific page.
 *
 */

import React, { useEffect, useRef } from 'react';
import { CustomerCard, CustomersList, Layout } from '@components/customers';
import { Routes, Route, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';
interface IProps {
    getCustomers: () => void;
}
const Customers = () => {
    const navigate = useNavigate();
    const _useRef = useRef<IProps>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        if (_userData.user_login == 'customer') {
            navigate(`/customers/${_userData.user._id}`);
        }
    }, []);

    const callCustomer = () => {
        if (_useRef?.current) {
            _useRef?.current?.getCustomers();
        }
    };

    return (
        <Wrapper>
            {_userData.user_login == 'customer' ? '' : <CustomersList ref={_useRef} />}
            <Routes>
                <Route path=":id" element={<CustomerCard callCustomer={callCustomer} />} />
            </Routes>
            <style>
                {`
                .customer-listing--main {
                    height: 100%;
                }
            `}
            </style>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0px;
    height: 100%;
    ${media.xs`
  display: block !important;
`}
    ${media.sm`
display: block !important;
`}
${media.md`
display: flex !important;
margin:0;
min-width:100%;
`}
${media.lg`
display: flex !important;
`}
`;

const Content = styled.div`
    flex: 1 1 0%;
`;

Customers.Layout = Layout;
export default Customers;
