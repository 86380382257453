/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Checkout Component
 * Description: React component for handling the checkout process. Includes steps for selecting services/asset, payment methods, and completing the transaction.
 *
 * Components:
 * - BasketSidebar: Component for displaying the basket sidebar during the checkout process.
 * - SelectServices: Component for selecting assets during the checkout process.
 * - PaymentMethods: Component for selecting payment methods during the checkout process.
 * - LoaderOverlay: Component for displaying a loading overlay.
 * - NewCreditCard: Component for adding a new credit card for Stripe payments.
 * - CreateAssetService: Component for creating and managing assets related to services.
 *
 * Purpose: This component serves the purpose of managing the checkout process, including selecting assets, payment methods, and completing the transaction.
 */

import { useCallback, useState } from 'react';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import BasketSidebar from './BasketSidebar';
import SelectAssets from './SelectAssets';
import PaymentMethods from './PaymentMethods';
import { useEffect } from 'react';
import { api } from 'helpers/auth-axios';
import finishImg from 'images/finish-success.svg';
import { LoaderOverlay, useSnackbar } from '@components/common';
import NewCreditCard from '@components/settings/businesBilling/creditCard';
import useRouter from 'hooks/router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import CreateAssetService from './assets/CreateAssetService';

const Checkout = () => {
    const { t }: any = useTranslation();
    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [subtotal, setSubtotal] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState({
        code: 'cash',
        label: 'Cash',
    });
    const [gratuity, setGratuity] = useState<any>({ label: 'No Tip', value: 0 });
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [selectedServices, setSelectedServices] = useState<any[]>([]);
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [paymentIntentId, setPaymentIntentId] = useState<any>(null);
    const [paymentMethodId, setPaymentMethodId] = useState<any>(null);
    const [business, setBusiness] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [customTip, setCustomTip] = useState<any>();
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [createAsset, setCreateAssets] = useState<any>(false);
    const [loadAssets, setLoadAssets] = useState<any>(false);
    const [editAssets, setEditAsset] = useState<any>();

    useEffect(() => {
        setTotal(gratuity.value ? parseFloat(gratuity.value) + subtotal : 0 + subtotal);
        let serviceTotal = selectedServices.reduce((a, b) => a + parseInt(b.price), 0);
        setSubtotal(
            paymentMethod !== undefined &&
                (paymentMethod.code === 'paypal' || paymentMethod.code === 'american_express')
                ? serviceTotal + 1
                : serviceTotal
        );
    }, [subtotal, gratuity, customTip, paymentMethod]);

    useEffect(() => {
        getBusiness();
    }, []);

    // When both the payment intent and the payment method are gathered
    // make the actual transaction
    useEffect(() => {
        if (paymentIntentId !== null && paymentMethodId !== null) {
            const customerData = {
                firstname: selectedCustomer?.firstname,
                lastname: selectedCustomer?.lastname,
                email: selectedCustomer?.email,
                phone: selectedCustomer?.phone,
            };
            setLoading(true);
            api.post('/appointments/payment/pay', {
                stripe_account_id: business && business.stripe_account_id,
                payment_intent_id: paymentIntentId,
                payment_method_id: paymentMethodId,
                appointment_details: {
                    sale: 'sale',
                    booking_without_payment: false,
                    stripe_account_verified: business && business.stripe_account_verified,
                },
                customer_details: customerData,
                transaction_details: transaction_details,
            })
                .then(res => {
                    if (res) {
                        setLoading(false);
                        setSelectedServices([]);
                        setSelectedCustomer('');
                        setSubtotal(0);
                        setGratuity({ label: 'No Tip', value: 0 });
                        setPaymentMethod({
                            code: 'cash',
                            label: 'Cash',
                        });
                        setFinished(false);
                        setOpenCard(false);
                        setPaymentIntentId(null);
                        setPaymentMethodId(null);
                        setStep(3);
                        openSnackbar(t('Payed successfully!'));
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    }, [paymentMethodId, paymentIntentId]);

    const onBack = () => {
        setStep(prevValue => prevValue - 1);
    };

    const onNext = () => {
        setStep(prevValue => prevValue + 1);
    };

    const handlePaymentGratuity = (
        method: { code: string; label: string },
        gratuity: { label: string; value: number }
    ) => {
        setGratuity(gratuity);
        setPaymentMethod(method);
    };

    const transaction_details = {
        customer: selectedCustomer?._id,
        total: _userData.user.business_id.currency + total.toFixed(2),
        total_value: total.toFixed(2),
        items: selectedServices.map(service => {
            return {
                price: _userData.user.business_id.currency + service.price,
                price_value: service.price,
                quantity: 1,
                service_name: service.name,
                service_id: service._id,
                tax_rate: service.tax_rate,
            };
        }),
        payments: [
            {
                amount: _userData.user.business_id.currency + total.toFixed(2),
                amount_value: total.toFixed(2),
                payment_method: paymentMethod,
                tip: gratuity.value ? gratuity.value : 0,
            },
        ],
        payment_method: paymentMethod,
        tip: {
            amount:
                _userData.user.business_id.currency +
                (gratuity.value ? parseFloat(gratuity.value).toFixed(2) : 0),
            amount_value: gratuity.value ? parseFloat(gratuity.value).toFixed(2) : 0,
            label: gratuity.label,
        },
    };

    const handleCharge = () => {
        setLoading(true);
        api.post('/transactions', transaction_details)
            .then(res => {
                if (res) {
                    setLoading(false);
                    setFinished(true);
                    openSnackbar(t('Transaction successfully!'));
                    router.navigate(`/sales`);
                    setSelectedServices([]);
                    setSelectedCustomer('');
                    setSubtotal(0);
                    setGratuity({ label: 'No Tip', value: 0 });
                    setPaymentMethod({
                        code: 'cash',
                        label: 'Cash',
                    });
                    setFinished(false);
                    setStep(3);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(t('Failed!'));
            });
    };

    const createPaymentIntent = useCallback(
        (customerData: any, total: any) => {
            if (!_userData || !selectedServices) return;
            let subTotal: any = total * 100;
            api.post('/billing/payment-intent', {
                stripe_account_id: business && business.stripe_account_id,
                amount: parseFloat(subTotal),
                customer_details: {
                    firstname: customerData.firstname,
                    lastname: customerData.lastname,
                    email: customerData.email,
                    phone: customerData.phone,
                },
                description: {
                    service_name: selectedServices.map(service => service.name),
                    staff_name: customerData.firstname + ' ' + customerData.lastname,
                    date: moment().format('YYYY-MM-DD'),
                    service_tip: gratuity,
                },
            })
                .then(res => {
                    const data = res.data as any;
                    setPaymentIntentId(data.payment_intent_id);
                    setStep(prevVal => prevVal + 1); // move to next step
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        },
        [selectedServices]
    );

    const getBusiness = () => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            if (res) {
                setBusiness(res.data);
            }
        });
    };

    return (
        <MainContainer>
            {loading && <LoaderOverlay />}
            {createAsset ? (
                <CreateAssetService
                    onClose={() => {
                        setCreateAssets(false);
                        setEditAsset('');
                    }}
                    handleAdd={() => {
                        setLoadAssets(true);
                        setCreateAssets(false);
                        setEditAsset('');
                    }}
                    editAssets={editAssets}
                />
            ) : (
                !finished && (
                    <Wrapper>
                        {openCard ? (
                            business && business.stripe_account_verified ? (
                                <NewCreditCard
                                    buttonTitle={`${
                                        loading == true ? t('Processing please wait') : t('Charge')
                                    }`}
                                    stripeAccountId={business && business.stripe_account_id}
                                    onSave={(paymentMethodId: string) => {
                                        setPaymentMethodId(paymentMethodId);
                                    }}
                                />
                            ) : (
                                <div>Please Connect your account with stripe first!</div>
                            )
                        ) : (
                            <Row>
                                {step == 1 && (
                                    <>
                                        <Col md={7} lg={8}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}>
                                                <TitleBody>
                                                    &nbsp; &nbsp;
                                                    <Heading>Add New Sale</Heading>
                                                </TitleBody>

                                                <Button
                                                    bgtype={'secondary'}
                                                    ifClicked={() => setCreateAssets(true)}
                                                    label={t('Create Assets')}></Button>
                                            </div>

                                            <SelectAssets
                                                selectedAsset={selectedServices}
                                                onSelect={(services: IService[]) => {
                                                    setSelectedServices(services);

                                                    setGratuity({ label: 'No Tip', value: 0 });
                                                }}
                                                onClose={() => {}}
                                                onEdit={e => {
                                                    setEditAsset(e);
                                                    setCreateAssets(true);
                                                }}
                                                setLoadAssets={setLoadAssets}
                                                loadAssets={loadAssets}
                                            />
                                        </Col>
                                        <BasketSidebar
                                            step={step}
                                            onNext={onNext}
                                            customer={selectedCustomer}
                                            items={selectedServices}
                                            subtotal={subtotal}
                                            gratuity={gratuity.value}
                                            onSelectCustomer={(customer: ICustomer) => {
                                                setSelectedCustomer(customer);
                                            }}
                                            onCharge={() => ''}
                                        />
                                    </>
                                )}{' '}
                                {step == 2 && (
                                    <>
                                        <PaymentMethods
                                            subtotal={subtotal}
                                            gratuity={gratuity}
                                            paymentMethod={paymentMethod}
                                            customTip={(val: any) => setCustomTip(val)}
                                            onBack={onBack}
                                            onChange={handlePaymentGratuity}
                                        />
                                        <BasketSidebar
                                            step={step}
                                            onNext={onNext}
                                            customer={selectedCustomer}
                                            items={selectedServices}
                                            subtotal={subtotal}
                                            gratuity={gratuity.value}
                                            paymentMethod={paymentMethod?.code}
                                            onSelectCustomer={(customer: ICustomer) => {
                                                setSelectedCustomer(customer);
                                            }}
                                            onCharge={() => {
                                                if (
                                                    business &&
                                                    business.stripe_account_verified == true &&
                                                    (paymentMethod.code == 'paypal' ||
                                                        paymentMethod.code == 'american_express')
                                                ) {
                                                    setOpenCard(true);
                                                    createPaymentIntent(selectedCustomer, total);
                                                } else if (
                                                    paymentMethod.code == 'cash' ||
                                                    'cheque' ||
                                                    'gift_card'
                                                ) {
                                                    handleCharge();
                                                }
                                            }}
                                        />
                                    </>
                                )}
                                {step == 3 && (
                                    <CheckoutSuccess>
                                        <Image src={finishImg} alt="Finished" />
                                        <SuccessTitle>{t('Checkout complete!')}</SuccessTitle>

                                        <Button
                                            bgtype={'secondary'}
                                            ifClicked={() => setStep(1)}
                                            label={t('Go Back')}></Button>
                                    </CheckoutSuccess>
                                )}
                            </Row>
                        )}
                    </Wrapper>
                )
            )}
        </MainContainer>
    );
};

const Wrapper = styled(Container)`
    max-width: 100%;
`;

const MainContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1.75rem;
`;

const Heading = styled.div`
    font-size: 25px;
    font-weight: 600;
`;

const CheckoutSuccess = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;
`;

const Image = styled.img`
    max-width: 220px;
`;

const SuccessTitle = styled.div`
    font-weight: 800;
    font-size: 27px;
`;

export default Checkout;
