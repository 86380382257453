/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Hook: useSnackbar
 * Description: Custom hook for interacting with the Snackbar component to show notifications.
 * Purpose:
 * - Provides functions to open and close the snackbar.
 * - Uses the SnackbarContext to access snackbar-related functionality.
 * - Default duration: 5000ms (taken from Snackbar component)
 *
 * Returns:
 * - open: (text?: string, duration?: number) => void - Function to open the snackbar with optional text and duration.
 * - closeSnackbar: () => void - Function to close the currently open snackbar.
 */

import { useContext } from 'react';
import { SnackbarContext, defaultDuration } from './Snackbar';

export const useSnackbar = () => {
    const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

    function open(text = '', duration = defaultDuration) {
        openSnackbar(text, duration);
    }
    return [open, closeSnackbar];
};
