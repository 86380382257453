/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ServiceProviderServices Component
 * Description: This component manages the services assigned to a service provider. It allows assigning, removing, and editing services, displaying details such as prices, durations, and additional information.
 *
 * Props:
 * - defaultServices: any[] - The default services assigned to the service provider.
 * - serviceProviderData: IServiceProvider - Data of the service provider.
 * - callBack: () => void - Callback function triggered after updating services to load service providers again.
 */

import Button from '@components/Button';
import { useSnackbar } from '@components/common';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import EditSelectedServicesModel from '@components/team/edit/editServiceProvider/EditSelectedServicesModel';
import {
    AddService,
    EditButton,
    LabelMyPrice,
    Services,
    ServicesContainer,
    ServicesWrapper,
    SetCustom,
    TabsContent,
} from '@components/team/edit/editServiceProvider/EditServiceProvider';
import SelectServices from '@components/team/selectServices/SelectServices';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import { IService } from 'interfaces/service.interface';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';

const ServiceProviderServices = ({ defaultServices, serviceProviderData, callBack }: any) => {
    const { t }: any = useTranslation();
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [showSelect, setShowSelect] = useState(false);
    const [editSelectedServicesModel, setEditSelectedServicesModel] = useState(false);
    const [editData, setEditData] = useState(null);
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const [assignedServices, setAssignedServices] = useState<any[]>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        if (defaultServices && serviceProviderData) {
            setAssignedServices(defaultServices);
            setServiceProvider(serviceProviderData);
        }
    }, [defaultServices]);

    const getServiceProvider = () => {
        if (router.query.id as string) {
            api.get(`/serviceProvider/${router.query.id as string}`).then((res: any) => {
                if (res.data !== 0) {
                    setServiceProvider(res.data);
                    setAssignedServices(res.data.services);
                }
            });
        }
    };

    const onSubmit = (data: any) => {
        setLoading(true);
        api.put(`/serviceProvider/${router.query.id as string}`, {
            services: data?.map((item: any) => {
                return {
                    price: item.price,
                    service_id: item.service_id,
                    my_price: item.my_price,
                    vip_price: item.vip_price ? item.vip_price : item.my_price,
                    my_duration: item.my_duration,
                    duration: item.duration,
                    name: item.name,
                    color: item.color,
                    available_for_boooking: item.available_for_boooking,
                    is_video: item?.is_video,
                };
            }),
        })
            .then((res: any) => {
                setLoading(false);
                if (res) {
                    openSnackbar(t('Service Provider updated successfully!'));
                    setShowSelect(false);
                    getServiceProvider();
                    callBack();
                }
            })
            .catch((e: any) => {
                setLoading(false);
                setShowSelect(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const openEditServicesModel = (data: any) => {
        setEditData(data);
        setEditSelectedServicesModel(true);
    };
    return (
        <Container>
            <Card>
                {serviceProvider && (
                    <>
                        <CardContent lg={12}>
                            <CardBody>
                                <TabsContent>
                                    {assignedServices?.length ? (
                                        <ServicesContainer>
                                            <ServicesWrapper>
                                                {assignedServices?.map((service, i) => (
                                                    <Services
                                                        key={i}
                                                        className={`service-color-${service.color}--bg`}>
                                                        {assignedServices.map(item =>
                                                            item.service_id ==
                                                            service.service_id ? (
                                                                <SetCustom
                                                                    onClick={() => {
                                                                        openEditServicesModel(
                                                                            service
                                                                        );
                                                                    }}>
                                                                    {t('Edit')}
                                                                </SetCustom>
                                                            ) : (
                                                                ''
                                                            )
                                                        )}

                                                        <div>
                                                            <Label>
                                                                &nbsp;
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {service.price && service.price}
                                                            </Label>
                                                            <LabelMyPrice>
                                                                &nbsp; {t('My Price')}{' '}
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {service.my_price == '' ||
                                                                service.my_price == undefined
                                                                    ? '0'
                                                                    : service.my_price}
                                                            </LabelMyPrice>
                                                            <LabelMyPrice>
                                                                &nbsp; {t('VIP Price')}{' '}
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {service.vip_price == '' ||
                                                                service.vip_price == undefined
                                                                    ? '0'
                                                                    : service.vip_price}
                                                            </LabelMyPrice>
                                                        </div>
                                                        <CustomDiv>
                                                            <Name>{service.name}</Name>
                                                            {service?.is_video && (
                                                                <i className="fal fa-video"></i>
                                                            )}
                                                        </CustomDiv>

                                                        <ServiceDuration>
                                                            <span
                                                                style={{
                                                                    fontSize: '12px',
                                                                }}>
                                                                Service Duration{' '}
                                                            </span>{' '}
                                                            {`${
                                                                service.duration &&
                                                                service.duration >= 60
                                                                    ? Math.floor(
                                                                          service.duration / 60
                                                                      ) + 'h'
                                                                    : '0h'
                                                            } , ${
                                                                service.duration &&
                                                                service.duration % 60
                                                            }${t('min')}`}
                                                        </ServiceDuration>
                                                        <ServiceDuration>
                                                            <>
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px',
                                                                    }}>
                                                                    {t('My Duration')}{' '}
                                                                </span>{' '}
                                                                {`${
                                                                    service.my_duration >= 60
                                                                        ? Math.floor(
                                                                              service.my_duration /
                                                                                  60
                                                                          ) + 'h'
                                                                        : '0h'
                                                                }, ${service.my_duration % 60}${t(
                                                                    'min'
                                                                )}`}
                                                            </>
                                                        </ServiceDuration>
                                                    </Services>
                                                ))}
                                            </ServicesWrapper>
                                        </ServicesContainer>
                                    ) : (
                                        <AddService>{t('No service found!')}</AddService>
                                    )}
                                </TabsContent>
                            </CardBody>

                            <EditButton>
                                <Button
                                    bgtype={'secondary'}
                                    type={'submit'}
                                    ifClicked={() => setShowSelect(true)}
                                    label={t('Assign / Remove Services')}></Button>
                            </EditButton>
                        </CardContent>
                    </>
                )}

                {showSelect && (
                    <SelectServices
                        selectedServices={assignedServices.map(service => {
                            return {
                                price: service.price,
                                _id: service.service_id,
                                my_price: service.my_price,
                                my_duration: service.my_duration,
                                duration: service.duration,
                                name: service.name,
                                color: service.color,
                                business_id: service.business_id,
                                available_for_boooking: service.available_for_boooking,
                                tax_rate: service.tax_rate,
                                updated_at: service.updated_at,
                                created_at: service.created_at,
                                description: service.description,
                                is_video: service?.is_video,
                                __v: service.__v,
                            };
                        })}
                        modelStatus={'editservice'}
                        onClose={() => {
                            setShowSelect(false);
                        }}
                        submitLoading={loading}
                        onSelect={(services: IService[]) => {
                            const data = services.map(item => {
                                return {
                                    price: item.price,
                                    service_id: item._id,
                                    _id: item._id,
                                    my_price: item.my_price,
                                    my_duration: item.my_duration,
                                    duration: item.duration,
                                    name: item.name,
                                    color: item.color,
                                    available_for_boooking: item.available_for_boooking,
                                    is_video: item?.is_video,
                                };
                            });
                            for (
                                let index = 0;
                                index < serviceProvider!.services?.length;
                                index++
                            ) {
                                for (let ind = 0; ind < data.length; ind++) {
                                    if (
                                        serviceProvider?.services[index].service_id ==
                                        data[ind].service_id
                                    ) {
                                        data[ind] = serviceProvider?.services[index];
                                    }
                                }
                            }
                            setAssignedServices(data);
                            onSubmit(data);
                        }}
                    />
                )}

                {editSelectedServicesModel ? (
                    <EditSelectedServicesModel
                        editData={editData}
                        upDateCustomServie={() => {
                            setEditSelectedServicesModel(false);
                            getServiceProvider();
                        }}
                        onClose={() => {
                            setEditSelectedServicesModel(false);
                        }}
                    />
                ) : (
                    ''
                )}
            </Card>
        </Container>
    );
};

const Card = styled(Row)`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.white};
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
`;

const CardContent = styled(Col)`
    padding: 1.71rem;
    flex: 1 1;
    ${media.xs`
    padding: 1rem;
    `}
    ${media.sm`
    padding: 1rem;
    `}
`;

const CardBody = styled.div``;

const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

export default ServiceProviderServices;
