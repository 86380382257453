/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ForgotPassword Component
 * Description: This component allows users to reset their password by providing their email and selecting their role.
 * It includes form validation, error handling, and redirects to the login page after successful password reset.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, Select, useSnackbar } from '@components/common';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/sideCover/SideCover';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    useEffect(() => {
        localStorage.removeItem('user');
    }, []);

    const { t }: any = useTranslation();
    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const [roleList, setRole] = useState([
        { value: 'user', label: t('Business Owner') },
        { value: 'serviceProvider', label: t('Service Provider') },
        { value: 'others', label: t('Others') },
        { value: 'teamMember', label: t('Team Member') },
    ]);
    const [selectedRole, setSeletedRole] = useState('user');

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(t('This field is required')),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = ({ email }: any) => {
        api.post('/auth/forgot_password', { email: email, role: selectedRole })
            .then((res: any) => {
                console.log('res', res);
                openSnackbar(t('Email sent to your email address Successfully'));
                router.navigate('/login');
            })
            .catch((e: any) => {
                if (e.response) {
                    openSnackbar(t('Incorrect email'));
                }
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>{t('Reset Password')}</Heading>
                            </Inner>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <InputGroup>
                                    <Input
                                        label={t('Email')}
                                        name="email"
                                        type="email"
                                        onChange={onChange}
                                        error={errors.email as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Select
                                        name="selectedRole"
                                        disabled={true}
                                        value={
                                            selectedRole === 'user'
                                                ? 'Business Owner'
                                                : selectedRole === 'serviceProvider'
                                                ? 'Service Provider'
                                                : selectedRole === 'others'
                                                ? 'Others'
                                                : selectedRole === 'teamMember'
                                                ? 'Team Member'
                                                : 'Please select your role'
                                        }
                                        options={roleList}
                                        onChange={e => {
                                            setSeletedRole(e);
                                        }}
                                    />
                                </InputGroup>
                                <Button
                                    type={'submit'}
                                    label={t('Reset')}
                                    width={'100% !important'}
                                    bgtype={'secondary'}
                                />
                            </form>
                            <div>
                                <AgreeText>
                                    {t(
                                        'By clicking the Reset button you therefore a email send to your email address with your password and you can use this password for sign in'
                                    )}
                                </AgreeText>
                            </div>
                            <SignInText>
                                {t('Already have password?')}
                                <Link to="/login">
                                    <SignUpText className="text-primary-light">
                                        {t('Log In')}
                                    </SignUpText>
                                </Link>
                            </SignInText>
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
    min-width: 100%;
    background-color: #fff;
`;

const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Inner = styled.div`
    margin-bottom: 1rem;
    /* margin-bottom: 3rem; */
`;

const Heading = styled.h3`
    font-size: 2.6rem;
    font-weight: 600 !important;
    color: #000;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;

const SignInText = styled.p`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    justify-content: center;
`;

const SignUpText = styled.p`
    /* color: #0154ff !important; */
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const AgreeText = styled.div`
    font-size: 0.85rem;
    /* max-width: 340px; */
    margin-right: auto;
    margin-left: auto;
    font-weight: 200 !important;
    text-align: center !important;
    margin-top: 1rem !important;
`;

export default ForgotPassword;
