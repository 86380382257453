/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PackagesService Component
 * Description: This component allows users to manage service packages, providing functionality to add, edit, and delete packages.
 * It includes a form for adding/editing packages, a table to display existing packages, and actions like delete and edit for each package.
 *
 */

import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, Layout, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { api } from 'helpers/auth-axios';
import { CustomButtom } from '../businessDetails/BusinessDetails';
import { CustomRow } from '@components/team/create/createTeamMember/CreateTeamMember';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MDBDataTableV5 } from 'mdbreact';
import { NotFound } from '../../team/list/serviceProviderList/ServiceProviderList';
import { HeaderCustom } from '../businessServices/EditService';
import MultiSelect from '@components/common/select/MultiSelect';
import React from 'react';
import DeleteModel from '@components/common/deleteModel/DeleteModel';

const PackagesService = () => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [packages, setPackages] = useState<any>([]);
    const [newPackage, setNewPackage] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        services: Yup.array()
            .required('This field is required')
            .min(2, t('At least two service must be selected'))
            .of(
                Yup.object().shape({
                    label: Yup.string().required(t('Service label is required')),
                    value: Yup.object().shape({
                        _id: Yup.string().required(t('Service _id is required')),
                        name: Yup.string().required(t('Service name is required')),
                    }),
                })
            ),
        discount: Yup.string()
            .required(t('This field is required'))
            .test(t('is-less-than-100'), t('Value must not be greater than 100%'), (value: any) => {
                const numericValue = parseFloat(value);
                return isNaN(numericValue) || numericValue <= 100;
            }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, getValues, formState, trigger, clearErrors } =
        useForm(formOptions);
    const { errors } = formState;
    const [loading, setLoading] = useState(false);
    const [services, setService] = useState<any>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [editPackage, setEditPackage] = useState<any>('');
    const [deletePackageModel, setDeletePackageModel] = useState(false);

    const [tableData, setTableData] = useState({
        columns: [
            {
                label: t('Name'),
                field: 'name',
                width: 470,
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: t('Actual Price'),
                field: 'actualPrice',
                width: 470,
                sort: String('disabled'),
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: t('Discounted Price'),
                field: 'discountPrice',
                width: 470,
                sort: String('disabled'),
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: t('Services'),
                field: 'services',
                width: 470,
                sort: String('disabled'),
            },
            { label: t('discount'), field: 'discount', width: 470, sort: String('disabled') },
            { label: t('Duration'), field: 'duration', width: 470, sort: String('disabled') },
            { label: t('Action'), field: 'action', width: 470, sort: String('disabled') },
        ],
        rows: [],
    });

    useEffect(() => {
        getPackagesService();
        getBusinessServices();
    }, []);

    useEffect(() => {
        if (packages?.length) {
            packages.map((e: any) => createTable(e));
        }
    }, [packages]);

    // Set initial values
    useEffect(() => {
        if (!editPackage) {
            setTimeout(() => {
                setValue('name', '');
                setValue('price', '');
                setValue('services', []);
                setValue('discount', '');
            });
        }
    }, [setValue, newPackage]);

    async function onChange({ name, value }: { name: any; value: any }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        setLoading(true);
        api.post(`package-service/create`, {
            ...data,
            price: calculateDiscount(
                getValues('services')?.reduce((a: any, b: any) => a + parseInt(b?.value?.price), 0),
                getValues('discount') ? getValues('discount') : 0
            ),
            services: getValues('services')?.map((e: any) => e?.value?._id),
            duration: getValues('services')?.length
                ? formatTime(
                      getValues('services')?.reduce(
                          (a: any, b: any) => a + parseInt(b?.value?.duration),
                          0
                      )
                  )
                : '0 minute',
        })
            .then((res: any) => {
                setLoading(false);
                setNewPackage(false);
                openSnackbar(t('Package added successfully') + '!');
                getPackagesService();
                setValue('name', '');
                setValue('price', '');
                setValue('services', []);
                setValue('discount', '');
                setEditPackage('');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const onUpdateSubmit = (data: any) => {
        if (editPackage) {
            setLoading(true);
            api.put(`package-service/${editPackage?._id}`, {
                ...data,
                price: calculateDiscount(
                    getValues('services')?.reduce(
                        (a: any, b: any) => a + parseInt(b?.value?.price),
                        0
                    ),
                    getValues('discount') ? getValues('discount') : 0
                ),
                services: getValues('services')?.map((e: any) => e?.value?._id),
                duration: getValues('services')?.length
                    ? formatTime(
                          getValues('services')?.reduce(
                              (a: any, b: any) => a + parseInt(b?.value?.duration),
                              0
                          )
                      )
                    : '0 minute',
            })
                .then((res: any) => {
                    setLoading(false);
                    setNewPackage(false);
                    openSnackbar(t('Package update successfully') + '!');
                    getPackagesService();
                    setValue('name', '');
                    setValue('price', '');
                    setValue('services', []);
                    setValue('discount', '');
                    setEditPackage('');
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    //get packages service data
    const getPackagesService = () => {
        setLoading(true);
        api.get(`package-service`)
            .then(res => {
                setTableData((preValue): any => {
                    return {
                        columns: [...preValue.columns],
                        rows: [],
                    };
                });
                setEditPackage('');
                setPackages(res.data);
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const createTable = (row?: any) => {
        let filterServices = services?.filter((e: any) =>
            row?.services?.some((re: any) => re?._id == e?.value?._id)
        );
        let _obj = {
            name: <Text>{row?.name}</Text>,
            actualPrice: (
                <Text>
                    {_userData?.user?.business_id?.currency +
                        row?.services
                            ?.reduce((a: any, b: any) => a + parseInt(b?.price), 0)
                            ?.toFixed(2)}
                </Text>
            ),
            discountPrice: <Text>{_userData?.user?.business_id?.currency + row?.price}</Text>,
            services: (
                <Text>
                    {row?.services?.map((e: any, index: number, array: any[]) => (
                        <React.Fragment key={e.id}>
                            {e.name}
                            {index < array.length - 1 && ', '}
                        </React.Fragment>
                    ))}
                </Text>
            ),

            duration: <Text>{row?.duration} </Text>,
            discount: <Text>{row?.discount + '%'}</Text>,
            action: (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <CustomButtom>
                        <Button
                            bgtype="primary"
                            label={t('Delete')}
                            ifClicked={() => {
                                setEditPackage(row);
                                setDeletePackageModel(true);
                            }}></Button>
                    </CustomButtom>
                    <CustomButtom>
                        <Button
                            bgtype="secondary"
                            label={t('Edit')}
                            ifClicked={() => {
                                setValue('name' as never, row?.name as never);
                                setValue('services', filterServices);
                                setValue('discount', row?.discount);
                                setNewPackage(true);
                                setEditPackage(row);
                            }}></Button>
                    </CustomButtom>
                </div>
            ),
        };
        setTableData((preValue): any => {
            return {
                columns: [...preValue.columns],
                rows: [...preValue.rows, _obj],
            };
        });
    };

    useEffect(() => {
        if (editPackage && newPackage) {
            let filterServices = services?.filter((e: any) =>
                editPackage?.services?.some((re: any) => re?._id == e?.value?._id)
            );
            setValue('name' as never, editPackage?.name as never);
            setValue('services', filterServices);
            setValue('discount', editPackage?.discount);
        }
    }, [newPackage, editPackage]);

    const formatTime = (hours: any) => {
        const duration = moment.duration(hours, 'minutes');
        const formattedTime = [];

        if (duration.hours() > 0) {
            formattedTime.push(`${duration.hours()} ${duration.hours() === 1 ? 'hour' : 'hours'}`);
        }

        if (duration.minutes() > 0) {
            formattedTime.push(
                `${duration.minutes()} ${duration.minutes() === 1 ? 'minute' : 'minutes'}`
            );
        }

        return formattedTime.join(' ');
    };

    const getBusinessServices = async () => {
        try {
            setLoading(true);
            await api
                .get('/services')
                .then((res: any) => {
                    setLoading(false);
                    if (res.data?.length) {
                        let data = res.data?.map((e: any) => ({
                            label: `${e?.name} (price: ${
                                _userData?.user?.business_id?.currency + e?.price
                            }, duration: ${formatTime(e?.duration)})`,
                            value: e,
                        }));
                        setService(data);
                    }
                })
                .catch(e => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const calculateDiscount = (amount: any, discountPercentage: any) => {
        const numericAmount = parseFloat(amount);
        const discountAmount = numericAmount * (discountPercentage / 100);
        let discountedTotal = numericAmount - discountAmount;
        discountedTotal = discountedTotal > 0 ? discountedTotal : 0;

        return discountedTotal.toFixed(2);
    };

    //get packages service data
    const deletePackage = (id = null) => {
        if (id) {
            setLoading(true);
            api.delete(`package-service/${id}`)
                .then(res => {
                    getPackagesService();
                    setDeletePackageModel(false);
                    setEditPackage('');
                    openSnackbar(t('Package deleted successfully') + '!');
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Wrapper>
                <CustomRow>
                    {loading && <LoaderOverlay />}
                    {newPackage === false ? (
                        <Col lg={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '1rem 0',
                                    justifyContent: 'space-between',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Link to="/settings/promotions">
                                        <Icon className="fal fa-chevron-left"></Icon>
                                    </Link>
                                    <Header>{t('Manage Packages')}</Header>
                                </div>
                                <div style={{ gap: '0.5rem', display: 'flex' }}>
                                    <Button
                                        label={t('Add new package')}
                                        bgtype="secondary"
                                        ifClicked={() => setNewPackage(true)}></Button>
                                </div>
                            </div>
                            {packages?.length ? (
                                <MDBDataTableV5
                                    data={tableData}
                                    entries={20}
                                    pagesAmount={40}
                                    noBottomColumns={true}
                                    searchTop={false}
                                    searchBottom={false}
                                    responsive={true}
                                    striped={true}
                                />
                            ) : (
                                <CustomDiv>
                                    <NotFound>{t('No Record Found!')}</NotFound>
                                </CustomDiv>
                            )}
                        </Col>
                    ) : (
                        <Col lg={6}>
                            <HeaderCustom lg={12} style={{ marginTop: '1rem' }}>
                                <HeadingAddCard>
                                    {t(editPackage ? 'Update Package' : 'Create New Package')}
                                </HeadingAddCard>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => {
                                        if (!loading) {
                                            setNewPackage(false);
                                            setEditPackage('');
                                            clearErrors(['name', 'discount', 'services']);
                                        }
                                    }}></Cross>
                            </HeaderCustom>
                            <Card>
                                <form
                                    onSubmit={handleSubmit(
                                        editPackage ? onUpdateSubmit : onSubmit
                                    )}>
                                    <Wrapper>
                                        <Row>
                                            <CustomCol lg={12}>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Name')}
                                                        name="name"
                                                        value={getValues('name')}
                                                        onChange={onChange}
                                                        error={errors?.name as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup style={{ gap: '0.3rem' }}>
                                                    <MultiSelect
                                                        label={t('Choose Services')}
                                                        name="services"
                                                        value={getValues('services')}
                                                        onChange={e => {
                                                            onChange({
                                                                name: 'services',
                                                                value: e,
                                                            });
                                                        }}
                                                        isMulti={true}
                                                        options={services}
                                                        error={errors.services as any}
                                                    />
                                                </InputGroup>

                                                <InputGroup
                                                    style={{ display: 'flex', gap: '0.3rem' }}>
                                                    <Input
                                                        label={t('Discount') + ' (%)'}
                                                        name="discount"
                                                        allowOnlyNumbersAndDecimal={true}
                                                        maxLength={3}
                                                        value={getValues('discount')}
                                                        onChange={onChange}
                                                        error={errors.discount as any}
                                                    />
                                                </InputGroup>

                                                {getValues('services')?.length ? (
                                                    <>
                                                        <FooterDiv>
                                                            <Label>{t('Duration')}</Label>
                                                            <LabelValue>
                                                                {getValues('services')?.length
                                                                    ? formatTime(
                                                                          getValues(
                                                                              'services'
                                                                          )?.reduce(
                                                                              (a: any, b: any) =>
                                                                                  a +
                                                                                  parseInt(
                                                                                      b?.value
                                                                                          ?.duration
                                                                                  ),
                                                                              0
                                                                          )
                                                                      )
                                                                    : '0 minute'}
                                                            </LabelValue>
                                                        </FooterDiv>
                                                        <FooterDiv>
                                                            <Label>{t('Total Price')}</Label>
                                                            <LabelValue>
                                                                {_userData?.user?.business_id
                                                                    ?.currency +
                                                                    getValues('services')
                                                                        ?.reduce(
                                                                            (a: any, b: any) =>
                                                                                a +
                                                                                parseInt(
                                                                                    b?.value?.price
                                                                                ),
                                                                            0
                                                                        )
                                                                        .toFixed(2)}
                                                            </LabelValue>
                                                        </FooterDiv>
                                                        <FooterDiv>
                                                            <Label>
                                                                {t('Discounted Total Price')}
                                                            </Label>
                                                            <LabelValue>
                                                                {_userData?.user?.business_id
                                                                    ?.currency +
                                                                    calculateDiscount(
                                                                        getValues(
                                                                            'services'
                                                                        )?.reduce(
                                                                            (a: any, b: any) =>
                                                                                a +
                                                                                parseInt(
                                                                                    b?.value?.price
                                                                                ),
                                                                            0
                                                                        ),
                                                                        getValues('discount')
                                                                            ? getValues('discount')
                                                                            : 0
                                                                    )}
                                                            </LabelValue>
                                                        </FooterDiv>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <CustomButtom>
                                                    <Button
                                                        type="submit"
                                                        bgtype="secondary"
                                                        label={t(
                                                            editPackage ? 'Update' : 'Add'
                                                        )}></Button>
                                                </CustomButtom>
                                            </CustomCol>
                                        </Row>
                                    </Wrapper>
                                </form>
                            </Card>
                        </Col>
                    )}
                </CustomRow>
            </Wrapper>
            {deletePackageModel && (
                <DeleteModel
                    onDelete={() => {
                        setDeletePackageModel(false);
                    }}
                    onClose={() => {
                        setDeletePackageModel(false);
                    }}
                    name={t('Package')}
                    content={t('Are you sure you want to delete this package?')}
                    loading={loading}
                    onClick={() => editPackage?._id && deletePackage(editPackage?._id)}
                />
            )}
        </>
    );
};

const FooterDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

const Label = styled.span`
    font-weight: bold;
`;

const LabelValue = styled.span`
    font-weight: normal;
`;

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CustomCol = styled(Col)`
    /* border-right: 0.1rem solid ${palette.grey.lightest}; */
    ${media.xs`
    border-right: none;
  `}
    ${media.sm`
    border-right: none;
  `}
    ${media.md`
    border-right: none;
  `}
    ${media.lg`
    /* border-right: 0.1rem solid ${palette.grey.lightest}; */
  `}
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

const CustomDiv = styled.div`
    margin-top: 2rem;
`;

const Text = styled.div``;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
    /* margin-right: 1.5rem !important; */
`;

export const HeadingAddCard = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

PackagesService.Layout = Layout;
export default PackagesService;
