/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: SelectServiceProvider
 * Description: This component represents the service provider selection modal, allowing users to search and select a service provider for a new appointment. It uses React, styled-components for styling, and interacts with the backend through API calls to fetch and display service provider information.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the service provider selection modal is closed.
 * - onSelect: (serviceProvider: IServiceProvider) => void - Callback function triggered when a service provider is selected.
 *
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import _ from 'lodash';
import SearchField from '@components/common/search/SearchField';
import { Spinner, useSnackbar } from '@components/common';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
const SelectServiceProvider = ({
    onClose,
    onSelect,
}: {
    onClose(): void;
    onSelect(serviceProvider: IServiceProvider): void;
}) => {
    const { t }: any = useTranslation();
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState('');
    const observer = useRef<IntersectionObserver>();
    const [serviceProvider, setServiceProvider] = useState<any>();
    const [serviceProviderData, setServiceProviderData] = useState<any>([]);
    const [openSnackbar] = useSnackbar();
    const pageSize = 20;
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (serviceProvider !== undefined && serviceProvider?.total !== 0) {
            setPageNumber(serviceProvider?.page);
            setValue(serviceProvider?.query ?? null);
            setQuery(serviceProvider?.query ?? null);
            setHasMore(true);
            return;
        }
        getAllServiceProvider();
    }, []);

    useEffect(() => {
        if (pageNumber > 1) {
            loadServiceProvider();
        }
    }, [pageNumber]);

    const searchServiceProviderDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(1);
            searchServiceProvider(searchVal);
        }, 300);
    }, []);

    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {});
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}`)
            .then((serviceProvider: any) => {
                setServiceProvider(serviceProvider.data);
                setServiceProviderData(serviceProvider.data.serviceProvider);
                setHasMore(serviceProvider.data.serviceProvider?.length > 0);
                setLoading(false);
                setTotal(serviceProvider?.data?.total);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const loadServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}`).then((res: any) => {
            setServiceProviderData((prev: any) => [...prev, ...res.data.serviceProvider]);
            setServiceProvider(res.data);
            setTotal(res.data.total);
            setHasMore(res.data.serviceProvider?.length > 0);
            setLoading(false);
        });
    };

    const searchServiceProvider = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value?.length <= 3) {
                value = value.replace(/[- .]/g, '');
            } else if (value?.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value?.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/serviceProvider?page=${1}&pageSize=${pageSize}&query=${value}`)
                .then((serviceProvider: any) => {
                    setLoading(false);
                    setServiceProvider(serviceProvider.data);
                    setServiceProviderData(serviceProvider.data.serviceProvider);

                    setHasMore(serviceProvider.data.serviceProvider?.length > 0);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        } else {
            setLoading(true);
            api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&query=${value}`)
                .then((serviceProvider: any) => {
                    setLoading(false);
                    setServiceProvider(serviceProvider.data);
                    setServiceProviderData(serviceProvider.data.serviceProvider);

                    setHasMore(serviceProvider.data.serviceProvider?.length > 0);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchServiceProviderDebounce(event.target.value);
    };

    const select = (serviceProvider: IServiceProvider) => {
        onSelect(serviceProvider);
        onClose();
    };

    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };

    return (
        <>
            <Wrapper>
                <CustomRow>
                    <CustomCol md={12} lg={12}>
                        <HeadingWrapper>
                            <CloseButton onClick={onClose}>
                                <i className="fal fa-chevron-left"></i>
                            </CloseButton>
                            <Header>{t('Select Service Provider')}</Header>
                        </HeadingWrapper>

                        <SearchField
                            value={value}
                            handleChange={handleChange}
                            name={t(`Service Provider`)}
                            setValue={() => {
                                setValue('');
                                if (value) {
                                    searchServiceProvider('');
                                }
                            }}
                        />

                        <ProvidersList>
                            {loading ? (
                                <Spinner color={'black'} />
                            ) : serviceProviderData?.length ? (
                                serviceProviderData.map(
                                    (serviceProvider: IServiceProvider, i: any) => {
                                        return (
                                            <li key={i} onClick={() => select(serviceProvider)}>
                                                <SearchItems>
                                                    <SearchItemWrapper>
                                                        <Circle>
                                                            <NameCircle
                                                                src={`${process.env.REACT_APP_PROFILE_URL}${serviceProvider.photo}`}></NameCircle>
                                                        </Circle>
                                                        <SearchItemName>
                                                            {serviceProvider.name}
                                                        </SearchItemName>
                                                    </SearchItemWrapper>
                                                </SearchItems>
                                            </li>
                                        );
                                    }
                                )
                            ) : (
                                loading == false &&
                                serviceProviderData?.length == 0 && (
                                    <NotFound>{t('No Service Provider found')}</NotFound>
                                )
                            )}
                            <div ref={loaderRef}></div>
                        </ProvidersList>
                        {query
                            ? ''
                            : (total >
                                  (pageNumber === 1 ? pageNumber + 0 : pageNumber + 1) * pageSize ||
                                  loading) && (
                                  <LoadMore>
                                      <Button
                                          bgtype={'secondary'}
                                          ifClicked={loadMore}
                                          disabled={loading}
                                          label={`${t('Load more Service Provider')} (${
                                              total -
                                              (pageNumber === 1 ? pageNumber + 0 : pageNumber + 1) *
                                                  pageSize
                                          })`}></Button>
                                  </LoadMore>
                              )}
                        <Col></Col>
                    </CustomCol>
                </CustomRow>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    margin-left: 0rem;
    padding: 0;
    background-color: ${palette.white};
`;

const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
`;

const CustomCol = styled(Col)`
    padding: 0;
`;

const Header = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
`;

const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;

const SearchItems = styled.div`
    cursor: pointer;
    position: relative;
    margin: 0px;
    padding: 0px;
    &:hover {
        background-color: ${palette.grey.lightest};
    }
`;

const SearchItemWrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.grey.lightest};
    padding: 12px 0;
`;

const SearchItemName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    flex: 1;
    font-size: 14px;
`;

const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;

const CloseButton = styled.div`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.5rem /* 30px */;
    line-height: 2.25rem /* 36px */;
    cursor: pointer;
`;

const ProvidersList = styled.ul`
    height: 30.6rem;
    overflow: auto;
    list-style: none;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
`;

const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
`;

const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;

const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
export default SelectServiceProvider;
