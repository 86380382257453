/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CardHeader Component
 * Description: This component displays user details along with action buttons. It is used in various parts of the application, providing a consistent layout for user information.
 *
 * Props:
 * - userData: any - User data object containing information such as name, email, phone, and photo.
 * - deleteModel: () => void - Callback function triggered when the delete action is initiated.
 * - messageModel: () => void - Callback function triggered when the message action is initiated.
 * - parent: string - Specifies the context in which the component is used, e.g., 'team' or 'customer'.
 * - available: boolean - Indicates whether the user is available or not.
 *
 */

import Button from '@components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from 'styled/common';

const CardHeader = ({ userData, deleteModel, messageModel, parent, available }: any) => {
    const { t }: any = useTranslation();
    let _user = JSON.parse(localStorage.getItem('user') || '{}');
    const navigate = useNavigate();

    return (
        <>
            <CustomDiv margin={'0'} marginBottom={'1rem'}>
                <InnerData>
                    <Icon>
                        {available === true && <OnlineBadge></OnlineBadge>}
                        <IconData
                            src={`${process.env.REACT_APP_PROFILE_URL}${userData?.photo}`}></IconData>
                    </Icon>
                    <CustomerDetail>
                        <Name>
                            {' '}
                            {parent === 'team'
                                ? userData?.name
                                : userData?.firstname + ' ' + userData?.lastname}
                        </Name>
                        <Email>{userData?.email}</Email>
                        <Phone>{userData?.phone}</Phone>
                    </CustomerDetail>
                </InnerData>
                {_user?.user_login === 'customer' ? (
                    <RightDiv>
                        <ButtonContainer>
                            <Button
                                bgtype="secondary"
                                label={t('New Appointment')}
                                ifClicked={() => navigate('/customer/appointment')}></Button>
                        </ButtonContainer>
                        <div></div>
                    </RightDiv>
                ) : (
                    <RightDiv>
                        <ButtonContainer>
                            <Button
                                bgtype="secondary"
                                label={t('Message')}
                                ifClicked={messageModel}></Button>
                        </ButtonContainer>
                        <DeletIcon onClick={deleteModel}>
                            <i className="fal fa-trash-alt"></i>
                        </DeletIcon>
                    </RightDiv>
                )}
            </CustomDiv>
        </>
    );
};

const CustomDiv = styled.div<any>`
    padding: ${({ padding }) => padding};
    height: 5rem;
    margin: ${({ margin }) => (margin ? margin : '0rem 1.7rem 0rem 1.7rem')};
    border-radius: 0.42rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-left: 1rem;
    justify-content: space-between;
    margin-bottom: ${({ marginBottom }) => marginBottom};
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        box-shadow: none;
    }
`;

const Icon = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;

const IconData = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.silver};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
`;

const Name = styled.div`
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    line-height: 2rem !important;
    /* margin-top: 0.75rem !important; */
`;

const Email = styled.div`
    color: ${palette.silver} !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
`;

const Phone = styled.div`
    color: ${palette.silver} !important;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
`;

const CustomerDetail = styled.div`
    display: column;
`;

const InnerData = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const RightDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const DeletIcon = styled.button`
    padding: 0;
    width: 2.85rem;
    height: 2.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 1.07rem;
    margin-right: 0.71rem;
    cursor: pointer;
    &:active {
        border: none !important;
        outline: none;
    }
    &:focus {
        border: none !important;
        outline: none;
    }
`;

const ButtonContainer = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-top: 0.25rem !important;
`;

export const OnlineBadge = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: ${palette.simpleGreen};
    border-radius: 50%;
    position: absolute;
    right: 0.2rem;
    bottom: 0.4rem;
`;

export default CardHeader;
