/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CustomerModal
 * Description: This component represents a modal for managing customer details. Users can add a new customer or edit an existing one.
 * The modal includes input fields for the customer's first name, last name, email, and phone number. It also provides validation and
 * error handling. The component integrates with various UI elements such as buttons and icons for a user-friendly experience.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the customer modal is closed.
 * - onSave: (data: any) => void - Callback function triggered when saving customer data.
 * - title?: any - Title for the customer modal.
 * - onEdit?: any - Callback function triggered when editing customer data.
 * - editModel?: any - Data for pre-filling the customer modal form when in edit mode.
 *
 */

import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ICustomer } from 'interfaces/customer.interface';
import { media } from 'styled-bootstrap-grid';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const CustomerModal = ({
    onClose,
    onSave,
    title,
    onEdit,
    editModel,
}: {
    onClose(): void;
    onSave(data: any): void;
    title?: any;
    onEdit?: any;
    editModel?: any;
}) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [phone, setPhone] = useState();
    const [loading, setLoading] = useState<any>(false);
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('This field is required')),
        lastname: Yup.string().required(t('This field is required')),
        email: Yup.string().email().required(t('This field is required')),
        phone: Yup.string()
            .min(10, t('Phone must be a valid phone'))
            .required(t('This field is required')),
    });
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });
    const { errors, isSubmitting } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            phoneNumber(name, value);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const onSubmit = (data: ICustomer) => {
        setLoading(true);
        editModel === true
            ? api
                  .put(`/customers/${onEdit?._id}`, {
                      ...data,
                  })
                  .then((res: any) => {
                      setLoading(false);

                      if (res) {
                          onSave(res?.data);
                          openSnackbar(t('Customer updated successfully'));
                          onClose();
                      }
                  })
                  .catch((e: any) => {
                      setLoading(false);

                      openSnackbar(e?.response?.data?.message);
                  })
            : api
                  .post('/customers', { ...data, email: data.email.toLowerCase(), photo: null })
                  .then((res: any) => {
                      setLoading(false);

                      onSave(res?.data);
                      openSnackbar(t('Customer added successfully'));
                      onClose();
                  })
                  .catch((e: any) => {
                      setLoading(false);

                      openSnackbar(e?.response?.data?.message);
                  });
    };

    useEffect(() => {
        if (onEdit && editModel === true) {
            setValue('firstname', onEdit?.firstname);
            setValue('lastname', onEdit?.lastname);
            setValue('email', onEdit?.email);
            setValue('phone', onEdit?.phone);
            setPhone(onEdit?.phone);
        }
    }, [setValue, getValues]);

    const phoneNumber = (name: any, value: any) => {
        let phone = value;
        if (phone?.length <= 3) {
            phone = phone.replace(/[- .]/g, '');
        } else if (phone?.length <= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
        } else if (phone?.length >= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
        }
        setPhone(phone);
        setValue(name as never, phone as never);
    };
    return (
        <Model tabIndex={-1} onClick={e => !loading && closeModal(e)}>
            {loading && <LoaderOverlay />}
            <ModelBody style={{ width: '680px' }}>
                <ModelHeading>
                    <Heading>{title}</Heading>
                    <Cover onClick={() => !loading && onClose()}>
                        <i className="fal fa-times"></i>
                    </Cover>
                </ModelHeading>
                <ModelHead></ModelHead>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModelContent>
                        <CustomDuv>
                            <InputGroup>
                                <Input
                                    label={t('First Name')}
                                    name="firstname"
                                    value={
                                        editModel === true
                                            ? onEdit?.firstname
                                            : getValues('firstname')
                                    }
                                    onChange={onChange}
                                    error={errors.firstname as any}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Input
                                    label={t('Last Name')}
                                    name="lastname"
                                    value={
                                        editModel === true
                                            ? onEdit?.lastname
                                            : getValues('lastname')
                                    }
                                    onChange={onChange}
                                    error={errors.lastname as any}
                                />
                            </InputGroup>
                        </CustomDuv>
                        <CustomDuv>
                            <InputGroup>
                                <Input
                                    label={t('Email')}
                                    name="email"
                                    value={getValues('email')?.toLowerCase()}
                                    onChange={onChange}
                                    error={errors.email as any}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Input
                                    allowPhoneNumberOnly={true}
                                    maxLength={12}
                                    label={t('Phone')}
                                    name="phone"
                                    value={phone ? phone : getValues('phone')}
                                    onChange={onChange}
                                    error={errors.phone as any}
                                />
                            </InputGroup>
                        </CustomDuv>
                    </ModelContent>

                    <Footer>
                        <Button
                            bgtype={'secondary'}
                            type={'submit'}
                            label={
                                editModel === true ? t('Update') : t('Add New Customer')
                            }></Button>
                        <Button
                            ifClicked={() => !loading && onClose()}
                            bgtype={'discard'}
                            label={t('Cancel')}></Button>
                    </Footer>
                </Form>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;
const Form = styled.form``;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;
const Text = styled.div`
    margin-bottom: 1.5rem !important;
    color: rgba(157, 160, 164) !important;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
    width: 100%;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CustomDuv = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    ${media.xs`
    display: block;
    `}
    ${media.sm`
      display: block;
    `}
      ${media.md`
      display: flex;
    `}
    ${media.lg`
      display: flex;
    `}
`;
export default CustomerModal;
