/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BasketItems Component
 * Description: React component for displaying a list of items in a basket or shopping cart during the checkout process.
 * It renders the items, quantities, and amounts, along with total price information, including subtotal, discount, and gratuity.
 *
 * Props:
 * - items: IService[] - An array of IService objects representing the assets items in the basket.
 * - subtotal: number - The subtotal amount for all items in the basket.
 * - gratuity: number - The gratuity amount.
 * - paymentMethod?: any - An optional payment method (used for conditional rendering).
 *
 * Purpose: This component serves the purpose of presenting a clear and organized view of items in the basket during the checkout process.
 *
 */

import { IService } from 'interfaces/service.interface';
import s from './Checkout.module.scss';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const BasketItems = ({
    items,
    subtotal,
    gratuity,
    paymentMethod,
}: {
    items: IService[];
    subtotal: number;
    gratuity: number;
    paymentMethod?: any;
}) => {
    const { t }: any = useTranslation();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let tip: any = gratuity;

    return (
        <>
            <Container style={{ padding: '0' }}>
                <Row>
                    <Wrapper lg={12}>
                        <Header>
                            <Items>{t('Item')}</Items>
                            <Quantity>{t('Qnt')}</Quantity>
                            <Amount>{t('Amount')}</Amount>
                        </Header>
                        <BasketItem>
                            {items.map(service => {
                                return (
                                    <BasketList key={service._id}>
                                        <BasketSingleItem>
                                            <BasketSingleItemContent>
                                                <ItemsName>{service.name}</ItemsName>
                                                <ItemsQuantity>x1</ItemsQuantity>
                                                <ItemsAmount>
                                                    {_userData.user.business_id.currency}
                                                    {service.price}
                                                </ItemsAmount>
                                            </BasketSingleItemContent>
                                        </BasketSingleItem>
                                    </BasketList>
                                );
                            })}
                        </BasketItem>
                        <TotalPriceContainer>
                            <Left></Left>
                            <Right>
                                <SubTotalWrapper>
                                    <SubTotalContent>{t('Subtotal')}</SubTotalContent>
                                    <SubTotalContent>
                                        {_userData.user.business_id.currency}
                                        {subtotal.toFixed(2)}
                                    </SubTotalContent>
                                </SubTotalWrapper>
                                <DiscountWrapper>
                                    <SubTotalContent>{t('Discount')}</SubTotalContent>
                                    <SubTotalContent>
                                        {_userData.user.business_id.currency}0.00
                                    </SubTotalContent>
                                </DiscountWrapper>
                                {gratuity > 0 ? (
                                    <GratuatyContent>
                                        <Gratuaty>{t('Gratuity')}</Gratuaty>
                                        <Gratuaty>
                                            {_userData.user.business_id.currency}
                                            {tip && parseFloat(tip)?.toFixed(2)}
                                        </Gratuaty>
                                    </GratuatyContent>
                                ) : (
                                    ''
                                )}
                                {paymentMethod !== undefined &&
                                    (paymentMethod === 'paypal' ||
                                        paymentMethod === 'american_express') && (
                                        <DiscountWrapper>
                                            <SubTotalContent>
                                                {t('Application fee')}
                                            </SubTotalContent>
                                            <SubTotalContent>
                                                {' '}
                                                {_userData.user.business_id.currency}1.00
                                            </SubTotalContent>
                                        </DiscountWrapper>
                                    )}
                            </Right>
                        </TotalPriceContainer>
                    </Wrapper>
                </Row>
            </Container>
        </>
    );
};

const Wrapper = styled(Col)`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    display: grid !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    border-bottom: 1px solid rgba(223, 226, 230);
    padding-bottom: 0.5rem !important;
`;

const Items = styled.div`
    grid-column: span 6 / span 6 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;

const Quantity = styled.div`
    grid-column: span 1 / span 1 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;

const Amount = styled.div`
    grid-column: span 3 / span 3 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: right !important;
    color: rgba(157, 160, 164) !important;
`;

const BasketItem = styled.ul`
    list-style: none;
    padding: 0;
`;
const BasketList = styled.li``;

const BasketSingleItem = styled.div`
    padding: 0.75rem 0;
`;

const BasketSingleItemContent = styled.div`
    display: grid !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;

const ItemsName = styled.div`
    font-size: 0.92rem;
    display: flex;
    align-items: center;
    grid-column: span 6 / span 6;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ItemsQuantity = styled.div`
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    color: rgba(157, 160, 164);
    grid-column: span 1 / span 1;
`;

const ItemsAmount = styled.div`
    font-size: 13.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: span 3 / span 3;
    text-align: right;
`;

const TotalPriceContainer = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;

const Left = styled.div`
    grid-column: span 3 / span 3 !important;
`;

const Right = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 1px solid rgba(223, 226, 230);
`;

const SubTotalWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
`;

const SubTotalContent = styled.div`
    font-size: 0.9rem;
`;

const DiscountWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 0.5rem !important;
`;
const GratuatyContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
`;

const Gratuaty = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

export default BasketItems;
