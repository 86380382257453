/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: ReminderModel
 * Description: This component represents a modal for video appointment reminders. It displays a message to join the video call 10 minutes before the appointment time. Users can close the modal by clicking the "Ok" button.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the reminder model is closed.
 *
 */

import styled from 'styled-components';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
const ReminderModel = ({ onClose }: { onClose(): void }) => {
    const { t }: any = useTranslation();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            <Model tabIndex={-1} onClick={e => closeModal(e)}>
                <ModelBody>
                    <ModelHeading>
                        <Heading>{t('Appointment Notification')}</Heading>
                        <Cover onClick={() => onClose()}>
                            <i className="fal fa-times"></i>
                        </Cover>
                    </ModelHeading>
                    <ModelHead>
                        <div>
                            Please join 10 minutes before the appointment time, Join the video call
                            by clicking on this video icon
                        </div>
                    </ModelHead>
                    <Space></Space>
                    <Footer>
                        <Button
                            bgtype={'secondary'}
                            ifClicked={() => onClose()}
                            label={t('Ok')}></Button>
                    </Footer>
                </ModelBody>
            </Model>
        </>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;
const Space = styled.div`
    padding-top: 4.5rem !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: center;
`;

export default ReminderModel;
