/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: NewRecurringModal
 * Description: This component represents a modal for creating new recurring appointments. Users can select the repeat rule, frequency, and end date. The modal provides options for daily, weekly, and monthly repeats, with the ability to specify the days of the week for weekly repeats.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - start: any - The start date of the recurring appointments.
 * - end: any - The end date of the recurring appointments.
 * - onClose: () => void - Callback function triggered when the new recurring appointments modal is closed.
 * - onSave: (repeating: any) => void - Callback function triggered when the recurring appointments is saved.
 * - recurring: any - The recurring appointments data.
 * - title?: any - Title for the modal.
 *
 */

import { Input, Select } from '@components/common';
import styled from 'styled-components';
import { EndRepeatBookingEnum, RepeatBookingEnum } from 'constants/enums';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
const repeatOptions = [
    { label: 'Daily', value: RepeatBookingEnum.DAILY },
    { label: 'Weekly', value: RepeatBookingEnum.WEEKLY },
    { label: 'Monthly', value: RepeatBookingEnum.MONTHLY },
];

const days = [
    { day: 1, label: '1' },
    { day: 2, label: '2' },
    { day: 3, label: '3' },
    { day: 4, label: '4' },
    { day: 5, label: '5' },
    { day: 6, label: '6' },
];
const NewRecurringModal = ({
    start,
    end,
    onClose,
    onSave,
    recurring,
    title,
}: {
    start: any;
    end: any;
    onClose(): void;
    onSave(repeating: any): void;
    recurring: any;
    title?: any;
}) => {
    useEffect(() => {
        if (recurring != undefined) {
            setRepeatType(recurring.repeat_type);
            setRepeat_every(recurring.repeat_every);
            setRepeat_till(moment(recurring.repeat_till).format('YYYY-MM-DD'));
            recurring?.on?.length &&
                recurring.on.map((id: any, index: any) => {
                    setWeek_on(preValue => {
                        preValue.map(val => {
                            if (val.day == id && val.active == false) {
                                val.active = true;
                                onDay.push(val.day);
                            } else if (val.day == id && val.active == true) {
                                val.active = false;
                                const dayOn = onDay.filter((item: any) => item !== val.day);
                                setOnDay(dayOn);
                            }
                        });
                        return [...preValue];
                    });
                });
        } else {
            activeCurrentDay();
        }
    }, []);
    const { t }: any = useTranslation();
    const [loading, setLoading] = useState(false);
    const [repeatType, setRepeatType] = useState<RepeatBookingEnum>(RepeatBookingEnum.DAILY);
    const [endType, setEndType] = useState<EndRepeatBookingEnum>(EndRepeatBookingEnum.NEVER);
    const [repeat_every, setRepeat_every] = useState();
    const [onDay, setOnDay] = useState<number[]>([]);
    const [week_on, setWeek_on] = useState([
        { day: 1, label: 'M', active: false },
        { day: 2, label: 'T', active: false },
        { day: 3, label: 'W', active: false },
        { day: 4, label: 'T', active: false },
        { day: 5, label: 'F', active: false },
        { day: 6, label: 'S', active: false },
        { day: 0, label: 'S', active: false },
    ]);
    const [repeat_till, setRepeat_till] = useState<any>(
        recurring != undefined ? recurring.repeat_till : ''
    );

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const save = () => {
        setLoading(true);
        const repeat_From = start.format('YYYY-MM-DD') + 'T' + start.format('HH:mm');
        const repeat_Till = repeat_till
            ? moment(repeat_till).format('YYYY-MM-DD') + 'T' + start.format('HH:mm')
            : moment().format('YYYY-MM-DD') + 'T' + start.format('HH:mm');
        const repeating = {
            repeat_from: moment(repeat_From).format(),
            repeat_till: moment(repeat_Till).format(),
            repeat_every: repeat_every ? repeat_every : '1',
            repeat_type: repeatType,
            end_type: endType,
            on: repeatType === 'weekly' ? onDay : '',
        };
        onSave(repeating);
    };

    const getCurrentDate = moment(start).weekday();
    const activeCurrentDay = () => {
        week_on.map(day => {
            if (day.day === getCurrentDate) {
                onDay.push(day.day);
                return (day.active = true);
            } else if (day.day !== getCurrentDate) {
                const dayOn = onDay.filter((item: any) => item !== day.day);
                setOnDay(dayOn);
                return (day.active = false);
            }
        });
    };

    const onDayClick = (id: any) => {
        setWeek_on(preValue => {
            preValue.map(val => {
                if (val.day == id && val.active == false) {
                    val.active = true;
                    onDay.push(val.day);
                } else if (val.day == id && val.active == true) {
                    val.active = false;
                    const dayOn = onDay.filter((item: any) => item !== val.day);
                    setOnDay(dayOn);
                }
            });
            return [...preValue];
        });
    };

    const reapeatTill = (e: any) => {
        setRepeat_till(e.value);
    };

    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '480px' }}>
                <ModelHeading>
                    <Heading>{t(title ? title : 'Recurring Appointment')}</Heading>
                    <Cover onClick={onClose}>
                        <i className="fal fa-times"></i>
                    </Cover>
                </ModelHeading>
                <ModelContent>
                    <InputGroup>
                        <Select
                            label={t('Select repeat rule')}
                            disabled={true}
                            value={repeatType}
                            options={repeatOptions}
                            onChange={(val: RepeatBookingEnum) => {
                                setRepeatType(val);
                            }}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Select
                            label={`${t('Every')}(${t(repeatType)})`}
                            value={repeat_every ? repeat_every : '1'}
                            options={days}
                            disabled={true}
                            onChange={(val: any) => {
                                setRepeat_every(val);
                            }}
                        />
                        {repeatType && repeatType === 'weekly' ? (
                            <InputGroup>
                                <DaysName>
                                    {week_on.map((data: any, i: any) => {
                                        return (
                                            <Options
                                                active={data.active}
                                                onClick={() => {
                                                    onDayClick(data.day);
                                                }}>
                                                {data.label}
                                            </Options>
                                        );
                                    })}
                                </DaysName>
                            </InputGroup>
                        ) : (
                            ''
                        )}
                    </InputGroup>

                    <InputGroup>
                        <Input
                            type="date"
                            label={t('Repeat till')}
                            name="repeat_till"
                            min={new Date().toISOString().split('T')[0]}
                            value={repeat_till ? repeat_till : moment().format('YYYY-MM-DD')}
                            onChange={(e: any) => reapeatTill(e)}
                        />
                    </InputGroup>
                </ModelContent>
                <Footer>
                    <Button
                        ifClicked={save}
                        bgtype={'secondary'}
                        disabled={loading}
                        label={t('Apply')}></Button>
                    <Button ifClicked={onClose} bgtype={'discard'} label={t('Cancel')}></Button>
                </Footer>
            </ModelBody>
        </Model>
    );
};

const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    max-width: 82vw;
    padding-top: 0;
`;

const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;

const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;

const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DaysName = styled.div`
    display: flex;
    flex-direction: row;
`;

const Options = styled.div<any>`
    margin-top: 0.5rem;
    height: 2rem;
    width: 2rem;
    border: 1px solid #e5e7eb;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ active }: any) => (active ? `rgb(57, 57, 185)` : 'white')};

    color: ${({ active }: any) => (active ? `white` : 'rgb(57, 57, 185)')};
`;

export default NewRecurringModal;
