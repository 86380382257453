/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * EmptyBasket Component
 * Description: React component for displaying an empty basket or cart, encouraging users to start selecting assets.
 *
 * Purpose: This component provides a visually appealing representation of an empty basket or cart, along with a message encouraging users to start selecting assets.
 */

import emptyCartImg from 'images/cart.svg';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const EmptyBasket = () => {
    const { t }: any = useTranslation();

    return (
        <>
            <Container>
                <Row>
                    <Basket lg={12}>
                        <Image src={emptyCartImg} alt="Cart" />
                        <BasketItem>{t('No Items')}</BasketItem>
                        <BasketContent>
                            {' '}
                            {t('Cart is currently empty. Start selecting services.')}
                        </BasketContent>
                    </Basket>
                </Row>
            </Container>
        </>
    );
};

const Basket = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
`;

const Image = styled.img`
    max-width: 6.42rem;
`;

const BasketItem = styled.div`
    font-weight: 500;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    padding-top: 0.5rem /* 8px */;
`;

const BasketContent = styled.p`
    text-align: center;
    font-size: 0.9rem /* 14.4px */;
    color: rgba(181, 181, 195);
`;

export default EmptyBasket;
