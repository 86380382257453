/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Redux Store Configuration
 * Description: Configures the Redux store with middleware and provides types for RootState, AppDispatch, TAppState, and ReduxDispatch.
 *
 * Store Configuration:
 * - rootReducer: Combined reducers for the application.
 * - composeWithDevTools: Enhances the store with DevTools extension.
 * - thunkMiddleware: Middleware for handling asynchronous actions.
 *
 * Types:
 * - RootState: Type for the complete state of the Redux store.
 * - AppDispatch: Type for the dispatch function provided by the Redux store.
 * - TAppState: Type for the state of the application.
 * - ReduxDispatch: Type for the dispatch function with Thunk support.
 */

import { createStore, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import rootReducer from '../reducers';

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type TAppState = ReturnType<typeof rootReducer>;
export type ReduxDispatch = ThunkDispatch<TAppState, any, Action>;
