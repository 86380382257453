/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * User Reducer
 * Description: This reducer handles the state related to user information.
 *
 * Initial State:
 * - INITIAL_STATE: Initial state for the user reducer.
 *
 * Reducer:
 * - user: Reducer function for handling user-related actions and updating the state.
 */

const INITIAL_STATE = {};
export function user(state = INITIAL_STATE, action: any) {
    return state;
}
