/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Breaks Component
 * Description: This component manages breaks related to a specific day. It allows users to add, view, and delete breaks.
 * It includes features for selecting the day, specifying break timings, and displaying existing breaks.
 *
 * Props:
 * - day: { day: number, from: string, to: string } - The day and timings for breaks.
 * - active: boolean - Indicates whether the breaks are active or not.
 * - onChang: (day: any) => void - Callback function triggered when breaks are changed.
 * - defaultBreak: any - Default breaks to be displayed.
 * - parent: any - Parent component information.
 *
 */

import React, { useEffect, useState } from 'react';
import { Select } from '@components/common';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import useRouter from 'hooks/router';
import { palette } from 'styled/common';
import { IBreaks } from 'interfaces/breaks.interface';
import { useSnackbar } from '../snackbar';
import DeleteModel from '../deleteModel/DeleteModel';
import { calculateHoursDifference } from '../utils';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import LoaderOverlay from '../loaderOverlay';

interface IProps {
    day: {
        day: number;
        from: string;
        to: string;
    };
    active: boolean;
    onChang: (day: any) => void;
    defaultBreak: any;
    parent?: any;
}

const Breaks = (props: any) => {
    const { day, onChang, defaultBreak, parent }: IProps = props;
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const [hour_Diff, setHours_Diff] = useState();
    const [breaks, setBreaks] = useState<any>([]);
    const [dayHours, setDayHours] = useState(day);
    const [deleteId, setDeleteId] = useState<any>();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let breakHours: { label: string; value: any }[] = [];

    useEffect(() => {
        onChang(dayHours);
    }, [onChang, dayHours]);

    useEffect(() => {
        if (parent == 'teamMember') {
            getBreaks();
        }
        if (defaultBreak) {
            setBreaks(defaultBreak);
        }
    }, [defaultBreak, router.query.id]);

    useEffect(() => {
        getWorkingHours();
    }, [dayHours]);

    const getWorkingHours = async () => {
        const difference: any = await calculateHoursDifference(dayHours?.from, dayHours?.to);
        setHours_Diff(difference);
    };

    const days = [
        { day: 1, label: t('Monday') },
        { day: 2, label: t('Tuesday') },
        { day: 3, label: t('Wednesday') },
        { day: 4, label: t('Thursday') },
        { day: 5, label: t('Friday') },
        { day: 6, label: t('Saturday') },
        { day: 0, label: t('Sunday') },
    ];

    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            breakHours.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    const onSubmit = () => {
        let data: any = {
            from: dayHours.from,
            to: dayHours.to,
            day: dayHours.day === 7 ? 0 : dayHours.day,
        };
        setLoading(true);
        api.post(`/breaks/${router.query.id as string}`, data)
            .then((res: any) => {
                setLoading(false);
                breaks.push(res.data);
                openSnackbar(t('Break saved successfully!'));
                getBreaks();
                return res.data;
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    //get breaks
    const getBreaks = () => {
        setLoading(true);
        api.get(`/breaks/all/${router.query.id as string}`)
            .then((res: any) => {
                setLoading(false);
                setBreaks(res.data);
                return res.data;
            })
            .catch(e => {
                setLoading(true);
            });
    };

    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };

    const deleteBreak = (id: any) => {
        setLoading(true);
        api.delete(`/breaks/${id}`)
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t('Break deleted successfully!'));
                setDeleteModel(false);
                const deleteBreaks: any = breaks.filter((item: any) => {
                    return item._id !== id;
                });
                setBreaks(deleteBreaks);
            })
            .catch(e => {
                setLoading(false);
                setDeleteModel(false);
            });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Wrapper>
                <Row>
                    <HourItems lg={12}>
                        <Header>{t('Add break')}</Header>
                        <FormBody>
                            <FormContent>
                                <InnerWrapper>
                                    <Row>
                                        <Col xs={12} sm={8} md={8} lg={8} xl={3}>
                                            <SelectGroup>
                                                <Select
                                                    options={days}
                                                    disabled={true}
                                                    name="day"
                                                    label={t('day')}
                                                    value={
                                                        dayHours.day
                                                            ? days[dayHours.day - 1].label
                                                            : ''
                                                    }
                                                    onChange={(val: string) => {
                                                        let day = days.findIndex(
                                                            days => days.label == val
                                                        );
                                                        day++;
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            day: day,
                                                        }));
                                                    }}
                                                />
                                            </SelectGroup>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={9}>
                                            <InnerWrapper>
                                                <Row>
                                                    <CustomCol xs={4} sm={4} md={4} lg={4} xl={4}>
                                                        <SelectGroup>
                                                            <Select
                                                                options={breakHours}
                                                                disabled={true}
                                                                label={t('from')}
                                                                name="from"
                                                                value={moment(
                                                                    moment().format() +
                                                                        ' ' +
                                                                        dayHours.from,
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                ).format(
                                                                    _userData.user.business_id
                                                                        .time_select === '12'
                                                                        ? 'hh:mm a'
                                                                        : 'HH:mm'
                                                                )}
                                                                onChange={(val: string) => {
                                                                    setDayHours(prevState => ({
                                                                        ...prevState,
                                                                        from: val,
                                                                    }));
                                                                }}
                                                            />
                                                        </SelectGroup>
                                                    </CustomCol>
                                                    <CustomCol xs={4} sm={4} md={4} lg={4} xl={4}>
                                                        <SelectGroup>
                                                            <Select
                                                                options={breakHours}
                                                                label={t('to')}
                                                                disabled={true}
                                                                name="to"
                                                                value={moment(
                                                                    moment().format() +
                                                                        ' ' +
                                                                        dayHours.to,
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                ).format(
                                                                    _userData.user.business_id
                                                                        .time_select === '12'
                                                                        ? 'hh:mm a'
                                                                        : 'HH:mm'
                                                                )}
                                                                onChange={(val: string) => {
                                                                    setDayHours(prevState => ({
                                                                        ...prevState,
                                                                        to: val,
                                                                    }));
                                                                }}
                                                            />
                                                        </SelectGroup>
                                                    </CustomCol>
                                                    <CustomCol
                                                        xs={2}
                                                        sm={2}
                                                        md={2}
                                                        lg={2}
                                                        xl={2}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                        <ClockIcon>
                                                            <Icon className="fal fa-clock "></Icon>
                                                            {hour_Diff}h
                                                        </ClockIcon>
                                                    </CustomCol>
                                                    <FlexEnd xs={2} sm={2} md={2} lg={2} xl={2}>
                                                        <AddButton>
                                                            <Button
                                                                type={'button'}
                                                                bgtype={'secondary'}
                                                                label={t('Add Break')}
                                                                ifClicked={onSubmit}></Button>
                                                        </AddButton>
                                                    </FlexEnd>
                                                </Row>
                                            </InnerWrapper>
                                        </Col>
                                    </Row>
                                </InnerWrapper>
                            </FormContent>
                        </FormBody>
                    </HourItems>
                    <Col lg={12}>
                        <div>
                            {days.map((day: any, i: any) => (
                                <BreakOff key={i}>
                                    <div>{day.label}</div>
                                    {breaks &&
                                        breaks.map((breaks: IBreaks, i: any) =>
                                            day.day == breaks.day ? (
                                                <InnerWrapper
                                                    key={i}
                                                    style={{ marginBottom: '0.5rem' }}>
                                                    <Row>
                                                        <FlexEnd xs={4} sm={4} md={4} lg={4}>
                                                            <div>
                                                                <CustomSpan>
                                                                    {t('From')}&nbsp;{' '}
                                                                </CustomSpan>
                                                                <span>
                                                                    {breaks.from
                                                                        ? moment(
                                                                              moment().format() +
                                                                                  ' ' +
                                                                                  breaks.from,
                                                                              'YYYY-MM-DD HH:mm:ss'
                                                                          ).format(
                                                                              _userData.user
                                                                                  .business_id
                                                                                  .time_select ===
                                                                                  '12'
                                                                                  ? 'hh:mm a'
                                                                                  : 'HH:mm'
                                                                          )
                                                                        : t('No Break')}
                                                                </span>
                                                            </div>
                                                        </FlexEnd>
                                                        <FlexEnd xs={4} sm={4} md={4} lg={4}>
                                                            <div>
                                                                <CustomSpan>
                                                                    {t('To')}&nbsp;{' '}
                                                                </CustomSpan>
                                                                <span>
                                                                    {breaks.to
                                                                        ? moment(
                                                                              moment().format() +
                                                                                  ' ' +
                                                                                  breaks.to,
                                                                              'YYYY-MM-DD HH:mm:ss'
                                                                          ).format(
                                                                              _userData.user
                                                                                  .business_id
                                                                                  .time_select ===
                                                                                  '12'
                                                                                  ? 'hh:mm a'
                                                                                  : 'HH:mm'
                                                                          )
                                                                        : t('No Break')}
                                                                </span>
                                                            </div>
                                                        </FlexEnd>
                                                        <FlexEnd xs={4} sm={4} md={4} lg={4}>
                                                            <DeletIcon
                                                                onClick={() =>
                                                                    openDeleteModel(breaks._id)
                                                                }>
                                                                <i className="fal fa-trash-alt"></i>
                                                            </DeletIcon>
                                                        </FlexEnd>
                                                    </Row>
                                                </InnerWrapper>
                                            ) : (
                                                ''
                                            )
                                        )}
                                </BreakOff>
                            ))}
                        </div>
                        {deleteModel && (
                            <DeleteModel
                                id={deleteId}
                                name={t('Break')}
                                content={t('Are you sure you want to delete?')}
                                loading={loading}
                                onClick={() => deleteBreak(deleteId)}
                                onClose={() => {
                                    setDeleteModel(false);
                                }}
                                onDelete={() => {
                                    setDeleteModel(false);
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const InnerWrapper = styled(Container)`
    padding: 0;
`;

const HourItems = styled(Col)``;

const Header = styled.div`
    font-weight: 500 !important;
    font-size: 1.4rem !important;
    line-height: 1.25rem !important;
    margin-top: 0.75rem !important;
`;

const FormBody = styled.div`
    margin-top: 1rem;
`;

const FormContent = styled.div`
    display: flex !important;
    align-items: center !important;
`;

const SelectGroup = styled.div`
    margin-bottom: 0.5rem;
`;

const CustomCol = styled(Col)`
    ${media.xs`
	padding-right:0rem;
	`}
    ${media.sm`
    padding-right:auto;
	`}
	${media.md`
    padding-right:auto;
	`}
	${media.lg`
    padding-right:auto;
	`}
`;

const FlexEnd = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const ClockIcon = styled.span`
    color: rgba(223, 226, 230);
    font-weight: 300;
    font-size: 0.9rem;
`;

const Icon = styled.i`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-right: 0.5rem !important;
`;

const AddButton = styled.div`
    display: flex;
    border: none;
    cursor: pointer;
`;

const BreakOff = styled.div`
    margin-top: 0.4rem;
    padding-top: 1rem;
    border-top: 1px solid ${palette.grey.lightest};
`;

const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;

export const CustomSpan = styled.span`
    font-weight: 600;
`;

export default Breaks;
