/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Calendar Component for managing appointments and team calendars.
 *
 * Component Description:
 * - Renders the CalendarHeader, CalendarLayout, BookingsCalendar, and TeamCalendar components.
 * - Manages state for view, date range, team calendar visibility, and other properties.
 * - Utilizes the Layout component from common components.
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import { CalendarHeader, CalendarLayout } from '@components/calendar';
import { BookingsCalendar } from '@components/calendar';
import TeamCalendar from '@components/calendar/teamCalendar/TeamCalendar';
import { useAppSelector } from 'hooks/redux';

interface IProps {
    next: () => void;
    prev: () => void;
    today: () => void;
    changeView: (view: string) => void;
    showTeamCalenda: boolean;
    teamDate: () => void;
}

const Calendar = () => {
    const _useRef = useRef<IProps>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const { current_view }: any = useAppSelector(state => state.calenderCurrentView);
    const [view, setView] = useState<any>(
        _userData !== undefined && _userData?.user_login === 'serviceProvider'
            ? 'timeGridDay'
            : current_view
            ? current_view
            : _userData.user.current_view
            ? _userData.user.current_view
            : 'timeGridDay'
    );
    const [dateRange, setDateRange] = useState('');
    const [showTeamCalendar, setShowTeamCalendar] = useState(false);
    const [serviceProviderId, setServiceProviderId] = useState();
    const [teamCalendarDate, setTeamCalendarDate] = useState();
    const [pickDate, setPickDate] = useState<any>();
    const [calendar_size, setCalendar_Size] = useState<any>();
    const [vipAppointment, setVipAppointment] = useState<any>(false);

    useEffect(() => {
        changeView(
            _userData !== undefined && _userData?.user_login === 'serviceProvider'
                ? 'timeGridDay'
                : current_view
        );
        if (current_view == 'teamGridWeek') {
            setView('timeGridDay');
            setShowTeamCalendar(true);
        }
    }, []);

    const next = () => {
        if (_useRef?.current) {
            _useRef?.current?.next();
        }
    };

    const prev = () => {
        if (_useRef?.current) {
            _useRef?.current?.prev();
        }
    };

    const today = () => {
        if (_useRef?.current) {
            _useRef?.current?.today();
        }
    };

    const changeView = (view: string) => {
        if (view != 'teamGridWeek') {
            setShowTeamCalendar(false);
            if (_useRef?.current) {
                _useRef?.current?.changeView(view);
                setView(view);
            }
        } else {
            setShowTeamCalendar(true);
        }
    };

    return (
        <>
            <CalendarHeader
                setServiceProviderId={setServiceProviderId}
                setCalendar_Size={setCalendar_Size}
                goNext={next}
                goPrev={prev}
                goToday={today}
                currentView={view}
                setPickDate={setPickDate}
                changeView={changeView}
                dateRange={dateRange}
                setDateRange={setDateRange}
                setTeamCalendarDate={setTeamCalendarDate}
                showTeamCalendar={showTeamCalendar}
                teamCalendarDate={teamCalendarDate}
                setVipAppointment={setVipAppointment}
                vipAppointment={vipAppointment}
            />
            {showTeamCalendar != true ? (
                <BookingsCalendar
                    teamCalendarDate={teamCalendarDate}
                    setTeamCalendarDate={setTeamCalendarDate}
                    ref={_useRef}
                    serviceProviderId={
                        _userData !== undefined && _userData?.user_login === 'serviceProvider'
                            ? _userData.user._id
                            : serviceProviderId
                    }
                    calendar_size={calendar_size}
                    setView={setView}
                    setDateRange={setDateRange}
                    initialView={view}
                    pickDate={pickDate}
                    dateRange={dateRange}
                    vipAppointment={vipAppointment}
                />
            ) : (
                <TeamCalendar
                    ref={_useRef}
                    teamCalendarDate={teamCalendarDate}
                    setTeamCalendarDate={setTeamCalendarDate}
                    serviceProviderId={serviceProviderId}
                    setDateRange={setDateRange}
                    viewMode={view}
                    pickDate={pickDate}
                    calendar_size={calendar_size}
                    vipAppointment={vipAppointment}
                />
            )}
            <style>{`
            body {
                background-color: #fff;
            }`}</style>
        </>
    );
};

Calendar.Layout = CalendarLayout;
export default Calendar;
