/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * GiftCard Component
 * Description: This component manages gift cards, allowing users to view existing gift cards, add new ones, and expire all gift cards at once.
 * It includes functionalities such as viewing, adding, and expiring gift cards. Real-time updates are handled for gift card changes.
 *
 */

import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, Layout, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { api } from 'helpers/auth-axios';
import { CustomButtom } from '../businessDetails/BusinessDetails';
import { CustomRow } from '@components/team/create/createTeamMember/CreateTeamMember';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MDBDataTableV5 } from 'mdbreact';
import { NotFound } from '../../team/list/serviceProviderList/ServiceProviderList';
import { HeaderCustom } from '../businessServices/EditService';
import ConfirmationModel from './ConfirmationModel';
import { palette } from 'styled/common';

const GiftCard = () => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [giftCards, setGiftCards] = useState<any>([]);
    const [addCard, setAddCard] = useState(false);
    const [confirmationModel, setConfirmationModel] = useState(false);
    const [type, setType] = useState('');
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        price: Yup.number().required(t('This field is required')),
        expiry_date: Yup.string().required(t('This field is required')),
        total_coupen: Yup.string().required(t('This field is required')),
        price_type: Yup.string().required(t('This field is required')),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, getValues, formState, trigger, clearErrors } =
        useForm(formOptions);
    const { errors } = formState;
    const [loading, setLoading] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [tableData, setTableData] = useState({
        columns: [
            {
                label: t('Name'),
                field: 'name',
                width: 470,
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: `${_userData?.user?.business_id?.currency} / %`,
                field: 'price',
                width: 470,
                sort: String('disabled'),
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: t('Redeem'),
                field: 'redeem',
                width: 470,
                sort: String('disabled'),
            },
            { label: t('Expiry Date'), field: 'expiry_date', width: 470, sort: String('disabled') },
            { label: t('Is Used'), field: 'is_used', width: 470, sort: String('disabled') },
        ],
        rows: [],
    });

    useEffect(() => {
        getGiftCards();
    }, []);

    useEffect(() => {
        if (giftCards?.length) {
            giftCards.map((e: any) => createTable(e));
        }
    }, [giftCards]);

    // Set initial values
    useEffect(() => {
        setTimeout(() => {
            setValue('name', '');
            setValue('price', '');
            setValue('total_coupen', '');
            setValue('expiry_date', '');
            setValue('price_type', '');
        });
    }, [setValue, addCard]);

    async function onChange({ name, value }: { name: any; value: any }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        setLoading(true);
        api.post(`gift-card/create`, {
            ...data,
            in_percentage: type === 'Percentage' ? true : false,
            percentage: type === 'Percentage' ? data?.price : 0,
        })
            .then((res: any) => {
                setLoading(false);
                setAddCard(false);
                openSnackbar(t('Gift Card added successfully!'));
                getGiftCards();
                setValue('name', '');
                setValue('price', '');
                setValue('total_coupen', '');
                setValue('expiry_date', '');
                setValue('price_type', '');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    //get gift cards data
    const getGiftCards = () => {
        setLoading(true);
        api.get(`gift-card`)
            .then(res => {
                setTableData((preValue): any => {
                    return {
                        columns: [...preValue.columns],
                        rows: [],
                    };
                });
                setGiftCards(res.data);
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const createTable = (row?: any) => {
        let _obj = {
            name: <Text>{row?.name}</Text>,
            price: (
                <Text>
                    {row?.in_percentage === true
                        ? row.percentage + '%'
                        : _userData?.user?.business_id?.currency + row?.price}
                </Text>
            ),
            redeem: <Text>{row?.redeem}</Text>,
            expiry_date: <Text>{moment(row?.expiry_date).format('YYYY-MM-DD')} </Text>,
            is_used: <Text>{row?.is_used === true ? 'true' : 'false'}</Text>,
        };

        setTableData((preValue): any => {
            return {
                columns: [...preValue.columns],
                rows: [...preValue.rows, _obj],
            };
        });
    };

    const expireAllGiftCards = () => {
        setLoading(true);
        api.post(`gift-card/expire`)
            .then(res => {
                openSnackbar(t('Gift Card expired successfully!'));
                getGiftCards();
                setLoading(false);
                setConfirmationModel(false);
            })
            .catch(e => {
                setLoading(false);
                setConfirmationModel(false);
            });
    };

    return (
        <>
            <Wrapper>
                <CustomRow>
                    {loading && <LoaderOverlay />}
                    {addCard === false ? (
                        <Col lg={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '1rem 0',
                                    justifyContent: 'space-between',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Link to="/settings/promotions">
                                        <Icon className="fal fa-chevron-left"></Icon>
                                    </Link>
                                    <Header>{t('Gift Cards')}</Header>
                                </div>
                                <div style={{ gap: '0.5rem', display: 'flex' }}>
                                    {giftCards?.length ? (
                                        <Button
                                            label={t('Expire All')}
                                            bgtype="secondary"
                                            ifClicked={() => {
                                                setConfirmationModel(true);
                                            }}></Button>
                                    ) : (
                                        ''
                                    )}
                                    <Button
                                        label={t('Add gift cards')}
                                        bgtype="secondary"
                                        ifClicked={() => setAddCard(true)}></Button>
                                </div>
                            </div>
                            {giftCards?.length ? (
                                <MDBDataTableV5
                                    data={tableData}
                                    entries={20}
                                    pagesAmount={40}
                                    noBottomColumns={true}
                                    searchTop={false}
                                    searchBottom={false}
                                    responsive={true}
                                    striped={true}
                                />
                            ) : (
                                <CustomDiv>
                                    <NotFound>{t('No Record Found!')}</NotFound>
                                </CustomDiv>
                            )}
                        </Col>
                    ) : (
                        <Col lg={6}>
                            <HeaderCustom lg={12} style={{ marginTop: '1rem' }}>
                                <HeadingAddCard>{t('Add Gift Cards')}</HeadingAddCard>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => {
                                        if (!loading) {
                                            setAddCard(false);
                                            clearErrors([
                                                'name',
                                                'price',
                                                'total_coupen',
                                                'expiry_date',
                                                'price_type',
                                            ]);
                                        }
                                    }}></Cross>
                            </HeaderCustom>
                            <Card>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Wrapper>
                                        <Row>
                                            <CustomCol lg={12}>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Title')}
                                                        name="name"
                                                        value={getValues('name')}
                                                        onChange={onChange}
                                                        error={errors?.name as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup
                                                    style={{ display: 'flex', gap: '0.3rem' }}>
                                                    <Input
                                                        label={
                                                            type === 'Percentage'
                                                                ? t('Percentage')
                                                                : t('Price')
                                                        }
                                                        name="price"
                                                        allowOnlyNumbersAndDecimal={true}
                                                        value={getValues('price')}
                                                        onChange={onChange}
                                                        error={errors.price as any}
                                                    />
                                                    <Select
                                                        label={t('Type')}
                                                        name="price_type"
                                                        disabled={true}
                                                        options={[
                                                            {
                                                                label: 'Fixed',
                                                            },
                                                            {
                                                                label: 'Percentage',
                                                            },
                                                        ]}
                                                        value={getValues('price_type')}
                                                        onChange={(val: string) => {
                                                            console.log('val', val);
                                                            setType(val);
                                                            onChange({
                                                                name: 'price_type',
                                                                value: val,
                                                            });
                                                        }}
                                                        error={errors.price_type as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Input
                                                        label={t(
                                                            'Total number of gift cards you want?'
                                                        )}
                                                        name="total_coupen"
                                                        value={getValues('total_coupen')}
                                                        allowPhoneNumberOnly={true}
                                                        onChange={onChange}
                                                        error={errors.total_coupen as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Expiry Date')}
                                                        name="expiry_date"
                                                        type={'date'}
                                                        value={getValues('expiry_date')}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        onChange={(val: any) => {
                                                            console.log('val', val?.value);
                                                            onChange({
                                                                name: 'expiry_date',
                                                                value: moment(val?.value).format(
                                                                    'YYYY-MM-DD'
                                                                ),
                                                            });
                                                        }}
                                                        error={errors.expiry_date as any}
                                                    />
                                                </InputGroup>
                                                <CustomButtom>
                                                    <Button
                                                        type="submit"
                                                        bgtype="secondary"
                                                        label={t('Add')}></Button>
                                                </CustomButtom>
                                            </CustomCol>
                                        </Row>
                                    </Wrapper>
                                </form>
                            </Card>
                        </Col>
                    )}
                    {confirmationModel && (
                        <ConfirmationModel
                            onClose={() => setConfirmationModel(false)}
                            onClick={() => {
                                setTableData((preValue): any => {
                                    return {
                                        columns: [...preValue.columns],
                                        rows: [],
                                    };
                                });
                                expireAllGiftCards();
                            }}
                        />
                    )}
                </CustomRow>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CustomCol = styled(Col)`
    ${media.xs`
    border-right: none;
  `}
    ${media.sm`
    border-right: none;
  `}
    ${media.md`
    border-right: none;
  `}
    ${media.lg`
  `}
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

const CustomDiv = styled.div`
    margin-top: 2rem;
`;

const Text = styled.div``;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
`;

export const HeadingAddCard = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

GiftCard.Layout = Layout;
export default GiftCard;
