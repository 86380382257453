/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ColorPicker Component
 * Description: This component provides a color picker with swatch display and block picker for selecting colors. It supports onChange callback and a callBack prop to control the visibility of the color picker.
 *
 * Props:
 * - color: string - The initial color value for the color picker.
 * - onChange: (color: string) => void - Callback function triggered when the color is changed/selected.
 * - callBack: any - Prop to control the visibility of the color picker.
 */

import { useEffect, useState } from 'react';
import { BlockPicker, ColorResult } from 'react-color';
import './ColorPicker.scss';

const ColorPicker = ({
    color,
    onChange,
    callBack,
}: {
    color?: string;
    onChange?(color: string): void;
    callBack?: any;
}) => {
    useEffect(() => {
        if (callBack) {
            setShowColorPicker(false);
        }
    }, [callBack]);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [currColor, setCurrColor] = useState(color || '#000000');

    const handleClick = () => {
        setShowColorPicker(prevVal => !prevVal);
    };

    const handleChange = (color: ColorResult) => {
        setCurrColor(color.hex);

        if (onChange) onChange(color.hex);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        console.log(event);
        const validKeys = ['Enter'];

        if (validKeys.indexOf(event.key) !== -1) {
            event.preventDefault();
            if (event.key === 'Enter') {
                setShowColorPicker(false);
            }
        }
    };

    return (
        <div className="colorPicker" onKeyDown={handleKeyDown}>
            <div className="colorPicker--swatch" onClick={handleClick}>
                <div className="swatch-color" style={{ backgroundColor: currColor }}></div>
                <div className="swatch-text pl-4">
                    <span className="font-medium pr-2">#</span>
                    <span>{currColor.slice(1)}</span>
                </div>
            </div>
            {showColorPicker && (
                <div className="colorPicker--popover">
                    <BlockPicker color={currColor} onChange={handleChange} />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
