/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * App Routes Component
 * Description: This component defines the routes for various pages in the application,
 * handling navigation based on user roles and permissions.
 */

import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import Login from '../pages/Login';
import Register from '../pages/Register';
import Dashboard from '../pages/Dashboard';
import Settings from '../pages/Settings';
import Calendar from '../pages/Calendar';

import BusinessHours from '../pages/BusinessHours';
import BusinessDetails from '../pages/Details';
import Booking from '../pages/Booking';
import Services from '../pages/Services';
import OnlineBooking from '../pages/OnlineBooking';

import Customers from '../pages/Customers';
import CreateCustomer from '../pages/CreateCustomer';

import ServiceProvider from '../pages/ServiceProvider';
import CreateServiceProvider from '../pages/CreateServiceProvider';
import Checkout from '../pages/Checkout';
import Billing from 'pages/Billing';

import CreateTeam from '../pages/CreateTeamMember';
import CreateOther from '../pages/CreateOther';
import Team from '../pages/Team';

import Teams from 'pages/Teams';
import Other from 'pages/Other';

import NotFound from 'pages/NotFound';
import ForgotPassword from 'pages/ForgotPassword';
import Admin from '../pages/Admin';
import CustomerLogin from 'pages/CustomerLogin';
import EditAppointmentDrawerForCustomer from '../components/appointments/editAppointmentDrawer/EditAppointmentDrawerForCustomer';
import NewAppointmentDrawerForCustomer from '../components/appointments/editAppointmentDrawer/NewAppointmentDrawerForCustomer';
import Notifications from 'pages/Notifications';
import PromotionOptions from 'pages/PromotionOptions';
import AppointmentReports from 'pages/AppointmentReports';
import VideoCallPage from 'pages/VideoCallAppointment';
import CustomiseAbleCalendar from '../pages/CustomiseAbleCalendar';
import CustomersOption from 'pages/CustomerOptions';
import CalendarOptions from 'pages/CalendarOptions';
import CreateService from '@components/settings/businessServices/CreateService';
import CreateBusiness from 'pages/CreateNewBusiness';
import GiftCard from '@components/settings/PromotionsAndGiftCard/GiftCard';
import LoyalityDiscount from '@components/settings/PromotionsAndGiftCard/LoyalityDiscount';
import PackagesService from '@components/settings/PromotionsAndGiftCard/PackagesService';

const AppRoutes = () => {
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let _url = JSON.parse(localStorage.getItem('url') || '{}');

    return (
        <>
            <Routes>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/:id/:id/appointment" element={<EditAppointmentDrawerForCustomer />} />
            </Routes>
            {_userData?.user === undefined ? (
                <Routes>
                    <Route
                        path="/settings/online-booking"
                        element={<PrivateRoute Component={OnlineBooking} />}
                    />
                    <Route path="login" element={<Login />} />
                    <Route path="/:id/login" element={<CustomerLogin />} />
                    <Route path="register" element={<Register />} />

                    <Route path="/admin" element={<PrivateRoute Component={Admin} />} />

                    <Route path="/admin/login" element={<Login />} />
                    <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
                    <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />

                    <Route path="/sales" element={<PrivateRoute Component={Checkout} />} />
                    <Route
                        path="/settings/business/new"
                        element={<PrivateRoute Component={CreateBusiness} />}
                    />

                    <Route path="/customers/*" element={<PrivateRoute Component={Customers} />} />
                    <Route
                        path="/customer"
                        element={<PrivateRoute Component={CustomersOption} />}
                    />
                    <Route
                        path="/customers/create"
                        element={<PrivateRoute Component={CreateCustomer} />}
                    />

                    <Route path="/team" element={<PrivateRoute Component={Teams} />} />
                    <Route
                        path="/team/service-provider-list/*"
                        element={<PrivateRoute Component={ServiceProvider} />}
                    />
                    <Route
                        path="/team/service-provider/create"
                        element={<PrivateRoute Component={CreateServiceProvider} />}
                    />
                    <Route path="/team/team-list/*" element={<PrivateRoute Component={Team} />} />
                    <Route
                        path="/team/team-member/create"
                        element={<PrivateRoute Component={CreateTeam} />}
                    />
                    <Route path="/team/other-list/*" element={<PrivateRoute Component={Other} />} />
                    <Route
                        path="/team/other/create"
                        element={<PrivateRoute Component={CreateOther} />}
                    />

                    <Route path="/settings" element={<PrivateRoute Component={Settings} />} />

                    <Route
                        path="/settings/business-hours"
                        element={<PrivateRoute Component={BusinessHours} />}
                    />
                    <Route
                        path="/settings/details"
                        element={<PrivateRoute Component={BusinessDetails} />}
                    />

                    <Route
                        path="/settings/booking-settings"
                        element={<PrivateRoute Component={Booking} />}
                    />

                    <Route
                        path="/settings/promotions"
                        element={<PrivateRoute Component={PromotionOptions} />}
                    />

                    <Route
                        path="/promotion/gift-card"
                        element={<PrivateRoute Component={GiftCard} />}
                    />

                    <Route
                        path="/promotion/loyality-discount"
                        element={<PrivateRoute Component={LoyalityDiscount} />}
                    />
                    <Route
                        path="/promotion/package"
                        element={<PrivateRoute Component={PackagesService} />}
                    />

                    <Route path="/services" element={<PrivateRoute Component={Services} />} />
                    <Route
                        path="/services/create"
                        element={<PrivateRoute Component={CreateService} />}
                    />

                    <Route
                        path="/settings/billing"
                        element={<PrivateRoute Component={Billing} />}
                    />

                    <Route path="/calendar" element={<PrivateRoute Component={Calendar} />} />
                    <Route
                        path="/customise-calendar"
                        element={<PrivateRoute Component={CustomiseAbleCalendar} />}
                    />
                    <Route
                        path="/select-calendar"
                        element={<PrivateRoute Component={CalendarOptions} />}
                    />
                    <Route
                        path="/settings/notification"
                        element={<PrivateRoute Component={Notifications} />}
                    />
                    <Route
                        path="/customer/appointment"
                        element={<NewAppointmentDrawerForCustomer />}
                    />
                    <Route path="/live-video-appointment/:id/:token" element={<VideoCallPage />} />
                    <Route
                        path="/customer-reports"
                        element={<PrivateRoute Component={AppointmentReports} />}
                    />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/live-video-appointment/:id/:token" element={<VideoCallPage />} />
                    {_url === '/admin' && (
                        <Route path="/admin" element={<PrivateRoute Component={Admin} />} />
                    )}
                    {_userData?.user_login === 'customer' ? (
                        <>
                            <Route
                                path="/customer/appointment"
                                element={<NewAppointmentDrawerForCustomer />}
                            />
                            <Route
                                path="/customers/*"
                                element={<PrivateRoute Component={Customers} />}
                            />
                            <Route
                                path="/customer"
                                element={<PrivateRoute Component={CustomersOption} />}
                            />
                            <Route
                                path="/customer-reports"
                                element={<PrivateRoute Component={AppointmentReports} />}
                            />
                        </>
                    ) : _userData.user?.permissions?.customers ? (
                        ''
                    ) : (
                        <Route path="/customers/*" element={<NotFound />} />
                    )}

                    <Route path="login" element={<Login />} />

                    <Route path="register" element={<Register />} />
                    {_userData?.user?.role === 'ADMIN' ? (
                        <>
                            <Route path="/admin" element={<PrivateRoute Component={Admin} />} />
                            <Route
                                path="/customers/*"
                                element={<PrivateRoute Component={Customers} />}
                            />
                            <Route
                                path="/customers/create"
                                element={<PrivateRoute Component={CreateCustomer} />}
                            />
                            <Route
                                path="/customer"
                                element={<PrivateRoute Component={CustomersOption} />}
                            />
                            <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
                            <Route
                                path="/dashboard"
                                element={<PrivateRoute Component={Dashboard} />}
                            />
                            <Route path="/team" element={<PrivateRoute Component={Teams} />} />
                            <Route
                                path="/team/service-provider-list/*"
                                element={<PrivateRoute Component={ServiceProvider} />}
                            />
                            <Route
                                path="/team/service-provider/create"
                                element={<PrivateRoute Component={CreateServiceProvider} />}
                            />
                            <Route
                                path="/team/team-list/*"
                                element={<PrivateRoute Component={Team} />}
                            />
                            <Route
                                path="/team/team-member/create"
                                element={<PrivateRoute Component={CreateTeam} />}
                            />
                            <Route
                                path="/team/other-list/*"
                                element={<PrivateRoute Component={Other} />}
                            />
                            <Route
                                path="/team/other/create"
                                element={<PrivateRoute Component={CreateOther} />}
                            />
                            <Route
                                path="/settings"
                                element={<PrivateRoute Component={Settings} />}
                            />

                            <Route
                                path="/settings/business-hours"
                                element={<PrivateRoute Component={BusinessHours} />}
                            />
                            <Route
                                path="/settings/details"
                                element={<PrivateRoute Component={BusinessDetails} />}
                            />

                            <Route
                                path="/settings/booking-settings"
                                element={<PrivateRoute Component={Booking} />}
                            />
                            <Route
                                path="/settings/business/new"
                                element={<PrivateRoute Component={CreateBusiness} />}
                            />

                            <Route
                                path="/services"
                                element={<PrivateRoute Component={Services} />}
                            />
                            <Route
                                path="/services/create"
                                element={<PrivateRoute Component={CreateService} />}
                            />

                            <Route
                                path="/settings/billing"
                                element={<PrivateRoute Component={Billing} />}
                            />
                            <Route
                                path="/settings/online-booking"
                                element={<PrivateRoute Component={OnlineBooking} />}
                            />
                            <Route
                                path="/calendar"
                                element={<PrivateRoute Component={Calendar} />}
                            />
                            <Route
                                path="/customise-calendar"
                                element={<PrivateRoute Component={CustomiseAbleCalendar} />}
                            />
                            <Route
                                path="/select-calendar"
                                element={<PrivateRoute Component={CalendarOptions} />}
                            />
                            <Route
                                path="/settings/notification"
                                element={<PrivateRoute Component={Notifications} />}
                            />
                        </>
                    ) : (
                        <Route path="/admin" element={<NotFound />} />
                    )}
                    <Route path="/:id/login" element={<CustomerLogin />} />
                    <Route path="/admin/login" element={<Login />} />
                    {_userData.user.permissions?.dashboard ? (
                        <>
                            <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
                            <Route
                                path="/dashboard"
                                element={<PrivateRoute Component={Dashboard} />}
                            />
                        </>
                    ) : (
                        <Route path="/" element={<NotFound />} />
                    )}
                    {_userData?.user?.permissions?.sales ? (
                        <>
                            <Route path="/sales" element={<PrivateRoute Component={Checkout} />} />
                        </>
                    ) : (
                        <Route path="/sales" element={<NotFound />} />
                    )}

                    {_userData.user?.permissions?.customers ? (
                        <>
                            <Route
                                path="/customers/*"
                                element={<PrivateRoute Component={Customers} />}
                            />
                            <Route
                                path="/customers/create"
                                element={<PrivateRoute Component={CreateCustomer} />}
                            />
                            <Route
                                path="/customer"
                                element={<PrivateRoute Component={CustomersOption} />}
                            />
                            <Route
                                path="/customer-reports"
                                element={<PrivateRoute Component={AppointmentReports} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/customers/*" element={<NotFound />} />
                            <Route path="/customers/:id/edit" element={<NotFound />} />
                            <Route path="/customers/create" element={<NotFound />} />
                            <Route path="/customer" element={<NotFound />} />
                            <Route path="/customer-reports" element={<NotFound />} />
                        </>
                    )}
                    {_userData?.user?.permissions?.team ? (
                        <>
                            <Route path="/team" element={<PrivateRoute Component={Teams} />} />
                            <Route
                                path="/team/service-provider-list/*"
                                element={<PrivateRoute Component={ServiceProvider} />}
                            />
                            <Route
                                path="/team/service-provider/create"
                                element={<PrivateRoute Component={CreateServiceProvider} />}
                            />
                            <Route
                                path="/team/team-list/*"
                                element={<PrivateRoute Component={Team} />}
                            />
                            <Route
                                path="/team/team-member/create"
                                element={<PrivateRoute Component={CreateTeam} />}
                            />
                            <Route
                                path="/team/other-list/*"
                                element={<PrivateRoute Component={Other} />}
                            />
                            <Route
                                path="/team/other/create"
                                element={<PrivateRoute Component={CreateOther} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/team" element={<NotFound />} />
                            <Route path="/team/service-provider-list/*" element={<NotFound />} />
                            <Route path="/team/service-provider/create" element={<NotFound />} />
                            <Route path="/team/service-provider/:id/edit" element={<NotFound />} />
                            <Route path="/team/team-list/*" element={<NotFound />} />
                            <Route path="/team/team-member/create" element={<NotFound />} />
                            <Route path="/team/team-member/:id/edit" element={<NotFound />} />
                            <Route path="/team/other-list/*" element={<NotFound />} />
                            <Route path="/team/other/create" element={<NotFound />} />
                            <Route path="/team/others/:id/edit" element={<NotFound />} />
                        </>
                    )}

                    {_userData?.user?.permissions?.business ? (
                        <>
                            <Route
                                path="/settings"
                                element={<PrivateRoute Component={Settings} />}
                            />

                            <Route
                                path="/settings/business-hours"
                                element={<PrivateRoute Component={BusinessHours} />}
                            />
                            <Route
                                path="/settings/details"
                                element={<PrivateRoute Component={BusinessDetails} />}
                            />

                            <Route
                                path="/settings/booking-settings"
                                element={<PrivateRoute Component={Booking} />}
                            />

                            <Route
                                path="/settings/promotions"
                                element={<PrivateRoute Component={PromotionOptions} />}
                            />
                            <Route
                                path="/promotion/gift-card"
                                element={<PrivateRoute Component={GiftCard} />}
                            />
                            <Route
                                path="/promotion/loyality-discount"
                                element={<PrivateRoute Component={LoyalityDiscount} />}
                            />
                            <Route
                                path="/promotion/package"
                                element={<PrivateRoute Component={PackagesService} />}
                            />
                            <Route
                                path="/services"
                                element={<PrivateRoute Component={Services} />}
                            />
                            <Route
                                path="/services/create"
                                element={<PrivateRoute Component={CreateService} />}
                            />

                            <Route
                                path="/settings/billing"
                                element={<PrivateRoute Component={Billing} />}
                            />
                            <Route
                                path="/settings/online-booking"
                                element={<PrivateRoute Component={OnlineBooking} />}
                            />
                            <Route
                                path="/settings/business/new"
                                element={<PrivateRoute Component={CreateBusiness} />}
                            />
                            <Route
                                path="/settings/notification"
                                element={<PrivateRoute Component={Notifications} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/settings" element={<NotFound />} />
                            <Route path="/settings/business-hours" element={<NotFound />} />
                            <Route path="/settings/details" element={<NotFound />} />
                            <Route path="/settings/booking-settings" element={<NotFound />} />
                            <Route path="/settings/promotions" element={<NotFound />} />
                            <Route path="/promotion/gift-card" element={<NotFound />} />
                            <Route path="/promotion/loyality-discount" element={<NotFound />} />
                            <Route path="/promotion/package" element={<NotFound />} />
                            <Route path="/services" element={<NotFound />} />
                            <Route path="services/create" element={<NotFound />} />
                            <Route path="/settings/billing" element={<NotFound />} />
                            <Route path="/settings/online-booking" element={<NotFound />} />
                            <Route path="/settings/notification" element={<NotFound />} />
                            <Route path="/settings/business/new" element={<NotFound />} />
                        </>
                    )}

                    {_userData?.user?.permissions?.calendar ? (
                        <>
                            <Route
                                path="/calendar"
                                element={<PrivateRoute Component={Calendar} />}
                            />
                            <Route
                                path="/customise-calendar"
                                element={<PrivateRoute Component={CustomiseAbleCalendar} />}
                            />
                            <Route
                                path="/select-calendar"
                                element={<PrivateRoute Component={CalendarOptions} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/calendar" element={<NotFound />} />
                            <Route path="/customise-calendar" element={<NotFound />} />
                            <Route path="/select-calendar" element={<NotFound />} />
                        </>
                    )}
                </Routes>
            )}
        </>
    );
};

export default AppRoutes;
