/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SendMessage Component
 * Purpose: This component provides a modal for sending text messages and emails. It supports sending messages to individual recipients or all users. The component includes tabs for selecting the message type (text or email) and input fields for composing the message or email. Additionally, it handles validation, error handling, and displays a loading overlay during the sending process.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the modal is closed.
 * - name: any - The name of the recipient.
 * - phone: any - The phone number of the recipient.
 * - senderEmail: any - The email address of the sender.
 * - reciverEmail: any - The email address of the recipient.
 * - parent: any - The context in which the component is used, indicating whether it's sending to all or an individual.
 */

import { useState } from 'react';
import styled from 'styled-components';
import Button from '@components/Button';
import { media } from 'styled-bootstrap-grid';
import { Input } from '@components/common';
import { Tab, TabPanel, Tabs } from '@components/common';
import TabContext from '@components/common/tabContext';
import { api } from 'helpers/auth-axios';
import { palette } from 'styled/common';
import { useSnackbar } from '@components/common';
import { useTranslation } from 'react-i18next';
import LoaderOverlay from './loaderOverlay';

export interface InputProps {
    error?: string;
}

const SendMessage = ({
    onClose,
    name,
    phone,
    senderEmail,
    reciverEmail,
    parent,
}: {
    onClose(): void;
    name?: any;
    phone?: any;
    senderEmail?: any;
    reciverEmail?: any;
    parent?: any;
}) => {
    const { t }: any = useTranslation();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();
    const [message, setMessage] = useState<any>('');
    const [subject, setSubject] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [tabValue, setTabValue] = useState(0);
    const [error, setError] = useState<any>(false);
    const [toggle, setToggle] = useState<any>(true);
    const [loading, setLoading] = useState(false);

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const sendMessage = async () => {
        if (IsValidMessage()) {
            let data: any = {
                message: message,
                sender_name: _userData.user.name,
                receiver_name: name,
                receiver_phone: phone,
            };
            setLoading(true);
            await api
                .post(`${parent == 'sendAll' ? '/users/send_sms_all' : '/users/send_sms'} `, data)
                .then((res: any) => {
                    setLoading(false);
                    if (res.data === true) {
                        onClose();
                        openSnackbar(t('Message sent successfully!'));
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const sendEmail = async () => {
        if (IsValidEamil()) {
            let data: any = {
                email: reciverEmail,
                subject: subject,
                text: email,
            };
            setLoading(true);
            await api
                .post(
                    `${parent == 'sendAll' ? '/users/send_email_all' : '/users/send_email'}`,
                    data
                )
                .then((res: any) => {
                    setLoading(false);
                    if (res.data === true) {
                        onClose();
                        openSnackbar(t('Eamil sent successfully!'));
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const IsValidMessage = () => {
        let valid = true;
        if (message == undefined || message == null || message.trim() == '') {
            valid = false;
            setError(true);
        } else {
            setError(false);
        }
        return valid;
    };

    const IsValidEamil = () => {
        let valid = true;
        if (email == undefined || email == null || email.trim() == '') {
            valid = false;
            setError(true);
        } else {
            setError(false);
        }
        return valid;
    };

    const removeError = (val: any) => {
        if (val.trim() !== '') {
            setError(false);
        } else {
            setError(true);
        }
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Model tabIndex={-1} onClick={e => !loading && closeModal(e)}>
                <ModelBody>
                    <ModelHead>
                        <Heading>
                            {t(parent == 'sendAll' ? 'Text Message To All' : 'Text Message')}
                        </Heading>
                        <Cover onClick={() => !loading && onClose()}>
                            <i className="fal fa-times"></i>
                        </Cover>
                    </ModelHead>
                    <CustomDiv>
                        <TabsCard>
                            <TabContext value={tabValue}>
                                <Tabs
                                    onChange={(event, newValue) => {
                                        setTabValue(newValue);
                                    }}>
                                    <Tab
                                        label={t('Send 1 Way Text')}
                                        onClick={() => {
                                            setToggle(!toggle);
                                            setError(false);
                                        }}
                                    />
                                    <Tab
                                        label={t('Send 1 Way Email')}
                                        onClick={() => {
                                            setToggle(!toggle);
                                            setError(false);
                                        }}
                                    />
                                </Tabs>
                                <TabPanel value={0} preload={true}>
                                    <TabContent>
                                        <InputGroup>
                                            <Textarea
                                                placeholder={t('Type your message here')}
                                                rows={4}
                                                value={message}
                                                error={error}
                                                onChange={(e): any => {
                                                    setMessage(e.target.value);
                                                    removeError(e.target.value);
                                                }}></Textarea>
                                        </InputGroup>
                                    </TabContent>
                                </TabPanel>
                                <TabPanel value={1} preload={true}>
                                    <TabContent>
                                        <InputGroup>
                                            <Input
                                                label={t('Subject')}
                                                name="subject"
                                                value={subject}
                                                onChange={(e: any) => {
                                                    setSubject(e.value);
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Textarea
                                                placeholder={t('Type your text here')}
                                                rows={4}
                                                value={email}
                                                error={error}
                                                onChange={(e): any => {
                                                    setEmail(e.target.value);
                                                    removeError(e.target.value);
                                                }}></Textarea>
                                        </InputGroup>
                                    </TabContent>
                                </TabPanel>
                            </TabContext>
                        </TabsCard>
                    </CustomDiv>
                    <Footer>
                        <Button
                            bgtype={'secondary'}
                            ifClicked={() => {
                                toggle ? sendMessage() : sendEmail();
                            }}
                            label={
                                toggle
                                    ? t(parent == 'sendAll' ? 'Send All' : 'Send Text')
                                    : t(parent == 'sendAll' ? 'Send All' : 'Send Email')
                            }></Button>
                    </Footer>
                </ModelBody>
            </Model>
        </>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    width: 37%;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    max-width: 82vw;
    ${media.xs`
    width: 100%;
	`}
    ${media.sm`
    width: 50%;
	`}
    ${media.md`
    width: 37%;
	`}
	${media.lg`
    width: 37%;
	`}
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0rem;
`;
const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
`;
const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;

const Heading = styled.div`
    font-weight: 600 !important;
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin: 0px !important;
`;

const CustomDiv = styled.div``;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const Textarea = styled.textarea<InputProps>`
    width: 100%;
    padding: 0.75rem 1.15rem;
    outline: none;
    resize: none;
    border: 1px solid ${({ error }) => (error ? `red` : '#dfe2e6')};
    border-radius: 0.325rem;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default SendMessage;
