/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CalendarBlock Component
 * Description: This component displays blocks for a specific service provider. It allows users to view and delete existing blocks.
 * It includes features for fetching and displaying block information, and handling block deletions.
 *
 * Props:
 * - serviceProviderId: any - The ID of the service provider for whom blocks are displayed.
 *
 */

import { NotFound } from '@components/team/list/serviceProviderList/ServiceProviderList';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
import { useSnackbar } from '..';
import DeleteModel from '../deleteModel/DeleteModel';
import LoaderOverlay from '../loaderOverlay';
const CalendarBlock = ({ serviceProviderId }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [block, setBlock] = useState<any>();
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        data();
    }, [router.query.id]);

    const data = () => {
        api.get(`/serviceProvider/${serviceProviderId}`).then((res: any) => {
            if (res.data !== 0) {
                setBlock(res.data.blocked);
            }
        });
    };

    const deleteBlock = (id: any) => {
        setLoading(true);
        api.delete(`/appointments/${id}`)
            .then(() => {
                setLoading(false);
                openSnackbar(t('Block deleted successfully!'));
                setDeleteModel(false);
                const deleteBlockAppointment: any = block.filter((item: any) => {
                    return item._id !== id;
                });
                setBlock(deleteBlockAppointment);
            })
            .catch((res: any) => {
                setLoading(false);
                openSnackbar(t('Failed to delete Block!'));
            });
    };

    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };

    return (
        <div>
            {loading && <LoaderOverlay />}
            <CustomCol lg={12}>
                {block?.length
                    ? block.map((block: any, i: any) => (
                          <div key={i}>
                              <Container style={{ marginBottom: '0.5rem' }}>
                                  <Row>
                                      <Flex xs={8} sm={5} md={5} lg={5}>
                                          <CustomDiv>
                                              {t('From')}&nbsp;
                                              <b>
                                                  {block.booked_from_iso
                                                      ? moment(block.booked_from_iso).format(
                                                            _userData.user.business_id
                                                                .time_select === '12'
                                                                ? 'DD-MM-YYYY hh:mm a'
                                                                : 'DD-MM-YYYY HH:mm'
                                                        )
                                                      : t('No Block found')}{' '}
                                              </b>
                                          </CustomDiv>
                                      </Flex>
                                      <Flex xs={8} sm={5} md={5} lg={5}>
                                          <CustomDiv>
                                              {t('To')}&nbsp;
                                              <b>
                                                  {block.booked_till_iso
                                                      ? moment(block.booked_till_iso).format(
                                                            _userData.user.business_id
                                                                .time_select === '12'
                                                                ? 'DD-MM-YYYY hh:mm a'
                                                                : 'DD-MM-YYYY HH:mm'
                                                        )
                                                      : t('No Block found')}
                                              </b>
                                          </CustomDiv>
                                      </Flex>
                                      <FlexEnd xs={4} sm={2} md={2} lg={2}>
                                          <DeletIcon onClick={() => openDeleteModel(block._id)}>
                                              <i className="fal fa-trash-alt"></i>
                                          </DeletIcon>
                                      </FlexEnd>
                                  </Row>
                              </Container>
                          </div>
                      ))
                    : loading === false && <NotFound>{t('No block found')}</NotFound>}
            </CustomCol>

            {deleteModel && (
                <DeleteModel
                    id={deleteId}
                    onDelete={() => {
                        setDeleteModel(false);
                    }}
                    name={t('Block')}
                    content={t('Are you sure you want to delete this Block?')}
                    loading={loading}
                    onClick={() => deleteBlock(deleteId)}
                    onClose={() => {
                        setDeleteModel(false);
                    }}
                />
            )}
        </div>
    );
};

const CustomCol = styled(Col)`
    padding: 0;
    margin-top: 1rem;
`;

const Flex = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 0rem;
    ${media.xs`
    justify-content: start;
    margin-bottom:1rem;
	`}
    ${media.sm`
    justify-content: end;
	`}
	${media.md`
    justify-content: end;
	`}
	${media.lg`
    justify-content: end;
	`}
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        padding-right: 0rem;
    }
`;

const FlexEnd = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
`;

const CustomDiv = styled.div`
    margin-left: 1rem;
`;

const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;

export default CalendarBlock;
