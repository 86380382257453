/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Models Header Component
 *
 * Description:
 * - Renders a header for a modal or model-like interface.
 * - Displays a title and a close button.
 *
 * Props:
 * - title: string - The title to be displayed in the header.
 * - onClose: () => void - A function to be called when the close button is clicked.
 *
 * Usage:
 * - Import this component and use it in a modal or model-like interface.
 */

import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const ModelHeader = ({ title, path, onClose }: any) => {
    return (
        <div>
            <TitleContainer>
                <TitleBody>
                    <Title>{title}</Title>
                </TitleBody>
                <TitleBody>
                    <CustomLink onClick={() => onClose()}>
                        <Icon className="fal fa-times"></Icon>
                    </CustomLink>
                </TitleBody>
            </TitleContainer>
        </div>
    );
};

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
`;

const CustomLink = styled.div`
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.7rem /* 36px */;
    line-height: 2.5rem /* 40px */;
    ${media.xs`
    font-size: 1.5rem
    `}
`;

const Title = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.3rem
`}
`;

export default ModelHeader;
