/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Auth Reducer
 * Description: This reducer handles the authentication state, including login, logout, and saving user information.
 *
 * Constants:
 * - userConstants: Constants for user actions.
 *
 * Initial State:
 * - user: Initial state based on user information from localStorage.
 *
 * Reducer:
 * - auth: Reducer function for handling authentication-related actions and updating the state.
 *
 */

import { userConstants } from 'actions/user';

const user = JSON.parse(localStorage.getItem('user')!);
const initialState = user ? { loggedIn: true, user } : { loggingIn: false };

export function auth(state = initialState, action: any) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};

        case userConstants.SAVE_USER:
            return {
                ...state,
                user: action.value,
            };
        default:
            return state;
    }
}
