/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BasketSidebar Component
 * Description: React component for displaying the basket sidebar during the checkout process.
 * It includes the customer information, basket items, and total price. It also provides options to continue to the next step or proceed with the payment.
 *
 * Props:
 * - customer?: ICustomer - An optional ICustomer object representing the selected customer.
 * - items: IService[] - An array of IService objects representing the assets items in the basket.
 * - step: number - The current step in the checkout process.
 * - subtotal: any - The subtotal amount for all items in the basket.
 * - gratuity: any - The gratuity amount.
 * - onNext: () => void - Callback function triggered when the "Continue" button is clicked.
 * - onCharge: () => void - Callback function triggered when the "Charge" button is clicked.
 * - onSelectCustomer: (customer: ICustomer) => void - Callback function triggered when a customer is selected.
 * - paymentMethod?: any - An optional payment method (used for conditional rendering).
 *
 * Purpose: This component serves the purpose of presenting the customer information, basket items, and total price in a clear and organized manner during the checkout process.
 */

import { useState } from 'react';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import SelectClient from '@components/appointments/newAppointmentDrawer/SelectClient';
import EmptyBasket from './EmptyBasket';
import BasketItems from './BasketItems';
import { Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const BasketSidebar = ({
    customer,
    items,
    step,
    subtotal,
    gratuity,
    onNext,
    onSelectCustomer,
    onCharge,
    paymentMethod,
}: {
    customer?: ICustomer;
    items: IService[];
    step: number;
    subtotal: any;
    gratuity: any;
    onNext(): void;
    onCharge(): void;
    onSelectCustomer(customer: ICustomer): void;
    paymentMethod?: any;
}) => {
    const { t }: any = useTranslation();
    const [customerView, setCustomerView] = useState(false);
    const disabledBtn = !items.length || !customer;
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const total: any = gratuity
        ? parseFloat(gratuity) + parseFloat(subtotal)
        : 0 + parseFloat(subtotal);

    return (
        <>
            <Wrapper md={5} lg={4}>
                {!customerView && (
                    <>
                        <Header>
                            {customer ? (
                                <div>
                                    <CustomersSelect
                                        onClick={() => {
                                            setCustomerView(true);
                                        }}>
                                        <CustomerIcon>
                                            <Icon
                                                src={`${process.env.REACT_APP_PROFILE_URL}${customer?.photo}`}></Icon>
                                        </CustomerIcon>
                                        <CustomerContent>
                                            <CusomerName>
                                                {customer.firstname + ' ' + customer.lastname}
                                            </CusomerName>
                                            <CustomerEmail>{customer.phone}</CustomerEmail>
                                            <CustomerEmail>{customer.email}</CustomerEmail>
                                        </CustomerContent>
                                        <div>
                                            <RightArrow className="fal fa-chevron-right"></RightArrow>
                                        </div>
                                    </CustomersSelect>
                                </div>
                            ) : (
                                <CustomersSelect
                                    onClick={() => {
                                        setCustomerView(true);
                                    }}>
                                    <CustomerIcon>
                                        <Icon></Icon>
                                    </CustomerIcon>
                                    <SelectAClient>{t('Select a client')}</SelectAClient>
                                    <div>
                                        <RightArrow className="fal fa-plus"></RightArrow>
                                    </div>
                                </CustomersSelect>
                            )}
                        </Header>
                        <BasketBody>
                            {items?.length ? (
                                <BasketItems
                                    items={items}
                                    subtotal={subtotal}
                                    gratuity={gratuity}
                                    paymentMethod={paymentMethod}
                                />
                            ) : (
                                <EmptyBasket />
                            )}
                        </BasketBody>
                        <BasketFooter>
                            <PriceContent>
                                <TotalPriceText>{t('Total')}</TotalPriceText>
                                <Price>
                                    {_userData.user.business_id.currency}
                                    {total.toFixed(2)}
                                </Price>
                            </PriceContent>
                            {step === 1 ? (
                                <Button
                                    disabled={disabledBtn}
                                    bgtype="secondary"
                                    ifClicked={onNext}
                                    width={'100% !important'}
                                    label={t('Continue')}></Button>
                            ) : (
                                <Button
                                    disabled={disabledBtn}
                                    bgtype="secondary"
                                    ifClicked={onCharge}
                                    width={'100% !important'}
                                    label={
                                        paymentMethod !== undefined &&
                                        (paymentMethod === 'paypal' ||
                                            paymentMethod === 'american_express')
                                            ? t('Next')
                                            : t('Charge')
                                    }></Button>
                            )}
                        </BasketFooter>
                    </>
                )}
                {customerView && (
                    <SelectClient
                        onClose={() => {
                            setCustomerView(false);
                        }}
                        onSelect={(customer: ICustomer) => {
                            onSelectCustomer(customer);
                        }}
                        height={true}
                    />
                )}
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Col)`
    position: absolute;
    top: 0;
    right: 0;
    padding: 0rem;
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    z-index: 1;
    ${media.xs`
  position: relative;
  `}
    ${media.sm`
  position: relative;

  `}
    ${media.md`
    position: absolute;
    top: 0;
    right: 0;

  `}
    ${media.lg`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const Header = styled.div`
    padding: 20px;
`;

const CustomersSelect = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed #d1d5db;
    cursor: pointer;
`;

const CustomerIcon = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    border-radius: 50%;
`;

const Icon = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    font-size: 17px;
    text-transform: capitalize;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;

const CusomerName = styled.div`
    font-weight: 500;
`;

const CustomerEmail = styled.div`
    color: rgba(157, 160, 164) !important;
    font-size: 0.9rem !important;
`;

const RightArrow = styled.i`
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;

const SelectAClient = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;

const BasketFooter = styled.div`
    padding: 0 20px 20px;
`;

const BasketBody = styled.div`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const PriceContent = styled.div`
    display: flex !important;
    margin-bottom: 1rem !important;
    align-items: center !important;
    justify-content: space-between !important;
`;

const TotalPriceText = styled.div`
    color: rgba(188, 192, 197) !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 0.9rem !important;
`;

const Price = styled.div`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    font-weight: 600 !important;
    text-align: right !important;
`;

export default BasketSidebar;
