/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * NotFound Component
 * Description: This component is displayed when a user tries to access a page that does not exist (404 error).
 * It provides a user-friendly message, an option to go back, and redirects the user based on their permissions.
 *
 */

import { useNavigate } from 'react-router-dom';
import { Layout } from '@components/common';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const goBack = () => {
        if (_userData?.user?.permissions?.dashboard) {
            navigate('/');
        } else if (_userData?.user?.permissions?.calendar) {
            navigate('/calendar');
        } else if (_userData?.user?.permissions?.sales) {
            navigate('/sales');
        } else if (_userData?.user?.permissions?.team) {
            navigate('/team');
        } else if (_userData?.user?.permissions?.customers) {
            navigate('/customers');
        } else if (_userData?.user?.permissions?.business) {
            navigate('/settings');
        } else {
            navigate('/login');
        }
    };

    return (
        <Page>
            <Error>404</Error>
            <Error>{t('Page Not Found')}</Error>
            <Button onClick={() => goBack()}>{t('Go Back')}</Button>
        </Page>
    );
};

NotFound.Layout = Layout;

const Page = styled.div`
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const Error = styled.h1``;

const Button = styled.a`
    cursor: pointer;
`;
export default NotFound;
