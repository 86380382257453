/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * getEmbededCode Function
 * Description: This function generates the embedded code for the EBookingSystem widget based on the provided parameters.
 *
 * Props
 * clientId: string - The client ID associated with the EBooking widget.
 * btnBgColor: string - Background color for the booking button.
 * btnTextColor: string - Text color for the booking button.
 * buttonTitle: string - Title or text content for the booking button.
 *
 */

import { palette } from 'styled/common';
import { CommonEnum } from 'utils';

export const getEmbededCode = (
    clientId: string,
    btnBgColor: any,
    btnTextColor: any,
    buttonTitle: any
) => {
    return `<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
    <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js"></script>
    <script>!function(e,r){if(e.EBooking)console.error("${
        CommonEnum.AppName
    } embed already included");else{e.EBooking={},m=["init"],e.EBooking._c=[],m.forEach(r=>e.EBooking[r]=function(){e.EBooking._c.push([r,arguments])});var a=r.createElement("script");a.type="text/javascript",a.async=!0,a.src="${
        process.env.REACT_APP_WIDGET_BUILD_URL
    }/shim.js";var n=r.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}}(window,document),EBooking.init("${clientId}");</script>
    <button id="bookNowBtn" style="cursor:pointer;box-sizing: border-box;padding: 1rem 3rem; font-weight: 550; max-width: 300px; text-transform: capitalize; color: ${
        btnTextColor ? btnTextColor : 'white'
    }; background-color: ${
        btnBgColor ? btnBgColor : palette.secondary
    }; font-size: 23px; border-radius: 8px; cursor: pointer; margin: 0.8rem; box-shadow: #36393c 0px 2px 8px -3px;border: none;">${buttonTitle}</button>
    <script> $('#bookNowBtn').click((function(){ $('#E-booking-container').css({display:'block'}) })) </script>`;
};
