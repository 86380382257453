/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Routes Configuration
 * Description: This file defines the configuration for the application routes, including the path, activeLink, title, and icon for each routes for sidebar/navbar.
 *
 */

var _user = JSON.parse(localStorage.getItem('user') || '{}');
export const routes = [
    {
        path: '/',
        activeLink: '/',
        title: 'Dashboard',
        icon: 'far fa-chart-line',
    },
    {
        path: '/services',
        activeLink: '/services',
        title: 'Services',
        icon: 'far fa-atlas',
    },
    {
        path: `/calendar`,
        activeLink: `/calendar`,
        title: 'Calendar',
        icon: 'far fa-calendar-week',
    },
    {
        path: '/admin',
        activeLink: '/admin',
        title: 'Admin',
        icon: 'far fa-user',
    },
    {
        path: '/team',
        activeLink: '/team',
        title: 'Team',
        icon: 'far fa-users',
    },
    {
        path: '/customer',
        activeLink: '/customer',
        title: 'Customers',
        icon: 'far far fa-user',
        patterns: [
            {
                path: '/customers/create',
                activeLink: '/customers',
                title: 'Create Customers',
                icon: 'far far fa-user',
            },
        ],
    },
    {
        path: '/sales',
        activeLink: '/sales',
        title: 'Sales',
        icon: 'far fa-receipt',
    },
    {
        path: '/settings/business/new',
        activeLink: '/settings',
        title: 'Create New Business',
        icon: 'far fa-cogs',
    },
    {
        path: '/promotion/gift-card',
        activeLink: '/settings',
        title: 'Create New Business',
        icon: 'far fa-cogs',
    },

    {
        path: '/settings',
        activeLink: '/settings',
        title: 'Business Settings',
        icon: 'far fa-cogs',
    },
];
export const customerRoute = [
    {
        path: `/customers/${_user?.user?._id}`,
        activeLink: `/customers/${_user?.user?._id}`,
        title: 'Customers',
        icon: 'far far fa-user',
    },
];
