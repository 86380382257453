/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * WorkingHours Component
 * Description: Component for managing business working hours of service provider/team/other, allowing users to set and update working hours for each day of the week.
 * It includes functionalities such as setting working hours, updating them, and calculating the time difference for each day.
 * The component utilizes styled-components for styling and responsiveness.
 *
 * Props:
 * - active: boolean - Boolean indicating whether the component is active.
 * - business_id: string - The ID of the business associated with the working hours.
 * - onChange: (day: any) => void - Callback function triggered when working hours are changed.
 * - updateCallBack?: any - Callback function triggered after successfully updating working hours.
 * - defaultWorkingHours?: any - Default working hours to be set initially.
 *
 * Purpose:
 * - Provides a user interface for managing business working hours.
 * - Allows users to set and update working hours for each day of the week.
 * - Calculates and displays the time difference for each day.
 *
 */

import React, { useEffect, useState } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Switch, Select, useSnackbar } from '@components/common';
import { IWorkingHours } from 'interfaces/workinghours.interface';
import { useForm } from 'react-hook-form';
import Button from '@components/Button';
import useRouter from 'hooks/router';
import { calculateHoursDifference } from '../utils';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
import LoaderOverlay from '../loaderOverlay';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';

interface IProps {
    active: boolean;
    business_id: string;
    onChange: (day: any) => void;
    updateCallBack?: any;
    defaultWorkingHours?: any;
}

const WorkingHours = ({ onChange, business_id, defaultWorkingHours, updateCallBack }: IProps) => {
    const { t } = useTranslation();
    const [openSnackbar] = useSnackbar();
    const { handleSubmit, formState } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [dayHours, setDayHours] = useState<IWorkingHours>();
    const [mo_Diff, setMonday] = useState();
    const [tu_Diff, setTeusday] = useState();
    const [we_Diff, setWednesday] = useState();
    const [th_Diff, setThursday] = useState();
    const [fr_Diff, setFriday] = useState();
    const [sa_Diff, setSaturday] = useState();
    const [su_Diff, setSunday] = useState();
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        return onChange(dayHours);
    }, [dayHours, onChange]);

    useEffect(() => {
        if (defaultWorkingHours) {
            setDayHours(defaultWorkingHours);
        }
    }, [defaultWorkingHours]);

    useEffect(() => {
        getWorkingHours();
    }, [dayHours]);

    let businesHours: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            businesHours.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    const onSubmit = async () => {
        setLoading(true);
        let id: any = dayHours?._id;
        let data: any = {
            business_id: business_id,
            mo_from: dayHours?.mo_from,
            mo_to: dayHours?.mo_to,
            tu_from: dayHours?.tu_from,
            tu_to: dayHours?.tu_to,
            we_from: dayHours?.we_from,
            we_to: dayHours?.we_to,
            th_from: dayHours?.th_from,
            th_to: dayHours?.th_to,
            fr_from: dayHours?.fr_from,
            fr_to: dayHours?.fr_to,
            sa_from: dayHours?.sa_from,
            sa_to: dayHours?.sa_to,
            su_from: dayHours?.su_from,
            su_to: dayHours?.su_to,
            mo_on: dayHours?.mo_on,
            tu_on: dayHours?.tu_on,
            we_on: dayHours?.we_on,
            th_on: dayHours?.th_on,
            fr_on: dayHours?.fr_on,
            sa_on: dayHours?.sa_on,
            su_on: dayHours?.su_on,
        };

        await api
            .put(`/workinghours/${id}`, data)
            .then((res: any) => {
                setLoading(false);
                setDayHours(res.data);
                openSnackbar(t('Updated successfully!'));
                updateCallBack();
            })
            .catch(() => {
                setLoading(false);
                openSnackbar(t('Failed to update!'));
            });
    };

    const getWorkingHours = async () => {
        const monday: any = await calculateHoursDifference(dayHours?.mo_from, dayHours?.mo_to);
        setMonday(monday);
        const teusday: any = await calculateHoursDifference(dayHours?.tu_from, dayHours?.tu_to);
        setTeusday(teusday);
        const wednesday: any = await calculateHoursDifference(dayHours?.we_from, dayHours?.we_to);
        setWednesday(wednesday);
        const thursday: any = await calculateHoursDifference(dayHours?.th_from, dayHours?.th_to);
        setThursday(thursday);
        const friday: any = await calculateHoursDifference(dayHours?.fr_from, dayHours?.fr_to);
        setFriday(friday);
        const saturday: any = await calculateHoursDifference(dayHours?.sa_from, dayHours?.sa_to);
        setSaturday(saturday);
        const sunday: any = await calculateHoursDifference(dayHours?.su_from, dayHours?.su_to);
        setSunday(sunday);
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            {dayHours && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`hours-item ${!dayHours?.mo_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours && dayHours?.mo_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            mo_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Monday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        openMenue={false}
                                        value={
                                            dayHours?.mo_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.mo_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                mo_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.mo_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.mo_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                mo_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {mo_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div className={`hours-item ${!dayHours?.tu_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.tu_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            tu_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Tuesday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.tu_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.tu_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                tu_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.tu_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.tu_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                tu_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {tu_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div className={`hours-item ${!dayHours?.we_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.we_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            we_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Wednesday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.we_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.we_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                we_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.we_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.we_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                we_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {we_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div className={`hours-item ${!dayHours?.th_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.th_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            th_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Thursday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.th_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.th_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                th_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.th_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.th_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                th_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {th_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div className={`hours-item ${!dayHours?.fr_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.fr_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            fr_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Friday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.fr_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.fr_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                fr_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.fr_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.fr_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                fr_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {fr_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div className={`hours-item ${!dayHours?.sa_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.sa_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            sa_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Saturday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.sa_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.sa_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                sa_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.sa_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.sa_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                sa_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {sa_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div className={`hours-item ${!dayHours?.su_on ? 'hours-item--disabled' : ''}`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.su_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            su_on: val,
                                        }));
                                    }}
                                />
                                <WeekName>{t('Sunday')}</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.su_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.su_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                su_from: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        disabled={true}
                                        value={
                                            dayHours?.su_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.su_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                su_to: val,
                                            }));
                                        }}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {su_Diff}
                                    {t('h')}
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <FooterButton style={{ marginTop: '1rem' }}>
                        <Button type="submit" bgtype="secondary" label={t('Save')}></Button>
                    </FooterButton>
                </Form>
            )}
        </>
    );
};

const List = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.xs`
  display: block;
  `}
    ${media.lg`
    display: flex;
  `}
`;

const WeekContent = styled.div`
    display: flex;
    align-items: center;
    ${media.xs`
    margin-bottom: 1rem;
  `}
    ${media.sm`
    margin-bottom: 1rem;
  `}
    ${media.md`
    margin-bottom: 1rem;
  `}
    ${media.lg`
    margin-bottom: 0rem;
    `}
`;

const WeekName = styled.div`
    width: 6.42rem;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    padding-left: 1rem !important;
`;

const HourContent = styled.div`
    display: flex;
    align-items: center;
`;

const Hour = styled.div`
    width: 9.28rem;
    margin-right: 0.75rem !important;
    ${media.xs`
    width: 7.28rem;
`}
    ${media.sm`
width: 7.28rem;
`}
${media.md`
width: 9.28rem;
`}
${media.lg`
width: 9.28rem;
`}
${media.xl`
width: 9.28rem;
`}
`;

const Time = styled.div`
    font-weight: 300 !important;
    font-size: 0.9rem !important;
`;

const Icon = styled.i`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-right: 0.5rem !important;
`;

const Form = styled.form`
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
export default WorkingHours;
