/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateAssetService Component
 * Description: React component for creating or updating asset used in appointment. It includes form fields for asset name, quantity, price, and color, with validation and submission functionality.
 *
 * Props:
 * - onClose: () => void - Callback function to close the component.
 * - handleAdd: () => void - Callback function triggered when a new asset is added or an existing asset is updated.
 * - editAssets: object | null - Data of the asset being edited, if any.
 */

import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Layout, LoaderOverlay, useSnackbar } from '@components/common';
import { Input } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import s from './CreateAssetService.module.scss';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
import { api } from 'helpers/auth-axios';

const CreateAssetService = ({ onClose, handleAdd, editAssets }: any) => {
    const { t }: any = useTranslation();
    const [serviceColor, setServiceColor] = useState(Math.floor(Math.random() * 32));
    const [showColorModal, setShowColorModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        quantity: Yup.number().required(t('This field is required')),
        price: Yup.number()
            .moreThan(0, t('Price should not be zero'))
            .typeError(t('A valid number is required'))
            .required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    useEffect(() => {
        if (editAssets) {
            setValue('name', editAssets?.name);
            setValue('price', editAssets?.price);
            setValue('quantity', editAssets?.quantity);
            setServiceColor(editAssets?.color);
        }
    }, [editAssets]);

    const onSubmit = (data: any) => {
        const serviceData = {
            ...data,
            price: parseFloat(data?.price).toFixed(2),
            color: serviceColor,
        };
        setLoading(true);
        let url = editAssets
            ? api.put(`/assets-service/${editAssets?._id}`, serviceData)
            : api.post('/assets-service', serviceData);
        url.then(() => {
            setLoading(false);
            openSnackbar(
                t(editAssets ? 'Asset updated successfully!' : 'Asset added successfully!')
            );
            handleAdd && handleAdd();
        }).catch(e => {
            setLoading(false);
            if (e?.response) {
                const response = e?.response?.data;
                if (response) {
                    openSnackbar(response?.message);
                }
            }
        });
    };

    const changeServiceColor = (color: number) => {
        setServiceColor(color);
        setShowColorModal(false);
    };

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            setShowColorModal(false);
        }
    };

    return (
        <MainContent>
            {loading && <LoaderOverlay />}
            <Inner>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        <Row>
                            <Header lg={12}>
                                <Heading>
                                    {t(editAssets ? 'Update Asset' : 'Add New Asset')}
                                </Heading>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => !loading && onClose && onClose()}></Cross>
                            </Header>
                            <Col lg={12}>
                                <Card>
                                    <Wrapper>
                                        <Row>
                                            <LeftCol sm={6} md={12} lg={12}>
                                                <CardBody>
                                                    <InputGroup>
                                                        <Input
                                                            label={t('Asset name')}
                                                            name="name"
                                                            value={getValues('name')}
                                                            onChange={onChange}
                                                            error={errors.name as any}
                                                        />
                                                    </InputGroup>

                                                    <Wrapper>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <InputGroup>
                                                                    <Input
                                                                        name={'quantity'}
                                                                        label={`${t('Quantity')}`}
                                                                        allowOnlyNumbersAndDecimal={
                                                                            true
                                                                        }
                                                                        onChange={onChange}
                                                                        value={getValues(
                                                                            'quantity'
                                                                        )}
                                                                        error={
                                                                            errors.quantity as any
                                                                        }
                                                                    />
                                                                </InputGroup>

                                                                <InputGroup>
                                                                    <Input
                                                                        name={'price'}
                                                                        label={`${t('Price')}(${
                                                                            _userData.user
                                                                                .business_id
                                                                                .currency
                                                                        })`}
                                                                        allowOnlyNumbersAndDecimal={
                                                                            true
                                                                        }
                                                                        onChange={onChange}
                                                                        value={getValues('price')}
                                                                        error={errors.price as any}
                                                                    />
                                                                </InputGroup>

                                                                <FooterButton>
                                                                    <Button
                                                                        type="submit"
                                                                        label={t(
                                                                            editAssets
                                                                                ? 'Update'
                                                                                : 'Add New Asset'
                                                                        )}
                                                                        bgtype="secondary"></Button>
                                                                </FooterButton>
                                                            </Col>
                                                        </Row>
                                                    </Wrapper>
                                                </CardBody>
                                            </LeftCol>
                                        </Row>
                                    </Wrapper>
                                </Card>
                            </Col>
                        </Row>
                    </Wrapper>
                </form>

                {showColorModal && (
                    <Model tabIndex={-1} onClick={closeModal}>
                        <ModelBody>
                            <ModelHeader>
                                <Header>
                                    <Heading>{t('Assets Colors')}</Heading>
                                    <Cross
                                        className="fal fa-times"
                                        onClick={() => setShowColorModal(false)}></Cross>
                                </Header>
                            </ModelHeader>
                            <ModelContent>
                                <ModelWrapper>
                                    <Row style={{ justifyContent: 'center' }}>
                                        {[...Array(32)].map((x, i) => (
                                            <Color key={i}>
                                                <div
                                                    className={` ${s.colorPicker_serviceColor} service-color-${i}--bd-light`}
                                                    onClick={() => changeServiceColor(i)}>
                                                    {serviceColor === i && (
                                                        <IconCover>
                                                            <i className="fal fa-check"></i>
                                                        </IconCover>
                                                    )}
                                                </div>
                                            </Color>
                                        ))}
                                    </Row>
                                </ModelWrapper>
                            </ModelContent>
                        </ModelBody>
                    </Model>
                )}
            </Inner>
        </MainContent>
    );
};

export const MainContent = styled.div`
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 2.7rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    ${media.xs`
	height: auto;
  `}
    ${media.sm`
	height: 100%;
  `}
	${media.md`
	height: 100%;
  `}
	${media.lg`
	height: 100%;
  `}
`;

const Inner = styled.div`
    margin-left: auto !important;
    margin-right: auto !important;
`;

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const Header = styled(Col)`
    display: flex;
    justify-content: space-between;
`;

const LeftCol = styled(Col)``;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const AlignFlex = styled.div<any>`
    padding-top: ${({ padding }) => (padding ? padding : '1rem')} !important;
    padding-bottom: 1rem !important;
    border-bottom: 0.1rem solid ${palette.grey.lightest}!important;
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
`;

const CardBody = styled.div`
    padding: 3rem 2rem 1.75rem 2rem !important;
    ${media.xs`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
    ${media.sm`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
	${media.md`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
	${media.lg`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
`;

const Icon = styled.i`
    font-size: 1.125rem;
    color: ${palette.white};
`;

const InnerContent = styled.div`
    padding-bottom: 1rem !important;
`;

const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.71rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled.div`
    min-width: 45.71rem;
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 1.71rem;
    width: 50rem;
    ${media.xs`
	min-width: 25rem;
  `}
    ${media.sm`
	min-width: 35rem;
  `}
	${media.md`
	min-width: 40rem;
  `}
	${media.lg`
	min-width: 45.71rem;
  `}
`;

const ModelHeader = styled.div`
    padding-top: 0rem !important;
    flex: 1 1;
    display: flex;
`;

const ModelContent = styled.div`
    padding-top: 2.5rem !important;
`;

const ModelWrapper = styled(Container)`
    height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    ${media.xs`
	height: 22rem;
  `}
    ${media.sm`
	height: 24rem;
  `}
	${media.md`
	height: 24rem;
  `}
	${media.lg`
	height: 28rem;
  `}
`;

const Color = styled.div`
    margin-bottom: 1rem;
    padding: 0.3rem;
`;

const IconCover = styled.div`
    color: ${palette.white};
    font-size: 2rem;
`;

CreateAssetService.Layout = Layout;
export default CreateAssetService;
