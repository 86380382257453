/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CustomiseBusinessHoursDrawer
 * Description: This component represents a drawer/modal for customizing custom calendar working hours. Users can set the start and end times, choose recurring options,
 * and select a color for the working hours. It provides features like repeating working hours, picking color, and handles conflicts with existing working hours.
 * The component integrates with various UI elements such as buttons, icons, and date pickers for a user-friendly experience.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - id: string - Identifier for the custom calendar business hours customization.
 * - startDate: any - Start date for the custom calendar business hours customization.
 * - endDate: any - End date for the custom calendar business hours customization.
 * - onClose: () => void - Callback function triggered when the business hours customization drawer is closed.
 * - onSave: (event: any) => void - Callback function triggered when saving the customized business hours.
 * - serviceProviderId: any - Identifier for the service provider associated with the custom calendar business hours.
 *
 */

import { Drawer, LoaderOverlay, Select, Spinner, useSnackbar } from '@components/common';
import { useEffect, useState } from 'react';
import moment from 'moment';
import NewRecurringModal from './NewRecurringModal';
import { IRecurring } from 'interfaces/recurring.interface';
import ConflictModal from './ConflictModal';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import ColorPickerModel from '@components/common/ColorPickerModel';
import { palette } from 'styled/common';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import DeleteWorkingHoursModel from './DeleteWorkingHoursModel';
const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};

const CustomiseBusinessHoursDrawer = ({
    id,
    startDate,
    endDate,
    onClose,
    onSave,
    serviceProviderId,
}: {
    id: string;
    startDate: any;
    endDate: any;
    onClose(): void;
    onSave(event: any): void;
    serviceProviderId: any;
}) => {
    useEffect(() => {
        setStart(startDate);
        setEnd(endDate);
    }, [startDate, endDate]);

    const { t }: any = useTranslation();
    const [active, setActive] = useState(false);
    const [start, setStart] = useState(formatDate(startDate));
    const [end, setEnd] = useState(formatDate(endDate));
    const [overbooking, setOverbooking] = useState(false);
    const [recurring, setRecurring] = useState<IRecurring>();
    const [showRecurringModal, setShowRecurringModal] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [pickColor, setPickColor] = useState<any>(false);
    const [selectedColor, setSelectedColor] = useState<number>();
    const [getLoading, setGetLoading] = useState<any>(false);
    const [workingHoursData, setWorkingHoursData] = useState<any>();
    const [deleteSingle, setDeleteSingle] = useState<any>(false);
    const [deleteAll, setDeleteAll] = useState<any>(false);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        if (id) {
            getWorkingHours();
        }
    }, [id]);

    useEffect(() => {
        setTimeout(() => setActive(true), 20);
    }, []);

    useEffect(() => {
        if (overbooking) onSubmit();
    }, [overbooking]);

    const appointmentTime: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            appointmentTime.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }
    const getWorkingHours = () => {
        setGetLoading(true);
        api.get(`/customise-workingHours/${id}`)
            .then((res: any) => {
                setWorkingHoursData(res.data);
                setRecurring(res.data.recurring);
                setSelectedColor(res.data.color);
                setStart(res.data?.from);
                setEnd(res.data?.to);
                setGetLoading(false);
            })
            .catch(e => {
                setGetLoading(false);
            });
    };

    const startTime =
        moment(
            workingHoursData && workingHoursData?.repeat === true
                ? workingHoursData?.recurring?.repeat_from
                : startdate
                ? startdate
                : start
        ).format('YYYY-MM-DD') +
        'T' +
        moment(start).format('HH:mm') +
        ':00';
    const endTime =
        moment(end).format('HH:mm') == '00:00'
            ? moment(
                  workingHoursData && workingHoursData?.repeat === true
                      ? workingHoursData?.recurring?.repeat_from
                      : startDate
                      ? startDate
                      : start
              ).format('YYYY-MM-DD') +
              'T' +
              '23:59' +
              ':00'
            : moment(
                  workingHoursData && workingHoursData?.repeat === true
                      ? workingHoursData?.recurring?.repeat_from
                      : enddate
                      ? enddate
                      : end
              ).format('YYYY-MM-DD') +
              'T' +
              moment(end).format('HH:mm') +
              ':00';
    const newBusinesHours = {
        from: startTime ? moment(startTime).format() : moment(start).format(),
        to: endTime ? moment(endTime).format() : moment(end).format(),
        recurring: recurring ? recurring : '',
        repeat: recurring ? true : false,
        color: selectedColor,
        day: moment(start).weekday(),
    };

    const onSubmit = () => {
        setLoading(true);
        if (workingHoursData) {
            api.put(`/customise-workingHours/${workingHoursData?._id}`, newBusinesHours)
                .then((res: any) => {
                    setLoading(false);
                    openSnackbar(t(`Customize Working Hours updated successfully`));
                    onSave(res.data);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data?.response;
                        openSnackbar(response?.message);
                        if (response?.conflictDates) {
                            setConflictDates(response?.conflictDates);
                            setShowConflictModal(true);
                        }
                    }
                });
        } else {
            api.post(`/customise-workingHours/${serviceProviderId}`, newBusinesHours)
                .then((res: any) => {
                    setLoading(false);
                    openSnackbar(t(`Customize Working Hours added successfully`));
                    onSave(res.data);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data?.response;
                        openSnackbar(response?.message);
                        if (response?.conflictDates) {
                            setConflictDates(response?.conflictDates);
                            setShowConflictModal(true);
                        }
                    }
                });
        }
    };

    const deleteSingleWorkingHours = () => {
        api.delete(`/customise-workingHours/${id}`)
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t(`Customize Working Hours deleted successfully`));
                onSave(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    openSnackbar(response?.message);
                }
            });
    };

    const calendarDate = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Drawer
                            custom={true}
                            left={false}
                            active={active}
                            onClose={() => {
                                !loading && onClose();
                            }}>
                            <AppointmentContent>
                                <HeaderContainer>
                                    <Header>{t('Customize Working Hours')}</Header>
                                    {workingHoursData && (
                                        <i
                                            className="fad fa-trash-alt"
                                            onClick={() => {
                                                workingHoursData?.repeat === true
                                                    ? setDeleteAll(true)
                                                    : setDeleteSingle(true);
                                            }}
                                            style={{
                                                color: 'red',
                                                fontSize: '1.4rem',
                                                cursor: 'pointer',
                                            }}></i>
                                    )}
                                    <CloseButton
                                        onClick={() => {
                                            !loading && setActive(false);
                                        }}>
                                        <Icon className="fal fa-times"></Icon>
                                    </CloseButton>
                                </HeaderContainer>
                                <DrawerBody>
                                    <FormContent>
                                        {getLoading ? (
                                            <Spinner color="black" />
                                        ) : (
                                            <FormDetails>
                                                <InputGroup>
                                                    <InputContent>
                                                        <Col lg={12}>
                                                            <Select
                                                                label={t('Start Time')}
                                                                value={moment(start).format(
                                                                    'hh:mm a'
                                                                )}
                                                                options={appointmentTime}
                                                                disabled={true}
                                                                onChange={(val: string) => {
                                                                    const newStart =
                                                                        moment(start).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                        'T' +
                                                                        val +
                                                                        ':00';

                                                                    setStart(newStart);
                                                                }}
                                                            />
                                                        </Col>
                                                    </InputContent>
                                                    <InputContent>
                                                        <Col lg={12}>
                                                            <Select
                                                                label={t('End Time')}
                                                                value={moment(end).format(
                                                                    'hh:mm a'
                                                                )}
                                                                options={appointmentTime}
                                                                disabled={true}
                                                                onChange={(val: string) => {
                                                                    const newEnd =
                                                                        moment(end).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                        'T' +
                                                                        val +
                                                                        ':00';

                                                                    setEnd(newEnd);
                                                                }}
                                                            />
                                                        </Col>
                                                    </InputContent>
                                                    <DatesBody>
                                                        <FormDate>
                                                            <CalenderDate>
                                                                {startdate
                                                                    ? moment(startdate).format(
                                                                          'ddd, MMM DD'
                                                                      )
                                                                    : moment(startDate).format(
                                                                          'ddd, MMM DD'
                                                                      )}
                                                            </CalenderDate>
                                                            <input
                                                                type="date"
                                                                min={
                                                                    new Date()
                                                                        .toISOString()
                                                                        .split('T')[0]
                                                                }
                                                                value={moment(startdate).format(
                                                                    'YYYY-MM-DD'
                                                                )}
                                                                onChange={(e: any) =>
                                                                    calendarDate(e)
                                                                }
                                                            />
                                                        </FormDate>
                                                        {_userData.user_login !== 'customer' && (
                                                            <RepeateButton
                                                                className={`${
                                                                    recurring
                                                                        ? 'border-success bg-success text-white'
                                                                        : ''
                                                                }`}
                                                                onClick={() =>
                                                                    setShowRecurringModal(true)
                                                                }>
                                                                {recurring
                                                                    ? t('Repeat')
                                                                    : t('Repeat')}{' '}
                                                                <RepeateIcon className="fas fa-repeat"></RepeateIcon>
                                                            </RepeateButton>
                                                        )}
                                                    </DatesBody>
                                                    <InputContent
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            marginRight: '0.1rem',
                                                        }}>
                                                        <CustomButton
                                                            bgtype={'secondary'}
                                                            className={
                                                                selectedColor &&
                                                                `service-color-${selectedColor}--bd-light`
                                                            }
                                                            color={selectedColor}
                                                            onClick={() => setPickColor(true)}
                                                            label={t('Choose Color')}>
                                                            {t('Choose Color')}
                                                        </CustomButton>
                                                    </InputContent>
                                                </InputGroup>

                                                <FormFooter>
                                                    <FormButtons>
                                                        <Button
                                                            bgtype={'discard'}
                                                            ifClicked={() =>
                                                                !loading && setActive(false)
                                                            }
                                                            width="10rem !important"
                                                            label={t('Cancel')}></Button>
                                                        <Button
                                                            bgtype={'secondary'}
                                                            ifClicked={onSubmit}
                                                            width="10rem !important"
                                                            label={t(
                                                                workingHoursData ? 'Update' : 'Add'
                                                            )}></Button>
                                                    </FormButtons>
                                                </FormFooter>
                                            </FormDetails>
                                        )}
                                    </FormContent>
                                </DrawerBody>
                            </AppointmentContent>
                            {showRecurringModal && (
                                <NewRecurringModal
                                    start={moment(start)}
                                    end={moment(end)}
                                    onSave={(recurring: any) => {
                                        setRecurring(recurring);
                                        setShowRecurringModal(false);
                                    }}
                                    title={'Recurring BusinessHours'}
                                    recurring={recurring}
                                    onClose={() => {
                                        setShowRecurringModal(false);
                                    }}
                                />
                            )}

                            {showConflictModal === true && (
                                <ConflictModal
                                    dates={conflictDates}
                                    title={'Customize Working Hours'}
                                    onContinue={() => {
                                        onClose();
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => {
                                        setShowConflictModal(false);
                                        onClose();
                                    }}
                                />
                            )}
                            {pickColor && (
                                <ColorPickerModel
                                    closeModal={() => setPickColor(false)}
                                    selectedColor={selectedColor}
                                    onSelect={(e: any) => {
                                        setSelectedColor(e);
                                        setPickColor(false);
                                    }}
                                />
                            )}

                            {deleteAll && workingHoursData?.recurring && (
                                <DeleteWorkingHoursModel
                                    deleteOne={
                                        workingHoursData !== undefined && workingHoursData._id
                                    }
                                    deleteAll={
                                        workingHoursData !== undefined &&
                                        workingHoursData?.recurring?.recurring_id
                                    }
                                    onClose={() => setDeleteAll(false)}
                                    getBack={() => onSave('delete')}
                                />
                            )}
                            {deleteSingle && workingHoursData && (
                                <DeleteModel
                                    id={id}
                                    onDelete={() => {
                                        setDeleteSingle(false);
                                    }}
                                    onClose={() => {
                                        setDeleteSingle(false);
                                    }}
                                    name={t('Customize Working Hours')}
                                    content={t(
                                        'Are you sure you want to delete this Customize Working Hours?'
                                    )}
                                    loading={loading}
                                    onClick={() => deleteSingleWorkingHours()}
                                />
                            )}
                        </Drawer>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
`;
const CloseButton = styled.div`
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.7rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    overflow-y: auto;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 2rem;
    height: calc(100% - 84px);
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const CalenderDate = styled.div`
    cursor: pointer;
`;
const RepeateButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    background: ${palette.dark};
    border-radius: 0.57rem;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    background-color: rgba(255, 255, 255);
    color: rgba(38, 52, 70);
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;
const RepeateIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
`;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;

const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;

export const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: #fff;
`;

export const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem /* 24px */;
    justify-content: space-between;
`;
const CustomButton = styled.button<any>`
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    padding: ${({ large, small }) =>
        large ? `1.4rem 1.8rem` : small ? '0.5rem 1rem' : '0.6rem 1.75rem'};
    color: ${({ color }) => (color ? 'black' : 'white')};
    background-color: ${({ bgtype }) => `${palette.secondary}`};
    box-shadow: ${({ hasShadow }) => (hasShadow ? `1px 1px 2px ${palette.dark}` : 'none')};
    margin: ${({ margin }) => `${margin}`};
    cursor: pointer;
    width: ${({ width }) => width};
    width: ${({ stretchMobile }) => (stretchMobile ? '100%' : 'min-content')};
    white-space: nowrap;
    ${media.lg`
		width: min-content
	`}
    transition: all ease 0.25s;
    &:hover {
        background: ${({ bgtype }) => `${palette.hover}`};
    }
    &:focus {
        border: none !important;
        outline: none;
    }
    &:active {
        border: none !important;
        outline: none;
    }
`;

export default CustomiseBusinessHoursDrawer;
