/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: AppointmentInvoice
 * Description: This component represents an invoice for a booked appointment. It displays details such as customer name, service name, duration, service provider name, booked time, payment status, tip, and total price. Users can also print the invoice.
 * Developed with React, styled-components for styling, and react-to-print for printing functionality.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the appointment invoice model is closed.
 * - height?: any - Height parameter to set for the model body.
 * - appointmentData: any - Data related to the appointment.
 *
 */

import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormButtons, FormFooter } from './NewAppointmentDrawer';
import { useReactToPrint } from 'react-to-print';
import { palette } from 'styled/common';
const AppointmentInvoice = ({
    onClose,
    height,
    appointmentData,
}: {
    onClose(): void;
    height?: any;
    appointmentData: any;
}) => {
    const { t }: any = useTranslation();
    const handlePrint = useReactToPrint({
        content: () => document.getElementById('appointment-invoice'),
    });
    return (
        <>
            <Container>
                <Row>
                    <ClientContent lg={12} id={'appointment-invoice'}>
                        <HeaderContainer>
                            <Header>{t('Appointment Invoice')}</Header>
                        </HeaderContainer>
                        <CustomersBody height={height}>
                            <SearchItemWrapper>
                                <Title>{t('Customer Name')}</Title>
                                <Title fw="light">
                                    {appointmentData?.appointment?.customer?.fullname}
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Service Name')}</Title>
                                <Title fw="light">
                                    {appointmentData?.appointment?.service?.name}
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Duration')}</Title>
                                <Title fw="light">
                                    {appointmentData?.appointment?.service?.duration} &nbsp;{'min'}
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Service Provider Name')}</Title>
                                <Title fw="light">
                                    {appointmentData?.appointment?.serviceProvider?.name}
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Booked Time')}</Title>
                                <Title fw="light" flexDirection={'block'}>
                                    <div>
                                        {moment(
                                            appointmentData?.appointment?.booked_fromt_iso
                                        ).format('YYYY-MM-DD')}
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        {moment(
                                            appointmentData?.appointment?.booked_fromt_iso
                                        ).format(' HH:mm')}
                                    </div>
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Payment Status')}</Title>
                                <Title fw="light">
                                    {appointmentData?.appointment?.payment
                                        ? appointmentData?.appointment?.payment
                                        : 'unpaid'}
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Tip')}</Title>
                                <Title fw="light">
                                    {appointmentData?.appointment?.payment
                                        ? appointmentData?.appointment?.tip
                                        : '0'}
                                </Title>
                            </SearchItemWrapper>
                            <SearchItemWrapper>
                                <Title>{t('Total Price')}</Title>
                                <Title fw="light">{appointmentData?.appointment?.total}</Title>
                            </SearchItemWrapper>
                        </CustomersBody>
                    </ClientContent>
                </Row>
                <FormFooter>
                    <FormButtons>
                        <Button
                            bgtype={'discard'}
                            ifClicked={() => onClose()}
                            width="10rem !important"
                            label={t('Cancel')}></Button>
                        <Button
                            bgtype={'secondary'}
                            ifClicked={handlePrint}
                            width="10rem !important"
                            label={t('Print')}></Button>
                    </FormButtons>
                </FormFooter>
            </Container>
        </>
    );
};
const ClientContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;

const CustomersBody = styled.div<any>`
    flex: 0 0 25rem;
    background-color: ${palette.white};
    margin-left: 0rem;
    overflow-y: auto;
    min-height: ${({ height }) => (height ? `58vh` : '71vh')};
`;

const SearchItemWrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.grey.lightest};
    padding: 12px 0;
    justify-content: space-between;
`;
const Title = styled.div<any>`
    display: ${({ flexDirection }) => (flexDirection ? flexDirection : 'flex')};
    justify-content: start;
    font-weight: ${({ fw }) => (fw ? fw : '400')};
`;
export default AppointmentInvoice;
